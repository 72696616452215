<template>
  <div class="timeline" :class="{ pause }">
    <ul>
      <li v-for="(n, i) in stepCount">
        <div
          v-if="i === currentStep"
          class="progress progressing"
          :style="`animation-duration: ${stepDuration}s`"
          @animationend="animationend"
        ></div>
        <div v-else-if="i < currentStep" class="progress full"></div>
        <div v-else class="progress none"></div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Timeline',
  props: {
    stepCount: {
      type: Number,
      required: true,
    },
    currentStep: {
      type: Number,
      default: 0,
    },
    stepDuration: {
      type: [String, Number],
      default: 5,
    },
    pause: {
      type: Boolean,
    },
  },
  emits: ['stepDone'],
  computed: {
    currentStepProgress() {
      return 50;
    },
  },
  methods: {
    animationend() {
      this.$emit('stepDone');
    },
    progress(step) {
      if (step === this.currentStep) {
        return this.currentStepProgress;
      } else if (step < this.currentStep) {
        return 100;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

@keyframes progressing {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.timeline {
  background-color: $grey80;
  padding: 16px 12px 12px 12px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;

    li {
      height: 2px;
      background-color: $grey20;
      flex-grow: 1;
      position: relative;

      &:not(:last-child) {
        margin-right: 1px;
      }
    }

    .progress {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-color: $white;

      &.none {
        width: 0;
      }

      &.full {
        width: 100%;
      }

      &.progressing {
        animation: progressing 1s linear forwards;
      }
    }
  }

  &.pause .progressing {
    animation-play-state: paused !important;
  }
}
</style>
