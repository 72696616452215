<template>
  <EmptyTimeline :heading="heading" :subheading="subheading">
    <template #links>
      <a
        v-if="hasMarketplace"
        class="link-main"
        href="#"
        @click.prevent="openSuccessTips"
      >
        {{ $t('DASHBOARD.EMPTY_SALES_CHART.LINK_MP_TIPS') }}
      </a>
      <a
        v-if="hasShops"
        class="link-main"
        href="#"
        @click.prevent="openSellingGuide"
      >
        {{ $t('DASHBOARD.EMPTY_SALES_CHART.LINK_SHOP_TIPS') }}
      </a>
    </template>
  </EmptyTimeline>
</template>

<script>
import EmptyTimeline from 'src/app/components/charts/timeline/EmptyTimeline.vue';
import { mapGetters } from 'vuex';
import localeService from 'src/app/commons/localeService/localeService';

import {
  articleKeys,
  getArticleByKey,
  getHref,
} from 'src/app/partnerarea/tipsAndTools/tipsAndToolsConfig';

export default {
  name: 'EmptySales',
  components: {
    EmptyTimeline,
  },
  data() {
    return {
      heading: 'DASHBOARD.EMPTY_SALES_CHART.HEADING',
      subheading: 'DASHBOARD.EMPTY_SALES_CHART.SUB_HEADING',
    };
  },
  computed: {
    ...mapGetters({
      hasShops: 'user/hasShops',
      hasMarketplace: 'user/hasMarketplace',
    }),
  },
  methods: {
    openSuccessTips() {
      this.openTipsAndToolsLink(articleKeys.SUCCESS_TIPS);
    },
    openSellingGuide() {
      this.openTipsAndToolsLink(articleKeys.SELLING_GUIDE);
    },
    openTipsAndToolsLink(articleKey) {
      const article = getArticleByKey(articleKey);
      const language = localeService.getLanguage();
      const domain = localeService.getSprdUrl();

      window.open(getHref(article, domain, language, SPRD.PLATFORM), '_blank');
    },
  },
};
</script>
