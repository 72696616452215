import { deepCopy } from '@/utils';
import publishingCoreService from '@/api/publishingCoreService/publishingCoreService';
import compositionHelper from '@/composition/compositionHelper';
import { isEqual } from '@/utils';
import compositionValidator from '@/api/validators/composition/compositionValidator';
import { PUBLISHING_CATEGORY } from '@/composition/compositionCategoryHelper';

export default {
  namespaced: true,
  state: {
    current: null,
    original: null,
  },
  getters: {
    dirty: (state) => !isEqual(state.current, state.original),
    isPublic: (state) =>
      state.current?.publishingDetails?.d2c?.mp ||
      state.current.publishingDetails.spreadShops.shops.length,
    shouldValidate: (state, getters) => getters.isPublic,
    defaultAppearanceGroup: (state) =>
      compositionHelper.getDefaultAppearanceGroup(state.current),
    hasMultipleDesigns: (state) => state.current.designs.length > 1,
    designDownloadLink: (state, getter, rootState) =>
      `/api/v1/users/${rootState.user.data.id}/design-downloads/${state.current.mainDesign.id}`,
  },
  mutations: {
    setComposition(state, { composition }) {
      state.current =
        compositionHelper.addEmptyDataStructureForDefaultMetaData(composition);
      state.original = deepCopy(state.current);
    },
    resetToOriginal(state) {
      state.current = deepCopy(state.original);
    },
    setName: (state, { name, language }) => {
      compositionHelper.getMetaDataPart({
        composition: state.current,
        part: 'name',
        language,
      }).value = name;
    },
    setDescription: (state, { description, language }) => {
      compositionHelper.getMetaDataPart({
        composition: state.current,
        part: 'description',
        language,
      }).value = description;
    },
    addTag: (state, { tag, language }) => {
      const tagsMetaData = compositionHelper.getMetaDataPart({
        composition: state.current,
        part: 'tags',
        language,
      });

      if (!tagsMetaData.values.includes(tag)) {
        tagsMetaData.values.push(tag);
      }
    },
    removeTag: (state, { tag, language }) => {
      const tagsMetaData = compositionHelper.getMetaDataPart({
        composition: state.current,
        part: 'tags',
        language,
      });
      tagsMetaData.values = tagsMetaData.values.filter((t) => t !== tag);
    },
    setTags: (state, { tags, language }) => {
      compositionHelper.getMetaDataPart({
        composition: state.current,
        part: 'tags',
        language,
      }).values = tags;
    },
    setMpPublishing: (state, mpState) => {
      state.current.publishingDetails.d2c.mp = mpState;
      state.current.publishingDetails.publish = true;
    },
    publishToShop: (state, shopId) => {
      if (
        state.current.publishingDetails.spreadShops.shops.some(
          (shop) => shop.id === parseInt(shopId),
        )
      ) {
        return;
      }
      state.current.publishingDetails.spreadShops.shops.push({
        id: parseInt(shopId),
      });
    },
    unpublishFromShop: (state, shopId) => {
      state.current.publishingDetails.spreadShops.shops =
        state.current.publishingDetails.spreadShops.shops.filter(
          (shop) => shop.id !== parseInt(shopId),
        );
    },
    setDefaultLanguage: (state, { language }) => {
      state.current.metaData.originLanguageCode = language;
      state.current = compositionHelper.addEmptyDataStructureForDefaultMetaData(
        state.current,
      );
    },
    setAutoTranslate: (state, { autoTranslate }) => {
      state.current.metaData.autoTranslate = autoTranslate;
    },
    toggleBackgroundColor: (state) => {
      state.current.properties.darkBackground =
        !state.current.properties.darkBackground;
    },
    setMpTransformerMode(state, mode) {
      if (state.current?.publishingDetails?.d2c?.mpTransformerMode) {
        state.current.publishingDetails.d2c.mpTransformerMode = mode;
      }
    },
    updateCompositionAfterProductSettingsUpdate(state, updatedComposition) {
      const propertiesToCopy = [
        'defaultValues',
        'sellableErrors',
        'sellables',
        'templateProducts',
        'assortmentRule',
        'properties',
      ];
      propertiesToCopy.forEach((property) => {
        state.current[property] = deepCopy(updatedComposition[property]);
        state.original[property] = deepCopy(updatedComposition[property]);
      });
    },
    setAttributes: (state, attributes) => {
      state.current.attributes = attributes;
    },
    editAttributes: (state, attributes) => {
      state.current.attributes = { ...state.current.attributes, ...attributes };
    },
  },
  actions: {
    fetchComposition: async ({ commit }, compositionId) => {
      const composition =
        await publishingCoreService.getComposition(compositionId);
      commit('setComposition', { composition });
    },
    updateComposition: async ({ commit, dispatch, state, getters }) => {
      let validation = {
        result: true,
      };
      if (getters.shouldValidate) {
        validation = compositionValidator.validate(state.current);
      }
      if (!validation.result) {
        return Promise.reject(validation);
      }
      const composition = await publishingCoreService.updateComposition(
        state.current,
      );
      commit('setComposition', { composition });
      commit('compositions/replaceComposition', composition, { root: true });
      dispatch('publishingCore/fetchPublishingIntents', null, { root: true });
    },
    updateCompositionProductSettings: async (
      { commit, state },
      { defaultHotspot, defaultAppearanceGroup, categories, labels } = {},
    ) => {
      const composition = await publishingCoreService.updateComposition({
        id: state.original.id,
        templateProducts: [
          {
            templateProduct: {
              defaultHotspot,
              appearanceRestriction: defaultAppearanceGroup
                ? {
                    includedAppearanceGroups: [defaultAppearanceGroup],
                  }
                : undefined,
            },
          },
        ],
        assortmentRule: compositionHelper.transformCategoryListToAssortmentRule(
          {
            categories,
            labelsForCategory: {
              [PUBLISHING_CATEGORY.APPAREL.id]: labels, // todo: refactor hardcoded category id
            },
          },
        ),
      });
      commit('updateCompositionAfterProductSettingsUpdate', composition);
    },
    publishToAllShops: ({ commit, rootState }) => {
      rootState.publishingCore.shops.forEach((shop) =>
        commit('publishToShop', shop.shopId),
      );
    },
    unpublishFromAllShops: ({ commit, rootState }) => {
      rootState.publishingCore.shops.forEach((shop) =>
        commit('unpublishFromShop', shop.shopId),
      );
    },
    setPublishedShops: ({ commit, state }, shopIds) => {
      const newShopIds = shopIds.map((shopId) => parseInt(shopId));
      state.current.publishingDetails.spreadShops.shops
        .filter((shop) => !newShopIds.includes(shop.id))
        .forEach((shop) => commit('unpublishFromShop', shop.id));

      newShopIds.forEach((shopId) => commit('publishToShop', shopId));
    },
  },
};
