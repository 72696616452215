<template>
  <div
    v-if="$store.state.shop.selectedCollection"
    class="collection-content-page"
  >
    <div v-if="$store.state.shop.collectionContent" class="collection-content">
      <div class="header">
        <div>
          <h1>
            {{ $t('POS.SHOP_SETTINGS.COLLECTIONS.CONTENT.HEADING') }}
          </h1>
          <p>
            {{
              $t('POS.SHOP_SETTINGS.COLLECTIONS.CONTENT.SELECTED_COUNT', {
                count: $store.state.shop.selectedCollection.ideaCount,
              })
            }}
          </p>
        </div>
        <div class="search-field">
          <input
            v-model="searchValue"
            type="text"
            :placeholder="$t('DESIGNS.LIST.PLACEHOLDER.SEARCH')"
            :class="{ filled: searchValue }"
            @keypress.enter="search()"
            @blur="search()"
          />
          <a v-if="searchValue" class="reset-link" @click="resetSearch()">
            <Icon icon="close" />
          </a>
          <a class="search-link">
            <Icon icon="lens" />
          </a>
        </div>
      </div>
      <div class="action-bar">
        <a
          class="link link-main"
          :disabled="allSelected"
          :class="{ disabled: allSelected }"
          @click="bulkSelection(true)"
          >{{ $t('GENERAL.SELECT_ALL') }}</a
        >
        <a
          class="link link-main"
          :disabled="noneSelected"
          :class="{ disabled: noneSelected }"
          @click="bulkSelection(false)"
          >{{ $t('GENERAL.DESELECT_ALL') }}</a
        >
        <div class="sorting">
          {{ $t('POS.SHOP_SETTINGS.COLLECTIONS.CONTENT.ACTIVE_FIRST_SORTING') }}
          <ToggleSwitch v-model="sortBySelected" />
        </div>
      </div>

      <ul class="designs">
        <li
          v-for="idea in $store.state.shop.collectionContent.list"
          :key="idea.id"
        >
          <DesignCard
            class="design-card"
            :idea="idea"
            :options="{ showToggle: true }"
            @on-toggle="
              changeIdeaSelection({ ideaId: idea.id, selected: !idea.selected })
            "
          />
        </li>
        <li
          v-for="i in [1, 2, 3, 4, 5]"
          :key="`placeholder-${i}`"
          class="placeholder-tile"
        ></li>
      </ul>

      <div
        v-if="!$store.state.shop.collectionContent.list.length"
        class="empty-placeholder"
      >
        <div v-if="query.queryString">
          <h1>{{ $t('GENERAL.SORRY') }}</h1>
          <p>{{ $t('DESIGNS.LIST.NO_SEARCH_RESULT') }}</p>
        </div>
        <div v-if="!query.queryString">
          <h1>
            {{ $t('POS.SHOP_SETTINGS.COLLECTIONS.CONTENT.EMPTY.HEADING') }}
          </h1>
          <button class="btn btn-primary btn-lg" @click="goToDesignsPage()">
            {{ $t('POS.SHOP_SETTINGS.COLLECTIONS.CONTENT.EMPTY.CTA') }}
          </button>
        </div>
      </div>

      <div class="footer">
        <Pagination
          :total="$store.state.shop.collectionContent.count"
          :page-size="pagination.pageSize"
          :current-page="pagination.currentPage"
          @page-change="onPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import DesignCard from './card/DesignCard.vue';
import Pagination from 'src/app/commons/pagination/Pagination.vue';
import productIdeaService from 'src/app/commons/api/productIdeaService/productIdeaService';

export default {
  name: 'CollectionContent',
  components: {
    ToggleSwitch,
    DesignCard,
    Pagination,
  },
  data() {
    return {
      searchValue: null,
      sortBySelected: true,
      pagination: {
        pageSize: 60,
        currentPage: 0,
      },
      query: {},
    };
  },
  computed: {
    allSelected() {
      return !this.$store.state.shop.collectionContent.list.some(
        (idea) => !idea.selected,
      );
    },
    noneSelected() {
      return !this.$store.state.shop.collectionContent.list.some(
        (idea) => idea.selected,
      );
    },
  },
  watch: {
    sortBySelected: function () {
      this.fetchIdeas();
    },
  },
  async created() {
    this.$store.commit('shop/setLivePreviewVisible', false);

    setTimeout(() => {
      this.fetchIdeas();
    }, 500);
  },
  methods: {
    ...mapMutations({
      changeIdeaSelection: 'shop/changeIdeaSelection',
    }),
    ...mapActions({
      loadIdeasOfPos: 'shop/loadIdeasOfPos',
      changeSelectionOfAllIdeas: 'shop/changeSelectionOfAllIdeas',
    }),
    async resetSearch() {
      this.searchValue = null;
      await this.search();
    },
    async search() {
      this.pagination.currentPage = 0;
      await this.fetchIdeas();
    },
    async fetchIdeas() {
      this.query = {
        queryString: this.searchValue,
        limit: this.pagination.pageSize,
        offset: this.pagination.pageSize * this.pagination.currentPage,
        collectionId: this.sortBySelected
          ? this.$store.state.shop.selectedCollection.id
          : null,
      };

      const collectionContent = await productIdeaService.getIdeasOfPointOfSale(
        this.$route.params.shopId,
        this.query,
      );
      await this.loadIdeasOfPos(collectionContent);
    },
    bulkSelection(selected) {
      this.changeSelectionOfAllIdeas({ selected });
    },
    async onPageChange(pageNumber) {
      this.pagination.currentPage = pageNumber;
      await this.fetchIdeas();
    },
    goToDesignsPage() {
      this.$router.push({ name: 'partnerarea.ideas' });
    },
  },
};
</script>

<style lang="scss">
#application.state-shop-area-collections-detail {
  .shopActionBar {
    display: none;
  }
  .shop-area .context .settings {
    padding: 0;
    border: none;
  }
}
</style>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.collection-content-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $grey0;
  z-index: 2;
}

.collection-content {
  padding: 20px;
  height: 100%;

  .designs {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;
      width: 50%;

      @media (min-width: 1060px) {
        width: 33.33%;
      }
      @media (min-width: 1240px) {
        width: 25%;
      }
      @media (min-width: 1460px) {
        width: 20%;
      }
      @media (min-width: 1640px) {
        width: 16.66%;
      }

      .design-card {
        width: 100%;
      }
    }
  }

  .empty-placeholder {
    text-align: center;
    margin-top: 100px;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h1,
    p {
      margin: 0;
    }
  }

  .action-bar {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    a {
      margin-right: 10px;
    }

    .sorting {
      margin-left: auto;
      display: flex;
      align-items: center;

      .sprd-toggle-switch {
        margin-left: 10px;
      }
    }
  }

  .search-field {
    margin-left: auto;
  }

  .footer {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 80px;
  }
}

.bottom-toolbar {
  align-items: stretch;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  z-index: 2;

  .btn {
    margin: 0 5px;
    padding: 0 20px;
  }
}
</style>
