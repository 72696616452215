<template>
  <div class="pagination">
    <ul v-if="pageCount > 1">
      <li :class="{ disabled: currentPage <= 0 }">
        <button type="button" class="text-btn" @click="previousPage()">
          <Icon icon="left" />
        </button>
      </li>
      <li v-if="currentPageSetNumber > 0">
        <button type="button" class="text-btn" @click="$emit('pageChange', 0)">
          1
        </button>
      </li>
      <li v-if="currentPageSetNumber > 0">
        <button type="button" class="text-btn" @click="toPreviousPageSet()">
          ...
        </button>
      </li>
      <li
        v-for="page in pageSet"
        :key="page.id"
        :class="{ active: page.id === currentPage }"
      >
        <button
          type="button"
          class="text-btn"
          @click="$emit('pageChange', page.id)"
        >
          {{ page.id + 1 }}
        </button>
      </li>
      <li v-if="currentPageSetNumber < pageSetCount - 1">
        <button type="button" class="text-btn" @click="toNextPageSet()">
          ...
        </button>
      </li>
      <li v-if="currentPageSetNumber < pageSetCount - 1">
        <button
          type="button"
          class="text-btn"
          @click="$emit('pageChange', pageCount - 1)"
        >
          {{ pageCount }}
        </button>
      </li>
      <li :class="{ disabled: currentPage >= pageCount - 1 }">
        <button type="button" class="text-btn" @click="nextPage()">
          <Icon icon="right" />
        </button>
      </li>
    </ul>
    <div v-if="pageCount > 10" class="pagination-direct">
      <input
        v-model="manualPageNumber"
        type="number"
        @blur="verifyManualPageNumber"
        @keyup.enter="goToPage"
      />
      <button
        type="button"
        class="text-btn"
        :disabled="parseInt(manualPageNumber) === currentPageNumber"
        @click="goToPage"
      >
        {{ $t('GENERAL.PAGINATION.GO') }}
      </button>
    </div>
  </div>
</template>

<script>
import { removeNullValues } from 'src/app/commons/utils';

export default {
  name: 'Pagination',
  props: {
    total: {},
    pageSize: {
      default: 10,
    },
    currentPage: {
      default: 0,
    },
    pageSetSize: {
      default: 3,
    },
    addStateParam: {
      type: Boolean,
    },
  },
  emits: ['pageChange'],
  data() {
    return {
      manualPageNumber: null,
    };
  },
  computed: {
    smartPageSetSize() {
      // do not show 1 2 3 ... 4 when the pageSetSize is 1 less than the pageCount
      return this.pageCount <= this.pageSetSize + 1
        ? this.pageCount
        : this.pageSetSize;
    },
    pageSetCount() {
      return Math.ceil(this.pageCount / this.smartPageSetSize);
    },
    currentPageSetNumber() {
      return Math.floor(this.currentPage / this.smartPageSetSize);
    },
    pageSet() {
      // show the full pageSetSize for the last set too
      const start = this.currentPageSetNumber * this.smartPageSetSize;
      const offset =
        start + this.smartPageSetSize > this.pageCount
          ? this.pageCount - this.smartPageSetSize
          : start;

      return Array.from({ length: this.smartPageSetSize }, (_, i) => ({
        id: i + offset,
      }));
    },
    pageCount() {
      return Math.ceil(this.total / this.pageSize);
    },
    currentPageNumber() {
      return parseInt(this.currentPage) + 1;
    },
  },
  watch: {
    currentPageNumber: {
      handler(newValue) {
        this.manualPageNumber = newValue;

        this.addPageNumberToStateParams();
      },
      immediate: true,
    },
  },
  methods: {
    toNextPageSet() {
      this.$emit(
        'pageChange',
        (this.currentPageSetNumber + 1) * this.smartPageSetSize,
      );
    },
    toPreviousPageSet() {
      this.$emit(
        'pageChange',
        this.currentPageSetNumber * this.smartPageSetSize - 1,
      );
    },
    nextPage() {
      if (this.currentPage < this.pageCount - 1) {
        this.$emit('pageChange', this.currentPage + 1);
      }
    },
    previousPage() {
      if (this.currentPage > 0) {
        this.$emit('pageChange', this.currentPage - 1);
      }
    },
    goToPage() {
      this.verifyManualPageNumber();

      if (this.manualPageNumber === this.currentPageNumber) {
        return;
      }
      this.$emit('pageChange', this.manualPageNumber - 1);
    },
    verifyManualPageNumber() {
      this.manualPageNumber = parseInt(this.manualPageNumber);

      if (this.manualPageNumber > this.pageCount) {
        this.manualPageNumber = this.pageCount;
      } else if (this.manualPageNumber <= 0) {
        this.manualPageNumber = 1;
      }
    },
    addPageNumberToStateParams() {
      if (this.addStateParam) {
        this.$router.replace({
          query: removeNullValues({
            ...this.$route.query,
            page: this.currentPageNumber > 1 ? this.currentPageNumber : null,
          }),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

ul {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.text-btn {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;

  & > .icon {
    width: 20px;
    height: 20px;
  }

  &:hover {
    color: $pa-color-main;
  }
}

.active .text-btn {
  z-index: 2 !important;
  color: $pa-color-main;
  border: 1px solid $pa-color-main;
  font-weight: bold;
}

.disabled .text-btn {
  color: $sprd-color-light-grey;
}

.pagination-direct {
  display: flex;
  align-items: center;
  margin-left: 8px;

  input {
    width: 60px;
    margin-right: 8px;
    padding-right: 0;
  }
}
</style>
