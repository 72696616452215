<template>
  <button v-click-outside="reset" :class="{ confirm }" @click="onClick">
    <slot :confirm="confirm"></slot>
  </button>
</template>

<script>
export default {
  name: 'DoubleConfirmButton',
  emits: ['onConfirm'],
  data() {
    return {
      confirm: false,
    };
  },
  methods: {
    onClick() {
      if (this.confirm) {
        this.$emit('onConfirm');
        this.confirm = false;
      } else {
        this.confirm = true;
      }
    },
    reset() {
      this.confirm = false;
    },
  },
};
</script>
