<template>
  <div class="dialog-size-lg">
    <h2>{{ $t('POS.SHOP_SETTINGS.IMAGE_SAMPLES_HEADING') }}</h2>
    <div class="stock-imgs">
      <div
        v-for="image in data.images"
        :key="image.filename"
        class="stock-img"
        :class="{
          stockImgFew: !data.manyImagesLayout,
          stockImgMany: data.manyImagesLayout,
        }"
        @click="selected(image)"
      >
        <img
          :class="{ selected: image == currentImage }"
          :src="image.url"
          alt=""
        />
      </div>
    </div>
    <div v-if="data.showUnsplashHint">
      {{ $t('POS.SHOP_SETTINGS.STARTPAGE.TEASER.IMAGE.SAMPLES_LINK') }}
      <a class="link-main" target="_blank" href="https://unsplash.com/"
        >unsplash</a
      >
    </div>
    <div class="modal-footer">
      <button
        id="confirm-dialog-cancel"
        class="btn btn-light btn-lg icon-btn"
        @click="dismiss"
      >
        <Icon icon="close" />
        {{ $t('GENERAL.CANCEL') }}
      </button>
      <button
        id="confirm-dialog-confirm"
        class="btn btn-primary btn-lg icon-btn"
        @click="confirm"
      >
        <Icon icon="savedisk" />
        {{ $t('GENERAL.SAVE') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageSelectorDialog',
  props: {
    modalInstance: { type: Object, required: true },
    data: { type: Object, required: true },
  },
  data() {
    return {
      currentImage: this.data.images.find((img) => img.active),
    };
  },
  methods: {
    dismiss() {
      this.modalInstance.dismiss();
    },
    confirm() {
      this.modalInstance.close(this.currentImage);
    },
    selected(image) {
      this.currentImage = image;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

h2 {
  margin: 0 0 20px;
}

.stock-imgs {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
}

.stock-img {
  width: calc(33.3333% - 20px);
  margin: 0 0 20px 20px;
  position: relative;

  img {
    width: 100%;
    display: block;
    border: 2px solid transparent;
  }
}

.selected {
  box-shadow: 0 0 0 1px $pa-color-main;
  border: 1px solid transparent;
  background-clip: padding-box;
}

.stockImgFew {
  width: calc(33.3333% - 20px);
}

.stockImgMany {
  width: calc(25% - 20px);
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.btn {
  padding: 0.25em 20px;

  & + & {
    margin-left: 1em;
  }
}
</style>
