<template>
  <div class="ai-design-generator-assortment">
    <h2>{{ $t('AI_DESIGN_GENERATOR.RESULT.HEADING') }}</h2>
    <p>{{ $t('AI_DESIGN_GENERATOR.RESULT.SUBHEADING') }}</p>
    <div class="created-idea-preview">
      <ul v-if="productsToDisplay.length" class="products-preview">
        <li v-for="product in productsToDisplay">
          <transition name="fade" mode="out-in">
            <img
              :key="product.filterId"
              :src="`${product.primarySellable.defaultImageUrl},width=300`"
              alt=""
            />
          </transition>
        </li>
      </ul>
      <div v-if="metaData" class="meta-data">
        <p class="name">{{ metaData.name }}</p>
        <p>{{ metaData.description }}</p>
        <ul class="tag-list">
          <li v-for="tag in metaData.tags">{{ tag }}</li>
        </ul>
      </div>
    </div>
    <button class="btn btn-primary btn-xl" @click="$emit('onSubmit')">
      {{ $t('AI_DESIGN_GENERATOR.RESULT.DONE') }}
    </button>
    <hr />
    <FeatureFeedback feature-name="AI Generator" />
  </div>
</template>

<script>
import sample from 'lodash.sample';
import samplesize from 'lodash.samplesize';
import FeatureFeedback from 'src/app/components/featureFeedback/FeatureFeedback.vue';

export default {
  name: 'AiDesignGeneratorAssortment',
  components: {
    FeatureFeedback,
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    metaData: {
      type: Object,
    },
  },
  data() {
    return {
      productIdsToDisplay: [],
      indexesToExchange: [],
      interval: null,
    };
  },
  computed: {
    productsToDisplay() {
      if (!this.productIdsToDisplay?.length) {
        return [];
      }

      return this.productIdsToDisplay.map((pId) =>
        this.products.find((p) => p.primarySellable.productType === pId),
      );
    },
    productIds() {
      return this.products.map((p) => p.primarySellable.productType);
    },
    productIdsNotDisplayed() {
      return this.productIds.filter(
        (pId) => !this.productIdsToDisplay.includes(pId),
      );
    },
  },
  created() {
    this.chooseInitialDisplayedProducts();
  },
  unmounted() {
    clearInterval(this.interval);
  },
  methods: {
    chooseInitialDisplayedProducts() {
      const displayNrOfProductTypes = 1;

      this.productIdsToDisplay = samplesize(
        this.productIds,
        displayNrOfProductTypes,
      );

      this.interval = setInterval(() => {
        if (!this.indexesToExchange.length) {
          this.indexesToExchange = [...Array(displayNrOfProductTypes).keys()];
        }
        const indexToExchange = sample(this.indexesToExchange);
        this.indexesToExchange = this.indexesToExchange.filter(
          (index) => index !== indexToExchange,
        );

        const productIdToInclude = sample(this.productIdsNotDisplayed);

        this.productIdsToDisplay[indexToExchange] = productIdToInclude;
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/constants';

.ai-design-generator-assortment {
  & > h2 {
    margin: 0 0 8px 0;
  }

  & > p {
    margin: 0 0 24px 0;
    color: $grey65;
    max-width: 70%;
  }
}

.created-idea-preview {
  display: flex;
}

ul.products-preview {
  list-style: none;
  padding: 0;
  width: 40%;
  max-width: 400px;
  margin: 0 24px 0 0;
  flex-shrink: 0;

  li {
    background-color: $grey5;
    border: 1px solid $grey10;
    border-radius: $border-radius-medium;
    padding: 12px;
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      aspect-ratio: 1;
    }
  }
}

.meta-data {
  p {
    margin: 0 0 16px 0;
    color: $grey65;

    &.name {
      @extend .text-xl;
      font-weight: bold;
    }
  }
}

button {
  margin: 16px 0 0 0;
  padding: 12px 24px;
}

hr {
  margin: 24px 0 24px -40px;
  width: calc(100% + 80px);
}
</style>
