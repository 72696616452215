<template>
  <div class="shopTracking">
    <h4 v-if="toggleLabel" class="label">{{ $t(toggleLabel) }}</h4>
    <p v-if="paragraphText" class="paragraph">
      {{ $t(paragraphText) }}
    </p>

    <div class="input-wrapper">
      <ValidLabelInput
        :validators="[validateInput]"
        :label="$t(inputLabel)"
        :model-value="trackingIdValue"
        @update:model-value="onInput"
      />
      <div v-if="toggleValue" class="clear">
        <button class="link icon-btn" @click="onInput('')">
          <Icon icon="close" />
        </button>
      </div>
    </div>
    <p v-if="additionalHelp" class="additional-help">
      {{ $t(additionalHelp) }}
    </p>
    <HelpLink
      v-if="helpLinkTarget"
      class="trackingHelpLink"
      :href="helpLinkTarget"
    />
    <SmartFaqLink
      v-if="smartFaqLink"
      class="trackingHelpLink"
      :link="smartFaqLink"
    />
  </div>
</template>

<script>
import ValidLabelInput from 'src/app/partnerarea/pos/shop/common/ValidLabelInput.vue';

import HelpLink from '@/helpLink/HelpLink.vue';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';

export default {
  name: 'ShopTracking',
  components: {
    HelpLink,
    SmartFaqLink,
    ValidLabelInput,
  },
  props: {
    toggleValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    trackingIdValue: {
      type: String,
      required: false,
      default: null,
    },
    toggleLabel: {
      type: String,
      required: true,
    },
    paragraphText: {
      type: String,
      required: false,
      default: null,
    },
    additionalHelp: {
      type: String,
      required: false,
      default: null,
    },
    inputLabel: {
      type: String,
      required: true,
    },
    helpLinkTarget: {
      type: String,
      required: false,
      default: null,
    },
    smartFaqLink: {
      type: Object,
    },
    regex: {
      type: String,
      required: true,
    },
  },
  emits: ['trackingIdInput', 'toggleInput'],
  methods: {
    validateInput(code) {
      if (code.length === 0) {
        return;
      }
      if (!code.match(this.regex)) {
        throw new Error(this.$t('SA.messages.warning.invalid'));
      }
    },
    onInput(val) {
      this.$emit('trackingIdInput', val);
      this.$emit('toggleInput', !!val?.length);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
.shopTracking {
  margin-bottom: 24px;
}

.label {
  margin: 0 0 8px 0;
}

.trackingHelpLink {
  margin: 8px 0 16px 0;
}

.input-wrapper {
  position: relative;
  margin: 16px 0 0 0;
}

.paragraph {
  margin: 0 0 8px 0;
}

.clear {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.additional-help {
  @extend .text-sm;
  margin: 8px 0;
  color: $grey65;
}
</style>
