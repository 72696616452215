<template>
  <div>
    <LegalLabelInput
      v-model="aboutPage.caption"
      class="sprd-shop-input"
      maxlength="100"
      :label="$t('POS.SHOP_SETTINGS.ABOUT_US.HEADLINE')"
    />
    <LegalLabelInput
      v-model="aboutPage.subcaption"
      class="sprd-shop-input"
      type="textarea"
      maxlength="300"
      :label="$t('POS.SHOP_SETTINGS.ABOUT_US.SUBLINE')"
      :placeholder="$t('POS.SHOP_SETTINGS.ABOUT_US.SUBLINE_PLACEHOLDER')"
    />
    <LegalLabelInput
      v-model="aboutPage.text"
      class="sprd-shop-input"
      type="textarea"
      maxlength="5000"
      :label="$t('POS.SHOP_SETTINGS.ABOUT_US.DESCRIPTION')"
      :placeholder="$t('POS.SHOP_SETTINGS.ABOUT_US.DESCRIPTION_PLACEHOLDER')"
    />
    <LegalLabelInput
      v-model="aboutPage.buttonCaption"
      class="sprd-shop-input"
      maxlength="50"
      :label="$t('POS.SHOP_SETTINGS.ABOUT_US.CTA')"
    />
    <Dropdown
      :value="currentTarget"
      :items="aboutPage.buttonTargets"
      :item-key="(option) => option.name"
      :item-to-string="(option) => option.name"
      :label="$t('POS.SHOP_SETTINGS.ABOUT_US.CTA_TARGET')"
      @change="onChangeButtonTarget"
    />
    <MediaSelector
      :media="media"
      :image="image"
      :max-images="4"
      :on-change="onMediaChange"
    />
  </div>
</template>

<script>
import LegalLabelInput from '../common/LegalLabelInput.vue';
import Dropdown from '@/Dropdown/Dropdown.vue';
import MediaSelector from '../common/mediaSelector/MediaSelector.vue';
import { NO_TYPE } from '../common/mediaSelector/mediaTypes';
import { aboutPage } from '../imageAttributes';
import { AboutPageData } from 'src/app/partnerarea/pos/shop/shopSavable/AboutPageData';

export default {
  name: 'AboutPage',
  components: {
    LegalLabelInput,
    Dropdown,
    MediaSelector,
  },
  mixins: [AboutPageData],
  data() {
    return {
      image: aboutPage,
    };
  },
  computed: {
    currentTarget() {
      const { buttonMode, buttonTarget, buttonTargets } = this.aboutPage;

      if (!buttonTargets) {
        return null;
      }

      return (
        buttonTargets.find(
          (target) =>
            target.buttonTargetMode === buttonMode &&
            target.buttonTarget === buttonTarget,
        ) || buttonTargets[0]
      );
    },
    media() {
      return this.aboutPage.media || [];
    },
  },
  created() {
    this.$store.commit('shop/setLivePreview', { path: 'about' });
  },
  methods: {
    onMediaChange({ media, mediaType, previewImage, key }) {
      if (mediaType === NO_TYPE) {
        // delete media element
        this.aboutPage.media.splice(key, 1);
        return;
      }

      if (
        this.aboutPage.media.length &&
        this.aboutPage.media[0].mediaType !== mediaType
      ) {
        // change type, currently only one type supported
        this.aboutPage.media = [];
      }

      const medium = { media, mediaType, previewImage };
      if (this.aboutPage.media[key]) {
        // update media element
        this.aboutPage.media.splice(key, 1, medium);
        return;
      }

      // add media element
      this.aboutPage.media.push(medium);
    },
    onChangeButtonTarget({ buttonTarget, buttonTargetMode }) {
      this.aboutPage.buttonMode = buttonTargetMode;
      this.aboutPage.buttonTarget = buttonTarget;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.sortable-image) .active-img {
  width: 6.64em;
  height: 4em;
}
</style>
