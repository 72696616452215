<template>
  <div class="step" :class="{ invertedStyle, fullCenterStyle }">
    <div v-if="invertedStyle" class="logo">
      <img
        v-if="invertedStyle"
        :src="`/images/${
          partnerType === 'SHOP'
            ? 'spreadshop-logo-white.svg'
            : 'spreadshirt-logo-white.svg'
        }`"
      />
    </div>
    <div
      v-if="image"
      class="content-img"
      :class="{ 'design-checked-background': tiledImageBackground }"
    >
      <img :src="image" alt="heading" />
    </div>
    <div class="intro">
      <h2 v-if="beforeHeading" class="above-heading">
        {{ $t(beforeHeading) }}
      </h2>
      <h1 v-if="heading">{{ $t(heading) }}</h1>
      <h2 v-if="afterHeading" class="below-heading">
        {{ $t(afterHeading) }}
      </h2>
      <p v-if="subHeading">{{ $t(subHeading) }}</p>
      <p v-if="invertedStyle" class="hashtag">{{ hashtag }}</p>
      <button
        v-if="restart"
        class="btn btn-block restart"
        @click="$emit('onRestart')"
      >
        {{ $t('RECAP.THANK_YOU.SUCCESS.RESTART') }}
      </button>
    </div>
    <div v-if="data" class="data" :class="{ grow: !image }">
      <ul>
        <li v-for="entry in data">
          <p class="text-grey">{{ $t(entry.label) }}</p>
          <h1 :class="entry.valueClass">{{ entry.value }}</h1>
        </li>
      </ul>
      <img v-if="illustration" :src="illustration" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Step',
  components: {},
  props: {
    image: {
      type: String,
    },
    tiledImageBackground: {
      type: Boolean,
    },
    beforeHeading: {
      type: String,
    },
    heading: {
      type: String,
    },
    afterHeading: {
      type: String,
    },
    subHeading: {
      type: String,
    },
    data: {
      type: Array,
    },
    illustration: {
      type: String,
    },
    invertedStyle: {
      type: Boolean,
    },
    fullCenterStyle: {
      type: Boolean,
    },
    restart: {
      type: Boolean,
    },
    hashtag: {
      type: String,
    },
  },
  emits: ['onRestart'],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      partnerType: 'user/partnerType',
    }),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/colors';

.step {
  display: flex;
  flex-direction: column;

  h1 {
    margin: 0;
    font-size: 36px;
  }

  h2 {
    @extend .heading1;
    margin: 0;
    font-size: 20px;
  }

  p {
    margin: 0;
  }

  .hashtag {
    color: $white !important;
  }

  .logo {
    background-color: $grey80;
    padding: 12px 12px 0 12px;

    img {
      height: 24px;
    }
  }

  .content-img {
    flex-grow: 1;
    position: relative;

    img {
      height: 100%;
      width: 100%;
      padding: 24px;
      position: absolute;
      object-fit: contain;
    }
  }

  .intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $white;
    padding: 24px 12px;

    p {
      margin: 12px 0 0 0;
      color: $grey65;
    }

    .hashtag {
      margin-top: 16px;
    }

    h2 {
      &.above-heading {
        margin-bottom: 8px;
      }

      &.below-heading {
        margin-top: 8px;
      }
    }

    .restart {
      border: none;
      border-radius: $border-radius-medium;
      color: $grey80;
      padding-top: 12px;
      padding-bottom: 12px;
      margin-top: 16px;

      &:hover {
        background-color: $grey5;
      }
    }
  }

  .data {
    background-color: $grey5;
    padding: 12px;
    position: relative;

    &.grow {
      flex-grow: 1;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        &:not(:last-child) {
          margin-bottom: 24px;
        }

        p {
          margin-bottom: 8px;
          color: $grey80;
        }
      }
    }

    img {
      position: absolute;
      bottom: 12px;
      right: 12px;
      height: 90px;
      width: 90xpx;
    }

    p {
      text-transform: uppercase;
    }
  }

  &.invertedStyle {
    .intro {
      background-color: $grey80;
      padding-top: 12px;

      h1,
      h2 {
        color: $white;
      }

      p {
        color: $white;
      }
    }

    .data {
      background-color: $white;

      h1 {
        color: $grey80;
      }
    }
  }

  &.fullCenterStyle {
    .intro {
      justify-content: center;
      flex-grow: 1;
      position: relative;

      img {
        position: absolute;
        top: 12px;
        left: 12px;
      }
    }

    .data {
      display: none;
    }
  }
}
</style>
