export const COMPOSITION_POS_STATES = {
  PUBLISHED: {
    key: 'PUBLISHED',
    name: 'PUBLISHING.STATUS.PUBLISHED.NAME',
    description: 'PUBLISHING.STATUS.PUBLISHED.DESCRIPTION',
    badgeType: 'success',
    color: '#3C854A',
  },
  PENDING: {
    key: 'PENDING',
    name: 'PUBLISHING.STATUS.PENDING.NAME',
    description: 'PUBLISHING.STATUS.PENDING.DESCRIPTION',
    badgeType: 'warning',
    color: '#BD8F2B',
  },
  UNPUBLISHED: {
    key: 'UNPUBLISHED',
    name: 'PUBLISHING.STATUS.UNPUBLISHED.NAME',
    description: 'PUBLISHING.STATUS.UNPUBLISHED.DESCRIPTION',
    badgeType: 'neutral',
    color: '#A3A5A7',
  },
  REJECTED: {
    key: 'REJECTED',
    name: 'PUBLISHING.STATUS.REJECTED.NAME',
    description: 'PUBLISHING.STATUS.REJECTED.DESCRIPTION',
    badgeType: 'error',
    color: '#9B4636',
  },
};

export const COMPOSITION_POS_TYPES = {
  MP: {
    key: 'MP',
    icon: 'sprd-heart',
    getSelectionState: (composition) => composition.publishingDetails.d2c.mp,
    getPublishingStatus: (composition) =>
      composition.publishingDetails.d2c.publishingStatus,
    getAccreditationStatus: (composition) =>
      composition.publishingDetails.d2c.mpAccreditation?.status,
  },
  SHOP: {
    key: 'SHOP',
    icon: 'shop',
    getSelectionState: (composition) =>
      composition.publishingDetails.spreadShops.shops.length > 0,
    getPublishingStatus: (composition) =>
      getCombinedShopPublishingStatus(composition),
    getAccreditationStatus: (composition) =>
      composition.publishingDetails.spreadShops.accreditation?.status,
  },
};

// if at least one shop is online we consider shop in general as online
export function getCombinedShopPublishingStatus(composition) {
  return composition.publishingDetails.spreadShops.shops.some(
    (shop) => shop.publishingStatus === 'ONLINE',
  )
    ? 'ONLINE'
    : 'OFFLINE';
}

export function getCompositionPosStatus({
  publishingStatus,
  accreditationStatus,
  selectionState,
}) {
  if (publishingStatus === 'ONLINE' && selectionState) {
    return COMPOSITION_POS_STATES.PUBLISHED;
  } else if (accreditationStatus === 'REJECTED') {
    return COMPOSITION_POS_STATES.REJECTED;
  } else if (!selectionState) {
    return COMPOSITION_POS_STATES.UNPUBLISHED;
  } else {
    return COMPOSITION_POS_STATES.PENDING;
  }
}

export function getAllCompositionPosStates({
  composition,
  relevantPosTypes = Object.values(COMPOSITION_POS_TYPES),
  relevantPosStates = Object.values(COMPOSITION_POS_STATES),
}) {
  return relevantPosTypes
    .map((posType) => ({
      posType: posType,
      status: getCompositionPosStatus({
        selectionState: posType.getSelectionState(composition),
        publishingStatus: posType.getPublishingStatus(composition),
        accreditationStatus: posType.getAccreditationStatus(composition),
      }),
    }))
    .filter((posState) =>
      relevantPosStates.some(
        (relevantPosState) => relevantPosState.key === posState.status.key,
      ),
    );
}

export function getAllCompositionPosStatesCombined(options) {
  const posStates = getAllCompositionPosStates(options);

  const posStatesCombined = {};

  posStates.forEach((posState) => {
    if (!posStatesCombined[posState.status.key]) {
      posStatesCombined[posState.status.key] = {
        posTypes: [],
        status: posState.status,
      };
    }

    posStatesCombined[posState.status.key].posTypes.push(posState.posType);
  });
  return posStatesCombined;
}

export function getCompositionPosStateForPosType({ composition, posType }) {
  return {
    posType: posType,
    status: getCompositionPosStatus({
      selectionState: posType.getSelectionState(composition),
      publishingStatus: posType.getPublishingStatus(composition),
      accreditationStatus: posType.getAccreditationStatus(composition),
    }),
  };
}

export function createInitialPosStateCounts() {
  const posStateCounts = Object.keys(COMPOSITION_POS_STATES).reduce(
    (acc, key) => {
      acc[key] = 0;
      return acc;
    },
    {},
  );
  return {
    total: 0,
    stateCounts: posStateCounts,
  };
}
