<template>
  <div class="condensed-preview-image">
    <div
      v-for="image in images"
      :key="image.href"
      class="design design-checked-background"
      :class="{ dark: darkBackground }"
      :style="{
        height: height + 'px',
        width: width + 'px',
      }"
    >
      <SprdImage :src="`${image.href}`" alt="Design Image" />
    </div>
    <div class="overlay">
      <div>
        <div class="overlay-content">
          <button class="overlay-button" @click="openImageDialog">
            <Icon icon="lens" />
          </button>
          <button class="overlay-button" @click="toggleBackgroundColor">
            <Icon icon="background-color" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SprdImage from '@/image/SprdImage.vue';
import ideaHelper from '@/ideaHelper/ideaHelper';
import { mapMutations, mapGetters } from 'vuex';
import dialogService from '@/dialogs/wrapper/dialogService';
import IdeaImageDialog from 'src/app/partnerarea/idea/previewImage/IdeaImageDialog.vue';

export default {
  name: 'CondensedIdeaPreviewImage',
  components: {
    SprdImage,
  },
  props: {
    idea: {
      required: true,
      type: Object,
    },
    width: {
      default: 90,
      type: Number,
    },
    height: {
      default: 90,
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      multipleDesigns: 'idea/multipleDesigns',
      designs: 'idea/designs',
    }),
    images() {
      let images;
      if (this.multipleDesigns) {
        images = this.designs
          ? this.designs.map((design) => design.preview)
          : [];
      } else {
        return this.idea.resources
          ? this.idea.resources.filter((r) => r.type !== 'default')
          : [];
      }
      return images;
    },
    darkBackground() {
      return ideaHelper.hasDarkBackground(this.idea);
    },
  },
  methods: {
    ...mapMutations({
      toggleBackgroundColor: 'idea/toggleBackgroundColor',
    }),
    openImageDialog() {
      dialogService.openDialog(IdeaImageDialog, this.idea);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';

.condensed-preview-image {
  display: flex;
  position: relative;

  &:hover {
    .overlay {
      display: block;
    }
  }

  .design {
    padding: 8px;

    & + .design {
      margin-left: 8px;
    }

    .dark {
      color: $grey20;
    }

    :deep(img) {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .overlay {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;

    &.display-overlay {
      display: block;
    }

    & > div {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;

      .overlay-content {
        display: flex;
        align-items: center;

        .overlay-button {
          background-color: white;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $pa-color-main;
          border: 1px solid $pa-color-main;

          &:first-child {
            border-radius: 8px 0 0 8px;
          }

          &:last-child {
            border-radius: 0 $border-radius-medium $border-radius-medium 0;
          }

          & + .overlay-button {
            border-left: none;
          }

          .icon {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}
</style>
