import localeService from '@/localeService/localeService';
import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';
import apiClient from '@/api/client/apiClient';
import userService from '@/api/userService/userService';
import applicationInitialization from 'src/app/config/applicationInitialization';
import pdvService from '@/partnerDataValidation/service/pdvService';
import authService from '@/api/authService/authService';
import MFA_STATES from '@/states/mfaStates';

export default {
  namespaced: true,
  state: {
    isUserLoggingOut: false,
    pointsOfSale: [],
    shops: [],
    publishingDetails: [],
    locale: '',
    data: {},
    productTypes: {},
    address: {
      country: {},
      person: {},
    },
    profileImageTimestamp: Date.now(),
    mfaStatus: MFA_STATES.LOADING,
  },
  getters: {
    shops: (state) =>
      state.publishingDetails.filter((pos) => pos.pointOfSale.type === 'SHOP'),
    hasShops: (state, getters) => getters.shops && getters.shops.length,
    hasInternationalShop: (state) =>
      state.shops.some((shop) => shop.international),
    youtubeShops: (state) =>
      state.shops.filter((shop) => shop.youtubeConnected),
    hasYoutubeShops: (state, getters) =>
      getters.youtubeShops && getters.youtubeShops.length,
    marketplace: (state) =>
      state.publishingDetails.find(
        (pos) => pos.pointOfSale.type === 'MARKETPLACE',
      ),
    hasMarketplace: (state, getters, rootState, rootGetters) =>
      rootGetters['settings/getSetting']('MP_ENABLED'),
    partnerType: (state, getters, rootState, rootGetters) => {
      const shop = !!getters.shops.length;
      const mp = rootGetters['settings/getSetting']('MP_ENABLED');
      if (mp && shop) {
        return 'BOTH';
      } else if (mp) {
        return 'MP';
      } else {
        return 'SHOP';
      }
    },
    sprdLocale: (state) => localeService.convertToSprdLocale(state.locale),
    language: (state) => (state.locale ? state.locale.split('_')[0] : null),
    isNonInternationalShopOnlyUser: (state, getters) =>
      !getters.hasMarketplace && !getters.hasInternationalShop,
    hasPublishRequirements: (state, getters, rootState) =>
      state.data.acceptTermsAndConditions &&
      rootState.account.originalData?.user?.city &&
      rootState.account.pdvState.softcheck.state !==
        pdvService.SOFTCHECK.FAILED,
    currency: (state, getters, rootState, rootGetters) =>
      rootGetters['platform/currencyById'](state.data.currency.id),
    country: (state, getters, rootState, rootGetters) =>
      rootGetters['platform/countryById'](state.data.country.id),
    profileImage: (state) =>
      `${state.data.resources[0].href}?v=${state.profileImageTimestamp}`,
  },
  mutations: {
    setPointsOfSale(state, pointsOfSale) {
      state.pointsOfSale = pointsOfSale;

      state.publishingDetails = pointsOfSale.map((pos) => ({
        pointOfSale: pos,
        id: pos.id,
      }));
    },
    setShops(state, shops) {
      state.shops = shops;
    },
    setLocale(state, locale) {
      state.locale = locale;
    },
    setUserData(state, data) {
      state.data = data;
    },
    updateUserData(state, updateData) {
      state.data = Object.assign(state.data, updateData);
    },
    setProductTypes(state, productTypes) {
      state.productTypes = productTypes;
    },
    setAddress(state, address) {
      state.address = address;
    },
    setIsUserLoggingOut(state, isUserLoggingOut) {
      state.isUserLoggingOut = isUserLoggingOut;
    },
    reloadProfileImage(state) {
      state.profileImageTimestamp = Date.now();
    },
    setMfaStatus(state, status) {
      state.mfaStatus = status;
    },
  },
  actions: {
    async initializeUser({ dispatch }) {
      await dispatch('fetchCurrentUser');
      await dispatch('fetchPointsOfSale');
      await dispatch('fetchLocale');
      await dispatch('identity/fetchIdentity', null, { root: true });

      applicationInitialization.onUserLogin();
    },
    async fetchCurrentUser({ commit }) {
      const res = await userService.fetchCurrentUser();
      commit('setUserData', res.data);
    },
    async fetchPointsOfSale({ commit }) {
      const res = await userService.fetchUserPointsOfSale();
      commit('setPointsOfSale', res.data.list);
    },
    async fetchLocale({ commit }) {
      const res = await userService.fetchUserLocale();
      commit('setLocale', res.data.locale);
    },
    async fetchProductTypes({ commit, state }) {
      const {
        data: { productTypes },
      } = await apiClient.request({
        method: 'GET',
        url: `/api/v1/shops/${
          state.pointsOfSale.find((pos) => pos.type === 'MARKETPLACE').target.id
        }/productTypes`,
        params: {
          mediaType: 'json',
          currency: state.data.currency.id,
          limit: 500,
          locale: localeService.convertToSprdLocale(localeService.getLocale()),
        },
      });
      commit(
        'setProductTypes',
        productTypes.reduce((result, current) => {
          result[current.id] = current;
          return result;
        }, {}),
      );
    },
    async fetchShops({ commit, state }) {
      return shopAPI.getShopsForUser(state.data.id).then((res) => {
        const shopsArray = Object.entries(res.data).reduce(
          (result, [shopId, shopData]) => {
            result.push({
              id: shopId,
              ...shopData,
            });
            return result;
          },
          [],
        );
        commit('setShops', shopsArray);
      });
    },
    async logout(
      { commit, dispatch, rootState },
      { redirectTargetAfterLogin } = {},
    ) {
      commit('setIsUserLoggingOut', true);
      dispatch('metrics/clearCache', { purge: true }, { root: true });
      dispatch('liveChat/clearSession', null, { root: true });

      await apiClient.request({
        method: 'DELETE',
        url: `api/v1/sessions/current`,
        params: {
          mediaType: 'json',
          locale: localeService.convertToSprdLocale(localeService.getLocale()),
        },
      });

      if (redirectTargetAfterLogin) {
        window.location.href = redirectTargetAfterLogin;
      } else if (rootState.application.isMobileApp) {
        window.location.href = `/app/connectDeviceMobile${window.location.search}`;
      } else {
        window.location.href = '/login';
      }
    },
    async updateMfaStatus({ commit }) {
      commit('setMfaStatus', MFA_STATES.LOADING);
      const { status } = await authService.getMfaStatus();
      if (status !== 'ACTIVATED') {
        commit('setMfaStatus', MFA_STATES.SETUP);
      } else {
        commit('setMfaStatus', MFA_STATES.ACTIVATED);
      }
    },
  },
};
