<template>
  <InternationalPublishingStatusTable
    v-bind="{ ...$props, ...$attrs }"
    :default-language="defaultLanguage"
    :languages="languagesToInclude"
    :online-status-text="onlineStatusText"
  />
</template>

<script setup>
import InternationalPublishingStatusTable from 'src/app/components/publishing/InternationalPublishingStatusTable.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  getDeeplinkForLocale: {
    required: true,
    type: Function,
  },
  requestDeeplinkForPendingTranslations: {
    type: Boolean,
  },
  displayNoneExistingTranslations: {
    type: Boolean,
  },
  displayUnavailableDeeplinks: {
    type: Boolean,
  },
});

const store = useStore();
const languages = computed(() => store.state.platform.languages);
const composition = computed(() => store.state.composition.current);

const requestedAutotranslations = computed(
  () => composition.value.metaData.autoTranslate,
);
const defaultLanguage = computed(
  () => composition.value.metaData.originLanguageCode,
);
const intPubRejected = computed(() => false); // TODO this.composition.metaData.intPub
const approvalPending = computed(() => false); // TODO
const onlineStatusText = computed(() =>
  approvalPending.value
    ? 'DESIGNS.STATUS.ONLINE_FIRST'
    : 'DESIGNS.STATUS.PUBLISHED',
);

const languagesToInclude = languages.value
  .filter((language) => {
    if (
      props.displayNoneExistingTranslations ||
      (requestedAutotranslations.value &&
        !intPubRejected.value &&
        !composition.value.publishingDetails.d2c.locales.length)
    ) {
      return true;
    } else {
      // TODO: Shop case?
      return (
        composition.value.publishingDetails.d2c.locales.includes(
          language.isoCode,
        ) || language.isoCode === defaultLanguage.value
      );
    }
  })
  .map((language) => ({
    ...language,
    pending: !composition.value.metaData.name.find(
      (translation) => translation.language === language.isoCode,
    ),
  }));
</script>
