<template>
  <div
    v-tooltip.top-start="{
      content: isAdmin ? 'not allowed as admin' : null,
      hideOnTargetClick: false,
    }"
    class="account-page-actions"
  >
    <ProgressButton
      class="btn btn-primary btn-lg"
      :label="$t('GENERAL.SAVE')"
      :on-click="save"
      :disabled="isAdmin || disabled"
    >
    </ProgressButton>
    <button :disabled="isAdmin || disabled" class="link-main" @click="revert">
      {{ $t('GENERAL.REVERT') }}
    </button>
  </div>
</template>

<script>
import ProgressButton from '@/btnProgress/ProgressButton.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'AccountPageActions',
  components: {
    ProgressButton,
  },
  props: {
    save: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ['onRevert'],
  computed: {
    ...mapGetters({
      isAdmin: 'account/isAdmin',
    }),
  },
  methods: {
    revert() {
      this.$emit('onRevert');
    },
  },
};
</script>

<style lang="scss" scoped>
.account-page-actions {
  display: flex;
  align-items: center;
  margin-top: 24px;
  position: relative;
  gap: 16px;

  .btn {
    padding: 8px 24px;
    min-width: 120px;
  }
}
</style>
