<template>
  <div v-if="labels.length" class="product-settings-categories">
    <SelectionTiles
      v-slot="{ item }"
      :items="labels"
      :item-key="(label) => label.id"
      :item-selected="
        (label) =>
          props.selectedLabels.some(
            (selectedLabel) => selectedLabel.id === label.id,
          )
      "
      :item-to-string="(label) => label.name"
      :min-item-width="120"
      multi-selection
      @change="selectLabel"
    >
      <Icon :icon="item.icon" />
    </SelectionTiles>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import SelectionTiles from 'src/app/components/selectionTiles/SelectionTiles.vue';
import { PublishingCategoryLabel } from '@/publishing/publishingLabels';

const store = useStore();
const labels = computed(
  () =>
    Object.values(
      store.state.publishingCore.apparelTargetGroupLabels,
    ) as PublishingCategoryLabel[],
);

const emit = defineEmits(['onSelect']);

interface Props {
  composition: any;
  selectedLabels: PublishingCategoryLabel[];
}
const props = defineProps<Props>();

const selectLabel = (label: PublishingCategoryLabel) => {
  emit('onSelect', label);
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';

.product-settings-categories {
  img {
    height: 70px;
  }
}
</style>
