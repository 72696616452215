import { i18n } from '@/translate/i18n';
import interpolateImgSize from 'src/app/partnerarea/pos/shop/imageAttributes';
import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';

const { t } = i18n.global;

const errorCodes = {
  INVALID_FORMAT: 'INVALID_FORMAT',
  INVALID_SIZE: 'INVALID_SIZE',
  GENERIC: 'GENERIC',
  413: 'INVALID_SIZE',
  415: 'INVALID_FORMAT',
};

const imageUploadErrors = {
  GENERIC: 'SA.imageUpload.message.genericUploadError',
  INVALID_SIZE: 'SA.imageUpload.message.imageSizeLimit',
  INVALID_FORMAT: 'SA.imageUpload.message.invalidFormat',
};

const allowedFormats = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/x-icon',
  'image/vnd.microsoft.icon',
  'image/svg+xml',
  'application/svg+xml',
];

const maxFileSize = 400 * 1024; // 400KB

export const shopImageUpload = {
  async uploadImage(
    store,
    imageData,
    imageUseCase,
    progressCallback,
    addtionalTag = null,
  ) {
    if (!imageData) {
      throw t(imageUploadErrors[errorCodes.GENERIC]);
    } else if (!allowedFormats.includes(imageData.type)) {
      throw t(imageUploadErrors[errorCodes.INVALID_FORMAT]);
    } else if (imageData.size > maxFileSize) {
      throw t(
        imageUploadErrors[errorCodes.INVALID_SIZE],
        interpolateImgSize(imageUseCase),
      );
    }

    return shopAPI
      .editShopImages(
        store,
        imageData,
        imageUseCase,
        progressCallback,
        addtionalTag,
      )
      .then((data) => data)
      .catch((status) => {
        throw t(
          imageUploadErrors[errorCodes[status]] ||
            imageUploadErrors[errorCodes.GENERIC],
          interpolateImgSize(imageUseCase),
        );
      });
  },
  deleteImage(store, imageName, imageUseCase) {
    return shopAPI.deleteShopImage(store, imageUseCase, imageName);
  },
};
