<template>
  <div>
    <p>
      {{ $t('SA.shopsettings.customDomain.description') }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'ShopCustomDomainLeftMenu',
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
</style>
