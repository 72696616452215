<template>
  <div v-if="display" class="noCollectionsPlaceholder">
    <h1>{{ $t('POS.SHOP_SETTINGS.COLLECTIONS.EMPTY.HEADING') }}</h1>
    <p>{{ $t('POS.SHOP_SETTINGS.COLLECTIONS.EMPTY.SUBHEADING') }}</p>
  </div>
</template>

<script>
import { CollectionData } from 'src/app/partnerarea/pos/shop/shopSavable/CollectionData';

export default {
  name: 'NoCollectionPlaceholder',
  mixins: [CollectionData],
  computed: {
    display() {
      if (!this.collectionsLoaded) {
        return false;
      }

      return this.collections.length === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.noCollectionsPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;

  h1 {
    margin: 0;
  }

  p {
    margin: 30px 0;
  }
}
</style>
