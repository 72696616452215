<template>
  <div class="ai-design-generator">
    <BetaIndicator class="beta" />

    <div v-if="currentMode === MODES.INITIALIZING" class="initializing">
      <LoadingHeart class="loader" />
    </div>
    <AiDesignGeneratorPrompt
      v-if="currentMode === MODES.PROMPT"
      :prefill="prompt"
      :usage-limit="usageStatus.grantAmount"
      :open-usage="usageStatus.credits"
      @on-submit="generate"
      @survey="goToSurvey"
    />
    <div v-if="currentMode === MODES.IMAGE_GENERATION" class="loading">
      <h2>{{ $t('AI_DESIGN_GENERATOR.GENERATING_IMAGE.HEADING') }}</h2>
      <p>{{ $t('AI_DESIGN_GENERATOR.GENERATING_IMAGE.SUBHEADING') }}</p>
      <LoadingHeart class="loader" />
      <LoadingWords :words="loadingWordsImageGeneration" />
    </div>
    <div v-if="currentMode === MODES.PROCESSING" class="loading">
      <h2>{{ $t('AI_DESIGN_GENERATOR.PROCESSING.HEADING') }}</h2>
      <p>{{ $t('AI_DESIGN_GENERATOR.PROCESSING.SUBHEADING') }}</p>
      <LoadingHeart class="loader" />
      <LoadingWords :words="loadingWordsProcessing" />
    </div>
    <AiDesignGeneratorImageSelection
      v-if="currentMode === MODES.IMAGE_SELECTION"
      :image="generatedImage"
      :usage-limit="usageStatus.grantAmount"
      :open-usage="usageStatus.credits"
      @on-submit="upload"
      @on-retry="retry"
      @survey="goToSurvey"
    />
    <AiDesignGeneratorAssortment
      v-if="currentMode === MODES.DONE"
      :products="products"
      :meta-data="generatedMetaData"
      @on-submit="onDone"
    />
    <AiDesignGeneratorError
      v-if="currentMode === MODES.ERROR"
      :error="errorType"
      @on-close="$emit('onDone')"
      @on-retry="retryAfterError"
    />
    <AiDesignGeneratorSurvey v-if="currentMode === MODES.SURVEY" @back="back" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import aiDesignGeneratorService from './aiDesignGeneratorService';
import LoadingHeart from 'src/app/commons/LoadingHeart/LoadingHeart.vue';
import designUploadService from '@/api/designUploadService/designUploadService';
import productIdeaService from '@/api/productIdeaService/productIdeaService';
import assortmentHelper from '@/assortmentHelper/assortmentHelper';
import LoadingWords from '@/dialogs/recap/LoadingWords.vue';
import AiDesignGeneratorPrompt from './AiDesignGeneratorPrompt.vue';
import AiDesignGeneratorImageSelection from './AiDesignGeneratorImageSelection.vue';
import AiDesignGeneratorAssortment from './AiDesignGeneratorAssortment.vue';
import AiDesignGeneratorError from './AiDesignGeneratorError.vue';
import AiDesignGeneratorSurvey from './AiDesignGeneratorSurvey.vue';
import BetaIndicator from 'src/app/components/indicator/BetaIndicator.vue';
import analytics from '@/tracking/analytics';

export default {
  name: 'AiDesignGenerator',
  components: {
    LoadingHeart,
    LoadingWords,
    AiDesignGeneratorPrompt,
    AiDesignGeneratorImageSelection,
    AiDesignGeneratorAssortment,
    AiDesignGeneratorError,
    AiDesignGeneratorSurvey,
    BetaIndicator,
  },
  emits: ['onDone'],
  data() {
    return {
      prompt: '',
      MODES: {
        INITIALIZING: 'INITIALIZING',
        PROMPT: 'PROMPT',
        IMAGE_GENERATION: 'IMAGE_GENERATION',
        IMAGE_SELECTION: 'IMAGE_SELECTION',
        PROCESSING: 'PROCESSING',
        DONE: 'DONE',
        ERROR: 'ERROR',
        SURVEY: 'SURVEY',
      },
      products: null,
      currentMode: null,
      previousMode: null,
      generatedImage: null,
      usageStatus: null,
      errorType: null,
      generatedMetaData: null,
      loadingWordsImageGeneration: [
        'AI_DESIGN_GENERATOR.GENERATING_IMAGE.PHRASE1',
        'AI_DESIGN_GENERATOR.GENERATING_IMAGE.PHRASE2',
        'AI_DESIGN_GENERATOR.GENERATING_IMAGE.PHRASE3',
        'AI_DESIGN_GENERATOR.GENERATING_IMAGE.PHRASE4',
        'AI_DESIGN_GENERATOR.GENERATING_IMAGE.PHRASE5',
        'AI_DESIGN_GENERATOR.GENERATING_IMAGE.PHRASE6',
      ],
      loadingWordsProcessing: [
        'AI_DESIGN_GENERATOR.PROCESSING.PHRASE1',
        'AI_DESIGN_GENERATOR.PROCESSING.PHRASE2',
        'AI_DESIGN_GENERATOR.PROCESSING.PHRASE3',
        'AI_DESIGN_GENERATOR.PROCESSING.PHRASE4',
        'AI_DESIGN_GENERATOR.PROCESSING.PHRASE5',
        'AI_DESIGN_GENERATOR.PROCESSING.PHRASE6',
      ],
    };
  },
  computed: {},
  async created() {
    this.currentMode = this.MODES.INITIALIZING;
    await this.updateUsageStatus();
    this.setMode(this.MODES.PROMPT);

    analytics.logEvent('ai_generator_open');
  },
  methods: {
    ...mapActions({
      fetchIdeas: 'ideas/fetchIdeas',
    }),
    setMode(mode) {
      this.previousMode = this.currentMode;
      this.currentMode = mode;
    },
    async updateUsageStatus() {
      this.usageStatus = await aiDesignGeneratorService.getCredits();
    },
    async generate(prompt) {
      if (this.usageStatus.credits <= 0) {
        return;
      }

      analytics.logEvent('ai_generator_generate', {
        promptLength: prompt.length,
      });

      this.prompt = prompt;
      this.setMode(this.MODES.IMAGE_GENERATION);
      try {
        const { image, metaData } =
          await aiDesignGeneratorService.generateImage(this.prompt);
        this.generatedImage = image;
        this.generatedMetaData = metaData;
        await this.updateUsageStatus();
        this.setMode(this.MODES.IMAGE_SELECTION);
      } catch (error) {
        this.handleError(error);
      }
    },
    retry() {
      analytics.logEvent('ai_generator_retry');
      this.setMode(this.MODES.PROMPT);
    },
    retryAfterError() {
      analytics.logEvent('ai_generator_retry_after_error');
      this.setMode(this.MODES.PROMPT);
    },
    upload(file) {
      analytics.logEvent('ai_generator_upload');
      this.setMode(this.MODES.PROCESSING);

      this.uploadingFiles = designUploadService.uploadFiles(
        [file],
        {
          success: () => {
            this.finalize();
          },
          error: () => {
            this.setMode(this.MODES.IMAGE_SELECTION);
          },
        },
        ['ai'],
      );
    },
    onDone() {
      analytics.logEvent('ai_generator_done');
      this.$emit('onDone');
    },
    handleError(error) {
      this.errorType = error?.data?.code;
      analytics.logEvent('ai_generator_error', {
        reason: this.errorType,
      });

      this.setMode(this.MODES.ERROR);
      this.updateUsageStatus();
    },
    async finalize() {
      try {
        const { idea, metaDataPrefilled } =
          await aiDesignGeneratorService.setupIdea(this.generatedMetaData);
        if (!metaDataPrefilled) {
          this.generatedMetaData = null;
        }
        const assortment = await productIdeaService.getAssortment(idea);

        this.products = assortmentHelper.getSelectedSellables(assortment);

        this.setMode(this.MODES.DONE);
        this.fetchIdeas();
      } catch (_) {
        this.handleError({ data: { code: 'IDEA_CREATING_ISSUE' } });
      }
    },
    goToSurvey() {
      this.setMode(this.MODES.SURVEY);
      analytics.logEvent('ai_generator_buy_more_usage');
    },
    back() {
      this.setMode(this.previousMode);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/links';
@import 'src/scss/page-layout.scss';

.ai-design-generator {
  position: relative;

  .beta {
    position: absolute;
    top: 0;
    right: 0;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 16px;
  min-width: 400px;
  max-width: 100%;

  li {
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
    }

    button {
      margin: 16px 0 0 0;
    }
  }
}

.loading {
  display: flex;
  flex-direction: column;

  & > h2 {
    margin: 0 0 8px 0;
  }

  & > p {
    margin: 0 0 24px 0;
    color: $grey65;
  }

  h4 {
    margin: 16px 0 0 0;
  }
}

textarea {
  padding: 12px;
}

.actions {
  display: flex;
  margin: 16px 0 0 0;
  align-items: center;

  p {
    margin: 0 0 0 16px;
  }
}

button {
  padding: 12px 24px;
}

.finished {
  .products-created-heading {
    display: flex;
    align-items: center;
    margin: 0 0 16px 0;

    h4 {
      margin: 0;
    }

    p {
      margin: 0 0 0 auto;
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 16px;
    min-width: 200px;
    max-width: 900px;

    li {
      background-color: $grey5;
      padding: 12px;
    }
  }

  button {
    margin: 24px 0 0 0;
    min-width: 200px;
  }
}
</style>
