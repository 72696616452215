import { parseISO } from 'date-fns/parseISO';
import { getDate } from 'date-fns/getDate';
import { getMonth } from 'date-fns/getMonth';
import { TIME_FILTERS } from 'src/app/components/charts/sales/timeFilters';
import { i18n } from '@/translate/i18n';
import date from '@/date/date';

const { t } = i18n.global;

export const timeFilters = {
  WEEK: {
    key: 'WEEK',
    duration: 7,
    metaLabel: (current) => {
      return date(parseISO(current.date));
    },
    metaGroupUnit: 'GENERAL.DAYS',
    label: (current) => ` ${getDate(parseISO(current.date))} `,
    group: (current) =>
      `${getMonth(parseISO(current.date))}-${getDate(parseISO(current.date))}`,
    dropdownLabel: () => t('DASHBOARD.TIME_FILTER.WEEK'),
  },
  TWO_WEEKS: {
    key: 'TWO_WEEKS',
    duration: 14,
    metaLabel: (current) => {
      return date(parseISO(current.date));
    },
    metaGroupUnit: 'GENERAL.DAYS',
    label: (current) => ` ${getDate(parseISO(current.date))} `,
    group: (current) =>
      `${getMonth(parseISO(current.date))}-${getDate(parseISO(current.date))}`,
    dropdownLabel: () => t('DASHBOARD.TIME_FILTER.TWO_WEEKS'),
  },
  MONTH: {
    ...TIME_FILTERS.MONTH,
    duration: 30,
  },
  QUARTER: {
    ...TIME_FILTERS.QUARTER,
    duration: 90,
  },
};
