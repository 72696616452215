<template>
  <div
    class="config-section"
    :class="{ displayInline: props.displayInline, hasError: errorText }"
  >
    <div v-if="errorText" class="error-indicator"></div>
    <div class="config-section-content">
      <div class="header">
        <component :is="headingTag">{{ $t(props.heading) }}</component>
        <p v-if="props.subheading">{{ $t(props.subheading) }}</p>
        <p v-if="errorText" class="error-text">
          <Icon icon="attention" /> {{ $t(errorText) }}
        </p>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue';

const props = defineProps<{
  heading: string;
  subheading?: string;
  displayInline?: boolean;
  secondary?: boolean;
  errorText?: string;
}>();

const headingTag = computed(() => (props.secondary ? 'h3' : 'h1'));
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';
@import 'src/scss/animations';

.config-section {
  display: flex;
  flex-direction: row;

  .error-indicator {
    background-color: $pa-color-red;
    width: 4px;
    border-radius: 3px;
    margin-right: 8px;
    transition: width 0.2s linear;
  }

  &.hasError {
    animation: shakeit 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }

  .config-section-content {
    flex: 1;
  }

  .header {
    margin-bottom: 24px;

    h1,
    h3 {
      margin: 0;
    }

    p {
      margin: 8px 0 0 0;
      color: $grey65;
    }

    .error-text {
      color: $pa-color-red;
      display: flex;
      align-items: center;
      margin: 8px 0 0 0;

      .icon {
        margin-right: 4px;
      }
    }
  }

  &:not(.displayInline) {
    background-color: $grey0;
    border: 1px solid $grey10;
    border-radius: $border-radius-medium;
    margin-bottom: 24px;
    padding: 24px;
    position: relative;
  }

  &.displayInline {
    .header {
      margin-bottom: 16px;
    }
  }
}
</style>
