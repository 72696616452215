<template>
  <div class="pos-and-time-filter">
    <div v-if="posFilters.length > minPosFilters" class="pos-filter">
      <Dropdown
        class="pos-filter-dropdown"
        :items="posFilters"
        :value="currentPosFilter"
        :item-key="(item) => item.key"
        :item-to-string="(posFilter) => posFilter.name"
        @change="setPosFilter"
      >
      </Dropdown>
    </div>
    <div class="time-filter">
      <Dropdown
        :items="TIME_FILTER_ARRAY"
        :value="currentTimeFilter"
        :item-to-string="(item) => item.name"
        :item-key="(item) => item.key"
        @change="setTimeFilter"
      ></Dropdown>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/Dropdown/Dropdown.vue';

export default {
  name: 'PosAndTimeFilter',
  components: {
    Dropdown,
  },
  props: {
    posFilters: {
      required: true,
      type: Array,
    },
    timeFilters: {
      required: true,
      type: Object,
    },
    defaultTimeFilter: {
      required: true,
      type: Object,
    },
    defaultPosFilter: {
      type: Object,
    },
    minPosFilters: {
      type: Number,
      default: 1,
    },
  },
  emits: ['change'],
  data() {
    return {
      currentTimeFilter: null,
      currentPosFilter: null,
    };
  },
  computed: {
    TIME_FILTER_ARRAY() {
      return Object.values(this.timeFilters).map((timeFilter) => ({
        key: timeFilter.key,
        name: timeFilter.dropdownLabel(),
      }));
    },
  },
  async created() {
    this.currentTimeFilter = this.TIME_FILTER_ARRAY.find(
      (timeFilter) => timeFilter.key === this.defaultTimeFilter.key,
    );
    this.currentPosFilter = this.defaultPosFilter || this.posFilters[0];
    this.emitCurrentFilters();
  },
  methods: {
    setPosFilter(filter) {
      this.currentPosFilter = filter;
      this.emitCurrentFilters();
    },
    setTimeFilter(filter) {
      this.currentTimeFilter = filter;
      this.emitCurrentFilters();
    },
    emitCurrentFilters() {
      this.$emit('change', {
        pos: this.currentPosFilter,
        time: this.timeFilters[this.currentTimeFilter.key],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.pos-and-time-filter {
  display: flex;

  & > div {
    margin-left: 16px;
  }
}

.pos-filter-dropdown {
  min-width: 200px;
}
</style>
