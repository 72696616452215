function initZendeskChat() {
  return new Promise(function (resolve, reject) {
    const ze = document.createElement('script');
    ze.async = true;
    ze.src =
      'https://static.zdassets.com/ekr/snippet.js?key=2d9f81c1-0421-4db8-887e-e526d048f1f3';
    ze.onload = resolve;
    ze.onerror = reject;
    ze.id = 'ze-snippet';
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(ze, s);
  });
}

const liveChatService = {
  isEnabled() {
    return false; // NA
  },
  async init(customVariables = [], onUnreadMessages) {
    if (!this.isEnabled()) {
      return;
    }

    await initZendeskChat();
    window.zE('messenger:set', 'conversationFields', customVariables);
    window.zE('messenger:set', 'zIndex', 100);
    this.closeChat();

    window.zE('messenger:on', 'unreadMessages', function (count) {
      if (onUnreadMessages) {
        onUnreadMessages(count);
      }
    });
  },
  closeChat() {
    if (!this.isEnabled()) {
      return;
    }

    window.zE('messenger', 'close');
  },
  openChat() {
    if (!this.isEnabled()) {
      return;
    }

    window.zE('messenger', 'show');
    window.zE('messenger', 'open');
  },
  onOpenChange(handlerFn) {
    if (!this.isEnabled()) {
      return;
    }

    window.zE('messenger:on', 'open', () => handlerFn(true));
    window.zE('messenger:on', 'close', () => handlerFn(false));
  },
  clearSession() {
    if (!this.isEnabled()) {
      return;
    }

    window.zE('messenger:set', 'cookies', false);
    window.zE('messenger:set', 'cookies', true);
  },
};

export default liveChatService;
