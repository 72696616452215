<template>
  <div>
    <div class="headline">
      <h4>{{ $t('POS.SHOP_SETTINGS.DESIGN.COLORS.HEADLINE') }}</h4>
      <Tooltip :text="$t('POS.SHOP_SETTINGS.DESIGN.COLORS.CONTRAST_DESC')" />
    </div>
    <Color
      v-for="(colorSettings, index) in colors"
      :key="index"
      :label="$t(colorSettings.label)"
      :current-color="colorSettings.currentColor"
      :suggested-colors="colorSettings.suggestedColors"
      :custom-color="colorSettings.customColor"
      @update:current="updateCurrentColor(colorSettings.settingsKey, $event)"
      @update:custom="updateCustomColor(colorSettings.settingsKey, $event)"
    />
    <Color
      :label="$t(tileBackground.label)"
      :current-color="tileBackground.currentColor"
      :suggested-colors="tileBackground.suggestedColors"
      :custom-color="tileBackground.lastColor"
      :disabled="isTileTransparent"
      @update:current="updateCurrentBgColor($event)"
      @update:custom="updateCurrentBgColor($event)"
    >
      <Checkbox
        class="checkbox"
        :model-value="isTileTransparent"
        @update:model-value="toggleTransparency"
      >
        {{ $t('SA.layout.tilebackground.transparency') }}
      </Checkbox>
    </Color>
  </div>
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import Color from '../../common/Color.vue';
import Checkbox from '@/checkbox/Checkbox.vue';
import Tooltip from 'src/app/partnerarea/pos/shop/common/Tooltip.vue';

const TRANSPARENT = 'Transparent';

export default {
  name: 'Colors',
  components: {
    Color,
    Checkbox,
    Tooltip,
  },
  mixins: [SettingsData],
  computed: {
    isTileTransparent() {
      return this.tileBackground.currentColor === TRANSPARENT;
    },
    colors() {
      const availableColors = [
        [
          'SA.layout.owncolorscheme.box.color2',
          'Main2',
          ['#1D781D', '#000000', '#F62459', '#1E90FF', '#FF4500', '#E2122F'],
        ],
      ];

      if (this.settings.template === 'CLASSIC') {
        availableColors.push(
          [
            'SA.layout.owncolorscheme.box.color1',
            'Main1',
            ['#FFFFFF', '#6DAF99', '#112C3F', '#413C46', '#39E56E', '#22262D'],
          ],
          [
            'SA.layout.owncolorscheme.box.color3',
            'Main3',
            ['#FFFFFF', '#FEFEFE', '#F6F6F6', '#FDF7E9', '#BBBBBB', '#000000'],
          ],
          [
            'SA.layout.owncolorscheme.box.color5',
            'Sub2',
            ['#22262D', '#0C3B2C', '#112C3F', '#413C46', '#1875A0', '#AAAAAA'],
          ],
          [
            'SA.layout.owncolorscheme.box.color6',
            'Sub3',
            ['#FFFFFF', '#FEFEFE', '#F6F6F6', '#FDF7E9', '#BBBBBB', '#000000'],
          ],
        );
      }

      return availableColors.map(([label, settingsKey, suggestedColors]) => ({
        label,
        suggestedColors,
        settingsKey,
        currentColor: this.settings[`schemeColor${settingsKey}`],
        customColor:
          this.settings[`ownSchemeColor${settingsKey}`] || suggestedColors[0],
      }));
    },
    tileBackground() {
      let tileBackgroundColor = this.settings.backgroundColor;
      if (!tileBackgroundColor) {
        tileBackgroundColor = '#ffffff';
      } else if (
        tileBackgroundColor !== TRANSPARENT &&
        !tileBackgroundColor.startsWith('#')
      ) {
        tileBackgroundColor = `#${tileBackgroundColor}`;
      }

      return {
        label: 'SA.layout.tilebackground.title',
        currentColor: tileBackgroundColor,
        lastColor:
          tileBackgroundColor === TRANSPARENT ? '#ffffff' : tileBackgroundColor,
        availableColors: [],
      };
    },
  },
  methods: {
    toggleTransparency(isTransparent) {
      this.updateCurrentBgColor(
        isTransparent ? TRANSPARENT : this.tileBackground.lastColor,
      );
    },
    updateCurrentColor(settingsKey, color) {
      this.settings[`schemeColor${settingsKey}`] = color;
    },
    updateCustomColor(settingsKey, color) {
      this.settings[`ownSchemeColor${settingsKey}`] = color;
      this.updateCurrentColor(settingsKey, color);
    },
    updateCurrentBgColor(color) {
      this.settings.backgroundColor = color.replace('#', ''); // Backend expects this color without #
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
.headline {
  display: flex;
  align-items: start;
}

h4 {
  margin-top: 0;
  padding-left: 25px;
}

.checkbox {
  width: 100%;
  margin-top: 1em;
}
</style>
