<template>
  <div class="modal-container force-update-dialog">
    <div class="modal-body">
      <Icon icon="refresh"></Icon>
      <h1>{{ $t('UPDATE_DIALOG.HEADING') }}</h1>
      <p>{{ $t('UPDATE_DIALOG.TEXT') }}</p>
      <button type="button" class="btn btn-primary" @click="refresh">
        {{ $t('UPDATE_DIALOG.CTA') }}
      </button>
      <a class="link text-sm" @click="modalInstance.dismiss">{{
        $t('UPDATE_DIALOG.REJECT')
      }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ForceUpdateDialog',
  props: ['modalInstance', 'data'],
  methods: {
    refresh() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.force-update-dialog {
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      width: 70px;
      height: 70px;
      color: $pa-color-main;
    }

    h1 {
      margin: 24px 0 19px 0;
    }

    button {
      margin: 24px 0 19px 0;
      padding: 16px 40px;
    }

    a {
      color: $sprd-color-medium-grey;
    }
  }
}
</style>
