<template>
  <div>
    <div class="entry sp-subheader">
      {{ $t('POS.SHOP_SETTINGS.STARTPAGE.TEASER.SUBHEADING') }}
    </div>
    <ToggleSwitch
      v-model="startPage.teaserEnabled"
      label="POS.SHOP_SETTINGS.STARTPAGE.TEASER.ENABLE_TEASER"
    />

    <div class="heading-image">
      <strong>{{
        $t('POS.SHOP_SETTINGS.STARTPAGE.TEASER.IMAGE.UPLOAD_IMG')
      }}</strong>
    </div>
    <ImageUpload
      class="clickable-img-upload"
      :image="image"
      :active="Boolean(!startPage.teaserStockImage && startPage.teaserImage)"
      :active-image-name="startPage.teaserImage"
      :active-image-url="activeImageUrl"
      :disabled="!startPage.teaserEnabled"
      @file-activated="onFileActivated"
      @file-deleted="onFileDeleted"
      @file-uploaded="onFileUploaded"
    />

    <ImageSelector
      :disabled="!startPage.teaserEnabled"
      :images="stockImages"
      :selected-image-filename="startPage.teaserStockImage"
      :on-image-selected="onStockImageSelected"
    />

    <ToggleSwitch
      v-model="teaserTextEnabled"
      :disabled="!startPage.teaserEnabled"
      label="POS.SHOP_SETTINGS.STARTPAGE.TEASER.ELEMENTS.ENABLE_CAPTION"
    />
    <LegalLabelInput
      v-model="startPage.teaserCaption"
      class="sprd-shop-input"
      maxlength="50"
      :disabled="!startPage.teaserEnabled || !teaserTextEnabled"
      :label="$t('POS.SHOP_SETTINGS.STARTPAGE.CAPTION_LABEL')"
    />
    <LegalLabelInput
      v-model="startPage.teaserText"
      maxlength="150"
      :disabled="!teaserTextEnabled"
      :label="$t('POS.SHOP_SETTINGS.STARTPAGE.TEXT_LABEL')"
    />
    <Color
      v-if="teaserTextColorOptions"
      class="teaserColorPicker"
      :label="$t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.TEXT_COLOR')"
      :current-color="teaserTextColor"
      :suggested-colors="teaserTextColorOptions"
      :custom-color-enabled="false"
      @update:current="(col) => (startPage.teaserTextColor = col)"
    />

    <ToggleSwitch
      v-model="buttonEnabled"
      class="teaserEnabledSwitch"
      :disabled="!startPage.teaserEnabled"
      label="POS.SHOP_SETTINGS.STARTPAGE.TEASER.BUTTON_MODE_HEADING"
    />
    <RadioButtonGroup
      v-model="selectedButtonMode"
      class="radiogroup"
      :items="buttonModes"
      :item-key="(item) => item.label"
      :item-to-string="(item) => item.label"
      :disabled="!buttonEnabled || !startPage.teaserEnabled"
    />
    <LegalLabelInput
      v-if="hasTeaserButtonText"
      v-model="startPage.teaserButtonCaption"
      class="sprd-shop-input"
      maxlength="50"
      :label="$t(teaserButtonTextLabel)"
      :disabled="!startPage.teaserEnabled"
    />

    <Dropdown
      v-if="hasTeaserButtonLink"
      :items="startPage.teaserButtonTargets"
      :item-key="(option) => option.name"
      :item-to-string="(option) => option.name"
      :label="
        $t('POS.SHOP_SETTINGS.STARTPAGE.TEASER.ELEMENTS.BUTTON_TARGET_LABEL')
      "
      :disabled="!startPage.teaserEnabled"
      :value="selectedTeaserButtonTarget"
      @change="setSelectedTeaserButtonTarget"
    />
  </div>
</template>

<script>
import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';

import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import LegalLabelInput from '../../common/LegalLabelInput.vue';
import ImageUpload from '../../imageUpload/ImageUpload.vue';
import Dropdown from '@/Dropdown/Dropdown.vue';
import RadioButtonGroup from '@/RadioButtonGroup/RadioButtonGroup.vue';
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import { StartPageData } from 'src/app/partnerarea/pos/shop/shopSavable/StartPageData';
import { teaser } from '../../imageAttributes';
import ImageSelector from '../../common/ImageSelector.vue';
import Color from 'src/app/partnerarea/pos/shop/common/Color.vue';

const BUTTON_MODE_OFF = 'OFF';
const BUTTON_MODE_SEARCH = 'SEARCH';
const BUTTON_MODE_DEFAULT = 'ALL_PRODUCTS';

export default {
  name: 'Teaser',
  components: {
    ImageSelector,
    ImageUpload,
    ToggleSwitch,
    LegalLabelInput,
    Dropdown,
    RadioButtonGroup,
    Color,
  },
  mixins: [StartPageData, SettingsData],
  data() {
    return {
      activeImageUrl: null,
      stockImages: [],
      rememberedTeaserImage: null,
      image: teaser,
      buttonModes: [
        {
          value: BUTTON_MODE_DEFAULT,
          label: this.$t(
            'POS.SHOP_SETTINGS.STARTPAGE.TEASER.BUTTON_MODE_BUTTON',
          ),
        },
        {
          value: BUTTON_MODE_SEARCH,
          label: this.$t(
            'POS.SHOP_SETTINGS.STARTPAGE.TEASER.BUTTON_MODE_SEARCH',
          ),
        },
      ],
    };
  },
  computed: {
    teaserTextEnabled: {
      set(newValue) {
        if (this.startPage.teaserEnabled) {
          this.startPage.teaserTextEnabled = newValue;
        }
      },
      get() {
        return this.startPage.teaserEnabled && this.startPage.teaserTextEnabled;
      },
    },
    teaserTextColorOptions() {
      return (
        this.startPage.teaserTextColorOptions &&
        this.startPage.teaserTextColorOptions[this.settings.template]
      );
    },
    teaserTextColor() {
      const options = this.teaserTextColorOptions;
      if (!options) {
        return null;
      }
      if (options.includes(this.startPage.teaserTextColor)) {
        return this.startPage.teaserTextColor;
      }
      return options[0];
    },
    buttonEnabled: {
      get() {
        return this.startPage.teaserButtonMode !== BUTTON_MODE_OFF;
      },
      set(enabled) {
        this.startPage.teaserButtonMode = enabled
          ? BUTTON_MODE_DEFAULT
          : BUTTON_MODE_OFF;
      },
    },
    selectedButtonMode: {
      get() {
        let { teaserButtonMode } = this.startPage;
        if (![BUTTON_MODE_OFF, BUTTON_MODE_SEARCH].includes(teaserButtonMode)) {
          teaserButtonMode = BUTTON_MODE_DEFAULT;
        }

        return this.buttonModes.find(({ value }) => value === teaserButtonMode);
      },
      set({ value }) {
        this.startPage.teaserButtonMode = value;
      },
    },
    selectedTeaserButtonTarget() {
      const { teaserButtonTargets, teaserButtonMode, teaserButtonTarget } =
        this.startPage;

      if (!teaserButtonTargets) {
        return null;
      }

      return teaserButtonTargets.find(
        ({ buttonTargetMode, buttonTarget }) =>
          buttonTargetMode === teaserButtonMode &&
          buttonTarget === teaserButtonTarget,
      );
    },
    hasTeaserButtonText() {
      return this.startPage.teaserButtonMode !== BUTTON_MODE_OFF;
    },
    teaserButtonTextLabel() {
      return this.startPage.teaserButtonMode === BUTTON_MODE_SEARCH
        ? 'POS.SHOP_SETTINGS.STARTPAGE.TEASER.SEARCH_FIELD_LABEL'
        : 'POS.SHOP_SETTINGS.STARTPAGE.BUTTON_LABEL';
    },
    hasTeaserButtonLink() {
      return (
        this.startPage.teaserButtonMode !== BUTTON_MODE_OFF &&
        this.startPage.teaserButtonMode !== BUTTON_MODE_SEARCH
      );
    },
    teaserImage() {
      return this.startPage.teaserImage;
    },
    teaserStockImage() {
      return this.startPage.teaserStockImage;
    },
  },
  watch: {
    teaserImage: {
      handler() {
        const teaserImage = this.teaserImage;
        if (teaserImage) {
          // resolve the image for the upload box
          this.rememberedTeaserImage = teaserImage;
          shopAPI
            .getShopImages(this.$store, 'teaser')
            .then((fileArray) => {
              const match = fileArray.find(
                (fileEntry) => fileEntry.filename === this.teaserImage,
              );
              if (match) {
                this.activeImageUrl = match.url;
              }
            })
            .catch((e) => console.error(e));
        } else {
          this.rememberedTeaserImage = null;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.$store.commit('shop/setLivePreview', {
      path: '',
      section: 'sprd-startpage-teaser',
    });
    shopAPI
      .getShopStockImages(this.$store, 'teaser')
      .then((fileArray) => {
        // resolve stock images
        this.stockImages = fileArray;
      })
      .catch((e) => console.error(e));
  },
  methods: {
    setSelectedTeaserButtonTarget(teaserButtonTarget) {
      // change method necessary because the Dropdown does not work with v-model
      this.startPage.teaserButtonMode = teaserButtonTarget.buttonTargetMode;
      this.startPage.teaserButtonTarget = teaserButtonTarget.buttonTarget;
    },
    onFileUploaded(data) {
      this.startPage.teaserImage = data.filename;
      this.activeImageUrl = data.url;
      this.startPage.teaserStockImage = '';
    },
    onFileDeleted() {
      this.startPage.teaserImage = '';
      this.activeImageUrl = null;
    },
    onFileActivated() {
      this.startPage.teaserStockImage = '';
      if (!this.startPage.teaserImage) {
        this.startPage.teaserImage = this.rememberedTeaserImage;
      }
    },
    onStockImageSelected(filename) {
      this.startPage.teaserStockImage = filename;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../startPageCommon.scss';
@import 'src/scss/styleguide/colors';

.radiobutton-group {
  display: block;
  margin: -1.5em 0 2em;
}

:deep(.radiobutton-container) {
  padding: 0.5em 0;
}

.heading-image {
  margin-bottom: 12px;
}

.clickable-img-upload {
  cursor: pointer;
  margin-bottom: 12px;
}

.teaserColorPicker {
  margin: 5px -25px 0 -25px;
}
.teaserEnabledSwitch {
  margin-top: 30px;
}
</style>
