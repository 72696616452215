<template>
  <div class="collections">
    <div
      v-tooltip="
        hasMaxCollections
          ? {
              content: $t('POS.SHOP_SETTINGS.COLLECTIONS.LIMIT_REACHED'),
            }
          : false
      "
      class="new-btn-wrapper"
    >
      <button
        class="btn btn-primary btn-block icon-btn"
        :disabled="hasMaxCollections"
        @click="createCollection()"
      >
        <Icon icon="plus" />
        {{ $t('POS.SHOP_SETTINGS.COLLECTIONS.NEW') }}
      </button>
    </div>
    <p class="subTitle">
      {{ $t('POS.SHOP_SETTINGS.COLLECTIONS.TOPICS.LABEL') }}
    </p>
    <ul>
      <draggable
        v-bind="{ handle: '.handle', forceFallback: true }"
        v-model="collections"
        item-key="id"
      >
        <template #item="{ element }">
          <DraggableElement
            :label="element.name"
            :subheading="$t('NAV.DESIGNS') + ': ' + element.ideaCount"
            :active="element.active"
            :disabled="element.length < 2"
            @edit="editCollection(element)"
          />
        </template>
      </draggable>
    </ul>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapMutations } from 'vuex';
import { CollectionData } from 'src/app/partnerarea/pos/shop/shopSavable/CollectionData';
import DraggableElement from 'src/app/partnerarea/pos/shop/common/DraggableElement.vue';

export default {
  name: 'Collections',
  components: {
    draggable,
    DraggableElement,
  },
  mixins: [CollectionData],
  watch: {
    collections: {
      handler(newCollections, oldCollections) {
        if (!this.collectionsLoaded) {
          return;
        }

        const wasOn = oldCollections && oldCollections.length !== 0;
        const willBeOn = newCollections && newCollections.length !== 0;
        if (wasOn !== willBeOn) {
          this.$store.commit('shop/setLivePreview', {
            path: 'collections',
            visible: willBeOn,
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.resetCollectionData();
  },
  methods: {
    ...mapMutations({
      resetCollectionData: 'shop/resetCollectionData',
      setCollection: 'shop/setCollection',
    }),
    editCollection(collection) {
      this.setCollection(collection);
      this.$router.push({ name: 'shop-area.pages.collections-detail' });
    },
    async createCollection() {
      this.$router.push({ name: 'shop-area.pages.collections-detail' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.collections {
  .new-btn-wrapper {
    button {
      height: 40px;
      margin-bottom: 26px;
    }
  }
}
</style>
