import ideaHelper from '@/ideaHelper/ideaHelper';
import store from '@/store/Store';

function checkMinAndMax(value, options) {
  let valid = true;
  if (options.min || options.min === 0) {
    valid = valid && value >= options.min;
  }
  if (options.max || options.max === 0) {
    valid = valid && value <= options.max;
  }

  return valid;
}

const _defaultOptions = {
  tags: {
    min: 3,
    max: 25,
    maxlength: 50,
  },
  name: {
    min: 1,
    max: 50,
  },
  description: {
    max: 200,
  },
};

export default {
  getDefaultOptions: function () {
    return _defaultOptions;
  },
  validateTags(idea, options = {}) {
    options = { ..._defaultOptions.tags, ...options };

    const translation = ideaHelper.getTranslation(idea, options.locale);
    if (!translation || translation.autotranslated) {
      return true;
    }
    var tags = translation.tags || [];

    return checkMinAndMax(tags.length, options);
  },
  validateName(idea, options) {
    options = { ..._defaultOptions.name, ...options };

    const translation = ideaHelper.getTranslation(idea, options.locale);

    if (!translation || translation.autotranslated) {
      return true;
    }
    var name = translation.name || '';

    return checkMinAndMax(name.length, options);
  },
  validateDescription(idea, options) {
    options = { ..._defaultOptions.description, ...options };
    const translation = ideaHelper.getTranslation(idea, options.locale);
    if (!translation || translation.autotranslated) {
      return true;
    }
    var description = translation.description || '';

    return checkMinAndMax(description.length, options);
  },
  validateLocale: function (idea, options, strict, locale) {
    let valid = true;
    let tagsValid = true;
    let descriptionValid = true;

    options = options || {};

    const nameValid = this.validateName(idea, { ...options.name, locale });

    if (strict) {
      tagsValid = this.validateTags(idea, { ...options.tags, locale });
      descriptionValid = this.validateDescription(idea, {
        ...options.description,
        locale,
      });
    }

    valid = tagsValid && nameValid && descriptionValid;

    return {
      result: valid,
      locale,
      reasons: {
        tags: !tagsValid,
        name: !nameValid,
        description: !descriptionValid,
      },
    };
  },
  validate: function (idea, options = {}, strict) {
    // if translations are editable all locales on idea are validated, otherwise only default user locale (language based)
    // validated locales can be overwriten by options.locales

    if (!options.locales) {
      const translationsEditable = store.getters['dms/translationsEditable'];

      if (!translationsEditable) {
        options.locales = [store.getters['user/language']];
      }
    }

    const translationsToValidate = options.locales
      ? idea.translations.filter((translation) =>
          options.locales.includes(translation.locale),
        )
      : idea.translations;
    const localeValidation = translationsToValidate.map((translation) =>
      this.validateLocale(idea, options, strict, translation.locale),
    );

    return {
      result: !localeValidation.some((validation) => !validation.result),
      locales: localeValidation,
    };
  },
};
