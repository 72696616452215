<template>
  <div class="dialog-size-lg">
    <div class="modal-body qrContainer">
      <h2 class="heading">{{ heading }}</h2>
      <div class="qrContainer2">
        <div class="canvasContainer">
          <QRCanvas :options="qrCodeOptions" />
        </div>
        <div>
          <h3>
            {{
              $t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.QR_OVERLAY_SUBHEADING_1')
            }}
          </h3>
          <ol class="explanationList">
            <li>
              {{
                $t(
                  'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.QR_OVERLAY_EXPLANATION_POINT_1',
                )
              }}
            </li>
            <li>
              {{
                $t(
                  'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.QR_OVERLAY_EXPLANATION_POINT_2',
                )
              }}
            </li>
            <li>
              {{
                $t(
                  'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.QR_OVERLAY_EXPLANATION_POINT_3',
                )
              }}
            </li>
          </ol>
          <h3 class="subHeading2">
            {{
              $t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.QR_OVERLAY_SUBHEADING_2')
            }}
          </h3>
          <div class="linkExplanation">
            {{
              $t(
                'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.QR_OVERLAY_EXPLANATION_LINK',
              )
            }}
          </div>
          <a
            :href="data.targetUrl"
            class="link-main shortLink"
            target="_blank"
            >{{ data.targetUrl }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  name: 'UrlQRCodeDialog',
  components: {
    QRCanvas: defineAsyncComponent(
      async () => (await import('qrcanvas-vue')).QRCanvas,
    ),
  },
  props: {
    modalInstance: { type: Object, required: true },
    data: { type: Object, required: true },
  },
  computed: {
    qrCodeOptions() {
      return {
        size: 300,
        correctLevel: 'H',
        foreground: '#000000',
        data: this.data.targetUrl,
      };
    },
    heading() {
      const h = this.$t(
        'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.QR_OVERLAY_HEADING',
        {
          platform: this.data.displayName,
        },
      );
      return h.replace('&amp;', '&'); // stupid angular filter results in double-escaping -.-
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
.qrContainer {
  word-break: break-word;
}
.qrContainer2 {
  display: flex;
}
.heading {
  margin-top: 0;
}
.canvasContainer {
  padding: 5px;
  border: 3px solid $pa-color-main;
  margin: 1rem 1.5rem 0 0;
  height: 100%;
}
.explanationList {
  padding-left: 1rem;
  > li {
    margin-bottom: 0.5rem;
  }
}
.linkExplanation {
  margin-bottom: 0.5rem;
}
.subHeading2 {
  margin-top: 3rem;
}
.shortLink {
  // this is necessary because in our usual fonts you can not tell Il and O0 apart
  font-family: 'Courier New', Courier, monospace;
}
</style>
