<template>
  <div class="box">
    <div class="box-header">
      <h2>{{ $t('ACCOUNT.BETA.HEADING') }}</h2>
    </div>
    <div class="box-content">
      <p>
        {{ $t('ACCOUNT.BETA.DESCRIPTION') }}
        <a class="link-main" :href="mailToLink">{{ mail }}</a
        >.
      </p>
      <ToggleSwitch
        v-model="currentBetaOptin"
        class="beta-switch"
        label="ACCOUNT.BETA.TOGGLE_LABEL"
        condensed
      />
      <AccountPageActions :save="save" @on-revert="revert" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import AccountPageActions from 'src/app/partnerarea/account/AccountPageActions.vue';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import unsavedChangesService from '@/unsavedChangesService/unsavedChangesService';
import analytics from '@/tracking/analytics';
import contactData from '@/contactData/contactData';

export default {
  name: 'Beta',
  components: {
    AccountPageActions,
    ToggleSwitch,
  },
  data() {
    return {
      currentBetaOptin: false,
      mail: contactData.getMpMail(),
    };
  },
  computed: {
    ...mapState({
      userId: (s) => s.user.data.id,
    }),
    ...mapGetters({
      getSetting: 'settings/getSetting',
    }),
    betaOptin() {
      return this.getSetting('PA_BETA_OPTIN');
    },
    hasDataChanged() {
      return this.betaOptin !== this.currentBetaOptin;
    },
    mailToLink() {
      return `mailTo:${this.mail}?bcc=partnerapp@spreadshirt.net&subject=Partner Area Beta Feedback (Spread-ID ${this.userId})`;
    },
  },
  created() {
    this.currentBetaOptin = this.betaOptin;

    unsavedChangesService.registerComponent({
      check: () => this.hasDataChanged,
      save: this.save,
    });
  },
  methods: {
    ...mapActions({
      setSetting: 'settings/setSetting',
    }),
    save() {
      analytics.logEvent('beta-optin-toggle', { optin: this.currentBetaOptin });

      return this.setSetting({
        key: 'PA_BETA_OPTIN',
        value: this.currentBetaOptin,
      });
    },
    revert() {
      this.currentBetaOptin = this.betaOptin;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
.box-content {
  p {
    color: $grey60;
    margin: 0;
    max-width: 700px;
  }
}

.beta-switch {
  display: inline-block;
  margin-top: 24px;

  :deep(.label) {
    margin-right: 16px;
  }
}
</style>
