<template>
  <div class="sales">
    <div ref="salesList" class="sales-list">
      <StatisticsFilters
        heading="STATISTICS.SALES"
        display-pos-filter
        display-date-from-filter
        display-date-to-filter
        :flush-bottom="salesSummary && !!salesSummary.length"
        @filter-update="onFilterUpdate"
      />
      <SalesSummary :data="salesSummary" class="sales-summary" />
      <img
        v-if="!salesByDay"
        class="sprd-loader loader"
        src="/images/loader.svg"
        alt="loader"
      />
      <div v-if="salesByDay" class="list-content">
        <ul v-if="salesByDay.length">
          <li v-for="saleDay in salesByDay" :key="saleDay.day" class="day">
            <div class="day-label">
              {{ saleDay.day }} - {{ saleDay.sales.length }}
              {{ $t('STATISTICS.SALE_DETAILS.ORDERS') }}
            </div>
            <ul>
              <li
                v-for="sale in saleDay.sales"
                :key="sale.orderId"
                class="sale"
              >
                <div class="sale-label">
                  <div class="order-info">
                    {{ $t('STATISTICS.ORDER_NUMBER') }}:
                    <strong>{{ sale.orderId }}</strong>
                    - {{ sale.$amount }}
                    {{ $t('STATISTICS.SALE_DETAILS.ITEMS') }}
                  </div>
                  <div class="shipping">
                    {{ $t('STATISTICS.SALE_DETAILS.SHIPPING') }}:
                    <strong>{{ sale.$shippingCountry }}</strong>
                  </div>
                </div>
                <ul>
                  <li v-for="item in sale.items" :key="item.order.orderItemId">
                    <a
                      class="sale-item sales-status"
                      href
                      @click.prevent="
                        openSaleDetails({ orderItemId: item.$data.orderItemId })
                      "
                    >
                      <div
                        class="status"
                        :style="{
                          color: SALES_STATES[item.combinedStatus].color,
                        }"
                      >
                        <div>
                          <Icon
                            :icon="SALES_STATES[item.combinedStatus].icon"
                          ></Icon>
                          <strong>{{ item.$data.status }}</strong>
                        </div>
                      </div>
                      <SaleItemImage
                        :image="item.images[0].url"
                        :type="item.images[0].type"
                        :width="130"
                        :height="130"
                        :request-props="{ width: 150, height: 150 }"
                        :design-id="
                          item.designs?.length ? item.designs[0].id : null
                        "
                      />
                      <div class="pos">
                        <div>
                          <small class="cell-heading">{{
                            $t('STATISTICS.SALE_DETAILS.POS')
                          }}</small>
                          <strong>{{ item.$data.pointOfSale }}</strong>
                        </div>
                      </div>
                      <div class="amount">
                        <div>
                          <small class="cell-heading">{{
                            $t('STATISTICS.SALE_DETAILS.QUANTITY')
                          }}</small>
                          <strong>{{ item.$data.amount }}</strong>
                        </div>
                      </div>
                      <div class="price">
                        <div>
                          <small class="cell-heading">{{
                            $t('STATISTICS.SALE_DETAILS.PRICE')
                          }}</small>
                          <strong> {{ item.$data.price }} </strong>
                        </div>
                      </div>
                      <div class="commission">
                        <div>
                          <small class="cell-heading">{{
                            $t('STATISTICS.SALE_DETAILS.COMMISSION')
                          }}</small>
                          <strong>{{ item.$data.commission }}</strong>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div v-if="!salesByDay.length" class="empty-message">
          <Icon icon="x"></Icon>
          <strong>{{ $t('STATISTICS.CREDIT_DETAILS.NO_RESULTS') }}</strong>
        </div>
        <div v-if="salesByDay.length" class="pagination-wrapper">
          <div class="export">
            <a
              :href="downloadLink"
              class="link-main"
              download="sales.csv"
              target="_self"
            >
              <Icon icon="download-file"></Icon>
              {{ $t('STATISTICS.DOWNLOAD_CSV') }}
            </a>
          </div>
          <Pagination
            v-if="metaData.count > pagination.pageSize"
            :total="metaData.count"
            :page-size="pagination.pageSize"
            :current-page="pagination.currentPage"
            add-state-param
            @page-change="onPageChange"
          ></Pagination>

          <div v-if="saleItemCount > 5" class="scroll">
            <a href="#" class="link-main" @click.prevent="scrollToTop()">
              <Icon class="rotate-icon-270" icon="arrow-end"></Icon>
              {{ $t('STATISTICS.TO_TOP') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns/format';
import { endOfDay } from 'date-fns/endOfDay';
import { parseISO } from 'date-fns/parseISO';
import { startOfDay } from 'date-fns/startOfDay';
import { mapGetters } from 'vuex';

import statisticsService from 'src/app/commons/api/statisticsService/statisticsService';
import date from 'src/app/commons/date/date';
import Pagination from 'src/app/commons/pagination/Pagination.vue';
import SaleItemImage from './saleImage/SaleItemImage.vue';
import { pointOfSaleName } from '@/api/models/pointsOfSale/pointsOfSale';
import SalesSummary from './SalesSummary.vue';
import StatisticsFilters from '../StatisticsFilters.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import SaleDetailsDialog from 'src/app/partnerarea/statistics/sales/saleDetailsDialog/SaleDetailsDialog.vue';

export default {
  name: 'Sales',
  components: {
    Pagination,
    SaleItemImage,
    SalesSummary,
    StatisticsFilters,
  },
  data() {
    return {
      SALES_STATES: statisticsService.getSaleStates(),
      pagination: {
        pageSize: 20,
        currentPage: 0,
        maxSize: 5,
      },
      metaData: {
        count: 0,
        limit: 0,
        offset: 0,
        listSize: 0,
      },
      filters: null,
      salesByDay: null,
      saleItemCount: null,
      downloadLink: null,
      salesSummary: null,
    };
  },
  computed: {
    ...mapGetters({
      shippingCountryByIso: 'platform/shippingCountryByIso',
    }),
  },
  created() {
    if (this.$route.query.page && this.$route.query.page >= 1) {
      this.pagination.currentPage = this.$route.query.page - 1;
    }
  },
  methods: {
    async fetchSalesData() {
      this.salesByDay = null;

      this.fetchSalesSummary();

      const data = await statisticsService.getSales(
        this.getOptions({ pagination: true }),
      );

      this.metaData = {
        count: data.count,
        limit: data.limit,
        offset: data.offset,
        listSize: data.list.length,
      };
      this.salesByDay = this.prepareSalesData(data.list);
      this.saleItemCount = data.list.reduce(
        (prev, cur) => prev + cur.items.length,
        0,
      );
      this.downloadLink = statisticsService.getSalesDetailsDownloadUrl(
        this.getOptions(),
      );

      if (!this.salesByDay.length && this.pagination.currentPage > 0) {
        this.onPageChange(0);
      }

      return data;
    },
    async fetchSalesSummary() {
      this.salesSummary = null;

      const data = await statisticsService.getSalesSummary(
        this.getOptions({ pagination: true }),
      );
      this.salesSummary = data.salesSummaries;
    },
    getOptions(opts = {}) {
      const options = {
        dateFrom: this.filters.dateFrom
          ? startOfDay(parseISO(this.filters.dateFrom))
          : null,
        dateTo: this.filters.dateTo
          ? endOfDay(parseISO(this.filters.dateTo))
          : null,
        pos: this.filters.pointOfSale.value,
      };

      if (opts.pagination) {
        options.limit = this.pagination.pageSize;
        options.offset = this.pagination.currentPage;
      }

      return options;
    },
    prepareSalesData(sales) {
      const result = [];

      const data = sales.reduce((result, current) => {
        const group = `${format(parseISO(current.saleDate), 'yyyy-MM-dd')}`;
        if (!result[group]) {
          result[group] = [];
        }

        current.$shippingCountry = this.shippingCountryByIso(
          current.shippingCountry,
        );
        current.$amount = current.items.reduce(
          (res, curr) => res + curr.salesAmount,
          0,
        );

        current.items.forEach((item) => {
          item.$data = {
            day: date(group, 'longDate'),
            status: this.$t(
              `STATISTICS.SALE_DETAILS.STATUS.${
                this.SALES_STATES[item.combinedStatus].key
              }.NAME`,
            ),
            pointOfSale: pointOfSaleName(item.pointOfSale),
            amount: this.sprdNumber(item.salesAmount),
            price: this.sprdCurrency(
              item.price.totalPrice,
              item.price.priceCurrencyId,
            ),
            commission: this.sprdCurrency(
              item.commission.totalCommission,
              item.commission.commissionCurrencyId,
            ),
            orderItemId: item.order.orderItemId,
          };
        });

        result[group].push(current);
        return result;
      }, {});
      Object.keys(data).forEach((day) => {
        result.push({
          day: date(day, 'longDate'),
          sales: data[day],
        });
      });

      return result;
    },
    onFilterUpdate(filters, { initial }) {
      this.filters = filters;

      if (!initial) {
        this.pagination.currentPage = 0;
      }
      this.fetchSalesData();
    },
    openSaleDetails(data) {
      dialogService.openDialog(SaleDetailsDialog, data);
    },
    async onPageChange(newPage) {
      this.pagination.currentPage = newPage;
      await this.fetchSalesData();
      this.scrollToTop();
    },
    scrollToTop() {
      this.$refs.salesList.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/type';

.sales {
  width: 100%;
  margin: 0 auto;

  .sales-summary {
    border-radius: 0 0 $border-radius-medium $border-radius-medium;
  }

  .sales-list {
    max-width: 1400px;

    .loader {
      width: 100%;
      height: 120px;
      margin-top: 48px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .day-label {
    padding: 0 0 8px 0;
    margin-top: 25px;
    border-bottom: 1px solid $sprd-color-light-grey;
    font-weight: bold;
    @extend .text-xxl;
  }

  .day:first-child .day-label {
    margin-top: 0;
  }

  .sale-label {
    padding: 0 0 8px 0;
    margin-top: 16px;
    display: flex;

    .shipping {
      margin-left: auto;
    }
  }

  .sale ul {
    li {
      background-color: $white;

      &:first-child {
        border-radius: $border-radius-medium $border-radius-medium 0 0;
      }

      &:last-child {
        border: none;
        border-bottom-left-radius: $border-radius-medium;
        border-bottom-right-radius: $border-radius-medium;
      }

      &:nth-child(even) {
        background-color: #f8f8f8;
      }

      &:hover {
        transform: scale(1);
        box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.24);
      }
    }
  }

  .sale-item {
    display: flex;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 10px;

      & > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .status {
      width: 10%;
      min-width: 120px;
      border-right: 1px solid $grey20;

      & > div {
        align-items: center;
      }

      .icon {
        font-size: 2.2em;
        margin-bottom: 5px;
      }

      strong {
        text-align: center;
      }
    }

    .pos {
      width: 20%;

      span {
        text-align: center;
      }
    }

    .amount {
      width: 15%;
    }

    .price {
      width: 20%;
    }

    .commission {
      width: 20%;
    }

    .cell-heading {
      margin-bottom: 5px;
      @extend .text-xs;
    }
  }

  .pagination-wrapper {
    background-color: transparent;
    border-top: 1px solid $sprd-color-light-grey;
    margin-top: 30px;
  }
}
</style>

<style lang="scss">
@import 'src/scss/styleguide/colors';

.sales-status {
  position: relative;
}
</style>
