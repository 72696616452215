<template>
  <div class="box">
    <div class="box-header">
      <h2 v-html="$t('ACCOUNT.TROLLEY.HEADING')"></h2>
    </div>
    <div v-if="hasSavedAddress" class="box-content">
      <MfaRequired show-content-without-pass overlay-content-without-pass>
        <TrolleyIframe />
      </MfaRequired>
    </div>
    <div v-else class="box-content no-personal-data">
      <img src="/images/checklist.svg" />
      <h3>
        {{ $t('ACCOUNT.TAXATION.PERSONAL_DATA_MISSING.HEADING') }}
      </h3>
      <p>
        {{ $t('ACCOUNT.TAXATION.PERSONAL_DATA_MISSING.TEXT') }}
      </p>
      <router-link to="/account/personal" class="link-main">
        {{ $t('ACCOUNT.TAXATION.PERSONAL_DATA_MISSING.CTA') }}
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import MfaRequired from '../security/mfa/MfaRequired.vue';
import TrolleyIframe from './TrolleyIframe.vue';
import { useRouter } from 'vue-router';
import { onBeforeMount } from 'vue';

const store = useStore();
const hasSavedAddress = store.getters['account/hasSavedAddress'];

onBeforeMount(() => {
  const isAdmin = store.getters['account/isAdmin'];
  if (isAdmin) {
    const router = useRouter();
    router.push({
      name: 'partnerarea.account',
      params: { subpage: 'personal' },
    });
  }
});
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.no-personal-data {
  img {
    width: 256px;
  }

  h3 {
    margin-top: 24px;
  }

  p {
    @extend .text-grey;
    margin-bottom: 16px;
    max-width: 650px;
  }
}
</style>
