import uploadService from '@/api/upload/uploadService';
import apiClient from '@/api/client/apiClient';
import localeService from 'src/app/commons/localeService/localeService';

const getLocale = () =>
  localeService.convertToSprdLocale(localeService.getLocale());

const fetch = (store, method, url, queryParams, body = null) => {
  const id = Math.floor(Math.random() * 9000000);
  store.commit('shop/addLoadingJob', id);
  const req = {
    method,
    url: url.replace('<shopId>', store.state.shop.currentShop.id),
    params: Object.assign({ locale: getLocale() }, queryParams),
    data: body,
  };
  return apiClient
    .request(req)
    .then((res) => res.data)
    .catch((e) => {
      console.error(`Failed to ${method} ${req.url}`, e);
      throw e;
    })
    .finally(() => store.commit('shop/removeLoadingJob', id));
};

export const shopAPI = {
  async createShop(userId, shopName, shopDescriptionName, template) {
    return apiClient.request({
      method: 'POST',
      url: `shopsettings/users/${userId}/createSpreadshop`,
      params: {
        locale: getLocale(),
      },
      data: {
        shopName,
        shopDescriptionName,
        template,
      },
    });
  },

  getLocalization(store) {
    return fetch(store, 'GET', 'shopsettings/localization');
  },

  getTemplates(store) {
    return fetch(store, 'GET', 'shopsettings/templates');
  },

  getShopSettings(store, shopId) {
    return fetch(store, 'GET', `shopsettings/shops/${shopId}/settings`).then(
      (settings) =>
        Object.assign(
          {},
          {
            listMode: 'PRODUCT',
            listLoadingCount: 48,
            showListPrice: false,
            productSuggestionMode: 'SAME_DESIGN',
            headerHtml: '',
            footerHtml: '',
            customCss: '',
          },
          settings,
        ),
    );
  },

  getShopConfigs(store, shopId) {
    return fetch(store, 'GET', `shopsettings/shops/${shopId}/configs`);
  },

  getShopSocialMediaChannels(store) {
    return fetch(store, 'GET', 'shopsettings/shops/<shopId>/social');
  },

  getShopImages(store, imageUseCase) {
    return fetch(
      store,
      'GET',
      `shopsettings/shops/<shopId>/images/${imageUseCase}`,
    );
  },

  getShopStockImages(store, imageUseCase) {
    return fetch(
      store,
      'GET',
      `shopsettings/shops/<shopId>/images/stock/${imageUseCase}`,
    );
  },

  getModelPersonPreview(store) {
    return fetch(store, 'GET', 'shopsettings/shops/<shopId>/models');
  },

  getStartPage(store) {
    return fetch(store, 'GET', 'shopsettings/shops/<shopId>/startPage');
  },

  getAboutPage(store) {
    return fetch(store, 'GET', 'shopsettings/shops/<shopId>/aboutPage');
  },

  getTracking(store) {
    return fetch(store, 'GET', 'shopsettings/shops/<shopId>/tracking');
  },

  getPromotions(store) {
    return fetch(store, 'GET', 'shopsettings/shops/<shopId>/promotions');
  },

  getYoutubeChannels(store) {
    return fetch(store, 'GET', `shopsettings/shops/<shopId>/youtube/channels`);
  },

  getYoutubeSellables(store, channelId) {
    return fetch(
      store,
      'GET',
      `shopsettings/shops/<shopId>/youtube/sellables?channelId=${channelId}`,
    );
  },

  checkShopNameAvailabilty(shopname) {
    return apiClient
      .request({
        method: 'GET',
        url: `shopsettings/shop/nameAvailable`,
        params: {
          name: shopname,
        },
      })
      .then((res) => res.data);
  },

  validateVideoUrl(store, videoUrl) {
    return fetch(store, 'POST', 'shopsettings/validateVideo', null, {
      url: videoUrl,
    });
  },

  deleteShopImage(store, imageUseCase, imageName) {
    return fetch(
      store,
      'DELETE',
      `shopsettings/shops/<shopId>/images/${imageUseCase}/${imageName}`,
    );
  },

  editShopSettings(store, shopSettings) {
    return fetch(
      store,
      'POST',
      'shopsettings/shops/<shopId>/settings',
      null,
      shopSettings,
    );
  },

  editShopConfigs(store, shopConfigs) {
    return fetch(
      store,
      'POST',
      'shopsettings/shops/<shopId>/configs',
      null,
      shopConfigs,
    );
  },

  editShopSocialMediaChannels(store, channels) {
    Object.keys(channels).forEach((channel) => {
      if (channels[channel] === '') {
        channels[channel] = null;
      }
    });

    return fetch(
      store,
      'PUT',
      'shopsettings/shops/<shopId>/social',
      null,
      channels,
    );
  },

  editStartPage(store, startPage) {
    return fetch(
      store,
      'PUT',
      'shopsettings/shops/<shopId>/startPage',
      null,
      startPage,
    );
  },

  editAboutPage(store, aboutPage) {
    return fetch(
      store,
      'PUT',
      'shopsettings/shops/<shopId>/aboutPage',
      null,
      aboutPage,
    );
  },

  editPromotions(store, promotions) {
    const payload = promotions.promotions.map((promo) => ({
      id: promo.id,
      active: promo.active,
      start: promo.start,
    }));
    return fetch(
      store,
      'PUT',
      'shopsettings/shops/<shopId>/promotions',
      null,
      payload,
    );
  },

  saveTracking(store, trackingData) {
    return fetch(
      store,
      'PUT',
      'shopsettings/shops/<shopId>/tracking',
      null,
      trackingData,
    );
  },

  activateShop(store) {
    return fetch(store, 'POST', 'shopsettings/shops/<shopId>/launch');
  },

  editShopImages(
    store,
    imageObj,
    imageUseCase,
    progressCallback,
    addtionalTag = null,
  ) {
    const shopId = store.state.shop.currentShop.id;
    const locale = getLocale();

    return uploadService
      .upload({
        url: `shopsettings/shops/${shopId}/images/${imageUseCase}?locale=${locale}&clientTime=${new Date().getTime()}${
          addtionalTag != null ? `&tag=${addtionalTag}` : ''
        }`,
        data: { image: imageObj },
        onUploadProgress: (evt) => {
          if (progressCallback) {
            progressCallback(parseInt((100.0 * evt.loaded) / evt.total));
          }
        },
      })
      .then(
        (resp) => resp.data,
        (res) => {
          throw res.status;
        },
      );
  },

  async getShop(userId, shopId) {
    const shops = await shopAPI.getShops(userId);
    return shops.find((shop) => shop.id === shopId);
  },

  async getShops(userId) {
    const res = await shopAPI.getShopsForUser(userId);
    const shops = res.data || {};
    return Object.keys(shops).map((shopId) => {
      const currentShop = shops[shopId];
      return {
        id: shopId,
        name: currentShop.shopName,
        type: currentShop.shopType,
        domain: currentShop.shopDomain,
        teaserImage: currentShop.shopTeaserImage,
        logoImage: currentShop.shopLogoImage,
        hidden: currentShop.hidden,
        youtubeConnected: currentShop.youtubeConnected,
      };
    });
  },

  getShopsForUser(userId) {
    return apiClient.request({
      method: 'GET',
      url: `shopsettings/users/${userId}/shopIds`,
      params: {
        locale: getLocale(),
      },
    });
  },

  getShopDeeplinkForIdea(shopId, ideaId) {
    return apiClient.request({
      method: 'GET',
      url: `shopsettings/shops/${shopId}/ideas/${ideaId}/deeplink`,
    });
  },

  getAvailableProductTypes(store) {
    return fetch(store, 'GET', 'shopsettings/shops/<shopId>/productTypes');
  },

  getSharableIdeas(store) {
    return fetch(store, 'GET', 'shopsettings/shops/<shopId>/sharableIdeas');
  },

  getSharableSellables(store, ideaId) {
    return fetch(
      store,
      'GET',
      `shopsettings/shops/<shopId>/sharableIdeas/${ideaId}`,
    );
  },

  getSharableSellableDetail(store, ideaId, sellableId) {
    return fetch(
      store,
      'GET',
      `shopsettings/shops/<shopId>/sharableIdeas/${ideaId}/sellables/${sellableId}`,
    );
  },

  async getTrackingMetrics(shopId) {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `shopsettings/shops/${shopId}/trackingMetrics`,
    });

    return data;
  },

  getCustomDomain(store) {
    return fetch(store, 'GET', 'shopsettings/shops/<shopId>/custom-domain');
  },

  createCustomDomain(store, hostname, locale) {
    return fetch(
      store,
      'POST',
      'shopsettings/shops/<shopId>/custom-domain',
      null,
      {
        domainName: hostname,
        locale,
      }
    );
  },

  deleteCustomDomain(store) {
    return fetch(store, 'DELETE', 'shopsettings/shops/<shopId>/custom-domain');
  },
};
