<template>
  <div class="register-page overflow-y login">
    <div class="top">
      <Icon icon="sprd-heart" />
    </div>
    <div class="skip-wrapper flex-center-vertical">
      <button
        type="button"
        class="flex-align-right modal-close"
        @click="onTemplateSelect('HOBBY')"
      >
        <Icon icon="close" /><span class="modal-close-label">{{
          $t('POS.TEMPLATES.OVERVIEW.SKIP')
        }}</span>
      </button>
    </div>
    <div class="content">
      <div class="themeheader">
        <h1>{{ $t('POS.TEMPLATES.OVERVIEW.HEADLINE') }}</h1>
        <p>{{ $t('POS.TEMPLATES.OVERVIEW.SUBHEADLINE') }}</p>
      </div>
      <div v-if="availableTemplates">
        <SelectTemplateContent
          :available-templates="availableTemplates"
          @input="onTemplateSelect"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SelectTemplateContent from 'src/app/partnerarea/pos/shop/design/templates/SelectTemplateContent.vue';

export default {
  name: 'ThemeSelection',
  components: {
    SelectTemplateContent,
  },
  data() {
    return {
      availableTemplates: null,
    };
  },
  created() {
    return this.onTemplateSelect('HOBBY');
  },
  methods: {
    onTemplateSelect(template) {
      this.$router.push({ name: 'partnerarea.register', params: { template } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.themeheader {
  margin-bottom: 24px;
}

.skip-wrapper,
.content {
  width: 80%;
  margin: 0 auto;
  max-width: 1400px;
}

.skip-wrapper {
  height: 60px;
  margin-top: -60px;
  position: relative;

  @media (max-width: 1399px) {
    height: 50px;
    margin-top: -50px;
  }
}

.content {
  padding: 12px 0;
}
</style>
