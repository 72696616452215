import eventBus from '@/eventBus/eventBus';

export default {
  namespaced: true,
  state: {
    currentToast: null,
  },
  mutations: {
    addToast(
      state,
      { variant, text, timeout, onRemove, dismissible, hideIcon },
    ) {
      state.currentToast = {
        variant,
        text,
        timeout: timeout || 4000,
        onRemove,
        dismissible,
        hideIcon,
      };

      eventBus.$emit('toasts:added');
    },
    removeToast(state) {
      if (state.currentToast.onRemove) {
        state.currentToast.onRemove();
      }
      state.currentToast = null;

      eventBus.$emit('toasts:removed');
    },
  },
};
