<template>
  <div id="map" class="bestseller-world-map">
    <div style="display: none">
      <BestsellerWorldMapTooltip
        :id="tooltipId"
        :country-id="currentCountryData.countryId"
        :sales="currentCountryData.sales"
        :name="currentCountryData.name"
      />
    </div>

    <div id="svgMap">
      <div class="legend">
        <p class="min">{{ sales.min }}</p>
        <div class="scale"></div>
        <p class="max">{{ sales.max }} {{ $t('DASHBOARD.SALES') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import svgMap from 'svgmap';
import 'svgmap/dist/svgMap.min.css';
import BestsellerWorldMapTooltip from './BestsellerWorldMapTooltip.vue';
import { genId } from 'src/app/commons/utils';

export default {
  name: 'Bestseller',
  components: {
    BestsellerWorldMapTooltip,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mapData: null,
      map: null,
      tooltipElement: null,
      tooltipId: genId('bestseller-world-map-tooltip'),
      currentCountryData: {
        countryId: '',
        sale: 0,
        name: '',
      },
      sales: {
        min: null,
        max: null,
      },
    };
  },
  mounted() {
    window.addEventListener('resize', this.positionMap);
    this.buildMap();
  },
  created() {
    svgMap.prototype.mapPaths = {
      ...svgMap.prototype.mapPaths,
      ATLNTS: {
        d: 'm 747.3 331.7 l -2.3 0.3 l 0 -2 l -2 0 l -1 1 l -2 1 l 1 2 l -1 4 l 2 0 l 1 0 l 3 -1 l 2 -2 l -0.7 -3.3 l 0 0 z',
      },
    };

    svgMap.prototype.countries = {
      ...svgMap.prototype.countries,
      ATLNTS: this.$t('STATISTICS.BESTSELLERS.MAP.ATLANTIS_NAME'),
    };

    this.mapData = Object.keys(this.data)
      .filter((key) => this.data[key].sales)
      .reduce((res, key) => ((res[key] = this.data[key]), res), {});
    const sales = Object.values(this.mapData).map((entry) => entry.sales);
    this.sales.min = Math.min(...sales);
    this.sales.max = Math.max(...sales);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.positionMap);
  },
  methods: {
    buildMap() {
      this.map = new svgMap({
        targetElementID: 'svgMap',
        data: {
          data: {
            sales: {
              name: 'Sales',
            },
          },
          applyData: 'sales',
          values: this.mapData,
        },
        minZoom: 1,
        maxZoom: 1,
        initialZoom: 1,
        mouseWheelZoomEnabled: false,
        flagURL: '/images/flags/{0}.svg',
        colorNoData: '#e5e5e5',
        colorMin: '#99C2E3',
        colorMax: '#28619E',
        onGetTooltip: (tooltip, country) => {
          this.currentCountryData.countryId = country;
          this.currentCountryData.name =
            this.$store.state.platform.shippingCountries[country] ||
            svgMap.prototype.countries[country];
          this.currentCountryData.sales = this.data[country]?.sales;

          if (!this.currentCountryData.sales && country !== 'ATLNTS') {
            tooltip.style.display = 'none';
          } else {
            tooltip.style.display = '';
          }

          return document.getElementById(this.tooltipId);
        },
      });
      this.map.mapPanZoom.disablePan();
      this.positionMap();
    },
    positionMap() {
      if (!this.map) {
        return;
      }

      this.map.mapPanZoom.resize();
      this.map.mapPanZoom.fit();
      this.map.mapPanZoom.center();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/type';

:deep(.svgMap-map-wrapper) {
  background: $grey0;
}

:deep(.svgMap-map-controls-wrapper) {
  display: none;
}

.bestseller-world-map {
  background-color: $grey0;
}

#svgMap {
  position: relative;
}

.legend {
  position: absolute;
  z-index: 1;
  left: 24px;
  bottom: 24px;
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }

  .scale {
    width: 124px;
    height: 16px;
    border-radius: $border-radius-medium;
    background-image: linear-gradient(to right, #99c2e3, #28619e);
    margin: 0 8px;
  }
}
</style>

<style lang="scss">
@import 'src/scss/styleguide/colors';

.svgMap-tooltip {
  border-bottom: none !important;
}
.svgMap-tooltip-pointer:after {
  border: none !important;
}

.svgMap-country {
  &:not(#svgMap-map-country-ATLNTS)[fill='#e5e5e5']:hover {
    filter: none !important;
  }

  &:hover {
    stroke: $grey0 !important;
    stroke-width: 1 !important;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5)) !important;
  }
}
</style>
