<template>
  <button
    type="button"
    class="btn-progress"
    :class="`${state} ${iconRight ? 'icon-right' : null}`"
    @click="handleClick"
  >
    <div :class="{ invisible: state !== 'IDLE' }">
      <Icon v-if="icon" :icon="`${icon}`" />
      {{ $t(label) }}
    </div>
    <div v-if="state === 'LOADING'"></div>
    <div v-if="state === 'SUCCESS'">
      <Icon icon="accept" />
    </div>
    <div v-if="state === 'ERROR'">
      <Icon icon="close" />
    </div>

    <div class="overlay"></div>
  </button>
</template>

<script>
export default {
  name: 'ProgressButton',
  props: {
    onClick: {
      type: Function,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      default: null,
    },
    iconRight: {
      default: false,
    },
  },
  data() {
    return {
      state: 'IDLE',
    };
  },
  methods: {
    handleClick(evt) {
      if (!(evt instanceof Event) || evt.isTrusted === false) {
        return;
      }

      if (this.state === 'LOADING') {
        return;
      }

      this.state = 'LOADING';
      this.onClick().then(
        () => {
          setTimeout(() => {
            this.state = 'SUCCESS';
          }, 500);
          setTimeout(() => {
            this.state = 'IDLE';
          }, 1500);
        },
        () => {
          setTimeout(() => {
            this.state = 'ERROR';
          });
          setTimeout(() => {
            this.state = 'IDLE';
          }, 3500);
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';

$btn-progress-dark-main: $pa-color-main-darker !default;
$btn-progress-dark-black: rgb(33, 33, 33) !default;
$btn-progress-disabled-main: $pa-color-main-light !default;
$btn-progress-red: $pa-color-red-darker !default;
$btn-progress-grey: rgb(244, 244, 244) !default;

.btn-progress {
  position: relative;

  &.icon-right {
    .icon {
      order: 1;
    }
  }

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > div:first-child {
    position: relative;
  }

  .icon {
    font-size: 1.5em;
    margin-right: 8px;
  }

  .overlay {
    display: block;
    border-radius: $border-radius-medium;
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $btn-progress-dark-main;
  }

  &.btn-grey-progress .overlay {
    background-color: $btn-progress-grey;
  }

  &.btn-dark .overlay {
    background-color: $btn-progress-dark-black;
  }

  &.btn-danger .overlay {
    background-color: $btn-progress-red;
  }

  &.LOADING:disabled {
    background-color: $btn-progress-disabled-main;
  }

  &.LOADING .overlay {
    animation: btn-progress-anim 2s ease-out;
    animation-fill-mode: forwards;
  }

  &.SUCCESS {
    background-color: $btn-progress-dark-main;
    color: #fff;
    animation: btn-progress-anim-msg 0.2s ease-out;
    animation-fill-mode: forwards;
  }

  &.btn-dark.SUCCESS {
    background-color: $btn-progress-dark-black;
  }

  &.ERROR {
    background-color: $btn-progress-red !important;
    color: #fff !important;
    animation: btn-progress-anim-msg 0.2s ease-out;
    animation-fill-mode: forwards;
  }

  &.SUCCESS,
  &.ERROR {
    .icon {
      margin-right: 0;
    }
  }

  @keyframes btn-progress-anim {
    0% {
      width: 0;
    }
    25% {
      width: 90%;
    }
    100% {
      width: 98%;
    }
  }

  @keyframes btn-progress-anim-msg {
    from {
      color: rgba(255, 255, 255, 0);
    }
    to {
      color: rgba(255, 255, 255, 1);
    }
  }

  .invisible {
    visibility: hidden;
  }
}
</style>
