import liveChatService from '@/liveChat/liveChatService';

export default {
  namespaced: true,
  state: {
    enabled: false,
    open: false,
    unreadMessages: 0,
  },
  getters: {},
  mutations: {
    setEnabled(state, enabled) {
      state.enabled = enabled;
    },
    setUnreadMessages(state, unreadMessages) {
      state.unreadMessages = unreadMessages;
    },
    setOpen(state, open) {
      state.open = open;
    },
  },
  actions: {
    async init({ commit }, customVariables) {
      commit('setEnabled', liveChatService.isEnabled());
      if (!liveChatService.isEnabled()) {
        return;
      }

      await liveChatService.init(customVariables, (count) =>
        commit('setUnreadMessages', count),
      );
      liveChatService.onOpenChange((open) => commit('setOpen', open));
    },
    openChat() {
      liveChatService.openChat();
    },
    clearSession() {
      liveChatService.clearSession();
    },
  },
};
