<template>
  <div class="empty-result-list">
    <div>
      <h1 class="welcome-heading">
        <Icon icon="lens" />
      </h1>
      <h1>{{ $t('GENERAL.SORRY') }}</h1>
      <h3>{{ $t('DESIGNS.LIST.NO_SEARCH_RESULT') }}</h3>
      <p>
        {{ $t('DESIGNS.LIST.SEARCH_AGAIN_OR_BACK_TO_ALL_DESIGNS') }}
        <a
          id="no-result-back-to-all-link"
          href="#"
          class="link-main"
          @click.prevent="$emit('clearSearchTerm')"
        >
          {{ $t('DESIGNS.LIST.TOOLBAR.ALL_DESIGNS') }}
        </a>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.empty-result-list {
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;

  & > div {
    width: 50%;
    height: 220px;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;

    .welcome-heading {
      margin: 0;
      font-size: 40px;

      .icon:first-child {
        transform: rotateY(180deg);
        margin-bottom: 10px;
      }
    }

    .welcome-subheading {
      margin: 0 0 20px 0;
    }

    .welcome-time {
      margin: 0 0 20px 0;
    }
  }
}
</style>
