<template>
  <div>
    <ToggleButton
      class="promoMode"
      :items="modes"
      :item-key="(lm) => lm.value"
      :item-selected="(item, value) => item.value === value.value"
      :value="selectedPromoMode"
      :disabled="!settings.promoChecked"
      @change="setPromoMode"
    />

    <div v-if="!customModeActive" class="standardModeDesc">
      {{ $t('POS.SHOP_SETTINGS.PROMO_APP.MODE_STANDARD_DESC') }}
    </div>
    <div v-if="customModeActive">
      <div class="heading-image">
        <strong>{{
          $t('POS.SHOP_SETTINGS.PROMO_APP.IMAGE.UPLOAD_IMG')
        }}</strong>
      </div>
      <ImageUpload
        class="clickable-img-upload"
        :image="image"
        :active-image-name="settings.promoImage"
        :active-image-url="activeImageUrl"
        @file-activated="onFileActivated"
        @file-deleted="onFileDeleted"
        @file-uploaded="onFileUploaded"
      />

      <ImageSelector
        :images="stockImages"
        :selected-image-filename="settings.promoStockImage"
        :on-image-selected="onStockImageSelected"
      />

      <div class="colorWrapper">
        <Color
          :label="$t(colorSettings.label)"
          :current-color="colorSettings.currentColor"
          :suggested-colors="colorSettings.suggestedColors"
          :custom-color-enabled="false"
          @update:current="updatePromoFontColor($event)"
          @update:custom="() => {}"
        />
      </div>
    </div>
    <div class="promotion-areas">
      <h4>{{ $t('POS.SHOP_SETTINGS.PROMO_APP.PROMO_AREAS') }}</h4>
      {{ $t('POS.SHOP_SETTINGS.PROMO_APP.PROMO_AREAS_DESC') }}
      <div class="startpage-promo-link" @click="goToStartpagePromoSection">
        {{ $t('POS.SHOP_SETTINGS.PROMO_APP.PROMO_AREAS_LINK_TEXT') }}
      </div>
    </div>
  </div>
</template>

<script>
import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';
import ToggleButton from 'src/app/commons/ToggleButton/ToggleButton.vue';
import ImageSelector from 'src/app/partnerarea/pos/shop/common/ImageSelector.vue';
import ImageUpload from 'src/app/partnerarea/pos/shop/imageUpload/ImageUpload.vue';
import Color from 'src/app/partnerarea/pos/shop/common/Color.vue';
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import { promo } from '../imageAttributes';

const MODE_STANDARD = {
  text: 'POS.SHOP_SETTINGS.PROMO_APP.MODE_STANDARD',
  value: 'STANDARD',
};

const MODE_CUSTOM = {
  text: 'POS.SHOP_SETTINGS.PROMO_APP.MODE_CUSTOM',
  value: 'CUSTOM',
};

export default {
  name: 'PromotionAppearance',
  components: {
    ToggleButton,
    ImageSelector,
    ImageUpload,
    Color,
  },
  mixins: [SettingsData],
  data() {
    return {
      modes: [MODE_STANDARD, MODE_CUSTOM],
      image: promo,
      activeImageUrl: null,
      rememberedPromoImage: null,
      stockImages: [],
    };
  },
  computed: {
    customModeActive() {
      return this.selectedPromoMode === MODE_CUSTOM;
    },
    colorSettings() {
      return {
        label: 'POS.SHOP_SETTINGS.PROMO_APP.FONT_COLOR',
        suggestedColors: ['#FFFFFF', '#000000'],
        currentColor:
          this.settings.promoFontColor || this.settings.schemeColorMain1, // replicates shop behaviour
      };
    },
    selectedPromoMode: {
      get() {
        return this.settings.promoCustomized ? MODE_CUSTOM : MODE_STANDARD;
      },
      set(mode) {
        this.settings.promoCustomized = mode.value === MODE_CUSTOM.value;
      },
    },
  },
  created() {
    this.$store.commit('shop/setLivePreview', { path: '' });
    shopAPI
      .getShopStockImages(this.$store, 'promo')
      .then((fileArray) => {
        // resolve stock images
        this.stockImages = fileArray;
      })
      .catch((e) => console.error(e));
  },
  methods: {
    setPromoMode(pm) {
      this.selectedPromoMode = pm;
    },
    onStockImageSelected(filename) {
      this.rememberedPromoImage = this.settings.promoImage;
      this.settings.promoImage = '';
      this.settings.promoStockImage = filename;
    },
    onFileUploaded(data) {
      this.settings.promoImage = data.filename;
      this.activeImageUrl = data.url;
      this.settings.promoStockImage = '';
    },
    onFileDeleted() {
      this.settings.promoImage = '';
      this.activeImageUrl = null;
    },
    onFileActivated() {
      this.settings.promoStockImage = '';
      if (!this.settings.promoImage) {
        this.settings.promoImage = this.rememberedPromoImage;
      }
    },
    updatePromoFontColor(color) {
      this.settings.promoFontColor = color;
    },
    goToStartpagePromoSection() {
      this.$router.push({ name: 'shop-area.pages.start-page.promotion' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.standardModeDesc {
  color: $grey50;
}

.promoMode {
  margin-bottom: 16px;
}

.heading-image {
  margin-bottom: 12px;
}

.clickable-img-upload {
  cursor: pointer;
  margin-bottom: 12px;
}

.colorWrapper {
  margin: 0 -25px;
}

.startpage-promo-link {
  color: $pa-color-main;
  display: inline;
  font-weight: bold;
  cursor: pointer;
}

.promotion-areas {
  color: $grey60;
  h4 {
    color: $grey80;
  }
}
</style>
