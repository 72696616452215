<template>
  <div class="youtubeStateContainer">
    <div class="colorCircle" :style="{ backgroundColor: stateColor }"></div>
    <div class="textCol">
      <div class="stateTitleRow">
        <div class="stateTitle">{{ $t(title) }} ({{ sellablesCount }})</div>
        <a class="link-main" :disabled="gotoDisabled" @click="gotoTiles">
          <Icon icon="move-last" class="gotoIcon" />
        </a>
      </div>
      <div class="stateDescr">
        {{ $t(description) }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  getStateColor,
  getStateDescription,
  getStateListHtmlId,
  getStateTitle,
  STATE_PENDING,
  STATE_SYNCED,
  STATE_TO_BE_REMOVED,
} from './youtubeSellableStates';

export default {
  name: 'YoutubeState',
  props: {
    state: {
      type: String,
      required: true,
    },
    channelId: {
      type: String,
      required: true,
    },
  },
  computed: {
    title() {
      return getStateTitle(this.state);
    },
    description() {
      return getStateDescription(this.state);
    },
    sellablesCount() {
      switch (this.state) {
        case STATE_PENDING:
          return this.$store.state.shop.youtubeSellables[this.channelId]
            .pendingProducts.length;
        case STATE_TO_BE_REMOVED:
          return this.$store.state.shop.youtubeSellables[this.channelId]
            .toBeRemovedProducts.length;
        case STATE_SYNCED:
          return this.$store.state.shop.youtubeSellables[this.channelId]
            .syncedProducts.length;
      }
      return 0;
    },
    stateColor() {
      return getStateColor(this.state);
    },
    gotoDisabled() {
      return this.sellablesCount === 0;
    },
  },
  methods: {
    gotoTiles() {
      if (!this.gotoDisabled) {
        const element = document.getElementById(getStateListHtmlId(this.state));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', top: 100 });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
.youtubeStateContainer {
  display: flex;
  margin-bottom: 16px;
}
.colorCircle {
  width: 16px;
  height: 16px;
  flex: none;
  margin-right: 8px;
  border-radius: 50%;
}
.textCol {
  width: calc(100% - 24px);
}
.stateTitleRow {
  display: flex;
  width: 100%;
}
.stateTitle {
  font-weight: bold;
  margin-bottom: 8px;
  width: 100%;
  overflow: hidden;
}
.gotoIcon {
  width: 20px;
  height: 20px;
}
.stateDescr {
  color: $grey60;
  line-height: 1.5;
}
</style>
