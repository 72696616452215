import shippingCountryService from '@/api/shippingCountryService/shippingCountryService';
import currencyService from '@/api/currencyService/currencyService';
import languageService from '@/api/languageService/languageService';
import countryService from '@/api/countryService/countryService';
import commissionerService from 'src/app/commons/api/commissionerService/commissionerService.js';
import localeService from '@/localeService/localeService';
import assortmentHelper from '@/assortmentHelper/assortmentHelper';

export default {
  namespaced: true,
  state: {
    shippingCountries: null,
    currencies: null,
    shippingStates: null,
    languages: null,
    countries: null,
    productTypeShares: null,
    metaColors: null,
  },
  getters: {
    shippingCountryByIso: (state) => (iso) =>
      state.shippingCountries ? state.shippingCountries[iso] : null,
    currencyById: (state) => (id) =>
      state.currencies
        ? state.currencies.find(
            (currency) => parseInt(currency.id, 10) === parseInt(id, 10),
          )
        : null,
    countryById: (state) => (id) =>
      state.countries
        ? state.countries.find(
            (country) => parseInt(country.id, 10) === parseInt(id, 10),
          )
        : null,
    currencyByIso: (state) => (isoCode) =>
      state.currencies
        ? state.currencies.find((currency) => currency.isoCode === isoCode)
        : null,
    languageByIso: (state) => (isoCode) =>
      state.languages
        ? state.languages.find((language) => language.isoCode === isoCode)
        : null,
    productsWithProductTypeShare: (state) =>
      state.productTypeShares
        ? Object.entries(state.productTypeShares)
            .filter((item) => item[1] > 0)
            .map((item) => item[0])
        : null,
  },
  mutations: {
    setShippingCountries(state, shippingCountries) {
      state.shippingCountries = shippingCountries;
    },
    setCurrencies(state, currencies) {
      state.currencies = currencies;
    },
    setShippingStates(state, shippingStates) {
      state.shippingStates = shippingStates;
    },
    setLanguages(state, languages) {
      state.languages = languages.map((language) => ({
        ...language,
        isoCode: localeService.convertFromSprdLanguage(language.isoCode),
      }));
    },
    setCountries(state, countries) {
      state.countries = countries;
    },
    setProductTypeShares(state, productTypeShares) {
      state.productTypeShares = productTypeShares[0].productTypeShares;
    },
    setMetaColors(state, metaColors) {
      state.metaColors = metaColors;
    },
  },
  actions: {
    async fetchShippingCountries({ commit, state }) {
      if (state.shippingCountries) {
        return Promise.resolve();
      }
      const shippingCountries =
        await shippingCountryService.fetchShippingCountries();
      commit('setShippingCountries', shippingCountries);
    },
    async fetchShippingStates({ commit, state }) {
      if (state.shippingStates) {
        return Promise.resolve();
      }
      const shippingStates = await shippingCountryService.fetchShippingStates();
      commit('setShippingStates', shippingStates);
    },
    async fetchCurrencies({ commit, state }) {
      if (state.currencies) {
        return Promise.resolve();
      }
      const { currencies } = await currencyService.fetchCurrencies();
      commit('setCurrencies', currencies);
    },
    async fetchLanguages({ commit, state }) {
      if (state.languages) {
        return Promise.resolve();
      }
      const languages = await languageService.fetchLanguages();
      commit('setLanguages', languages);
    },
    async fetchCountries({ commit, state }) {
      if (state.countries) {
        return Promise.resolve();
      }
      const countries = await countryService.fetchCountries();
      commit('setCountries', countries);
    },
    async fetchProductTypeShares({ commit, rootState }) {
      const productTypeShares =
        await commissionerService.fetchProductTypeShares(
          rootState.user.data.currency.id,
        );
      commit('setProductTypeShares', productTypeShares.levels);
    },
    async fetchMetaColors({ commit }) {
      const metaColors = await assortmentHelper.fetchMetaColors();
      commit('setMetaColors', metaColors);
    },
  },
};
