<template>
  <div id="detail-page" class="sprd-headline">
    <ToggleSwitch
      id="product-suggestions"
      v-model="productSuggestions"
      class="sprd-toggle-switch--first"
      label="SA.pages.detailpage.productSuggestions.label"
      @update:model-value="onProductSuggestionsChange"
    />
    <ToggleSwitch
      id="design-suggestions"
      v-model="settings.detailDesignSuggestions"
      label="POS.SHOP_SETTINGS.DESIGN_SLIDER"
      @update:model-value="onDesignSuggestionsChange"
    />

    <ToggleSwitch
      id="customer-reviews"
      v-model="settings.customerReviews"
      label="POS.SHOP_SETTINGS.CUSTOMER_REVIEWS"
      @update:model-value="onCustomerReviewsChange"
    />
    <p class="toggle-explaination text-sm">
      {{ $t('POS.SHOP_SETTINGS.CUSTOMER_REVIEWS_EXPLANATION') }}
    </p>
    <SmartFaqLink :link="reviewHelpLink" />
  </div>
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';

export default {
  name: 'ShopDetailPage',
  components: {
    ToggleSwitch,
    SmartFaqLink,
  },
  mixins: [SettingsData],
  data() {
    return {
      reviewHelpLink: smartFaqLinks.PRODUCT_RATINGS,
    };
  },
  computed: {
    productSuggestions: {
      get() {
        return this.settings.productSuggestionMode !== 'NONE';
      },
      set(enableProductSuggestions) {
        this.settings.productSuggestionMode = enableProductSuggestions
          ? 'SAME_DESIGN'
          : 'NONE';
      },
    },
  },
  created() {
    if (this.config.shopCurrencyEditable === true) {
      // nothing published to the shop yet - let the NoProductsPlaceholder take over the right-hand side
      this.$store.commit('shop/setLivePreviewVisible', false);
    } else {
      this.$store.commit('shop/setLivePreview', { path: '-A' });
    }
  },
  methods: {
    onProductSuggestionsChange(newVal) {
      if (newVal) {
        this.$store.commit('shop/setLivePreview', {
          path: '-A',
          section: 'sprd-detail-product-suggestions',
        });
      }
    },
    onDesignSuggestionsChange(newVal) {
      if (newVal) {
        this.$store.commit('shop/setLivePreview', {
          path: '-A',
          section: 'sprd-detail-design-suggestions',
        });
      }
    },
    onCustomerReviewsChange(newVal) {
      if (newVal) {
        this.$store.commit('shop/setLivePreview', {
          path: '-A',
          section: 'sprd-customer-reviews',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.toggle-explaination {
  margin: -21px 0 8px 0;
  padding-right: 77px;
  color: $grey60;
  line-height: 1.6;
}
</style>
