<template>
  <SalesEarningsGraph
    class="graph"
    heading="DASHBOARD.SALES_GRAPH.HEADING"
    subheading="DASHBOARD.SALES_GRAPH.SUBHEADING"
    sales-subheading="DASHBOARD.SALES_GRAPH.SALES_SUBHEADING"
    earnings-subheading="DASHBOARD.SALES_GRAPH.EARNINGS_SUBHEADING"
    :default-time-filter="defaultTimeFilter"
    :data="timelineData"
    :initialized="initialized"
    @on-load-data="update"
  />
</template>

<script>
import statisticsService from '@/api/statisticsService/statisticsService';
import { mapGetters } from 'vuex';
import SalesEarningsGraph from 'src/app/components/charts/sales/SalesEarningsGraph.vue';
import { TIME_FILTERS } from 'src/app/components/charts/sales/timeFilters';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import { isEqual } from 'date-fns';

export default {
  name: 'Sales',
  components: {
    SalesEarningsGraph,
  },
  data() {
    return {
      timelineData: null,
      initialized: false,
      defaultTimeFilter: TIME_FILTERS.MONTH,
    };
  },
  computed: {
    ...mapGetters({
      lifetimeSales: 'statistics/lifetimeSales',
      onboardingFinished: 'onboarding/finished',
    }),
    display() {
      return this.lifetimeSales || this.onboardingFinished;
    },
  },
  methods: {
    async update(filters) {
      this.initialized = false;

      const data = await statisticsService.getSalesOverview({
        pos: filters.pos.value,
        dateFrom: filters.time.from,
        dateTo: filters.time.to,
      });
      this.timelineData = data.timeline ? data : null;

      // ensure that we only got dates back that are actually between the from and to date
      if (this.timelineData?.timeline) {
        this.timelineData.timeline = this.timelineData.timeline.filter(
          (dataPoint) =>
            (!filters.time.from ||
              isAfter(parseISO(dataPoint.saleDate), filters.time.from) ||
              isEqual(parseISO(dataPoint.saleDate), filters.time.from)) &&
            (!filters.time.to ||
              isBefore(parseISO(dataPoint.saleDate), filters.time.to) ||
              isEqual(parseISO(dataPoint.saleDate), filters.time.to)),
        );
      }

      this.initialized = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
</style>
