<template>
  <picture>
    <img :src="`${src}`" :alt="alt" :style="imgStyle" />
  </picture>
</template>

<script>
export default {
  name: 'SprdImage',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
    },
    imgStyle: {
      type: Object,
    },
  },
};
</script>
