import { iconForPublishingLabel } from '@/publishing/publishingLabels';
import { PUBLISHING_SCOPE } from '@/publishing/publishingScope';
import { HOTSPOTS } from '@/states/hotspots';
import { z } from 'zod';
import { PUBLISHING_CATEGORY } from './compositionCategoryHelper';
import { z_enumFromArray } from '@/utils';

const AccreditationSchema = z.object({
  state: z.string(),
  rejectionReason: z.string().optional(),
});
type Accreditation = z.infer<typeof AccreditationSchema>;

const DesignSchema = z.object({
  id: z.number(),
});
type Design = z.infer<typeof DesignSchema>;

const TranslationValueSchema = z.object({
  language: z.string().optional(),
  country: z.string().optional(),
  value: z.string().optional(),
  values: z.array(z.string()).optional(),
  manual: z.boolean().optional(),
  autoTranslated: z.boolean().optional(),
});
type TranslationValue = z.infer<typeof TranslationValueSchema>;

const TemplateProductSchema = z.object({
  defaultHotspot: z_enumFromArray(Object.keys(HOTSPOTS)),
  hotspotConfigs: z.record(
    z_enumFromArray(Object.keys(HOTSPOTS)),
    z.array(z.object({ designId: z.number() })).max(1),
  ),
  appearanceRestriction: z
    .object({
      includedAppearanceGroups: z.array(z.string()).max(1),
    })
    .optional(),
});
type TemplateProduct = z.infer<typeof TemplateProductSchema>;

const AssortmentRuleCategoryLabelSchema = z.object({
  exclude: z.literal(false),
  labelGroup: z.literal('MP_CLOTHING_DEPARTMENTS'),
  labelId: z_enumFromArray(Object.keys(iconForPublishingLabel)),
});
type AssortmentRuleCategoryLabel = z.infer<
  typeof AssortmentRuleCategoryLabelSchema
>;

const AssortmentRuleCategorySchema = z
  .object({
    categoryId: z_enumFromArray(
      Object.values(PUBLISHING_CATEGORY).map((pc) => pc.id),
    ),
    exclude: z.literal(false),
    labelTerm: z
      .object({
        exclude: z.literal(false),
        operator: z.literal('OR'),
        labels: z.array(AssortmentRuleCategoryLabelSchema),
      })
      .optional(),
  })
  .refine(
    (data) =>
      !data.labelTerm || data.categoryId === PUBLISHING_CATEGORY.APPAREL.id,
    {
      message: `labelTerm must be empty when categoryId is not ${PUBLISHING_CATEGORY.APPAREL.id}`,
    },
  );
type AssortmentRuleCategory = z.infer<typeof AssortmentRuleCategorySchema>;

const AssortmentRuleSchema = z
  .object({
    categories: z.array(AssortmentRuleCategorySchema),
  })
  .or(z.object({}).strict());
type AssortmentRule = z.infer<typeof AssortmentRuleSchema>;

const CompositionSchema = z
  .object({
    id: z.string(),
    assortmentRule: AssortmentRuleSchema.optional(),
    scopes: z.array(z_enumFromArray(Object.keys(PUBLISHING_SCOPE))),
    publishingDetails: z.object({
      spreadShops: z.object({
        accreditation: AccreditationSchema.optional(),
        shops: z.array(
          z.object({
            id: z.number(),
            publishingStatus: z.string(),
          }),
        ),
      }),
      d2c: z.object({
        mp: z.boolean(),
        mpAccreditation: AccreditationSchema.optional(),
        publishingStatus: z.string(),
      }),
      publish: z.boolean(),
    }),
    mainDesign: DesignSchema,
    designs: z.array(DesignSchema).max(1),
    properties: z.object({
      darkBackground: z.boolean().optional(),
    }),
    metaData: z.object({
      originLanguageCode: z.string(),
      name: z.array(TranslationValueSchema),
      description: z.array(TranslationValueSchema),
      tags: z.array(TranslationValueSchema),
      autoTranslate: z.boolean(),
      intPub: z.boolean(),
    }),
    templateProducts: z.array(
      z.object({
        templateProduct: TemplateProductSchema,
      }),
    ),
  })
  .refine(
    (data) => {
      const templateProduct = data.templateProducts?.[0]?.templateProduct;
      const hotspotConfig =
        templateProduct?.hotspotConfigs[templateProduct.defaultHotspot];
      return !(
        hotspotConfig?.length &&
        hotspotConfig[0].designId !== data.mainDesign.id
      );
    },
    {
      message: 'hotspot config should only contain main design',
    },
  );
type Composition = z.infer<typeof CompositionSchema>;

export {
  AccreditationSchema,
  DesignSchema,
  TranslationValueSchema,
  TemplateProductSchema,
  CompositionSchema,
  AssortmentRuleCategoryLabelSchema,
  AssortmentRuleCategorySchema,
  AssortmentRuleSchema,
};

export type {
  Accreditation,
  Design,
  TranslationValue,
  TemplateProduct,
  Composition,
  AssortmentRuleCategoryLabel,
  AssortmentRuleCategory,
  AssortmentRule,
};
