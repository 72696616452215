<template>
  <div class="dialog-size-full select-template-dialog">
    <div class="modal-header">
      <div class="flex-center-vertical">
        <div>
          <h4>{{ $t('POS.TEMPLATES.OVERVIEW.HEADLINE') }}</h4>
          <p class="text-sm text-grey">
            {{ $t('POS.TEMPLATES.OVERVIEW.SUBHEADLINE') }}
          </p>
        </div>
        <button
          v-if="data.isNewShop"
          type="button"
          class="flex-align-right modal-close"
          @click="modalInstance.close('HOBBY')"
        >
          <Icon icon="close" /><span class="modal-close-label">{{
            $t('POS.TEMPLATES.OVERVIEW.SKIP')
          }}</span>
        </button>
        <button
          v-else
          type="button"
          class="flex-align-right modal-close"
          @click="modalInstance.dismiss"
        >
          <Icon icon="close" /><span class="modal-close-label">{{
            $t('POS.TEMPLATES.OVERVIEW.BACK')
          }}</span>
        </button>
      </div>
    </div>
    <div class="modal-body">
      <SelectTemplateContent
        :available-templates="data.availableTemplates"
        @input="modalInstance.close"
      />
    </div>
  </div>
</template>

<script>
import SelectTemplateContent from './SelectTemplateContent.vue';

export default {
  name: 'SelectTemplateDialog',
  components: {
    SelectTemplateContent,
  },
  props: ['modalInstance', 'data'],
};
</script>
