<template>
  <NoStuffPlaceholder
    :display="display"
    heading="POS.SHOP_SETTINGS.STARTPAGE.COLLECTION_PREVIEW.EMPTY.HEADING"
    sub-heading="POS.SHOP_SETTINGS.STARTPAGE.COLLECTION_PREVIEW.EMPTY.SUBHEADING"
    faq="POS.SHOP_SETTINGS.STARTPAGE.COLLECTION_PREVIEW.EMPTY.FAQ"
    :faq-link="faqLink"
    cta-icon="shop"
    cta-text="POS.SHOP_SETTINGS.STARTPAGE.COLLECTION_PREVIEW.EMPTY.LINK_TOPICS"
    cta-target="shop-area.pages.collections"
  />
</template>

<script>
import NoStuffPlaceholder from 'src/app/partnerarea/pos/shop/common/NoStuffPlaceholder.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';
import { StartPageData } from 'src/app/partnerarea/pos/shop/shopSavable/StartPageData';

export default {
  name: 'StartPageNoCollectionPlaceholder',
  components: {
    NoStuffPlaceholder,
  },
  mixins: [StartPageData],
  data() {
    return {
      faqLink: smartFaqLinks.SHOP_COLLECTION_HELP,
    };
  },
  computed: {
    display() {
      const { collectionPreviewPossible } = this.startPage;
      return (
        typeof collectionPreviewPossible === 'boolean' &&
        !collectionPreviewPossible
      );
    },
  },
};
</script>
