<template>
  <div class="tile" :class="{ selected, inverted }">
    <div class="image-wrapper">
      <Checkbox
        v-if="isSelectable"
        class="tile-checkbox"
        :model-value="selected"
        @update:model-value="$emit('toggleSelection')"
      />
      <div class="preview-image" @click="clickable && $emit('onClick')">
        <slot name="image">
          <div v-if="image || icon">
            <div
              v-if="image"
              class="preview-image-container design-checked-background"
              :class="{ dark: darkImageBg }"
            >
              <img :src="image" :alt="name" />
            </div>
            <div v-else class="preview-image-container icon-only">
              <Icon :icon="icon" />
              <p>{{ $t('GENERAL.NO_PREVIEW') }}</p>
            </div>
          </div>
        </slot>
        <div v-if="clickable" class="image-overlay">
          <div>
            <div class="overlay-content">
              <div class="edit-icon">
                <Icon :icon="imageOverlayIcon || 'pen'" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="$slots.overlay" class="overlay">
        <slot class="overlay" name="overlay"></slot>
      </div>
    </div>
    <div class="card-footer" :class="{ condensed: !displayStatus }">
      <strong class="footer-name text-truncate">{{ name }}</strong>
      <div v-if="displayStatus" class="status">
        <div class="main-status">
          <slot name="status"></slot>
        </div>

        <slot name="sideinfo"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/checkbox/Checkbox.vue';

export default {
  name: 'Tile',
  components: {
    Checkbox,
  },
  props: {
    name: {
      type: String,
    },
    inverted: {
      type: Boolean,
    },
    isSelectable: {
      type: Boolean,
    },
    selected: {
      type: Boolean,
    },
    image: {
      type: String,
    },
    darkImageBg: {
      type: Boolean,
    },
    icon: {
      type: String,
    },
    clickable: {
      type: Boolean,
    },
    imageOverlayIcon: {
      type: String,
    },
    displayStatus: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['toggleSelection', 'onClick'],
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';

.tile {
  overflow: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-medium;
  position: relative;
  border: 1px solid $grey10;

  &.inverted {
    background-color: $grey5;
  }

  &.selected {
    box-shadow: 0 0 0 2px $pa-color-main;
  }

  :deep(.checkbox-container) .checkmark {
    border-radius: $border-radius-small;
  }

  :deep(.preview-image) {
    cursor: pointer;

    & > div:first-child::before {
      content: '';
      display: block;
      padding-top: math.div(100, math.div(300, 300)) * 1%;
    }

    &:hover {
      .image-overlay {
        display: block;
      }
    }
  }

  .card-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 90px;
    width: 100%;
    padding: 16px;
    border: none;
    border-top: 1px solid $grey10;

    &.condensed {
      height: 50px;
    }

    .footer-name {
      width: 100%;
    }
  }
}

.image-wrapper {
  position: relative;

  .tile-checkbox {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $grey15;
    padding: 16px;
  }
}

.status {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: auto;

  .main-status {
    flex-grow: 1;
  }
}

.preview-image-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 12px;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  &.icon-only {
    background-color: $grey10;
    flex-direction: column;

    .icon {
      width: 32px;
      height: 32px;
    }

    p {
      margin: 8px 0 0 0;
    }
  }
}

.image-overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;

  & > div {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    .overlay-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .edit-icon {
        background-color: white;
        border-radius: 50%;
        padding: 5px;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $pa-color-main;

        .icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
</style>
