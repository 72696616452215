<template>
  <NoStuffPlaceholder
    :display="display"
    heading="POS.SHOP_SETTINGS.STARTPAGE.DESIGNS_WITH_PRODUCTS.EMPTY.HEADING"
    faq="POS.SHOP_SETTINGS.STARTPAGE.COLLECTION_PREVIEW.EMPTY.FAQ"
    :faq-link="faqLink"
    cta-icon="shirt"
    cta-text="POS.SHOP_SETTINGS.STARTPAGE.DESIGNS_WITH_PRODUCTS.EMPTY.LINK_UPLOAD"
    cta-target="partnerarea.ideas"
  />
</template>

<script>
import NoStuffPlaceholder from 'src/app/partnerarea/pos/shop/common/NoStuffPlaceholder.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';
import { StartPageData } from 'src/app/partnerarea/pos/shop/shopSavable/StartPageData';

export default {
  name: 'NoDesignsWithProductsPlaceholder',
  components: {
    NoStuffPlaceholder,
  },
  mixins: [StartPageData],
  data() {
    return {
      faqLink: smartFaqLinks.IDEA_CREATION_HELP,
    };
  },
  computed: {
    display() {
      const { designsWithProductsPossible } = this.startPage;
      return (
        typeof designsWithProductsPossible === 'boolean' &&
        !designsWithProductsPossible
      );
    },
  },
};
</script>
