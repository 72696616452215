<template>
  <a
    href="#"
    tabindex="-1"
    class="link text-sm tc-link"
    style="position: absolute; top: 10px; right: 10px; color: #7f7f7f"
    @click.prevent
  >
    <VDropdown :triggers="['click']" :container="undefined">
      <Icon class="icon" icon="info-circle" style="width: 20px; height: 20px" />
      <template #popper>
        <div class="google-tc" v-html="$t('PDV.GOOGLE_TC')"></div>
      </template>
    </VDropdown>
  </a>
</template>

<script>
import Icon from '@/icon/component/Icon.vue';
import { Dropdown } from 'floating-vue';
import { i18n } from '@/translate/i18n';

const { t } = i18n.global;

export default {
  name: 'GoogleMapsTC',
  components: { Icon, VDropdown: Dropdown },
  methods: {
    $t: t,
  },
};
</script>

<style>
.google-tc {
  min-width: 150px;
  font-weight: normal;
  text-align: left;
}
</style>
