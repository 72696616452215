<template>
  <div class="commissioninfo">
    <h3>{{ $t(commissionTypeName) }}</h3>
    <div v-if="dateBooked" class="commission-date">
      {{ $t('STATISTICS.CREDIT_DETAILS.DATE') }}:
      {{ date(dateBooked, 'longDate') }}
    </div>
    <div v-if="datePaidOut" class="commission-date">
      {{ $t('DATE.PAYED_OUT') }}: {{ date(datePaidOut, 'longDate') }}
    </div>
    <div class="commission-bottom">
      <h1>{{ sprdCurrency(profit.amount, profit.currencyId) }}</h1>
      <div
        v-if="commission && commission.currencyId"
        class="original-commission"
      >
        <div class="label">
          {{ $t('STATISTICS.CREDIT_DETAILS.ORIGINAL_COMMISSION') }}
        </div>
        <div class="amount">
          {{ sprdCurrency(commission.amount, commission.currencyId) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import statisticsService from '@/api/statisticsService/statisticsService';

export default {
  name: 'CommissionInfo',
  props: {
    commissionType: {
      type: String,
      required: true,
    },
    profit: {
      type: Object,
      required: true,
    },
    commission: {
      type: Object,
    },
    dateBooked: {
      type: String,
    },
    datePaidOut: {
      type: String,
    },
  },
  data() {
    return {
      commissionTypes: statisticsService.getCommissionTypes(),
    };
  },
  computed: {
    commissionTypeName() {
      const entryKey = Object.keys(this.commissionTypes).find(
        (key) => this.commissionTypes[key] === this.commissionType,
      );
      return `STATISTICS.CREDIT_DETAILS.ENTRY_TYPE.${entryKey}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/mixins/media';

.commissioninfo {
  border: 1px solid $grey20;
  border-radius: $border-radius-medium;
  padding: 12px;
  display: flex;
  flex-direction: column;
  position: relative;

  h1,
  h3 {
    margin: 0;
    text-align: left;
  }

  h3 {
    margin-bottom: 5px;
  }

  .commission-date {
    text-align: left;
    @extend .text-sm;
    @extend .text-grey;
  }

  .commission-bottom {
    margin-top: auto;
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .original-commission {
    text-align: right;

    .label {
      margin-bottom: 4px;
      @extend .text-sm;
      @extend .text-grey;
    }

    .amount {
      font-weight: bold;
      @extend .text-lg;
    }
  }
}
</style>
