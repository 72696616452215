import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';

const startPageSections = [
  'teaserEnabled',
  'productPreviewEnabled',
  'designPreviewEnabled',
  'designsWithProductsEnabled',
  'promotionEnabled',
  'trustElementsEnabled',
  'collectionPreviewEnabled',
  'aboutUsEnabled',
  'followUsEnabled',
  'cyoIntroEnabled',
];

export function atLeastOneSectionEnabled(startPage) {
  return startPage && startPageSections.some((section) => startPage[section]);
}

export const StartPageData = {
  beforeCreate() {
    const startPage = this.$store.state.shop.shopSavable.clientData.startPage;
    if (startPage) {
      // hack1: when collection data is changed, we have to refresh this flag (that depends on the number of collections and their content).
      // this is necessary because the logic resides on the server side and there is no such thing as a push notification available.

      // hack2: update the followUsPossible flag without forcing the partner to save changes to the social media profiles
      const { channels } = this.$store.state.shop.shopSavable.clientData;
      const hasChannels =
        Boolean(channels) && Object.values(channels).some((channel) => channel);
      const channelsChanged =
        Object.prototype.hasOwnProperty.call(startPage, 'followUsPossible') &&
        hasChannels !== startPage.followUsPossible;
      const collectionHolder =
        this.$store.state.shop.shopSavable.clientData.collections;
      const collectionsChanged =
        collectionHolder && collectionHolder.collectionHasChanged;
      if (channelsChanged) {
        this.$store.commit('shop/updateSectionOriginalData', {
          sectionKey: 'startPage',
          origData: { followUsPossible: hasChannels },
        });
      }
      if (collectionsChanged) {
        this.$store.commit('shop/setCollectionHasChanged', false);
        shopAPI
          .getStartPage(this.$store)
          .then(({ collectionPreviewPossible }) => {
            this.$store.commit('shop/updateSectionOriginalData', {
              sectionKey: 'startPage',
              origData: { collectionPreviewPossible },
            });
          });
      }
    } else {
      this.$store.commit('shop/addDynamicSection', {
        key: 'startPage',
        initialData: {},
      });

      shopAPI.getStartPage(this.$store).then((data) =>
        this.$store.commit('shop/updateSectionOriginalData', {
          sectionKey: 'startPage',
          origData: data,
        }),
      );
    }
  },
  computed: {
    startPage() {
      return this.$store.state.shop.shopSavable.clientData.startPage || {};
    },
  },
  watch: {
    startPage: {
      handler(updateStartPage, lastStartPage) {
        if (Object.keys(lastStartPage).length === 0) {
          // we only want to update the state after user input, not when the lazy loaded data arrives
          return;
        }

        const startPageEnabled = atLeastOneSectionEnabled(updateStartPage);
        if (
          this.$store.state.shop.shopSavable.clientData.settings
            .startPageEnabled !== startPageEnabled
        ) {
          this.$store.commit('shop/updateSectionData', {
            key: 'settings',
            data: { startPageEnabled },
          });
        }
      },
      deep: true,
    },
  },
};
