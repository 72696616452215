export default {
  EU: {
    de_DE: 'https://www.spreadshop.de/stick-service/',
    de_AT: 'https://www.spreadshop.de/stick-service/',
    de_CH: 'https://www.spreadshop.de/stick-service/',
    default: 'https://www.spreadshop.com/embroidery-service/',
  },
  NA: {
    default: 'https://www.spreadshop.com/embroidery-service/us/',
  },
};
