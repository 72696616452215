<template>
  <div class="">
    <DialogBase
      v-for="(dialog, index) in currentDialogs"
      :key="dialog.id"
      :modal-instance="dialog.modalInstance"
      :display-close="dialog.options.displayClose"
      :static-backdrop="
        dialog.options.staticBackdrop || index < currentDialogs.length - 1
      "
      :dialog-base-class="dialog.options.dialogBaseClass"
      :dialog-content-class="dialog.options.dialogContentClass"
      :full-size="dialog.options.fullSize"
    >
      <component
        :is="dialog.component"
        :data="dialog.data"
        :modal-instance="dialog.modalInstance"
      ></component>
    </DialogBase>
  </div>
</template>

<script>
import DialogBase from './DialogBase.vue';
import { mapState } from 'vuex';

export default {
  name: 'DialogWrapper',
  components: {
    DialogBase,
  },
  props: {},
  computed: {
    ...mapState({
      currentDialogs: (state) => state.dialogs.currentDialogs,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
