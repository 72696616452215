<template>
  <div
    v-if="isLivePreviewVisible"
    class="shopActionBar"
    :class="{ fullscreen: fullScreen }"
  >
    <div v-if="fullScreen" class="toolbar">
      <div class="mode-wrapper">
        <button
          class="btn btn-small"
          :class="{ selected: mode === 'desktop' }"
          @click="changeMode('desktop')"
        >
          <Icon icon="desktop"></Icon>
        </button>
        <button
          class="btn btn-small"
          :class="{ selected: mode === 'tablet' }"
          @click="changeMode('tablet')"
        >
          <Icon icon="tablet"></Icon>
        </button>
        <button
          class="btn btn-small"
          :class="{ selected: mode === 'mobile' }"
          @click="changeMode('mobile')"
        >
          <Icon icon="phone-case"></Icon>
        </button>
      </div>
      <a
        class="link-main icon-link collapse-preview-link"
        @click="closePreview"
      >
        <Icon icon="fullscreen-off"></Icon>
        {{ $t('SA.action.collapsePreview') }}
      </a>
    </div>
    <div v-else class="toolbar">
      <a class="link-main icon-link" @click="openPreview">
        <Icon icon="fullscreen-on"></Icon>
        {{ $t('SA.action.expandPreview') }}
      </a>
      <a :href="shopUrl" target="_blank" class="link-main icon-link">
        <Icon icon="globe_light"></Icon>
        {{ $t('SA.menubar.preview') }}
      </a>
      <a class="link-main icon-link" @click="openSelfOrderModal">
        <Icon icon="basket" />
        {{
          $t('POS.SHOP_SETTINGS.NAVIGATION.SELFORDER_DIALOG.OPEN_BTN_CAPTION')
        }}
      </a>
    </div>
  </div>
</template>

<script>
import dialogService from '@/dialogs/wrapper/dialogService';
import ShopSelfOrderDialog from 'src/app/partnerarea/pos/shop/navigation/ShopSelfOrderDialog.vue';

export default {
  name: 'ShopActionBar',
  computed: {
    isLivePreviewVisible() {
      return this.$store.state.shop.livePreview.visible;
    },
    fullScreen() {
      return this.$store.state.shop.livePreview.fullScreen;
    },
    mode() {
      return this.$store.state.shop.livePreview.mode;
    },
    shopUrl() {
      return this.$store.getters['shop/getSpreadshopUrl']('', false);
    },
  },
  methods: {
    openPreview() {
      this.$store.commit('shop/setLivePreviewFullScreen', true);
    },
    closePreview() {
      this.$store.commit('shop/setLivePreviewFullScreen', false);
      this.$store.commit('shop/setLivePreviewMode', 'desktop');
    },
    changeMode(newMode) {
      this.$store.commit('shop/setLivePreviewMode', newMode);
    },
    openSelfOrderModal() {
      dialogService.openDialog(ShopSelfOrderDialog, null, { fullSize: true });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
.shopActionBar {
  width: 100%;
  background-color: #fff;
  padding: 16px;

  .mode-wrapper {
    display: flex;
    justify-content: center;

    .btn {
      border: none;
      padding: 0 24px;

      .icon {
        width: 32px;
        height: 32px;
      }

      &.selected {
        color: $pa-color-main;
      }
    }
  }

  a {
    padding: 0 16px;

    &:not(:last-child) {
      border-right: 1px solid $grey20;
    }
  }

  .collapse-preview-link {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }

  .toolbar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
