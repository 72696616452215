<template>
  <button :disabled="loading > 0 || popup" @click="authenticate">
    {{ $t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.HUBSPOT_BTN_CAPTION') }}
  </button>
</template>

<script>
export default {
  name: 'SocialMediaToolHubspotUploader',
  props: {
    getCanvasRefs: {
      type: Function,
      required: true,
    },
  },
  emits: ['pushToast', 'removeToast'],
  data() {
    return {
      popup: null,
      pollingInterval: null,
      token: null,
      loading: 0,
      isError: false,
      tokenToast: {
        variant: 'warning',
        message: this.$t(
          'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.HUBSPOT_LOGIN_WAITING',
        ),
      },
      uploadToast: {
        variant: 'loading-bar',
        message: this.$t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.UPLOADING'),
      },
    };
  },
  beforeUnmount() {
    if (this.popup && !this.popup.closed) {
      this.popup.close();
    }
    this.onClose();
  },
  methods: {
    /*
      The child window potentially ends up on a different domain, so we can't access it because of CORS.
      The redirect also invalidates window.opener in the child window, so we can't just post a message
      to the parent. Continuously polling the child for the token seems like the only solution for now.
    */
    authenticate() {
      if (this.popup) {
        return;
      }
      this.token = null;
      this.popup = window.open('/shopsettings/hubspot/start', '_blank');
      this.$emit('pushToast', this.tokenToast);
      this.pollingInterval = setInterval(() => {
        if (!this.popup.closed) {
          this.popup.postMessage({ type: 'requestHubspotOauthResult' }, '*');
        } else {
          this.onClose();
          setTimeout(() => {
            if (!this.token) {
              removeEventListener('message', this.onTokenReceived);
              this.$emit('removeToast', this.tokenToast);
              this.$emit('pushToast', {
                variant: 'error',
                message: this.$t(
                  'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.HUBSPOT_LOGIN_FAILED',
                ),
                dismissible: true,
              });
            }
          }, 1000);
        }
      }, 500);
      addEventListener('message', this.onTokenReceived);
    },
    onTokenReceived(event) {
      if (event.data.type !== 'hubspotOauthResult') {
        return;
      }
      this.$emit('removeToast', this.tokenToast);
      this.token = event.data.accessToken;
      this.onClose();
      this.hubspotPost();
    },
    onClose() {
      this.popup = null;
      removeEventListener('message', this.onTokenReceived);
      clearInterval(this.pollingInterval);
    },
    hubspotPost() {
      this.$emit('pushToast', this.uploadToast);
      const requests = [];
      // offset to prevent the string from becoming too long
      const timestamp = Date.now() - 1633940056001;
      Object.entries(this.getCanvasRefs()).forEach(([name, ref]) => {
        requests.push(
          new Promise((resolve) => {
            ref[0].toBlob((blob) => {
              const formData = new FormData();
              formData.append('file', blob, `spreadshirt-${name}-${timestamp}`);
              resolve(this.performHubspotPost(formData));
            });
          }),
        );
      });
      Promise.all(requests)
        .then(
          () => {
            this.$emit('pushToast', {
              variant: 'success',
              message: this.$t(
                'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.HUBSPOT_UPLOAD_SUCCESSFUL',
              ),
              dismissible: true,
            });
          },
          (err) => {
            console.error(err);
            this.$emit('pushToast', {
              variant: 'error',
              message: this.$t(
                'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.UPLOAD_FAILED',
              ),
              dismissible: true,
            });
          },
        )
        .finally(() => this.$emit('removeToast', this.uploadToast));
    },
    performHubspotPost(formData) {
      this.loading += 1;

      return fetch('/shopsettings/hubspot/uploadImage', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).finally(() => (this.loading -= 1));
    },
  },
};
</script>
