import store from '@/store/Store.js';
import statisticsService from '@/api/statisticsService/statisticsService';
import { startOfYear } from 'date-fns/startOfYear';
import { lastDayOfYear } from 'date-fns/lastDayOfYear';
import { differenceInDays } from 'date-fns/differenceInDays';
import { endOfDay } from 'date-fns/endOfDay';
import { parseISO } from 'date-fns/parseISO';
import { getMonth } from 'date-fns/getMonth';
import { getYear } from 'date-fns/getYear';
import { subDays } from 'date-fns/subDays';
import { subYears } from 'date-fns/subYears';
import {
  getDesignImageUrl,
  getProductTypeImageUrl,
} from '@/image/imageUrlBuilder';
import YearRecapDialog from '@/dialogs/recap/YearRecapDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';

export default {
  getCurrentRecapYear() {
    const yearToRecap = getYear(subYears(subDays(new Date(), 9), 1));
    if (getYear(parseISO(store.state.user.data.created)) <= yearToRecap) {
      return yearToRecap;
    } else {
      return null;
    }
  },
  checkForUnseenRecap() {
    if (
      this.getCurrentRecapYear() >
      store.getters['settings/getSetting']('LAST_RECAP')
    ) {
      this.openCurrentRecapDialog();
    }
  },
  openCurrentRecapDialog() {
    dialogService.openDialog(
      YearRecapDialog,
      { year: this.getCurrentRecapYear() },
      {
        dialogBaseClass: 'dialog-borderless',
      },
    );
  },
  async getData(year) {
    return {
      ...(await this.fetchSalesEarningsStatistics(year)),
      ...(await this.fetchBestCountryStatistics(year)),
      ...(await this.fetchBestProductStatistics(year)),
      ...(await this.fetchBestDesignStatistics(year)),
    };
  },
  dateFrom(year) {
    return startOfYear(new Date(year, 1, 1));
  },
  dateTo(year) {
    return endOfDay(lastDayOfYear(new Date(year, 1, 1)));
  },
  getBestEntry(entries, criteria, secondaryCriteria) {
    if (!entries) {
      return null;
    }
    return entries.reduce((prev, current) => {
      let criteriaToCompare = criteria;
      if (
        secondaryCriteria &&
        (prev[criteria] || 0) === (current[criteria] || 0)
      ) {
        criteriaToCompare = secondaryCriteria;
      }
      return (prev[criteriaToCompare] || 0) > (current[criteriaToCompare] || 0)
        ? prev
        : current;
    }, {});
  },
  getBestMonth(timeline, criteria, secondaryCriteria) {
    if (!timeline) {
      return null;
    }

    const months = [];

    timeline.forEach((entry) => {
      const monthNr = getMonth(parseISO(entry.saleDate));
      if (!months[monthNr]) {
        months[monthNr] = {
          month: monthNr,
          monthDate: entry.saleDate,
          sales: 0,
          earnings: 0,
        };
      }

      months[monthNr].sales += entry.sales || 0;
      months[monthNr].earnings += entry.earnings || 0;
    });

    return this.getBestEntry(months, criteria, secondaryCriteria);
  },
  async fetchSalesEarningsStatistics(year) {
    const data = await statisticsService.getSalesOverview({
      dateFrom: this.dateFrom(year),
      dateTo: this.dateTo(year),
    });

    const bestDay = this.getBestEntry(data.timeline, 'sales', 'earnings');
    const bestMonth = this.getBestMonth(data.timeline, 'sales', 'earnings');
    return {
      lifeTime: {
        earnings: data.earnings?.amount,
        currencyId: data.currencyId,
        sales: data.quantity?.amount,
        registeredSinceDays: differenceInDays(
          new Date(),
          parseISO(store.state.user.data.created),
        ),
      },
      bestDay: {
        date: bestDay?.saleDate,
        earnings: bestDay?.earnings,
        currencyId: data.currencyId,
        sales: bestDay?.sales,
      },
      bestMonth: {
        month: bestMonth?.month,
        monthDate: bestMonth?.monthDate,
        earnings: bestMonth?.earnings,
        currencyId: data?.currencyId,
        sales: bestMonth?.sales,
      },
    };
  },
  async fetchBestCountryStatistics(year) {
    const data = await statisticsService.getBestsellerMapData({
      dateFrom: this.dateFrom(year),
      dateTo: this.dateTo(year),
    });
    const bestsellingCountry = this.getBestEntry(
      Object.entries(data.countries).map(([country, value]) => ({
        country,
        ...value,
      })),
      'sales',
    );

    return {
      bestCountry: {
        country: bestsellingCountry.country,
        sales: bestsellingCountry.sales,
        countryCount: Object.keys(data.countries).length,
      },
    };
  },
  async fetchBestProductStatistics(year) {
    const data = await statisticsService.getBestsellers({
      dateFrom: this.dateFrom(year),
      dateTo: this.dateTo(year),
      type: 'productTypes',
    });

    if (data?.list?.length) {
      const bestsellingProductType = data.list[0];

      return {
        bestProduct: {
          productName: bestsellingProductType.translation,
          sales: bestsellingProductType.quantity,
          productImageUrl: getProductTypeImageUrl(
            bestsellingProductType.objectName,
          ),
        },
      };
    } else {
      return {
        bestProduct: null,
      };
    }
  },
  async fetchBestDesignStatistics(year) {
    const data = await statisticsService.getTopDesigns({
      dateFrom: this.dateFrom(year),
      dateTo: this.dateTo(year),
    });

    if (data?.list?.length) {
      const bestsellingDesign = data.list[0];

      return {
        bestDesign: {
          designName: bestsellingDesign.translation,
          sales: bestsellingDesign.quantity,
          designImageUrl: getDesignImageUrl(bestsellingDesign.objectName),
        },
      };
    } else {
      return {
        bestDesign: null,
      };
    }
  },
};
