<template>
  <div class="domainVerification">
    <h4 v-if="heading" class="heading">
      {{ $t(heading) }}
    </h4>
    <p class="paragraph subheading">
      {{ $t(subheading) }}
    </p>
    <div class="input-wrapper">
      <ValidLabelInput
        :validators="[validateInput]"
        :label="$t(label)"
        :model-value="modelValue"
        @update:model-value="$emit('onChange', $event)"
      />
      <div v-if="modelValue" class="clear">
        <button class="link icon-btn" @click="$emit('input', null)">
          <Icon icon="close" />
        </button>
      </div>
    </div>
    <p v-if="additionalHelp" class="additional-help">
      {{ $t(additionalHelp) }}
    </p>
    <HelpLink
      v-if="helpLinkTarget"
      class="domainHelpLink"
      :href="helpLinkTarget"
    />
    <SmartFaqLink
      v-if="smartFaqLink"
      class="domainHelpLink"
      :link="smartFaqLink"
    />
  </div>
</template>

<script>
import ValidLabelInput from 'src/app/partnerarea/pos/shop/common/ValidLabelInput.vue';
import HelpLink from '@/helpLink/HelpLink.vue';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';

export default {
  name: 'DomainVerification',
  components: { HelpLink, SmartFaqLink, ValidLabelInput },
  props: {
    heading: {
      type: String,
      required: false,
    },
    subheading: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    additionalHelp: {
      type: String,
    },
    helpLinkTarget: {
      type: String,
    },
    smartFaqLink: {
      type: Object,
    },
    modelValue: {
      type: String,
      required: false,
      default: '',
    },
    regex: {
      type: String,
      required: true,
    },
  },
  emits: ['onChange', 'input'],
  methods: {
    validateInput(code) {
      if (code.length === 0) {
        return;
      }
      if (!code.match(this.regex)) {
        throw new Error(this.$t('SA.messages.warning.invalid'));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
.domainVerification {
  margin-bottom: 24px;
}

.heading {
  margin-bottom: 8px;
}

.subheading {
  margin-bottom: 8px !important;
}

.domainHelpLink {
  margin: 8px 0 16px 0;
}

.additional-help {
  @extend .text-sm;
  margin: 8px 0;
  color: $grey65;
}

.input-wrapper {
  position: relative;
  margin: 16px 0 0 0;
}

.clear {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
