<template>
  <div>
    <RadioButtonGroup
      class="radiogroup"
      :items="logoOptions"
      :label="$t('POS.SHOP_SETTINGS.NAVIGATION.HEADER.LOGO.SELECTOR_HEADLINE')"
      :model-value="selectedLogoOption"
      @update:model-value="onLogoOptionChange"
    />
    <h4 v-if="logoImageSelected">
      {{ $t('POS.SHOP_SETTINGS.NAVIGATION.HEADER.LOGO.UPLOADER_HEADLINE') }}
    </h4>
    <ImageUpload
      v-if="logoImageSelected"
      :image="image"
      :active="false"
      :active-image-name="settings.logoImage"
      :active-image-url="activeLogoUrl"
      :disabled="false"
      @file-deleted="onLogoDeleted"
      @file-uploaded="onLogoUploaded"
    />
  </div>
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import RadioButtonGroup from 'src/app/commons/RadioButtonGroup/RadioButtonGroup.vue';
import ImageUpload from 'src/app/partnerarea/pos/shop/imageUpload/ImageUpload.vue';
import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';

import { logo } from '../../imageAttributes';

export default {
  name: 'Logos',
  components: {
    ImageUpload,
    RadioButtonGroup,
  },
  mixins: [SettingsData],
  data: function () {
    return {
      // the code below assumes that the logoOptions order is fixed and that index==1 means a logo image is desired
      logoOptions: [
        this.$t('POS.SHOP_SETTINGS.NAVIGATION.HEADER.LOGO.SELECTOR_TEXT'),
        this.$t('POS.SHOP_SETTINGS.NAVIGATION.HEADER.LOGO.SELECTOR_UPLOAD'),
      ],
      selectedLogoOption: null, // contains one of the strings from logoOptions. reflects UI state alone, not shop settings flags
      activeLogoUrl: null,
      image: logo,
    };
  },
  computed: {
    logoImageSelected() {
      return this.selectedLogoOption === this.logoOptions[1];
    },
    hasLogoImage() {
      return (
        typeof this.settings.logoImage === 'string' &&
        this.settings.logoImage.length > 0
      );
    },
  },
  created() {
    this.selectedLogoOption =
      this.logoOptions[this.settings.logoImageEnabled ? 1 : 0];

    if (this.settings.logoImage) {
      shopAPI
        .getShopImages(this.$store, 'logo')
        .then((fileArray) => {
          const match = fileArray.find(
            (fileEntry) => fileEntry.filename === this.settings.logoImage,
          );
          if (match) {
            this.activeLogoUrl = match.url;
          }
        })
        .catch((e) => console.error(e));
    }
  },
  methods: {
    onLogoOptionChange(newOpt) {
      this.selectedLogoOption = newOpt;
      this.settings.logoImageEnabled =
        this.logoImageSelected && this.hasLogoImage;
      this.$store.commit('shop/setLivePreview', {
        path: '',
        section: 'sprd-header-container',
      });
    },
    onLogoUploaded(data) {
      this.settings.logoImage = data.filename;
      this.settings.logoImageEnabled = true;
      this.activeLogoUrl = data.url;
      this.$store.commit('shop/setLivePreview', {
        path: '',
        section: 'sprd-header-container',
      });
    },
    onLogoDeleted() {
      this.settings.logoImage = '';
      this.settings.logoImageEnabled = false;
      this.activeLogoUrl = null;
      this.$store.commit('shop/setLivePreview', {
        path: '',
        section: 'sprd-header-container',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.radiogroup {
  flex-direction: column;

  :deep(.radiobutton-container) {
    margin: 0.5em 0;
  }
}
</style>
