<template>
  <div>
    {{ counter }}
  </div>
</template>

<script setup>
import { ref, defineProps, onUnmounted, onMounted } from 'vue';

const counter = ref(0);
let intervalId = null;

const props = defineProps({
  to: {
    type: Number,
    required: true,
  },
  time: {
    type: Number,
    required: true,
  },
});

const clearIntervalIfExists = () => {
  if (intervalId) {
    clearInterval(intervalId);
    intervalId = null;
  }
};

const startIncrementingCounter = () => {
  clearIntervalIfExists();
  counter.value = 0;
  intervalId = setInterval(() => {
    if (counter.value < props.to) {
      counter.value++;
    } else {
      clearIntervalIfExists();
    }
  }, props.time / props.to);
};

onMounted(startIncrementingCounter);
onUnmounted(clearIntervalIfExists);
</script>
