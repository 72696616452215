<template>
  <div
    v-if="hintContent"
    class="digital-products-moderation-hint"
    :class="{ rejected: hintContent.rejected }"
  >
    <h2>{{ $t(hintContent.title) }}</h2>
    <p>
      {{ $t(hintContent.description) }}
    </p>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import {
  DIGITAL_PRODUCTS_STATES,
  getDigitalProductState,
  DIGITAL_PRODUCTS_UPDATE_STATES,
  getDigitalProductUpdateState,
} from '@/api/models/digitalProducts/digitalProductsStates';

export default {
  name: 'DigitalProductModerationHint',
  components: {},
  props: {
    strictValidation: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      digitalProduct: (s) => s.digitalProduct.current,
    }),
    ...mapGetters({}),
    hintContent() {
      if (
        getDigitalProductState(this.digitalProduct) ===
        DIGITAL_PRODUCTS_STATES.REVIEW
      ) {
        return {
          title: 'DIGITAL_PRODUCTS.MODERATION.REVIEW.HEADING',
          description: 'DIGITAL_PRODUCTS.MODERATION.REVIEW.DESCRIPTION',
        };
      } else if (
        getDigitalProductState(this.digitalProduct) ===
        DIGITAL_PRODUCTS_STATES.REJECTED
      ) {
        return {
          title: 'DIGITAL_PRODUCTS.MODERATION.REJECTED.HEADING',
          description: 'DIGITAL_PRODUCTS.MODERATION.REJECTED.DESCRIPTION',
          rejected: true,
        };
      } else if (
        getDigitalProductUpdateState(this.digitalProduct) ===
        DIGITAL_PRODUCTS_UPDATE_STATES.REVIEW
      ) {
        return {
          title: 'DIGITAL_PRODUCTS.MODERATION.UPDATE_REVIEW.HEADING',
          description: 'DIGITAL_PRODUCTS.MODERATION.UPDATE_REVIEW.DESCRIPTION',
        };
      } else if (
        getDigitalProductUpdateState(this.digitalProduct) ===
        DIGITAL_PRODUCTS_UPDATE_STATES.REJECTED
      ) {
        return {
          title: 'DIGITAL_PRODUCTS.MODERATION.UPDATE_REJECTED.HEADING',
          description:
            'DIGITAL_PRODUCTS.MODERATION.UPDATE_REJECTED.DESCRIPTION',
          rejected: true,
        };
      } else {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.digital-products-moderation-hint {
  padding: 24px;
  background-color: $grey10;

  h2 {
    margin: 0;
  }

  p {
    margin: 8px 0 0 0;
    color: $grey65;
  }

  &.rejected {
    background-color: $pa-color-red;

    h2,
    p {
      color: $grey0;
    }
  }
}
</style>
