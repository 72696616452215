<template>
  <div class="credit-details-payout">
    <div class="header">
      <h3>{{ $t('STATISTICS.CREDIT_DETAILS.ENTRY_TYPE.PAYOUT') }}</h3>
      <SmartFaqLink :link="helpTarget" />
    </div>
    <h1>
      {{ sprdCurrency(Math.abs(data.payoutAmount), data.currencyId) }}
    </h1>
    <div class="sub-details">
      <div>
        {{ $t('DATE.PAYED_OUT') }}:
        {{ date(data.dateBooked, 'longDate') }}
      </div>
      <div>{{ $t('STATISTICS.SALES') }}: {{ sprdNumber(data.quantity) }}</div>
    </div>
    <div class="split">
      <CommissionInfo
        v-for="commission in data.split"
        :key="commission.commissionType"
        :commission-type="commission.commissionType"
        :profit="{
          amount: commission.amount,
          currencyId: data.currencyId,
        }"
      />
    </div>
    <p
      v-if="isWithin30Days(data.dateBooked)"
      class="text-sm text-grey payout-disclaimer"
    >
      {{ $t('STATISTICS.CREDIT_DETAILS.PAYOUT_DISCLAIMER') }}
    </p>
  </div>
</template>

<script>
import CommissionInfo from 'src/app/partnerarea/statistics/credit/details/CommissionInfo.vue';
import { isAfter } from 'date-fns/isAfter';
import { subDays } from 'date-fns/subDays';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';

export default {
  name: 'CreditDetailsPayout',
  components: {
    SmartFaqLink,
    CommissionInfo,
  },
  props: ['data'],
  data() {
    return {
      helpTarget: smartFaqLinks.COMMISSION_INFO,
    };
  },
  methods: {
    isWithin30Days(date) {
      return isAfter(new Date(date), subDays(new Date(), 31));
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/utils';

.credit-details-payout {
  h1,
  h3 {
    margin: 0;
  }

  h1 {
    margin-top: 8px;
  }

  .sub-details {
    display: flex;
    margin-top: 4px;
    @extend .text-sm;
    @extend .text-grey;

    & > div {
      margin-right: 24px;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .split {
    display: flex;
    margin-top: 8px;

    & > div {
      flex-basis: 100%;
      width: 220px;
      min-width: 120px;
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }

    :deep(h1) {
      @extend .heading2;
      margin: 0;
    }
  }

  .payout-disclaimer {
    text-align: center;
    word-wrap: normal;
    margin: 8px 0 0;
    min-width: 400px;
  }
}
</style>
