<template>
  <div class="dialog-size-md">
    <div class="modal-body">
      <IdeaPreviewImage
        class="preview-image design-preview"
        :idea="data"
        :checked-background="true"
        :width="500"
        :height="500"
      />
    </div>
  </div>
</template>

<script>
import IdeaPreviewImage from 'src/app/partnerarea/idea/previewImage/IdeaPreviewImage.vue';

export default {
  name: 'IdeaImageDialog',
  components: {
    IdeaPreviewImage,
  },
  props: ['modalInstance', 'data'],
};
</script>

<style lang="scss" scoped>
.preview-image:before {
  content: '';
  display: block;
  padding-top: 100%;
}
</style>
