<template>
  <div class="pos-rejected-hint">
    <p class="text">{{ $t('DESIGNS.STATUS.REJECTED_HINT') }}</p>
    <div v-if="reason" class="rejection-reasons-content">
      <div class="rejection-caption text">
        {{ $t('DESIGNS.CONFIGURATION.POINTS_OF_SALE.REJECTION_REASONS') }}:
      </div>
      <div class="text-default rejection-reasons">
        {{ $t(rejectionReasonName(reason)) }}
      </div>
      <SmartFaqLink :link="helpLink" class="help-link" />
    </div>
  </div>
</template>

<script>
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';
import { rejectionReasonName } from '@/api/models/pointsOfSale/pointsOfSale';

export default {
  name: 'PosRejectedHint',
  components: {
    SmartFaqLink,
  },
  props: {
    reason: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      helpLink: smartFaqLinks.REJECTION_HELP,
    };
  },
  methods: {
    rejectionReasonName,
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/links';

p {
  margin: 0;
  color: $grey60;
}

.rejection-reasons-content {
  margin: 12px 0 4px 0;

  .rejection-caption {
    margin: 0;
  }

  .rejection-reasons {
    font-weight: bold;
    margin: 4px 0 0;
  }
}

.help-link {
  margin-top: 12px;
}
</style>
