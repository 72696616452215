<template>
  <div>
    <div v-if="isCondensed" :style="props.condensedStyle">
      <VDropdown class="info-popover" placement="left">
        <a href="#" class="link-main icon-link" @click.prevent>
          <Icon icon="info-circle" /> {{ $t('GENERAL.GET_HELP') }}
        </a>
        <template #popper>
          {{ $t(props.text) }}
        </template>
      </VDropdown>
    </div>
    <div v-else class="inline-onboarding">
      <Icon icon="graduation" />
      {{ $t(props.text) }}
      <button class="link link-underlined condense" @click="condense">
        {{ $t('GENERAL.DISMISS') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';

const forceCondensed = ref(false);
const isCondensed = computed(() => props.condensed || forceCondensed.value);

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  condensed: {
    type: Boolean,
    default: false,
  },
  condensedStyle: {
    type: Object,
    default: () => {},
  },
});

const condense = () => {
  forceCondensed.value = true;
};
</script>

<style scoped lang="scss">
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';

.condense {
  font-weight: normal;
}

.inline-onboarding {
  display: flex;
  padding: 12px;
  background-color: #b0cfeb;
  border-radius: $border-radius-medium;

  .icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 8px;
  }

  button {
    margin-left: 16px;
  }
}
</style>
