<template>
  <div class="onboarding-dialog">
    <div class="modal-body">
      <div class="header-img-line">
        <div class="header-img-wrapper">
          <img src="/images/onboarding.svg" />
        </div>
      </div>

      <transition name="fade" mode="out-in">
        <div :key="content.heading">
          <h1>{{ $t(content.heading) }}</h1>
          <p>{{ $t(content.subheading) }}</p>
          <div v-if="content.element === 'checklist'">
            <h3>{{ $t('ONBOARD.CHECKLIST.HEADING') }}</h3>
            <div class="checklist-wrapper">
              <OnboardingChecklist compact :animate-step="newlyFinishedStep" />
            </div>
          </div>
          <div v-else>
            <OnboardingHelpCards />
            <OriginSurvey v-if="partnerType === 'SHOP'" />
          </div>
          <button
            class="btn btn-primary btn-block btn-lg continue-button"
            :disabled="disableNextBtn"
            @click="goToNextStep"
          >
            {{ $t(content.cta) }}
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OnboardingChecklist from 'src/app/components/onboarding/OnboardingChecklist.vue';
import OnboardingHelpCards from 'src/app/components/onboarding/OnboardingHelpCards.vue';
import confetti from '@/confetti/confetti.js';
import analytics from '@/tracking/analytics';
import OriginSurvey from 'src/app/components/survey/OriginSurvey.vue';

export default {
  name: 'OnboardingDialog',
  components: {
    OnboardingChecklist,
    OnboardingHelpCards,
    OriginSurvey,
  },
  props: {
    modalInstance: Object,
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      newlyFinishedStep: null,
      onboardingComplete: false,
      disableNextBtn: false,
    };
  },
  computed: {
    ...mapGetters({
      firstUnfinishedRelevantStep: 'onboarding/firstUnfinishedRelevantStep',
      unfinishedSteps: 'onboarding/unfinishedRelevantSteps',
      partnerType: 'user/partnerType',
    }),
    content() {
      if (this.data.welcome) {
        return {
          heading: 'WELCOME.HEADING',
          subheading: 'WELCOME.SUBHEADING',
          cta: 'WELCOME.START_ONBOARDING',
          element: 'checklist',
        };
      }
      if (this.onboardingComplete) {
        return {
          heading: 'ONBOARD.COMPLETE.HEADING',
          subheading: 'ONBOARD.COMPLETE.SUBHEADING',
          cta: 'ONBOARD.COMPLETE.CTA',
          element: 'help',
        };
      } else {
        return {
          heading: 'ONBOARD.STEP_DONE.HEADING',
          subheading: 'ONBOARD.STEP_DONE.SUBHEADING',
          cta: 'ONBOARD.STEP_DONE.CTA',
          element: 'checklist',
        };
      }
    },
  },
  created() {
    if (this.data.oldSteps && this.data.newSteps) {
      const step = this.data.newSteps.find(
        (newStep) =>
          !this.data.oldSteps.some((oldStep) => oldStep.key === newStep.key),
      );
      this.newlyFinishedStep = step ? step.key : null;
    }

    if (this.unfinishedSteps.steps && !this.unfinishedSteps.steps.length) {
      analytics.events.onboardingComplete();

      this.disableNextBtn = true;
      setTimeout(() => {
        this.onboardingComplete = true;
        confetti.randomCanons();
        this.disableNextBtn = false;
      }, 1500);
    }
  },
  methods: {
    goToNextStep() {
      if (this.firstUnfinishedRelevantStep) {
        this.firstUnfinishedRelevantStep.onClick();
        this.modalInstance.close();
      } else {
        this.modalInstance.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';

.onboarding-dialog {
  width: 600px;

  .modal-body {
    padding-top: 24px;
  }

  .header-img-line {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: -72px;
    left: 0;

    .header-img-wrapper {
      width: 144px;
      height: 144px;
      border-radius: 100%;
      background-color: $white;
      border: 12px solid $white;
      background-color: $grey5;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 96px;
      }
    }
  }

  h1 {
    margin: 0 0 16px 0;
  }

  p {
    color: $grey60;
    margin: 0 0 24px 0;
  }

  h3 {
    margin: 0 0 8px;
  }

  .checklist-wrapper {
    background-color: $grey5;
    border-radius: $border-radius-medium;
    padding: 8px;
    margin-bottom: 24px;
  }

  .continue-button {
    padding: 12px;
  }
}
</style>

<style>
.dialog-onboarding {
  .dialog-backdrop {
    padding-top: 80px;
  }
}
</style>
