<template>
  <h2>
    {{ $t('POS.MARKETPLACE.DELETION.HEADING') }}
  </h2>
  <p class="text-grey info-text">
    {{ $t('POS.MARKETPLACE.DELETION.DELETE_DESCRIPTION') }}
  </p>
  <p class="text-grey composition-count-text">
    {{
      `${$t('POS.MARKETPLACE.DELETION.COMPOSITION_COUNT')}: ${compositionCount}`
    }}
  </p>
  <div
    class="text-xl confirmation-text"
    v-html="
      $t('POS.MARKETPLACE.DELETION.CONFIRM_TEXT', {
        word: $t('POS.MARKETPLACE.DELETION.CONFIRM_WORD'),
      })
    "
  ></div>
  <input
    v-model="confirmationText"
    type="text"
    :placeholder="$t('POS.MARKETPLACE.DELETION.CONFIRM_PLACEHOLDER')"
    class="confirmation-input"
  />
  <div class="dialog-footer">
    <span>
      <ProgressButton
        class="btn btn-danger"
        :label="$t('POS.MARKETPLACE.DELETION.DELETE_CTA')"
        :on-click="closeShowroom"
        :disabled="!textConfirmed"
      />
    </span>
    <a href="#" class="link-main cancel-button" @click.prevent="dismissDialog">
      {{ $t('GENERAL.CANCEL') }}
    </a>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import publishingCoreService from '@/api/publishingCoreService/publishingCoreService';
import { PUBLISHING_SCOPE } from '@/publishing/publishingScope';
import analyticsService from '@/tracking/analytics';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { i18n } from '@/translate/i18n';
import ProgressButton from '@/btnProgress/ProgressButton.vue';
import { delay } from '@/utils';

const { t } = i18n.global;
const router = useRouter();
const store = useStore();

const confirmationText = ref('');
const isShowroomClosed = ref(false);
const textConfirmed = computed(
  () => confirmationText.value === t('POS.MARKETPLACE.DELETION.CONFIRM_WORD'),
);
const props = defineProps({
  modalInstance: {
    type: Object,
    required: true,
  },
});

const compositionCount = computed(
  () => store.state.compositions.posStateCounts.total,
);

watch(isShowroomClosed, async (newValue) => {
  if (newValue) {
    await delay(1000);
    await router.push({ name: 'partnerarea.dashboard' });
  }
});

const dismissDialog = () => {
  props.modalInstance.dismiss({ reset: true });
};

const closeShowroom = async () => {
  await delay(500);
  analyticsService.logEvent('remove_marketplace_delete');

  await publishingCoreService.deleteAllScopeCompositions(
    PUBLISHING_SCOPE.MP.key,
  );

  await store.dispatch('settings/setSetting', {
    key: 'MP_ENABLED',
    value: false,
  });

  store.commit('toasts/addToast', {
    variant: 'black',
    text: 'POS.MARKETPLACE.DELETION.TOAST',
    hideIcon: true,
  });
  isShowroomClosed.value = true;
};
</script>

<style scoped lang="scss">
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.confirmation-input {
  margin-bottom: 16px;
  width: 33%;
}

.composition-count-text {
  margin-bottom: 24px;
  margin-top: 16px;
}

.confirmation-text {
  margin-bottom: 8px;
}

p {
  max-width: 800px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.cancel-button {
  margin-left: 16px;
}
</style>
