import apiClient from '@/api/client/apiClient';
import store from '@/store/Store';

export default {
  getDigitalProducts(options) {
    return apiClient
      .request({
        method: 'GET',
        url: `/dgp-pa/users/${store.state.user.data.id}/digitalProducts`,
        params: {
          ...options,
        },
      })
      .then((res) => res.data);
  },
  getDigitalProduct(id) {
    return apiClient
      .request({
        method: 'GET',
        url: `/dgp-pa/users/${store.state.user.data.id}/digitalProducts/${id}`,
      })
      .then((res) => ({
        ...res.data,
        contentPrice: res.data.contentPrice || {
          currencyId: store.state.user.data.currency.id,
          amount: null,
        },
      }));
  },
  createDigitalProduct(category) {
    return apiClient
      .request({
        method: 'POST',
        url: `/dgp-pa/users/${store.state.user.data.id}/digitalProducts`,
        data: {
          productClass: category,
          name: '',
          tags: [],
          description: '',
        },
      })
      .then((res) => res.data);
  },
  updateDigitalProduct(digitalProduct) {
    return apiClient
      .request({
        method: 'PUT',
        url: `/dgp-pa/users/${store.state.user.data.id}/digitalProducts/${digitalProduct.id}`,
        data: {
          name: digitalProduct.workingState.name,
          description: digitalProduct.workingState.description,
          tags: digitalProduct.workingState.tags,
          shopIds: digitalProduct.shopIds,
          productImageOrder: digitalProduct.workingState.images?.map(
            (file) => file.id,
          ),
          productClass: digitalProduct.productClass,
          contentPrice:
            digitalProduct.contentPrice.amount !== null
              ? digitalProduct.contentPrice
              : null,
        },
      })
      .then((res) => ({
        ...res.data,
        contentPrice: res.data.contentPrice || {
          currencyId: store.state.user.data.currency.id,
          amount: null,
        },
      }));
  },
  publishDigitalProduct(digitalProduct) {
    return apiClient
      .request({
        method: 'POST',
        url: `/dgp-pa/users/${store.state.user.data.id}/digitalProducts/${digitalProduct.id}/publish`,
      })
      .then((res) => res.data);
  },
  deleteDigitalProduct(digitalProduct) {
    return apiClient.request({
      method: 'DELETE',
      url: `/dgp-pa/users/${store.state.user.data.id}/digitalProducts/${digitalProduct.id}`,
    });
  },
  getDigitalProductFile(digitalProduct, fileId) {
    return apiClient
      .request({
        method: 'GET',
        url: `/dgp-pa/users/${store.state.user.data.id}/digitalProducts/${digitalProduct.id}/files/${fileId}`,
      })
      .then((res) => res.data);
  },
  deleteDigitalProductImage(digitalProduct, imageId) {
    return apiClient
      .request({
        method: 'DELETE',
        url: `/dgp-pa/users/${store.state.user.data.id}/digitalProducts/${digitalProduct.id}/images/${imageId}`,
      })
      .then((res) => res.data);
  },
  deleteDigitalProductFile(digitalProduct, fileId) {
    return apiClient
      .request({
        method: 'DELETE',
        url: `/dgp-pa/users/${store.state.user.data.id}/digitalProducts/${digitalProduct.id}/files/${fileId}`,
      })
      .then((res) => res.data);
  },
  uploadDigitalProductFiles(digitalProduct, files) {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(`file`, files[i]);
    }

    return apiClient
      .request({
        method: 'POST',
        url: `/dgp-pa/users/${store.state.user.data.id}/digitalProducts/${digitalProduct.id}/files`,
        params: {
          locale: null,
        },
        data: formData,
        headers: { 'Content-Type': undefined },
      })
      .then((res) => res.data);
  },
  uploadDigitalProductImages(digitalProduct, files) {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(`file`, files[i]);
    }

    return apiClient
      .request({
        method: 'POST',
        url: `/dgp-pa/users/${store.state.user.data.id}/digitalProducts/${digitalProduct.id}/images`,
        params: {
          locale: null,
        },
        data: formData,
        headers: { 'Content-Type': undefined },
      })
      .then((res) => res.data);
  },
  getDigitalProductsPricingData() {
    return apiClient
      .request({
        method: 'GET',
        url: `/api/v1/priceStrategies/mds/digitalProductParams`,
      })
      .then((res) => res.data);
  },
};
