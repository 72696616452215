<template>
  <div>
    <div class="entry sp-subheader">
      {{ $t('POS.SHOP_SETTINGS.STARTPAGE.PRODUCT_PREVIEW.SUBHEADING') }}
      <div class="separator" />
      <button
        v-if="sortingPossible"
        class="btn btn-light"
        @click="openProductSorting"
      >
        {{
          $t('POS.SHOP_SETTINGS.STARTPAGE.PRODUCT_PREVIEW.EMPTY.LINK_LISTPAGE')
        }}
      </button>
    </div>
    <ToggleSwitch
      v-model="startPage.productPreviewEnabled"
      label="POS.SHOP_SETTINGS.STARTPAGE.PRODUCT_PREVIEW.ENABLE_PRODUCT_PREVIEW"
      :disabled="!startPage.productPreviewPossible"
    />
    <LegalLabelInput
      v-model="startPage.productPreviewCaption"
      class="sprd-shop-input"
      maxlength="50"
      :disabled="!startPage.productPreviewEnabled"
      :label="$t('POS.SHOP_SETTINGS.STARTPAGE.CAPTION_LABEL')"
    />
    <LegalLabelInput
      v-model="startPage.productPreviewButtonCaption"
      class="sprd-shop-input"
      maxlength="50"
      :disabled="!startPage.productPreviewEnabled"
      :label="$t('POS.SHOP_SETTINGS.STARTPAGE.BUTTON_LABEL')"
    />
  </div>
</template>

<script>
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import LegalLabelInput from '../../common/LegalLabelInput.vue';
import { StartPageData } from 'src/app/partnerarea/pos/shop/shopSavable/StartPageData';
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import dialogService from '@/dialogs/wrapper/dialogService';
import IdeaSortingDialog from 'src/app/partnerarea/pos/shop/ideaSorting/IdeaSortingDialog.vue';

export default {
  name: 'ProductPreview',
  components: {
    ToggleSwitch,
    LegalLabelInput,
  },
  mixins: [StartPageData, SettingsData],
  computed: {
    productPreviewPossible() {
      return this.startPage.productPreviewPossible;
    },
    sortingPossible() {
      return (
        this.settings.listPage && // list page enabled
        this.config.shopCurrencyEditable !== true
      ); // products available
    },
  },
  watch: {
    productPreviewPossible: {
      handler() {
        this.$store.commit('shop/setLivePreview', {
          path: '',
          section: 'sprd-startpage-product-preview',
          visible: this.productPreviewPossible,
        });
      },
      immediate: true,
    },
  },
  methods: {
    openProductSorting() {
      dialogService.openDialog(IdeaSortingDialog, null, {
        displayClose: false,
        fullSize: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../startPageCommon.scss';
.separator {
  height: 12px;
}
</style>
