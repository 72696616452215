import collectionsService from '@/api/shopCollectionsService/shopCollectionsService';

export const CollectionData = {
  beforeCreate() {
    if (!this.$store.state.shop.shopSavable.clientData.collections) {
      this.$store.commit('shop/addDynamicSection', {
        key: 'collections',
        initialData: {
          collectionList: [],
          collectionsLoaded: false,
          collectionHasChanged: false,
        },
      });

      collectionsService
        .getCollections(this.$store.state.shop.currentShop.id)
        .then((data) =>
          this.$store.commit('shop/updateSectionOriginalData', {
            sectionKey: 'collections',
            origData: {
              collectionList: data,
              collectionsLoaded: true,
              collectionHasChanged: false,
            },
          }),
        );
    }
  },
  computed: {
    collectionContainer() {
      return (
        this.$store.state.shop.shopSavable.clientData.collections || {
          collectionList: [],
        }
      );
    },
    collections: {
      get() {
        return this.collectionContainer.collectionList;
      },
      set(newValue) {
        this.$store.commit('shop/updateSectionData', {
          key: 'collections',
          data: {
            collectionList: newValue,
          },
        });
      },
    },
    collectionsLoaded() {
      return this.collectionContainer.collectionsLoaded;
    },
    hasMaxCollections() {
      return this.collections.length > 50;
    },
  },
};
