<template>
  <table class="data-table">
    <thead>
      <tr>
        <th>
          {{
            $t(
              'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.TRANSLATION_LANGUAGES',
            )
          }}
        </th>
        <th>{{ $t('GENERAL.STATUS') }}</th>
        <th>{{ $t('SA.shopsettings.information.url.label') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="language in languagesToDisplay" :key="language.isoCode">
        <td>{{ language.name }}</td>
        <td class="text-grey">
          <span v-if="language.pending">{{ $t(pendingStatusText) }}</span>
          <span v-else>{{ $t(props.onlineStatusText) }}</span>
        </td>
        <td class="country-links">
          <a
            v-for="deeplink in language.deeplinks"
            :key="deeplink.url"
            :href="deeplink.url || null"
            target="_blank"
            class="link-main"
            :title="deeplink.countryName"
            :class="{ disabled: !deeplink.url }"
          >
            <img :src="`images/flags/${deeplink.country}.svg`" alt="" />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import localeService from 'src/app/commons/localeService/localeService';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  getDeeplinkForLocale: {
    required: true,
    type: Function,
  },
  requestDeeplinkForPendingTranslations: {
    type: Boolean,
  },
  displayNoneExistingTranslations: {
    type: Boolean,
  },
  displayUnavailableDeeplinks: {
    type: Boolean,
  },
  pendingStatusText: {
    type: String,
    default: 'DESIGNS.STATUS.REVIEW',
  },
  onlineStatusText: {
    type: String,
    default: 'DESIGNS.STATUS.PUBLISHED',
  },
  includeEurope: {
    type: Boolean,
  },
  defaultLanguage: {
    type: String,
  },
  languages: {
    type: Array,
  },
});

const store = useStore();
const languageByIso = store.getters['platform/languageByIso'];
const countries = computed(() => store.state.platform.countries);
const addedTranslations = reactive(
  props.languages
    .map((language) => ({
      isoCode: language.isoCode,
      name: languageByIso(language.isoCode).name,
      deeplinks: [],
      pending: language.pending,
    }))
    .sort((x, y) =>
      x.isoCode === props.defaultLanguage
        ? -1
        : y.isoCode === props.defaultLanguage
          ? 1
          : 0,
    ),
);
addedTranslations.map((translation) => fetchDeeplinks(translation));

const languagesToDisplay = computed(() => {
  return addedTranslations.filter(
    (translation) =>
      props.displayUnavailableDeeplinks || translation.deeplinks.length,
  );
});

async function fetchDeeplinks(language) {
  const localeInformations = localeService.getLocaleInformation();

  if (props.includeEurope) {
    localeInformations.push({
      locale: 'en_EU',
    });
  }

  const localeData = localeInformations
    .filter(
      (localeInformation) =>
        localeService.getLanguageFromLocale(localeInformation.locale) ===
        language.isoCode,
    )
    .map((localeInformation) => ({
      locale: localeInformation.locale,
      country: localeService
        .getCountryFromLocale(localeInformation.locale)
        .toLowerCase(),
    }))
    .map((localeInformation) => ({
      ...localeInformation,
      countryName: countries.value.find(
        (country) =>
          country.isoCode === localeInformation.country.toUpperCase(),
      )?.name,
    }));

  language.deeplinks = localeData;

  if (language.pending && !props.requestDeeplinkForPendingTranslations) {
    return;
  }

  try {
    const data = await Promise.all(
      localeData.map(({ locale }) => props.getDeeplinkForLocale(locale)),
    );

    if (data?.length) {
      language.deeplinks = localeData
        .map((localeEntry) => ({
          ...localeEntry,
          url: data.find((entry) => entry.locale === localeEntry.locale).url,
        }))
        .filter(
          (deeplink) => props.displayUnavailableDeeplinks || deeplink.url,
        );
    }
  } catch (error) {
    console.error('design not available on pos', error);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.country-links {
  a {
    margin-left: 8px;

    img {
      width: 20px;
    }

    &.disabled img {
      opacity: 0.5;
    }

    &:not(.disabled):hover img {
      box-shadow: 0 0 4px 0px rgb(0 0 0 / 23%);
      transform: scale(1.3);
    }
  }
}
</style>
