<template>
  <div>
    <ToggleSwitch
      v-model="settings.listPage"
      class="sprd-toggle-switch--first bold"
      label="SA.pages.productList.activateListPage.label"
    />

    <p class="subTitle" :class="{ disabledText: !settings.listPage }">
      {{ $t('SA.pages.productSorting.listPageHeading') }}
    </p>
    <div class="itemCount">
      <label
        class="itemCountLabel"
        :class="{ disabledText: !settings.listPage }"
      >
        {{ $t('SA.pages.itemcount.label') }}
      </label>

      <Dropdown
        class="dropdown"
        :items="possibleListLoadingCounts"
        :disabled="!settings.listPage"
        :value="settings.listLoadingCount"
        @change="(llc) => (settings.listLoadingCount = llc)"
      />
    </div>
    <ToggleSwitch
      class="toggle-button"
      label="POS.SHOP_SETTINGS.SHOW_FILTERS"
      :disabled="!settings.listPage"
      :model-value="hasFilters"
      @update:model-value="setHasFilters"
    />

    <ToggleSwitch
      class="toggle-button"
      label="POS.SHOP_SETTINGS.SHOW_LIST_ITEM_DETAILS"
      :disabled="!settings.listPage"
      :model-value="hasListItemDetails"
      @update:model-value="setHasListItemDetails"
    />

    <div class="listModeContainer">
      <p class="subTitle" :class="{ disabledText: !settings.listPage }">
        {{ $t('SA.pages.listmode.title') }}
      </p>
      <ToggleButton
        :items="listModes"
        :item-key="(lm) => lm.value"
        :value="selectedListMode"
        :disabled="!settings.listPage"
        @change="setListMode"
      />

      <div v-if="settings.listMode === 'PRODUCT'">
        <div class="itemCount">
          <label
            class="itemCountLabel"
            :class="{ disabledText: !settings.listPage }"
          >
            {{ $t('SA.pages.productSorting.productsPerDesign.label') }}
            <Tooltip
              :text="
                $t('SA.pages.productSorting.productsPerDesign.description')
              "
            />
          </label>
          <Dropdown
            :items="productListCounts"
            :item-key="(lc) => lc.value"
            :item-to-string="(lc) => lc.label"
            :value="selectedProductListCount"
            @change="(lc) => (settings.simpleSortedProductListCount = lc.value)"
          />
        </div>

        <div>
          <p class="subTitle sortLabel">
            {{ $t('SA.pages.productSorting.shuffle.heading') }}
          </p>
          <RadioButtonGroup
            v-model="selectedShuffleMode"
            class="shuffleMode"
            :items="shuffleModes"
            :item-key="(item) => item.value"
            :item-to-string="(item) => item.label"
            :disabled="!settings.listPage"
          >
            <template #item="{ item }">
              <div class="item">
                <div class="checkmark">
                  <svg class="icon" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8"></circle>
                  </svg>
                </div>
                <div class="itemLabel">{{ item.label }}</div>
                <Tooltip :text="item.description" />
              </div>
            </template>
          </RadioButtonGroup>
        </div>
      </div>

      <ToggleSwitch
        v-if="settings.listMode === 'DESIGN'"
        v-model="settings.designWithPT"
        label="SA.pages.listmode.designWithPT.label"
        :disabled="!settings.listPage"
      />
    </div>

    <div class="sortingBtns">
      <button
        class="btn btn-light icon-btn"
        :disabled="!isSortingPossible"
        @click="openIdeaSorting"
      >
        <Icon icon="list" />
        {{ $t('POS.SHOP_SETTINGS.LISTPAGE.SORT_DESIGNS') }}
      </button>
      <button
        class="btn btn-light icon-btn"
        :disabled="!isSortingPossible"
        @click="openProductTypeSorting"
      >
        <Icon icon="list" />
        {{ $t('POS.SHOP_SETTINGS.LISTPAGE.SORT_PRODUCT_TYPES') }}
      </button>
    </div>
  </div>
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import Dropdown from 'src/app/commons/Dropdown/Dropdown.vue';
import RadioButtonGroup from 'src/app/commons/RadioButtonGroup/RadioButtonGroup.vue';
import ToggleButton from 'src/app/commons/ToggleButton/ToggleButton.vue';
import ToggleSwitch from 'src/app/commons/toggleSwitch/ToggleSwitch.vue';

import Tooltip from 'src/app/partnerarea/pos/shop/common/Tooltip.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import IdeaSortingDialog from 'src/app/partnerarea/pos/shop/ideaSorting/IdeaSortingDialog.vue';
import ProductTypeSortingDialog from 'src/app/partnerarea/pos/shop/productTypeSorting/ProductTypeSortingDialog.vue';

export default {
  name: 'ListPage',
  components: {
    Tooltip,
    Dropdown,
    RadioButtonGroup,
    ToggleSwitch,
    ToggleButton,
  },
  mixins: [SettingsData],
  data() {
    return {
      possibleListLoadingCounts: [12, 24, 48, 96],
      listModes: [
        {
          text: 'SA.pages.listmode.productlist.label',
          value: 'PRODUCT',
          icon: 'shirt',
        },
        {
          text: 'SA.pages.listmode.design.label',
          value: 'DESIGN',
          icon: 'design',
        },
      ],
      shuffleModes: [
        {
          label: this.$t('SA.pages.productSorting.shuffle.OFF.label'),
          description: this.$t(
            'SA.pages.productSorting.shuffle.OFF.description',
          ),
          value: 'OFF',
        },
        {
          label: this.$t(
            'SA.pages.productSorting.shuffle.IDEA_DIVERSITY.label',
          ),
          description: this.$t(
            'SA.pages.productSorting.shuffle.IDEA_DIVERSITY.description',
          ),
          value: 'IDEA_DIVERSITY',
        },
        {
          label: this.$t(
            'SA.pages.productSorting.shuffle.FULL_DIVERSITY.label',
          ),
          description: this.$t(
            'SA.pages.productSorting.shuffle.FULL_DIVERSITY.description',
          ),
          value: 'FULL_DIVERSITY',
        },
      ],
      productListCounts: [
        {
          label: this.$t('SA.pages.productSorting.productsPerDesign.all.label'),
          value: -1,
        },
      ].concat(
        Array.from({ length: 12 }, (_, i) => ({
          label: `${i + 1}`,
          value: i + 1,
        })),
      ),
    };
  },
  computed: {
    selectedListMode() {
      return this.listModes.find((lm) => lm.value === this.settings.listMode);
    },
    hasFilters() {
      return this.settings.listSizeFilter && this.settings.listColorFilter;
    },
    hasListItemDetails() {
      return (
        this.settings.showProperProductName &&
        this.settings.showListPrice &&
        this.settings.listShowColorsAndSizes
      );
    },
    isSortingPossible() {
      return (
        this.settings.listPage && // list page enabled
        this.config.shopCurrencyEditable !== true
      ); // products available
    },
    selectedProductListCount() {
      return this.productListCounts.find(
        (plc) => plc.value === this.settings.simpleSortedProductListCount,
      );
    },
    selectedShuffleMode: {
      get() {
        const currentMode = this.settings.listShuffleMode;
        return this.shuffleModes.find((mode) => mode.value === currentMode);
      },
      set({ value }) {
        this.settings.listShuffleMode = value;
      },
    },
  },
  created() {
    if (this.config.shopCurrencyEditable === true) {
      // nothing published to the shop yet - let the NoProductsPlaceholder take over the right-hand side
      this.$store.commit('shop/setLivePreviewVisible', false);
    } else {
      this.$store.commit('shop/setLivePreview', { path: 'all' });
    }
  },
  methods: {
    setListMode(lm) {
      this.settings.listMode = lm.value;
    },
    setHasFilters(active) {
      this.settings.listSizeFilter = active;
      this.settings.listColorFilter = active;
    },
    setHasListItemDetails(active) {
      this.settings.showProperProductName = active;
      this.settings.showListPrice = active;
      this.settings.listShowColorsAndSizes = active;
    },
    openIdeaSorting() {
      dialogService.openDialog(IdeaSortingDialog, null, {
        displayClose: false,
        fullSize: true,
      });
    },
    openProductTypeSorting() {
      dialogService.openDialog(ProductTypeSortingDialog, null, {
        displayClose: false,
        fullSize: true,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.itemCount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
}

.listModeContainer {
  margin-top: 4px;
}

.sprd-toggle-switch.sprd-toggle-switch--first {
  margin-top: 20px;
}

.itemCountLabel {
  display: flex;
  align-content: center;
  margin: 0;
}

p.subTitle {
  margin: 28px 0 8px;
}

.listMode {
  margin: 24px 0;
}

.disabledText {
  color: $grey20;
}

.radiobutton-group {
  flex-direction: column;
  margin-bottom: 16px;

  :deep(.radiobutton-container) {
    margin: 8px 0;
  }

  .checkmark {
    flex: 0 0 24px;
  }
}

.toggle-button {
  margin: 12px 0;
}

.item {
  flex: 1;
}

.itemLabel {
  flex: 1;
}

.sortLabel {
  margin: 24px 0 16px !important;
}

.sortingBtns .btn {
  width: 100%;
  height: 40px;
  margin-top: 24px;
  justify-content: flex-start;
  text-align: left;

  &:first-child {
    margin-top: 0;
  }
}
</style>
