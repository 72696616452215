<template>
  <PosAndTimeFilter
    v-if="defaultTimeFilter && defaultPosFilter"
    :time-filters="timeFilters"
    :default-time-filter="defaultTimeFilter"
    :default-pos-filter="defaultPosFilter"
    :pos-filters="posFilters"
    @change="onFilterUpdate"
  />
</template>

<script>
import PosAndTimeFilter from 'src/app/components/charts/timeline/PosAndTimeFilter.vue';
import { timeFilters } from './trackingMetricsConfig';
import statisticsService from '@/api/statisticsService/statisticsService';
import { removeNullValues } from 'src/app/commons/utils';

export default {
  name: 'TrackingMetricsFilter',
  components: {
    PosAndTimeFilter,
  },
  emits: ['filter-update'],
  data() {
    return {
      filters: {
        pos: null,
        time: null,
      },
      timeFilters: timeFilters,
      defaultTimeFilter: null,
      defaultPosFilter: null,
    };
  },
  computed: {
    posFilters: statisticsService.getShopPosFilters,
  },
  created() {
    this.defaultPosFilter =
      this.posFilters.find(
        (posFilter) => posFilter.key === this.$route.query?.pos,
      ) || this.posFilters[0];

    this.defaultTimeFilter =
      Object.values(this.timeFilters).find(
        (time) => time.duration === parseInt(this.$route.query?.daysAgo),
      ) || timeFilters.MONTH;
  },
  methods: {
    onFilterUpdate(filters) {
      this.filters.pos = filters.pos;
      this.filters.time = filters.time;
      // nextTick needed, otherwise this.filter.dateFrom will still be emtpy even just set https://github.com/charliekassel/vuejs-datepicker/issues/119
      this.$nextTick(() => {
        this.$emit('filter-update', this.filters);

        this.updateParams();
      });
    },
    updateParams() {
      this.$router.replace({
        query: removeNullValues({
          ...this.$route.query,
          pos: this.filters.pos && this.filters.pos.key,
          daysAgo: this.filters.time && this.filters.time.duration,
        }),
      });
    },
  },
};
</script>
