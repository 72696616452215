import apiClient from '@/api/client/apiClient';
import store from '@/store/Store';
import designUploadServiceV2 from '../designUploadService/designUploadServiceV2';
import localeService from '@/localeService/localeService';

export default {
  async createCompositionFromDesignIdWithScope(designId, scope) {
    return await apiClient.request({
      method: 'POST',
      url: `api/pubc/partner/${store.state.user.data.id}/compositions/fromDesign`,
      params: {
        designId,
        scope: scope?.key,
        creator: 'partnerarea',
      },
    });
  },
  async createCompositionFromDesignUploadUrlWithScope(uploadUrl, scope) {
    const { designId, rejectionReason } =
      await designUploadServiceV2.waitForUploadProcessing(uploadUrl);
    if (rejectionReason) {
      return Promise.reject({ rejectionReason });
    }
    return this.createCompositionFromDesignIdWithScope(designId, scope);
  },
  async getCompositions(options) {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `api/pubc/partner/${store.state.user.data.id}/compositions`,
      params: options,
    });
    return data;
  },
  async getComposition(compositionId) {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `api/pubc/partner/${store.state.user.data.id}/compositions/${compositionId}`,
    });
    return data;
  },
  async getPosStateCounts(options) {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `api/pubc/partner/${store.state.user.data.id}/compositions/counts`,
      params: options,
    });
    return data;
  },
  async deleteComposition(compositionId) {
    const { data } = await apiClient.request({
      method: 'DELETE',
      url: `api/pubc/partner/${store.state.user.data.id}/compositions/${compositionId}`,
    });
    return data;
  },
  async updateComposition(composition) {
    const { data } = await apiClient.request({
      method: 'PUT',
      url: `api/pubc/partner/${store.state.user.data.id}/compositions/${composition.id}`,
      data: composition,
    });
    return data;
  },
  async updateCompositionSellables({ composition, payload, preview }) {
    const { data } = await apiClient.request({
      method: 'PUT',
      url: `api/pubc/partner/${store.state.user.data.id}/compositions/${composition.id}/updateSellables`,
      data: payload,
      params: {
        preview,
      },
    });
    return data;
  },
  async getTranslations() {
    const { data } = await apiClient.request({
      method: 'GET',
      url: 'api/pubc/translations/inventory',
      params: {
        language: localeService.getLanguage(),
      },
    });
    return data;
  },
  async getCategories() {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `api/pubc/partner/${store.state.user.data.id}/categories`,
    });
    return data;
  },
  async getConfig() {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `api/pubc/config`,
    });
    return data;
  },
  async getAppearanceGroups() {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `api/pubc/config/appearanceGroups`,
    });
    return data;
  },
  async getShops() {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `api/pubc/partner/${store.state.user.data.id}/shops`,
    });
    return data;
  },
  async registerShop(shopId) {
    const { data } = await apiClient.request({
      method: 'POST',
      url: `api/pubc/partner/${store.state.user.data.id}/shops`,
      data: { shopId },
    });
    return data;
  },
  async updateCompositionAttributes(composition, attributes) {
    const { data } = await apiClient.request({
      method: 'PUT',
      url: `api/pubc/partner/${store.state.user.data.id}/compositions/${composition.id}/attributes`,
      data: attributes,
    });
    return data;
  },
  async unpublishAllScopeCompositions(scope) {
    const { data } = await apiClient.request({
      method: 'PUT',
      url: `api/pubc/partner/${store.state.user.data.id}/compositions/unpublish`,
      params: { scope },
    });
    return data;
  },
  async deleteAllScopeCompositions(scope) {
    const { data } = await apiClient.request({
      method: 'DELETE',
      url: `api/pubc/partner/${store.state.user.data.id}/compositions`,
      params: { scope },
    });
    return data;
  },
};
