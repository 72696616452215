<template>
  <div class="recap-dialog">
    <div class="modal-body">
      <Recap :year="data.year" />
    </div>
  </div>
</template>

<script>
import Recap from './Recap.vue';

export default {
  name: 'YearRecap',
  components: {
    Recap,
  },
  props: {
    modalInstance: { type: Object, required: true },
    data: { type: Object, required: false },
  },
  methods: {
    dismiss() {
      this.modalInstance.dismiss(true);
    },
    cancel() {
      this.modalInstance.dismiss();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';

.recap-dialog {
  .modal-body {
    border-radius: $border-radius-medium;
    box-shadow: 2px 4px 8px #00000029;
  }
}
</style>
