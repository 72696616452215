<template>
  <div class="help-panel-wrapper">
    <div class="actions">
      <button
        v-if="liveChatEnabled && (liveChatUnreadMessaged > 0 || liveChatOpen)"
        class="chat-action"
        @click="openLiveChat"
      >
        <span v-if="liveChatUnreadMessaged > 0"
          >{{ liveChatUnreadMessaged }}
        </span>
        <Icon icon="livechat" />
      </button>
      <button
        class="panel-action"
        :class="{ highlight }"
        @click="triggerExpanded"
      >
        <Icon icon="questionmark" />
      </button>
    </div>
    <div v-if="expanded" class="modal-backdrop help-panel-backdrop"></div>
    <div class="help-panel" :class="{ expanded }">
      <div v-if="expanded" class="help-panel-close">
        <Icon icon="x" />
      </div>
      <div
        v-click-outside="{ isActive: expanded, handler: triggerExpanded }"
        class="help-panel-content overflow-y"
      >
        <div class="help-center">
          <div>
            <h1>{{ $t('HELP_PANEL.HEADING') }}</h1>
            <div class="userid">
              <Copyable id="pid" :content="userData.id" inline>
                <p>{{ $t('DASHBOARD.USER_ID') }}: {{ userData.id }}</p>
              </Copyable>
            </div>
          </div>
          <SmartFaq />
          <div class="common-links">
            <h3>{{ $t('HELP_PANEL.CONTACT.HEADING') }}</h3>
            <a
              v-if="liveChatEnabled"
              v-trackClickEvent="{
                name: 'help-panel-chat-click',
              }"
              href="#"
              @click.prevent="openLiveChat"
            >
              <strong>{{ $t('HELP_PANEL.CONTACT.CHAT') }}</strong>
              <Icon icon="livechat" />
            </a>
            <a
              v-trackClickEvent="{
                name: 'help-panel-help-dialog-click',
              }"
              href="#"
              @click.prevent="openHelpDialog"
            >
              <strong>{{ $t('HELP_PANEL.CONTACT.HELP') }}</strong>
              <Icon icon="help" />
            </a>
          </div>
          <div class="common-links">
            <h3>{{ $t('HELP_PANEL.HELP_PAGES.HEADING') }}</h3>
            <a
              v-if="hasMarketplace"
              v-trackClickEvent="{
                name: 'help-panel-mp-center-link-click',
              }"
              :href="mpHelpLink"
              target="_blank"
            >
              <MarketplaceLogo class="logo" />
              <Icon icon="outside" />
            </a>
            <a
              v-if="hasShops"
              v-trackClickEvent="{
                name: 'help-panel-mds-center-link-click',
              }"
              :href="shopHelpLink"
              target="_blank"
            >
              <SpreadshopLogo class="logo" />
              <Icon icon="outside" />
            </a>
          </div>
        </div>

        <div class="legal-links">
          <a :href="privacyPolicyLink" class="link-main" target="_blank">{{
            $t('GENERAL.PRIVACY_POLICY')
          }}</a>
          <a :href="imprintLink" class="link-main" target="_blank">{{
            $t('GENERAL.IMPRINT')
          }}</a>
        </div>
        <div class="version">{{ partnerAreaVersion }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import Copyable from 'src/app/commons/copyable/Copyable.vue';
import liveChatService from 'src/app/commons/liveChat/liveChatService';
import {
  getArticleByKey,
  articleKeys,
  getHref,
} from 'src/app/partnerarea/tipsAndTools/tipsAndToolsConfig';
import localeService from 'src/app/commons/localeService/localeService';
import SmartFaq from './SmartFaq.vue';
import MarketplaceLogo from '@/logos/MarketplaceLogo.vue';
import SpreadshopLogo from '@/logos/SpreadshopLogo.vue';
import { getExternalLinks } from '@/externalLinks/externalLinks';
import HelpDialog from 'src/app/components/helpDialog/HelpDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import { format } from 'date-fns';

export default {
  name: 'HelpPanel',
  components: {
    Copyable,
    SmartFaq,
    MarketplaceLogo,
    SpreadshopLogo,
  },
  data() {
    return {
      privacyPolicyLink: getExternalLinks().PRIVACY_POLICY,
      imprintLink: getExternalLinks().IMPRINT,
      chatStatusCheckInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      hasShops: 'user/hasShops',
      hasMarketplace: 'user/hasMarketplace',
      partnerType: 'user/partnerType',
    }),
    ...mapState({
      userData: (s) => s.user.data,
      expanded: (s) => s.application.helpPanelExpanded,
      highlight: (s) => s.application.helpPanelHighlighted,
      liveChatEnabled: (s) => s.liveChat.enabled,
      liveChatUnreadMessaged: (s) => s.liveChat.unreadMessages,
      liveChatOpen: (s) => s.liveChat.open,
    }),
    shopHelpLink() {
      return getHref(
        getArticleByKey(articleKeys.HELP_CENTER_SHOP),
        null,
        localeService.getLanguage(),
        SPRD.PLATFORM,
      );
    },
    mpHelpLink() {
      return getHref(
        getArticleByKey(articleKeys.HELP_CENTER),
        localeService.getSprdUrl(),
        localeService.getLanguage(),
        SPRD.PLATFORM,
      );
    },
    partnerAreaVersion() {
      return `Partner Area ${BUILD.VERSION} (${this.date(
        BUILD.TIME,
        'short',
      )})`;
    },
    liveChatCustomVariables() {
      return [
        { id: '14573117050268', value: this.partnerType },
        {
          id: '13875465811484',
          value: this.userData.created
            ? format(new Date(this.userData.created), 'yyyy-MM-dd')
            : '',
        },
        {
          id: SPRD.PLATFORM === 'EU' ? '13875459083548' : '15519922421916',
          value: this.userData.id,
        },
      ];
    },
  },
  watch: {
    userData: {
      handler() {
        this.initLiveChat(this.liveChatCustomVariables);
      },
      once: true,
    },
  },
  unmounted() {
    clearInterval(this.chatStatusCheckInterval);
  },
  methods: {
    ...mapMutations({
      setHelpPanelExpansion: 'application/setHelpPanelExpansion',
    }),
    ...mapActions({
      openChat: 'liveChat/openChat',
    }),
    ...mapActions({
      initLiveChat: 'liveChat/init',
    }),
    triggerExpanded() {
      this.setHelpPanelExpansion(!this.expanded);
    },
    openHelpDialog() {
      dialogService.openDialog(HelpDialog);
      this.setHelpPanelExpansion(false);
    },
    openLiveChat() {
      if (!liveChatService.isEnabled()) {
        return;
      }

      this.openChat();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.help-panel-backdrop {
  z-index: 1060;
}

.help-panel {
  position: fixed;
  height: 100vh;
  right: -355px;
  z-index: 1060;
  display: flex;
  align-items: flex-end;
  transition: right 0.1s linear;
  pointer-events: none;

  &.expanded {
    right: 0;
  }
}

.help-panel-close {
  position: absolute;
  top: 0;
  left: -32px;
  color: white;
  height: 32px;
  width: 32px;
  font-size: 24px;
}

.actions {
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  flex-direction: column;
  margin: 0 16px 24px 0;

  .chat-action,
  .panel-action {
    border-radius: $border-radius-medium;
    height: 40px;
    width: 40px;
    border: 1px solid $pa-color-main;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.1s linear;

    .icon {
      height: 20px;
      width: 20px;
    }

    &:hover {
      box-shadow: 2px 4px 6px 1px transparentize($grey90, 0.75);
    }
  }

  .chat-action {
    margin-bottom: 4px;
    color: $pa-color-main;

    span {
      position: absolute;
      z-index: 1;
      top: 4px;
      right: 4px;
      height: 16px;
      width: 16px;
      border-radius: $border-radius-medium;
      background-color: $pa-color-main;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 8px;
      border: 2px solid $white;
    }
  }

  .panel-action {
    background-color: $pa-color-main;
    color: $white;
  }

  .highlight {
    box-shadow: 2px 4px 6px 1px transparentize($grey90, 0.75);
  }
}

.help-center {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.help-panel-content {
  background-color: $white;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.2);
  width: 350px;
  height: 100%;
  pointer-events: auto;
  padding: 8px 0;
  display: flex;
  flex-direction: column;

  h1 {
    margin: 0 0 16px 0;
  }

  .common-links {
    display: flex;
    flex-direction: column;
    gap: 16px;

    h3 {
      margin: 0;
    }

    a {
      padding: 16px;
      border-radius: $border-radius-medium;
      border: 1px solid $grey20;
      display: flex;
      align-items: flex-start;

      &:hover {
        border-color: $pa-color-main;
      }

      &.disabled {
        opacity: 0.5;
      }

      &.primary {
        background-color: $pa-color-main;
        border-color: $pa-color-main;

        &:hover {
          background-color: $pa-color-main-dark;
        }

        strong {
          color: $white;
        }

        p,
        .icon {
          color: #e5e5e5;
        }
      }
    }

    .logo {
      height: 16px;
    }

    strong {
      color: $grey80;
    }

    p {
      margin: 8px 0 0 0;
      color: $grey60;
    }

    .icon {
      flex-shrink: 0;
      margin-left: auto;
      width: 16px;
      height: 16px;
      color: $pa-color-main;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
  }

  .userid {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      @extend .text-sm;
      margin: 0;
    }

    :deep(.icon) {
      width: 16px;
      height: 16px;
    }
  }

  .legal-links {
    margin-top: auto;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    a {
      @extend .text-sm;
      margin-top: 8px;

      &:first-child {
        margin-right: 12px;
      }
    }
  }

  .version {
    padding-top: 8px;
    padding-bottom: 0;
    font-size: 8px;
  }
}
</style>
