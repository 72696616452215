<template>
  <div class="inline-edit">
    <p v-if="!editing">{{ displayValue || modelValue }}</p>
    <a v-if="!editing && !disabled" class="link link-main" @click="startEdit">
      <Icon icon="pencil"></Icon>
    </a>
    <LabelInput v-if="editing" class="field">
      <label>{{ label }}</label>
      <input
        ref="input"
        type="text"
        :value="modelValue"
        :maxLength="maxLength"
        @input="$emit('update:modelValue', $refs.input.value)"
        @blur="stopEdit"
        @keyup.enter="stopEdit"
      />
      <slot></slot>
    </LabelInput>
  </div>
</template>

<script>
import LabelInput from 'src/app/commons/labelInput/LabelInput.vue';

export default {
  name: 'InlineEdit',
  components: {
    LabelInput,
  },
  props: {
    modelValue: {
      type: String,
    },
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    displayValue: {
      type: String,
    },
    maxLength: {
      type: Number,
    },
  },
  emits: ['onEditDone', 'update:modelValue'],
  data() {
    return {
      editing: false,
    };
  },
  created() {
    if (!this.modelValue && !this.displayValue) {
      this.startEdit();
    }
  },
  methods: {
    startEdit() {
      this.editing = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    stopEdit() {
      if (!this.$refs.input?.value) {
        return;
      }
      this.editing = false;
      this.$emit('onEditDone');
    },
  },
};
</script>

<style lang="scss" scoped>
.inline-edit {
  display: flex;
  align-items: center;
  height: 100%;

  .field {
    width: 100%;
  }

  a {
    display: flex;
  }

  .icon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }
}
</style>
