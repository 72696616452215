<template>
  <div class="dialog-size-lg sale-details-dialog">
    <div v-if="creditData" class="modal-body">
      <component
        :is="detailComponent"
        :data="creditData"
        @open-performance-summary="openPerformanceSummary"
      />
    </div>
    <div v-else class="modal-body">
      <img src="/images/loader.svg" class="sprd-loader loader" />
    </div>
  </div>
</template>

<script>
import statisticsService from '@/api/statisticsService/statisticsService';
import CreditDetailsCommission from 'src/app/partnerarea/statistics/credit/details/CreditDetailsCommission.vue';
import CreditDetailsPayout from 'src/app/partnerarea/statistics/credit/details/CreditDetailsPayout.vue';
import CreditDetailsRollback from 'src/app/partnerarea/statistics/credit/details/CreditDetailsRollback.vue';
import CreditDetailsVolume from 'src/app/partnerarea/statistics/credit/details/CreditDetailsVolume.vue';
import CreditDetailsUnknown from 'src/app/partnerarea/statistics/credit/details/CreditDetailsUnknown.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import CreditDetailsVolumeSummary from 'src/app/partnerarea/statistics/credit/details/CreditDetailsVolumeSummary.vue';

export default {
  name: 'CreditDetailsDialog',
  components: {
    CreditDetailsCommission,
    CreditDetailsPayout,
    CreditDetailsRollback,
    CreditDetailsVolume,
    CreditDetailsUnknown,
  },
  props: ['modalInstance', 'data'],
  data() {
    return {
      creditData: null,
      commissionTypes: statisticsService.getCommissionTypes(),
    };
  },
  computed: {
    detailComponent() {
      switch (this.creditData.commissionType) {
        case this.commissionTypes.AFFILIATE_COMMISSION:
        case this.commissionTypes.DESIGN_COMMISSION:
        case this.commissionTypes.MDS_COMMISSION:
          return 'CreditDetailsCommission';
        case this.commissionTypes.VOLUME_COMMISSION:
          return 'CreditDetailsVolume';
        case this.commissionTypes.ROLLBACK:
          return 'CreditDetailsRollback';
        case this.commissionTypes.PAYOUT:
          return 'CreditDetailsPayout';
        case this.commissionTypes.UNKOWN:
          return 'CreditDetailsUnknown';
        default:
          throw new Error('commision type missing');
      }
    },
  },
  async created() {
    this.creditData = await statisticsService.getCreditDetail(this.data.id);
  },
  methods: {
    openPerformanceSummary() {
      this.modalInstance.close();
      dialogService.openDialog(CreditDetailsVolumeSummary);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/utils';

.loader {
  height: 120px;
  margin: 24px auto;
}
</style>
