import store from '@/store/Store';
import apiClient from '@/api/client/apiClient';
import localeService from 'src/app/commons/localeService/localeService';

export default {
  async generateMetaData({
    language,
    imageUrl,
  }: {
    language: string;
    imageUrl: string;
  }) {
    const { data } = await apiClient.request({
      method: 'POST',
      url: `/api/v1/splitbrain/partner/${store.state.user.data.id}/generate/metadata`,
      data: {
        imageUrl,
        language:
          localeService.convertLanguageToLocale(language) ||
          store.getters['user/language'].toUpperCase() ||
          'EN',
      },
    });
    return {
      name: data.name,
      description: data.description,
      tags: data.tags,
    };
  },
};
