export type PublishingCategoryLabel = {
  id: string;
  name: string;
  icon: string;
};

export const iconForPublishingLabel: { [id: string]: string } = {
  MP_UNISEX: 'unisex',
  MP_MEN: 'male',
  MP_WOMEN: 'female',
  MP_KIDS: 'kid',
  MP_BABIES: 'babies',
};
