<template>
  <EmptyTimeline :heading="heading" :subheading="subheading" icon="gear">
    <template #links>
      <a class="link-main" @click="goToShopArea">
        {{ $t('STATISTICS.TRACKING_METRICS.HIDDEN_SHOP.LINK_SHOP_AREA') }}
      </a>
    </template>
  </EmptyTimeline>
</template>

<script>
import EmptyTimeline from 'src/app/components/charts/timeline/EmptyTimeline.vue';

export default {
  name: 'ActivateShop',
  components: {
    EmptyTimeline,
  },
  props: {
    shop: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      heading: 'STATISTICS.TRACKING_METRICS.HIDDEN_SHOP.HEADING',
      subheading: 'STATISTICS.TRACKING_METRICS.HIDDEN_SHOP.SUBHEADING',
    };
  },
  methods: {
    goToShopArea() {
      this.$router.push({
        name: 'shop-area.general.shop-status',
        params: {
          shopId: this.shop,
        },
      });
    },
  },
};
</script>
