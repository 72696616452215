// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  getAnalytics,
  logEvent as firebaseLogEvent,
  setAnalyticsCollectionEnabled,
  setUserId,
  setUserProperties,
} from 'firebase/analytics';
import { trackEvent, trackPageView } from '@/tracking/trackingManager';
import store from '@/store/Store';
import CryptoJS from '@/api/apiAuthentication/sha1.js';
import { differenceInHours } from 'date-fns/differenceInHours';
import { parseISO } from 'date-fns/parseISO';
import { sha256 } from '@/utils';

const firebaseConfig = {
  apiKey: 'AIzaSyDxl5htCYRWN24I3aSAcsHd3PvMr7d-Y3A',
  authDomain: 'api-7951421285675397748-685005.firebaseapp.com',
  databaseURL: 'https://api-7951421285675397748-685005.firebaseio.com',
  projectId: 'api-7951421285675397748-685005',
  storageBucket: 'api-7951421285675397748-685005.appspot.com',
  messagingSenderId: '490653366798',
  appId: '1:490653366798:web:d584395a24da3cdd789a5f',
  measurementId: 'G-4LXLBX5ZNB',
};

let app;
let analytics;

const analyticsService = {
  hasConsent() {
    if (window.sprdConsent?.getStatus) {
      const consent = window.sprdConsent.getStatus();
      return consent?.functional;
    }

    return false;
  },
  isEnabled() {
    return this.hasConsent();
  },
  disable() {
    if (analytics) {
      setAnalyticsCollectionEnabled(analytics, false);
    }
  },
  enable() {
    if (analytics) {
      setAnalyticsCollectionEnabled(analytics, true);
    }
  },
  init() {
    if (!this.isEnabled()) {
      this.disable();
      return;
    }

    this.enable();
    app = initializeApp(firebaseConfig);
    analytics = getAnalytics(app);
    this.keepUserDataUpdated();
  },
  getApp() {
    return app;
  },
  getAnalytics() {
    return analytics;
  },
  logScreenView(screen) {
    const screenExceptions = ['partnerarea.account'];
    if (screenExceptions.includes(screen)) {
      return;
    }

    if (this.isEnabled() && !!analytics) {
      firebaseLogEvent(analytics, 'screen_view', { firebase_screen: screen });
    }
    trackPageView(screen);
  },
  logEvent(name, properties, { skipAdobe } = {}) {
    if (this.isEnabled() && !!analytics) {
      firebaseLogEvent(analytics, name, properties);
    }

    if (!skipAdobe) {
      trackEvent({ name, details: properties });
    }
  },
  async keepUserDataUpdated() {
    await store.state.settings.onDataLoaded;
    store.watch(
      (state, getters) => getters['user/partnerType'],
      (newState, oldState) => {
        if (newState && newState !== oldState && analytics) {
          setUserProperties(analytics, {
            partnerType: newState,
            sprdPlatform: SPRD.PLATFORM.toUpperCase(),
          });
        }
      },
      {
        immediate: true,
      },
    );
  },
  setUserId() {
    if (!this.isEnabled() || !analytics) return;

    setUserId(analytics, CryptoJS.SHA1(store.state.user.data.id).toString());
  },
  events: {
    login() {
      analyticsService.logEvent('login');
    },
    async register(method) {
      const email = await sha256(store.state.identity.originalEmail);
      analyticsService.logEvent('sign_up', { method }, { skipAdobe: true });
      trackEvent({
        name: 'REGISTER',
        details: {
          type: method,
          partnerId: store.state.user.data.id,
          email,
        },
      });
    },
    onboardingComplete() {
      const hoursSinceRegistration = differenceInHours(
        new Date(),
        parseISO(store.state.user.data.created),
      );

      let timeSinceRegistration = '>90d';
      if (hoursSinceRegistration < 1) {
        timeSinceRegistration = '<1h';
      } else if (hoursSinceRegistration < 24) {
        timeSinceRegistration = '<1d';
      } else if (hoursSinceRegistration < 7 * 24) {
        timeSinceRegistration = '<7d';
      } else if (hoursSinceRegistration < 14 * 24) {
        timeSinceRegistration = '<14d';
      } else if (hoursSinceRegistration < 28 * 24) {
        timeSinceRegistration = '<28d';
      } else if (hoursSinceRegistration < 90 * 24) {
        timeSinceRegistration = '<90d';
      }

      analyticsService.logEvent('tutorial_complete', {
        timeSinceRegistration,
      });
    },
    searchIdeas(search_term) {
      analyticsService.logEvent('search', {
        type: 'idea',
        search_term,
      });
    },
    searchCompositions(searchTerm) {
      analyticsService.logEvent('search', {
        type: 'composition',
        searchTerm,
      });
    },
  },
};

export default analyticsService;
