import store from '@/store/Store.js';
import router from 'src/app/router/router';
import analyticsService from '@/tracking/analytics';

export const notificationTopics = {
  WEEKLY_SALES: {
    key: 'WEEKLY_SALES',
    name: 'NOTIFICATIONS.TOPICS.SALES_WEEKLY',
    action: {
      default: () =>
        router.push({
          name: 'partnerarea.statistics',
          params: { subpage: 'sales' },
        }),
    },
    icon: '7days',
  },
  ANNOUNCEMENTS: {
    key: 'ANNOUNCEMENTS',
    name: 'NOTIFICATIONS.TOPICS.ANNOUNCEMENTS',
    icon: 'announcement',
    nonExcludable: true,
    action: {
      WELCOME: () => router.push({ name: 'partnerarea.notifications' }),
      GENERAL: (data) => {
        if (data.values?.internalUrl) {
          router.push({
            path: data.values.internalUrl,
          });
        } else if (data.values?.url) {
          window.open(data.values.url, '_blank');
        }
      },
      SURVEY: (data) => {
        if (data.values && data.values.url) {
          const partnerType = store.getters['user/partnerType'];
          const starClass =
            partnerType === 'SHOP'
              ? 'NONE'
              : store.getters['dms/currentGroup']?.key;
          window.open(
            data.values.url
              .replace('[spreadid_value]', store.state.user.data.id)
              .replace('%5Bspreadid_value%5D', store.state.user.data.id)
              .replace('%5bspreadid_value%5d', store.state.user.data.id)
              .replace('[partner_type_value]', partnerType)
              .replace('%5Bpartner_type_value%5D', partnerType)
              .replace('%5bpartner_type_value%5d', partnerType)
              .replace('[starclass_value]', starClass)
              .replace('%5Bstarclass_value%5D', starClass)
              .replace('%5bstarclass_value%5d', starClass),
            '_blank',
          );
        }
      },
    },
  },
  MP_NEWS: {
    key: 'MP_NEWS',
    name: 'NOTIFICATIONS.TOPICS.MP_NEWS',
    icon: 'sprd-heart',
    targetPartnerType: ['MP', 'BOTH'],
    action: {
      default: (data) => {
        if (data.values?.internalUrl) {
          router.push({
            path: data.values.internalUrl,
          });
        } else if (data.values?.url) {
          window.open(data.values.url, '_blank');
        }
      },
    },
  },
  SHOP_NEWS: {
    key: 'SHOP_NEWS',
    name: 'NOTIFICATIONS.TOPICS.SHOP_NEWS',
    icon: 'shop',
    targetPartnerType: ['SHOP', 'BOTH'],
    action: {
      SHOP_PROMOTION: () => {
        const userShops = store.state.user.shops;
        if (!userShops?.length) {
          return;
        }

        router.push({
          path: '/shop-area/generic/promos',
        });
      },
      default: (data) => {
        if (data.values?.internalUrl) {
          router.push({
            path: data.values.internalUrl,
          });
        } else if (data.values?.url) {
          window.open(data.values.url, '_blank');
        }
      },
    },
  },
  ASSORTMENT: {
    key: 'ASSORTMENT',
    name: 'NOTIFICATIONS.TOPICS.ASSORTMENT',
    icon: 'product-type',
    action: {
      NEW_PRODUCT_TYPE: (data) =>
        router.push({
          name: 'partnerarea.assortmentUpdate',
          query: {
            ptId: data.values && data.values.productTypeId,
          },
        }),
      EMPTY_IDEA_ASSORTMENT: () =>
        router.push({ name: 'partnerarea.assortmentEmpty' }),
      default: (data) =>
        data.values && data.values.url
          ? window.open(data.values.url, '_blank')
          : null,
    },
  },
  DESIGNS: {
    key: 'DESIGNS',
    name: 'NOTIFICATIONS.TOPICS.DESIGN_APPROVALS',
    icon: 'approval',
    action: {
      DESIGN_REJECTION: () => {
        router.push({
          name: 'partnerarea.ideas',
          query: { lifeState: 'REJECTED' },
        });
      },
    },
  },
  DEFAULT: {
    key: 'DEFAULT',
    icon: 'speech-bubble',
  },
};

export function notificationTopicConfig(topic) {
  return notificationTopics[topic] || notificationTopics.DEFAULT;
}

export const notificationGroups = [
  {
    key: 'SALES_GROUP',
    heading: 'NOTIFICATIONS.GROUPS.SALES',
    topics: [notificationTopics.WEEKLY_SALES],
  },
  {
    key: 'OTHER',
    heading: 'NOTIFICATIONS.GROUPS.OTHER',
    topics: [notificationTopics.ANNOUNCEMENTS],
  },
];

export function triggerNotificationAction(
  notification,
  { browserNotification } = {},
) {
  const topicConfig = notificationTopicConfig(notification.topic);
  const bundleName = notification.additionalGatewayData.templateBundleName;

  if (topicConfig.action) {
    const action = topicConfig.action[bundleName]
      ? topicConfig.action[bundleName]
      : topicConfig.action.default;

    if (typeof action === 'function') {
      action(notification.additionalGatewayData);
    }
  }

  analyticsService.logEvent('notification-clicked', {
    eventArea: 'notifications',
    topic: notification.topic,
    bundle: bundleName,
    browserNotification,
  });
}
