<template>
  <div class="modal-container digital-products-create-dialog dialog-size-md">
    <h1>{{ $t('DIGITAL_PRODUCTS.ADD.HEADING') }}</h1>
    <p>
      {{ $t('DIGITAL_PRODUCTS.ADD.SUBHEADING') }}
    </p>
    <ul>
      <li v-for="category in categories" :key="category.key">
        <a @click="chooseCategory(category.key)">
          <div class="category-icon">
            <Icon :icon="category.icon" />
          </div>
          <div class="category-content">
            <h3>{{ $t(category.name) }}</h3>
            <p>{{ $t(category.description) }}</p>
            <button class="link link-main">
              {{ $t('DIGITAL_PRODUCTS.ADD.SELECT') }}
            </button>
          </div>
        </a>
      </li>
    </ul>
    <a class="feedback" :href="feedbackMailTo">
      <h3>{{ $t('DIGITAL_PRODUCTS.ADD.OTHER.HEADING') }}</h3>
      <p>
        {{ $t('DIGITAL_PRODUCTS.ADD.OTHER.SUBHEADING') }}
      </p>
      <button class="link link-main icon-link">
        <Icon icon="speech-bubble" />{{ $t('DIGITAL_PRODUCTS.ADD.OTHER.CTA') }}
      </button>
    </a>
  </div>
</template>

<script>
import { DIGITAL_PRODUCTS_SORTED_CATEGORIES } from '@/api/models/digitalProducts/digitalProductsCategories';

export default {
  name: 'DigitalProductsCategoriesDialog',
  components: {},
  props: ['modalInstance', 'data'],
  data() {
    return {
      categories: DIGITAL_PRODUCTS_SORTED_CATEGORIES,
    };
  },
  computed: {
    feedbackMailTo() {
      return `mailTo:digitalproducts@spreadshop.com?bcc=partnerapp@spreadshirt.net&subject=Digital Products Feedback (Spread-ID ${this.$store.state.user.data.id})`;
    },
  },
  methods: {
    chooseCategory(category) {
      this.modalInstance.close(category);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/links';
@import 'src/scss/constants';

.digital-products-create-dialog {
  & > h1 {
    margin: 0;
  }

  & > p {
    margin: 8px 0 24px 0;
    color: $grey65;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 24px;
    grid-auto-rows: 1fr;
  }

  a {
    display: flex;
    border: 1px solid $grey10;
    border-radius: $border-radius-medium;
    padding: 16px;
    color: $grey80;
    height: 100%;

    &:hover {
      border-color: $pa-color-main;
    }

    .category-icon {
      background-color: $grey5;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
    }

    .category-content {
      display: flex;
      flex-direction: column;
    }

    .icon {
      width: 26px;
      height: 26px;
    }

    h3 {
      margin: 0;
    }

    p {
      color: $grey65;
      margin: 8px 0 24px 0;
    }

    button {
      margin-top: auto;
      display: flex;
      padding: 0;
    }
  }

  .feedback {
    margin-top: 24px;
    flex-direction: column;
  }
}
</style>
