<template>
  <div class="empty-search-result-container">
    <div>
      <Icon icon="error"></Icon>
    </div>
    <div class="error-messages">
      <div class="error-header-message">
        No Results! Your search or filter was unsuccessful
      </div>
      <div class="error-message">Adjust your filter or search term</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PublishingEmptySearchResult',
};
</script>

<style scoped lang="scss">
@import 'src/scss/constants';

.empty-search-result-container {
  display: flex;
  width: 100%;
  margin: 0 0 24px 0;
  padding: 12px 0 12px 16px;
  background: #fff;
  border-radius: $border-radius-medium;

  .icon {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }

  .error-messages {
    display: flex;
    flex-direction: column;
  }

  .error-header-message {
    color: var(--color-text-strong, #252626);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .error-message {
    color: #252626;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }
}
</style>
