<template>
  <div v-if="$store.state.shop.selectedCollection" class="collectionData">
    <LegalLabelInput
      class="sprd-shop-input"
      maxlength="35"
      :model-value="$store.state.shop.selectedCollection.name"
      :label="$t('SA.shopsettings.information.name')"
      :safe-value="false"
      @update:model-value="updateName"
    />
    <LegalLabelInput
      class="sprd-shop-input"
      maxlength="200"
      :model-value="$store.state.shop.selectedCollection.description"
      :label="$t('SA.shopsettings.information.description.label')"
      :safe-value="false"
      @update:model-value="updateDescription"
    />
    <div class="config-row">
      {{ $t('GENERAL.ACTIVE') }}
      <ToggleSwitch
        :model-value="$store.state.shop.selectedCollection.active"
        @update:model-value="
          updateCollection({
            active: !$store.state.shop.selectedCollection.active,
          })
        "
      />
    </div>
    <button class="btn icon-btn btn-block" @click="onDelete">
      <Icon icon="bin" />
      {{ $t('GENERAL.DELETE') }}
    </button>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import unsavedChangesService from '@/unsavedChangesService/unsavedChangesService';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import LegalLabelInput from '../../common/LegalLabelInput.vue';
import { deepCopy } from 'src/app/commons/utils';
import ConfirmDialog from '@/dialogs/confirm/ConfirmDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import collectionSaving from './collectionSaving';

export default {
  name: 'CollectionData',
  components: {
    ToggleSwitch,
    LegalLabelInput,
  },
  data() {
    return {
      unsavedChangesComponent: null,
    };
  },

  beforeCreate() {
    if (!this.$store.state.shop.selectedCollection) {
      const newCollection = {
        active: true,
        name: '',
        description: '',
        ideaCount: 0,
      };

      this.$store.commit('shop/updateSectionData', {
        key: 'collections',
        data: {
          collectionList: [
            newCollection,
            ...(this.$store.state.shop.shopSavable.clientData.collections
              ?.collectionList || []),
          ],
        },
      });
      this.$store.commit('shop/setCollection', newCollection);
    }
  },
  created() {
    this.unsavedChangesComponent = {
      check: () => !collectionSaving.validate() || collectionSaving.dirty(),
      save: collectionSaving.save,
      discard: collectionSaving.reset,
    };

    unsavedChangesService.registerComponent(this.unsavedChangesComponent);
  },
  beforeUnmount() {
    unsavedChangesService.unregisterComponent(this.unsavedChangesComponent);
  },
  methods: {
    ...mapMutations({
      updateCollection: 'shop/updateCollection',
      resetCollection: 'shop/resetCollection',
    }),
    ...mapActions({
      saveCollection: 'shop/saveCollection',
      deleteCollection: 'shop/deleteCollection',
      saveCollectionChanges: 'shop/saveCollectionChanges',
    }),
    updateName(value) {
      this.updateCollection({ name: value });
    },
    updateDescription(value) {
      this.updateCollection({ description: value });
    },
    onDelete() {
      return dialogService
        .openDialog(ConfirmDialog, {
          heading: 'GENERAL.WARNING',
          description: 'POS.SHOP_SETTINGS.COLLECTIONS.DELETE_CONFIRMATION',
          cancel: 'GENERAL.CANCEL',
          confirm: 'GENERAL.DELETE',
          danger: true,
        })
        .then(() => {
          this.deletion();
        });
    },
    async deletion() {
      const updatedCollections = deepCopy(
        this.$store.state.shop.shopSavable.clientData.collections.collectionList.filter(
          (c) => c.id !== this.$store.state.shop.selectedCollection.id,
        ),
      );
      await this.deleteCollection();
      this.$store.commit('shop/updateSectionOriginalData', {
        sectionKey: 'collections',
        origData: { collectionList: updatedCollections },
      });

      this.$router.push({ name: 'shop-area.pages.collections' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.collectionData {
  .config-row {
    .sprd-toggle-switch {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .btn {
    height: 40px;
    margin-top: 30px;
  }

  .counter {
    text-align: right;
    margin-top: 5px;
    color: $sprd-color-grey-60;

    &.invalid {
      color: $pa-color-red;
    }
  }
}
</style>
