<template>
  <div
    class="sprd-toggle-switch"
    :class="{ disabled, condensed, fullLabelOpacityWhenDisabled }"
  >
    <label>
      <div v-if="label || $slots.default" class="label">
        {{ $t(label) }}
        <slot></slot>
      </div>
      <input
        type="checkbox"
        :checked="modelValue"
        :disabled="disabled"
        @change.prevent="$emit('update:modelValue', $event.target.checked)"
      />
      <div class="toggle">
        <Icon icon="accept-big" />
        <Icon v-if="!disabled" icon="close" />
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: {
    disabled: {
      type: Boolean,
    },
    modelValue: {
      type: Boolean,
    },
    label: {
      type: String,
      default: '',
    },
    condensed: {
      type: Boolean,
    },
    fullLabelOpacityWhenDisabled: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
.sprd-toggle-switch {
  outline: none;

  label {
    margin: 0;
    position: relative;
    display: flex;
    align-items: center;

    & input {
      display: none;
    }

    .label {
      align-self: center;
      flex-grow: 1;
      text-align: left;
      cursor: pointer;
    }

    /* The slider */
    .toggle {
      width: 60px;
      min-width: 60px;
      height: 24px;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: $grey45;
      padding: 0 8px;
      border-radius: 34px;
      cursor: pointer;
      transition: 0.4s;
      transition-timing-function: ease-out;

      &:before {
        height: 32px;
        width: 32px;
        position: absolute;
        left: 0;
        bottom: -4px;
        background-color: white;
        border-radius: 50%;
        border: 1px solid $grey20;
        content: '';
        transition: 0.2s;
      }

      .icon {
        width: 16px;
        height: 16px;
        align-self: center;
        text-align: center;
        color: white;
      }
    }

    input[type='checkbox']:checked + .toggle {
      background-color: $pa-color-main;
    }

    input[type='checkbox']:focus + .toggle {
      box-shadow: 0 0 1px $pa-color-main;
    }

    input[type='checkbox']:checked + .toggle:before {
      transform: translateX(30px);
    }
  }

  &.condensed {
    .toggle {
      height: 16px;
      width: 45px;
      min-width: 45px;

      &:before {
        height: 22px;
        width: 22px;
        bottom: -3px;
      }

      .icon {
        height: 10px;
        width: 10px;
      }
    }

    input[type='checkbox']:checked + .toggle:before {
      transform: translateX(23px);
    }
  }

  &.disabled {
    label {
      opacity: 0.7;

      .label {
        color: $grey20;
        cursor: not-allowed;
      }

      .toggle {
        cursor: not-allowed;
      }

      .toggle,
      input[type='checkbox']:checked + .toggle {
        background-color: $grey20;
      }
    }

    &.fullLabelOpacityWhenDisabled label {
      opacity: 1;
    }
  }
}
</style>
