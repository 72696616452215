<template>
  <div class="sharingContainer">
    <div v-for="type in types" :key="type.name" class="sharingLine">
      <a class="sharingA" @click="share(type.url)">
        <img
          :alt="type.name"
          :src="`/images/${type.name}_color.svg`"
          class="sharingIcon"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivationSocialSharing',
  data() {
    return {
      types: [
        { name: 'twitter', url: 'https://twitter.com/intent/tweet?url=' },
        {
          name: 'facebook',
          url: 'https://www.facebook.com/sharer/sharer.php?sdk=joey&display=popup&ref=plugin&src=share_button&u=',
        },
        {
          name: 'pinterest',
          url: 'https://pinterest.com/pin/create/button/?url=',
        },
      ],
    };
  },
  methods: {
    share(url) {
      const shopUrl = this.$store.getters['shop/getSpreadshopUrl']('', false);
      const sharingUrl = `${url}${shopUrl}`;
      window.open(sharingUrl, '_blank', 'width=400, height=400');
    },
  },
};
</script>

<style lang="scss" scoped>
.sharingContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.sharingLine {
  flex: 0 0 10%;
  padding: 0 1em;
}

.sharingIcon {
  width: 30px;
  height: 30px;
}

.sharingA {
  text-decoration: none;
  display: flex;

  &:hover {
    cursor: pointer;
  }
}
</style>
