<template>
  <label
    v-tooltip="{
      content: disabled && disabledTooltip ? $t(disabledTooltip) : null,
      hideOnTargetClick: false,
    }"
    class="radiobutton-container"
    :class="{
      disabled: disabled,
      checked: selected,
    }"
  >
    <input
      class="radiobox"
      type="radio"
      :name="name"
      :disabled="disabled"
      :checked="selected"
      @change="onSelected"
    />
    <slot :checked="selected">
      <div class="item">
        <div class="checkmark">
          <svg class="icon" viewBox="0 0 16 16">
            <circle cx="8" cy="8" r="8"></circle>
          </svg>
        </div>
        <span v-if="label" class="label">{{ label }}</span>
      </div>
    </slot>
  </label>
</template>

<script>
export default {
  name: 'RadioButton',
  props: {
    selected: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledTooltip: {
      type: String,
    },
  },
  emits: ['onSelected'],
  methods: {
    onSelected() {
      this.$emit('onSelected');
    },
  },
};
</script>

<style lang="scss">
@import 'src/scss/styleguide/colors';

.radiobutton-container {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 32px;
  }

  .radiobox {
    display: none;
  }

  .item {
    display: flex;
    align-items: center;
  }

  .label {
    margin-left: 8px;
  }

  .checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border: 1px solid $grey40;
    border-radius: 50%;
    background-color: white;
    flex-shrink: 0;

    .icon {
      width: 14px;
      height: 14px;
      display: none;
      color: $pa-color-main;
    }
  }

  .radiobox:checked ~ .item .checkmark,
  &.checked .item .checkmark {
    border-color: $pa-color-main;
    .icon {
      display: inline-block;
    }
  }

  &:hover {
    .checkmark {
      border-color: $pa-color-main-dark;
    }
  }

  &.disabled {
    color: $grey20;
    cursor: not-allowed;
  }

  .radiobox:disabled ~ .item .checkmark {
    border-color: $grey20;

    .icon {
      color: $grey20;
    }
  }
}
</style>
