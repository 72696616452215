export default {
  mounted(el, bindings) {
    const button = el.querySelector(bindings.value);
    const input = el.querySelector('input');
    if (button) {
      input.addEventListener('blur', function () {
        if (input.value.trim()) {
          const e = new KeyboardEvent('keydown', {
            bubbles: true,
            cancelable: true,
            key: 'Enter',
            shiftKey: false,
          });
          button.dispatchEvent(e);
        }
      });
    }
  },
};
