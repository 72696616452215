<template>
  <div class="points-of-sale-config">
    <div class="headings">
      <h1 class="main-heading">
        {{ $t('DESIGNS.CONFIGURATION.POINTS_OF_SALE.HEADING') }}
      </h1>
      <p class="heading-hint">
        {{ $t('DESIGNS.CONFIGURATION.POINTS_OF_SALE.SUBHEADING') }}
      </p>
      <p
        v-if="showPosNotSelectedWarning"
        class="highlight subheading color-box text-sm"
      >
        {{ $t('DESIGNS.CONFIGURATION.POINTS_OF_SALE.MESSAGE') }}
      </p>
    </div>

    <div v-if="initialized" class="pos">
      <div class="pos-cards">
        <PosCard
          v-if="mpEnabled"
          logo-component="MarketplaceLogo"
          :selected="showPosSelectionBorder('MARKETPLACE')"
          :selectable="mpSelectable"
          heading="DESIGNS.CONFIGURATION.POINTS_OF_SALE.MP_SELECT"
          @toggle="toggleMarketplace"
        >
          <template #hint>
            <MpPublishingLimitReachedHint
              v-if="mpPublishingLimitReached && !marketplaceSelected"
            />
            <PosRejectedHint
              v-else-if="!getLegalState('MARKETPLACE').allowed"
              :reason="getRejectionReason('MARKETPLACE')"
            />
          </template>
          <template #footer>
            <DmsPublishingCounter />
            <span>{{ ` ${$t('DMS.PUBLISHES')}` }}</span>
          </template>
        </PosCard>
        <PosCard
          v-if="availableShops.length"
          logo-component="SpreadshopLogo"
          :selected="showPosSelectionBorder('SHOP')"
          :selectable="shopSelectable"
          heading="DESIGNS.CONFIGURATION.POINTS_OF_SALE.PUBLISH_TO_SHOPS"
          @toggle="toggleAllShops"
        >
          <template #hint>
            <PosRejectedHint
              v-if="!getLegalState('SHOP').allowed"
              :reason="getRejectionReason('SHOP')"
            />
          </template>
          <template v-if="showMultipleShopsIndicator" #footer>
            <span class="shop-count">{{ shopsSelected.length }}</span
            >/{{ availableShops.length }}
            <span>{{ $t('GENERAL.SELECTED') }}</span>
          </template>
        </PosCard>
      </div>
      <div class="pos-card-details">
        <div v-if="mpEnabled"></div>
        <div
          v-if="availableShops.length && showMultipleShopsIndicator"
          class="single-shop-selection"
        >
          <div
            v-for="shop in availableShops"
            :key="shop.pointOfSale.id"
            :class="{ selected: isPublishedOn(shop.pointOfSale.id) }"
            class="shop-item"
            @click.prevent="togglePos(shop)"
          >
            <div class="shop-description">
              <p v-if="isShopHidden(shop)" class="text-xs text-medium-grey">
                {{ $t('GENERAL.INACTIVE') }}
              </p>
              <p v-if="!isShopHidden(shop)" class="text-xs text-main">
                {{ $t('GENERAL.ACTIVE') }}
              </p>
              <h3 class="text-truncate">{{ shop.pointOfSale.name }}</h3>
            </div>
            <div
              :id="shop.pointOfSale.target.id"
              class="single-shop-selection-toggler"
            >
              <ToggleSwitch :model-value="isPublishedOn(shop.pointOfSale.id)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import productIdeaService from '@/api/productIdeaService/productIdeaService';
import PosRejectedHint from './PosRejectedHint.vue';
import MpPublishingLimitReachedHint from './MpPublishingLimitReachedHint.vue';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import DmsPublishingCounter from 'src/app/components/dms/DmsPublishingCounter.vue';
import PosCard from './PosCard.vue';

export default {
  name: 'IdeaConfigPointOfSale',
  components: {
    ToggleSwitch,
    PosRejectedHint,
    MpPublishingLimitReachedHint,
    DmsPublishingCounter,
    PosCard,
  },
  props: {
    showPosNotSelectedWarning: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      availableShops: [],
      marketplace: {},
      initialized: false,
      pointsOfSale: null,
    };
  },
  computed: {
    ...mapGetters({
      marketplaceSelected: 'idea/hasMarketplace',
      shopsSelected: 'idea/selectedShops',
      isPublishedOn: 'idea/isPublishedOn',
      getSetting: 'settings/getSetting',
      mpPublishingLimitExceeded: 'dms/publishingLimitExceeded',
      mpPublishingLimitReached: 'dms/publishingLimitReached',
      isMpScope: 'publishing/isMpScope',
      isShopScope: 'publishing/isShopScope',
    }),
    showMultipleShopsIndicator() {
      return (
        this.getLegalState('SHOP').allowed && this.availableShops.length > 1
      );
    },
    mpEnabled() {
      const mpOriginallySelected =
        this.$store.state.idea.original?.publishingDetails?.find(
          (detail) => detail.pointOfSale.type === 'MARKETPLACE',
        );
      return (
        (mpOriginallySelected || this.getSetting('MP_ENABLED')) &&
        !this.isShopScope
      );
    },
    mpSelectable() {
      return (
        this.getLegalState('MARKETPLACE').allowed &&
        (!this.mpPublishingLimitReached || this.marketplaceSelected)
      );
    },
    shopSelectable() {
      return this.getLegalState('SHOP').allowed;
    },
  },
  async created() {
    this.pointsOfSale = await productIdeaService.getPointsOfSale(
      this.$store.state.idea.current,
    );

    this.availableShops = this.pointsOfSale
      .filter((pos) => pos.type === 'SHOP')
      .map((pos) => ({
        pointOfSale: pos,
      }));

    this.marketplace = {
      pointOfSale: this.pointsOfSale.find((pos) => pos.type === 'MARKETPLACE'),
    };

    this.initialized = true;
  },
  methods: {
    ...mapActions({
      togglePos: 'idea/togglePos',
    }),
    ...mapMutations('idea', {
      setPointOfSale: 'setPointOfSale',
      unsetPointOfSale: 'unsetPointOfSale',
      unsetPointOfSaleType: 'unsetPointOfSaleType',
    }),
    getLegalState(pointOfSaleType) {
      const legalState = this.pointsOfSale?.find(
        (pos) => pos.type === pointOfSaleType,
      );
      // allowed flag is not reliable (see DEV-196664), so we need to "fix" it by checking for rejection reasons
      return legalState
        ? {
            ...legalState,
            allowed: legalState.allowed && !legalState.rejectionReason?.length,
          }
        : {};
    },
    toggleMarketplace() {
      if (!this.mpSelectable) {
        return;
      }

      this.togglePos(this.marketplace);
    },
    toggleAllShops() {
      if (!this.shopSelectable) {
        return;
      }

      if (this.shopsSelected.length) {
        this.unsetPointOfSaleType('SHOP');
      } else {
        this.availableShops.forEach((element) => {
          this.setPointOfSale(element);
        });
      }
    },
    isShopHidden(shop) {
      const shopPos = this.$store.state.user.shops.find(
        (s) => s.id === shop.pointOfSale.target.id,
      );
      return shopPos ? shopPos.hidden : false;
    },
    getRejectionReason(posType) {
      // might return undefined
      const rejectionReasons = this.getLegalState(posType).rejectionReason;
      if (rejectionReasons) {
        const reasons = rejectionReasons.filter(
          (reason) => !['SILENT', 'REJECTED_IN_OPOSSUM'].includes(reason),
        );
        if (reasons.length > 0) {
          return reasons[0];
        }
      }
    },
    showPosSelectionBorder(posType) {
      return (
        this.getLegalState(posType).allowed &&
        (posType === 'MARKETPLACE'
          ? this.marketplaceSelected
          : !!this.shopsSelected.length)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/type';
@import 'src/scss/animations';
@import 'src/scss/utils';

.points-of-sale-config {
  .headings {
    .main-heading {
      margin: 0 0 8px 0;
    }

    .heading-hint {
      color: $grey60;
    }

    .subheading {
      &.highlight {
        animation: shakeUpDown 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      }
    }
  }

  .pos {
    display: flex;
    flex-direction: column;
    margin: 0 -12px;
    padding: 24px 0;

    .pos-cards,
    .pos-card-details {
      display: flex;

      & > div {
        flex: 1;
        max-width: 760px;
        margin: 0 12px;
      }
    }

    .shop-count {
      font-weight: 700;
    }

    .single-shop-selection {
      width: 100%;

      .shop-item {
        display: flex;
        justify-content: space-between;
        background-color: $grey0;
        margin-top: 16px;
        padding: 16px 32px;
        border-radius: $border-radius-medium;
        border: 1px solid $grey0;
        cursor: pointer;

        &:hover {
          border-color: $pa-color-main;
        }

        .shop-description h3,
        p {
          margin: 0px;
        }

        h3 {
          padding-top: 5px;
        }

        .single-shop-selection-toggler {
          display: flex;
          align-self: center;
        }

        &.selected {
          border-color: $pa-color-main;
          box-shadow: 0 0 0 1px $pa-color-main;
        }
      }
    }
  }
}
</style>
