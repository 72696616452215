<template>
  <div class="color-slot">
    <button
      class="color-slot__toggle"
      :class="{ 'color-slot__toggle--open': isOpen }"
      @click="toggle"
    >
      <span class="color-slot__label">{{ label }}</span>
      <ColorCircle :color="currentColor" size="24" />
    </button>
    <div v-if="isOpen" class="colors">
      <ColorCircle
        v-for="(color, colorIndex) in suggestedColors"
        :key="colorIndex"
        size="24"
        :color="color"
        :deactivated="disabled"
        :active="color === currentColor"
        @click="$emit('update:current', color)"
      />
      <ColorCircle
        v-if="customColorEnabled"
        size="24"
        :color="customColor"
        :deactivated="disabled"
        :active="isCustomColor"
        :show-plus="true"
        @click="toggleColorPicker"
      />
      <ColorPicker
        v-if="isOpen && isColorPickerActive && !disabled"
        :model-value="customColor"
        @update:model-value="$emit('update:custom', $event)"
      />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ColorPicker from './ColorPicker.vue';
import ColorCircle from 'src/app/commons/colorCircle/ColorCircle.vue';

export default {
  name: 'Color',
  components: {
    ColorPicker,
    ColorCircle,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    currentColor: {
      type: String,
      required: true,
    },
    suggestedColors: {
      type: Array,
      default() {
        return [];
      },
    },
    customColorEnabled: {
      type: Boolean,
      default: true,
    },
    customColor: {
      type: String,
      default: '#ffffff',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:current', 'update:custom'],
  data: function () {
    return {
      isOpen: false,
      isColorPickerActive: false,
    };
  },
  computed: {
    isCustomColor() {
      return !this.suggestedColors.includes(this.currentColor);
    },
  },
  methods: {
    toggle: function () {
      this.isOpen = !this.isOpen;

      if (!this.isOpen && this.isColorPickerActive) {
        this.isColorPickerActive = false;
      }
    },
    toggleColorPicker: function () {
      if (this.disabled) {
        return;
      }

      this.isColorPickerActive = !this.isColorPickerActive;

      if (this.isColorPickerActive) {
        this.$emit('update:current', this.customColor);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.color-slot__toggle {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 25px;
  text-align: left;
  background: none;
  border: 0;

  &:hover,
  &--open {
    background-color: $sprd-color-lightest-grey;
  }

  &:focus {
    outline: none;
  }

  .color-icon {
    margin: -0.5em 0 -0.5em 0.5em;
  }
}

.color-slot__label {
  flex: 1;
}

.color-icon {
  cursor: pointer;
}

.icon {
  margin: -0.5em 0;
  width: 2em;
  height: 2em;
}

.color-slot {
  color: $sprd-color-darkest-grey;
  text-decoration: none;
}

.colors {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin: 4px 0;
  padding: 12px 25px;
  background: $sprd-color-lightest-grey;
}

.colorPicker {
  margin-top: 12px;
}
</style>
