<template>
  <div>
    <div class="hint-text">
      {{ $t('POS.SHOP_SETTINGS.MEDIA_SELECTOR.EMBED_VIDEO.SUBHEADING') }}
    </div>
    <div v-if="previewImage" class="active-img preview-img">
      <img :src="previewImage" />
    </div>

    <div v-if="errorMessage" class="error-box">
      {{ errorMessage }}
    </div>
    <LabelInput class="url-input" :disabled="disabled">
      <label>{{
        $t('POS.SHOP_SETTINGS.MEDIA_SELECTOR.EMBED_VIDEO.TEXT_LABEL')
      }}</label>
      <input v-model="rawVideoLink" type="text" maxlength="1000" />
    </LabelInput>
    <div v-if="previewImage" class="ud-row">
      <button
        class="ud-btn replace-btn"
        :disabled="disabled || !rawVideoLink"
        @click="processVideoLink"
      >
        <Icon icon="replace" />
        {{ $t('DESIGNS.CONFIGURATION.PRODUCT_TEMPLATES.SAVE.REPLACE_CTA') }}
      </button>
      <button
        class="ud-btn delete-btn"
        :disabled="disabled"
        @click="deleteVideo"
      >
        <Icon icon="bin" />
      </button>
    </div>
    <div v-else class="ud-row">
      <button
        class="ud-btn replace-btn"
        :disabled="disabled || !rawVideoLink"
        @click="processVideoLink"
      >
        <Icon icon="code" />
        {{ $t('POS.SHOP_SETTINGS.MEDIA_SELECTOR.EMBED_VIDEO.BUTTON') }}
      </button>
    </div>
  </div>
</template>

<script>
import LabelInput from '@/labelInput/LabelInput.vue';
import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';

import { NO_TYPE } from './mediaTypes';
import ConfirmDialog from '@/dialogs/confirm/ConfirmDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';

export default {
  name: 'VideoEmbed',
  components: {
    LabelInput,
  },
  props: ['active', 'previewImage', 'onChange'],
  emits: ['change'],
  data() {
    return {
      key: '',
      rawVideoLink: '',
      loading: false,
      errorMessage: null,
    };
  },
  computed: {
    disabled() {
      return !this.active || this.loading;
    },
  },
  created() {
    const key = this.$vnode.key;
    if (key) {
      this.key = key;
    }
  },
  methods: {
    processVideoLink() {
      if (!this.rawVideoLink) {
        return;
      }

      this.loading = true;
      this.errorMessage = null;

      shopAPI
        .validateVideoUrl(this.$store, this.rawVideoLink)
        .then(
          ({
            embedUrl: media,
            previewImageUrl: previewImage,
            streamingPlatform: mediaType,
          }) => {
            this.loading = false;
            this.rawVideoLink = '';

            this.$emit('change', {
              media,
              previewImage,
              mediaType,
              key: this.key,
            });
          },
        )
        .catch((err) => {
          this.loading = false;
          this.rawVideoLink = '';
          const msg = err && err.data && err.data.message;
          console.error('Failed to validate video url, got:', msg ? msg : err);
          this.errorMessage = this.$t(
            'POS.SHOP_SETTINGS.MEDIA_SELECTOR.EMBED_VIDEO.ERROR',
          );
        });
    },
    deleteVideo() {
      dialogService
        .openDialog(ConfirmDialog, {
          description: 'POS.SHOP_SETTINGS.MEDIA_SELECTOR.EMBED_VIDEO.DELETE',
          cancel: 'SA.action.cancel',
          confirm: 'SA.action.delete',
          danger: true,
        })
        .then(() => {
          this.$emit('change', {
            mediaType: NO_TYPE,
            key: this.key,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/app/partnerarea/pos/shop/imageUpload/imageUploadReusable.scss';
.hint-text {
  margin: 16px 0;
  color: #7f7f7f;
}
.preview-img {
  margin-bottom: 16px;
}
.url-input {
  margin-bottom: 8px;
}
</style>
