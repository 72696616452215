import localeService from '@/localeService/localeService';
import store from '@/store/Store';

export const PUBLISHING_CATEGORY = {
  APPAREL: {
    id: 'GP25',
    previewImage: '/images/publishingCategories/categoryPreviewApparel.png',
  },
  ACCESSORIES: {
    id: 'GP30',
    previewImage: '/images/publishingCategories/categoryPreviewAccessories.png',
  },
  HOME_AND_LIVING: {
    id: 'GP31',
    previewImage: '/images/publishingCategories/categoryPreviewHomeLiving.png',
  },
};

const getPublishingCategoryById = (id: string) =>
  Object.values(PUBLISHING_CATEGORY).find((category) => category.id === id);

export type PublishingCategoryConfig = {
  id: string;
  name: string;
  previewImage: string;
};

interface PublishingCategoriesTree {
  id: string;
  names: PublishingCategoryName;
  children: PublishingCategoriesTree[];
  productTypes: PublishingCategoryProductType[];
}

interface PublishingCategoryName {
  [locale: string]: string;
}

interface PublishingCategoryProductType {
  id: number;
  hotspots: string[];
  appearanceGroups: string[];
}

export default {
  getAllProductTypes(
    category: PublishingCategoriesTree,
  ): PublishingCategoryProductType[] {
    if (category.children?.length) {
      return [
        ...new Set(
          category.children.reduce(
            (result, current) =>
              result
                .concat(category.productTypes)
                .concat(this.getAllProductTypes(current)),
            [] as PublishingCategoryProductType[],
          ),
        ),
      ];
    } else {
      return [...new Set([...category.productTypes])];
    }
  },
  getAllAvailableHotspots(category: PublishingCategoriesTree): string[] {
    const productTypes = this.getAllProductTypes(category);

    const hotspots = [
      ...new Set(
        productTypes.reduce(
          (prev, current) => [...prev, ...current.hotspots],
          [] as string[],
        ),
      ),
    ];
    return hotspots;
  },
  getAllAvailableAppearanceGroups(category: PublishingCategoriesTree) {
    const productTypes = this.getAllProductTypes(category);

    const appearanceGroups = [
      ...new Set(
        productTypes.reduce(
          (prev, current) => [...prev, ...current.appearanceGroups],
          [] as string[],
        ),
      ),
    ];

    const groups = store.state.publishingCore.appearanceGroups;
    const appearanceGroupTranslations =
      store.state.publishingCore.translations.appearanceGroups;

    return appearanceGroups.map((appearanceGroupId) => ({
      ...groups.find((group) => group.id === appearanceGroupId),
      name: appearanceGroupTranslations[appearanceGroupId].name,
    }));
  },
  getMainCategories(
    categories: PublishingCategoriesTree,
  ): Record<string, PublishingCategoryConfig> {
    return categories.children
      .map((cat) => ({
        id: cat.id,
        name: cat.names[localeService.getLocale()] || cat.id,
        previewImage:
          getPublishingCategoryById(cat.id)?.previewImage || 'placeholder',
      }))
      .reduce(
        (prev, current) => ({
          ...prev,
          [current.id]: current,
        }),
        {},
      );
  },
};
