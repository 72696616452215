<template>
  <div class="digital-products-details-wrapper">
    <div class="digital-products-details overflow-y">
      <div v-if="initialized" class="digital-products-details-content">
        <div class="header">
          <a href="#" class="link-main icon-link" @click.prevent="back">
            <Icon icon="left" />
            {{ $t('GENERAL.BACK') }}
          </a>
          <div>
            <ProgressButton
              class="sprd-btn-light"
              :on-click="save"
              :label="$t('GENERAL.SAVE')"
              :disabled="!unsavedChanges"
            />
            <ProgressButton
              class="sprd-btn-primary"
              :on-click="publish"
              :label="$t('GENERAL.PUBLISH')"
              :disabled="
                unsavedChanges ||
                !digitalProduct.workingState.hasUnpublishedChanges
              "
            />
          </div>
        </div>
        <div class="subheader">
          <h1>{{ $t(category.name) }}</h1>
          <DigitalProductStatus
            :digital-product="originalDigitalProduct"
            label
            display-guide
          />
        </div>
        <div class="configuration">
          <DigitalProductModerationHint />
          <ConfigSection
            heading="DIGITAL_PRODUCTS.DETAILS.META_DATA.HEADING"
            subheading="DIGITAL_PRODUCTS.DETAILS.META_DATA.SUBHEADING"
            display-inline
          >
            <DigitalProductConfigMetaData />
          </ConfigSection>
          <hr />
          <ConfigSection
            heading="DIGITAL_PRODUCTS.DETAILS.IMAGES.HEADING"
            subheading="DIGITAL_PRODUCTS.DETAILS.IMAGES.SUBHEADING"
            display-inline
          >
            <DigitalProductConfigPreviewImages />
          </ConfigSection>
          <hr />
          <ConfigSection
            heading="DIGITAL_PRODUCTS.DETAILS.INVENTORY.HEADING"
            subheading="DIGITAL_PRODUCTS.DETAILS.INVENTORY.SUBHEADING"
            display-inline
          >
            <DigitalProductConfigInventoryFiles />
          </ConfigSection>
        </div>
        <div class="visibility">
          <ConfigSection
            heading="DIGITAL_PRODUCTS.DETAILS.VISIBILITY.HEADING"
            subheading="DIGITAL_PRODUCTS.DETAILS.VISIBILITY.SUBHEADING"
            display-inline
          >
            <DigitalProductConfigVisibility />
          </ConfigSection>
        </div>
        <div class="pricing">
          <ConfigSection
            heading="DIGITAL_PRODUCTS.DETAILS.PRICING.HEADING"
            subheading="DIGITAL_PRODUCTS.DETAILS.PRICING.SUBHEADING"
            display-inline
          >
            <DigitalProductConfigPrice />
          </ConfigSection>
        </div>
        <div class="footer">
          <VDropdown class="info-popover" container=".footer">
            <a href="#" class="link-main icon-link" @click.prevent>
              <Icon icon="info-circle" /> {{ $t('DASHBOARD.LEARN_MORE') }}
            </a>
            <template #popper>
              <DigitalProductInfos :digital-product="digitalProduct" />
            </template>
          </VDropdown>

          <a href="#" class="link-red icon-link" @click.prevent="triggerDelete"
            ><Icon icon="bin" /> {{ $t('GENERAL.DELETE') }}</a
          >
        </div>
      </div>

      <div v-else class="loader">
        <LoadingHeart />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import LoadingHeart from 'src/app/commons/LoadingHeart/LoadingHeart.vue';
import { getDigitalProductCategory } from '@/api/models/digitalProducts/digitalProductsCategories';
import DigitalProductStatus from '../list/DigitalProductStatus.vue';
import ConfigSection from 'src/app/components/publishing/ConfigSection.vue';
import DigitalProductConfigMetaData from './DigitalProductConfigMetaData.vue';
import DigitalProductConfigPreviewImages from './DigitalProductConfigPreviewImages.vue';
import DigitalProductConfigInventoryFiles from './DigitalProductConfigInventoryFiles.vue';
import DigitalProductConfigVisibility from './DigitalProductConfigVisibility.vue';
import DigitalProductConfigPrice from './DigitalProductConfigPrice.vue';
import DigitalProductModerationHint from './DigitalProductModerationHint.vue';
import DigitalProductInfos from './DigitalProductInfos.vue';
import ConfirmDialog from '@/dialogs/confirm/ConfirmDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import ProgressButton from 'src/app/commons/btnProgress/ProgressButton.vue';
import unsavedChangesService from '@/unsavedChangesService/unsavedChangesService';

export default {
  name: 'DigitalProductDetail',
  components: {
    LoadingHeart,
    DigitalProductStatus,
    ConfigSection,
    DigitalProductConfigMetaData,
    DigitalProductConfigPreviewImages,
    DigitalProductConfigInventoryFiles,
    DigitalProductConfigVisibility,
    DigitalProductConfigPrice,
    DigitalProductModerationHint,
    ProgressButton,
    DigitalProductInfos,
  },
  data() {
    return {
      initialized: false,
    };
  },
  computed: {
    ...mapState({
      digitalProduct: (s) => s.digitalProduct.current,
      originalDigitalProduct: (s) => s.digitalProduct.original,
      userId: (s) => s.user.data.id,
    }),
    ...mapGetters({
      unsavedChanges: 'digitalProduct/unsavedChanges',
    }),
    category() {
      return getDigitalProductCategory(this.digitalProduct.productClass);
    },
  },
  async created() {
    try {
      await Promise.all([
        this.fetchDigitalProductPricingData(),
        this.fetchDigitalProduct(this.$route.params.digitalProductId),
      ]);
      this.initialized = true;
    } catch (error) {
      this.$router.push({
        name: 'partnerarea.digitalProducts',
        params: { restoreState: true },
      });
      return;
    }

    unsavedChangesService.registerComponent({
      check: () => this.unsavedChanges,
      save: () => {
        return this.saveCurrentDigitalProduct();
      },
      discard: () => {},
    });
  },
  methods: {
    ...mapMutations({
      addToast: 'toasts/addToast',
    }),
    ...mapActions({
      fetchDigitalProduct: 'digitalProduct/fetchDigitalProduct',
      fetchDigitalProductPricingData:
        'digitalProduct/fetchDigitalProductPricingData',
      saveCurrentDigitalProduct: 'digitalProduct/saveCurrentDigitalProduct',
      publishDigitalProduct: 'digitalProduct/publishDigitalProduct',
      deleteDigitalProduct: 'digitalProduct/deleteDigitalProduct',
    }),
    async save() {
      await this.saveCurrentDigitalProduct();
      this.addToast({ variant: 'black', text: 'Successfully saved changes.' });
    },
    async publish() {
      try {
        await this.publishDigitalProduct();
        this.addToast({
          variant: 'success',
          text: 'Product successfully published, after moderation it will be available to your customers.',
        });
      } catch (error) {
        this.addToast({
          variant: 'error',
          text: 'Could not publish product. Please fix the displayed issues first.',
        });
        return Promise.reject();
      }
    },
    back() {
      this.$router.push({
        name: 'partnerarea.digitalProducts',
        params: { restoreState: true },
      });
    },
    async triggerDelete() {
      try {
        await dialogService.openDialog(ConfirmDialog, {
          heading: 'DIGITAL_PRODUCTS.CONFIRM_DELETE',
          cancel: 'GENERAL.DISCARD',
          confirm: 'GENERAL.APPLY',
        });
        await this.deleteDigitalProduct();
        this.$router.push({
          name: 'partnerarea.digitalProducts',
        });
      } catch (error) {
        // nothing
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';

.digital-products-details {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 24px;
  width: 100%;

  .digital-products-details-content {
    flex-grow: 1;
    max-width: 1400px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  button {
    margin-left: 16px;
  }
}

.subheader {
  margin-bottom: 24px;

  h1 {
    margin: 0 0 16px 0;
  }
}

.configuration,
.visibility,
.pricing {
  background-color: $grey0;
  border: 1px solid $grey10;
  border-radius: $border-radius-medium;
  overflow: hidden;

  & > div {
    padding: 24px;
  }

  hr {
    margin: 0;
    border-color: $grey10;
  }
}

.visibility,
.pricing {
  margin-top: 24px;
}

.footer {
  padding: 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .info-popover {
    display: flex;
    align-items: center;
  }
}

.digital-products-details-wrapper {
  position: relative;
  display: flex;
}
</style>
