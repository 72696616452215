import localeService from '@/localeService/localeService';
import apiClient from '@/api/client/apiClient';

export default {
  fetchShippingCountries() {
    return apiClient
      .request({
        method: 'GET',
        url: '/api/v1/shippingCountryTranslations',
        params: {
          mediaType: 'json',
          locale: localeService.convertToSprdLocale(localeService.getLocale()),
        },
      })
      .then((res) => res.data);
  },
  fetchShippingStates() {
    return apiClient
      .request({
        method: 'GET',
        url: '/shopsettings/localization',
        params: {
          mediaType: 'json',
          locale: localeService.convertToSprdLocale(localeService.getLocale()),
        },
      })
      .then((res) => {
        return res.data.shippingCountries
          .filter((country) => country.shippingStates.length)
          .reduce(
            (result, current) => ({
              ...result,
              [current.isoCode]: current.shippingStates.map(
                (shippingState) => ({
                  id: shippingState.isoCode,
                  name: shippingState.name,
                }),
              ),
            }),
            {},
          );
      });
  },
};
