<template>
  <div>
    <IdeaListFilter
      :enabled="displayFilterPanel"
      @close="displayFilterPanel = false"
      @apply="applyFilter"
    />
    <AdvancedPanel
      :enabled="displayAdvancedPanel"
      @close="displayAdvancedPanel = false"
    />

    <div class="idea-list-header">
      <div class="level1">
        <h1>{{ $t('NAV.DESIGNS') }}</h1>

        <div class="view-mode-selection">
          <button
            v-tooltip="{
              content: $t('DESIGNS.LIST.TILE_VIEW'),
            }"
            :class="{ selected: viewMode === 'TILES' }"
            @click="setViewMode('TILES')"
          >
            <Icon icon="tile-view" />
          </button>
          <div class="view-mode-devider"></div>
          <button
            v-tooltip="{
              content: $t('DESIGNS.LIST.LIST_VIEW'),
            }"
            :class="{ selected: viewMode === 'LIST' }"
            @click="setViewMode('LIST')"
          >
            <Icon icon="list" />
          </button>
        </div>
      </div>

      <Banner
        v-if="!ideaPublished"
        class="banner"
        heading="ONBOARD.DESIGN.LIST.HEADING"
        subheading="ONBOARD.DESIGN.LIST.SUBHEADING"
      />

      <div class="level2">
        <button
          class="btn btn-light icon-btn"
          @click="displayFilterPanel = true"
        >
          <Icon icon="control" />
          {{ $t('GENERAL.FILTER') }}
          <span v-if="filterActive">(1)</span>
        </button>
        <button
          class="btn btn-light icon-btn advanced"
          @click="displayAdvancedPanel = true"
        >
          <Icon icon="more" />
          {{ $t('GENERAL.ADVANCED') }}
        </button>
        <div class="search-field">
          <input
            v-model="searchTerm"
            type="text"
            :class="{ filled: searchTerm }"
            :placeholder="$t('DESIGNS.LIST.PLACEHOLDER.SEARCH')"
            @keyup.enter="search"
            @blur="search"
          />
          <button
            class="icon-only-btn reset-link"
            @mousedown="preventBlur"
            @click="clearSearchTerm"
          >
            <Icon icon="close" />
          </button>
          <button class="icon-only-btn search-link">
            <Icon icon="lens" />
          </button>
        </div>

        <button
          class="text-btn link-main icon-link glossary-link"
          @click="openStatusGlossary"
        >
          <Icon icon="info-circle" />
          {{ $t('DESIGNS.LIST.STATUS_GLOSSARY_CTA') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IdeaListFilter from './IdeaListFilter.vue';
import AdvancedPanel from './AdvancedPanel.vue';
import Banner from 'src/app/components/banners/Banner.vue';
import { mapMutations, mapGetters, mapActions } from 'vuex';
import IdeaStatusGlossaryDialog from 'src/app/partnerarea/idea/list/statusGlossary/IdeaStatusGlossaryDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import analytics from '@/tracking/analytics';

export default {
  name: 'IdeaListHeader',
  components: {
    IdeaListFilter,
    AdvancedPanel,
    Banner,
  },
  props: [],
  emits: ['change'],
  data() {
    return {
      displayFilterPanel: false,
      displayAdvancedPanel: false,
      searchTerm: this.$store.state.ideas.filter.q,
    };
  },
  computed: {
    ...mapGetters({
      filterActive: 'ideas/filterActive',
      allSelected: 'ideas/allSelected',
      selectable: 'ideas/selectable',
      getSetting: 'settings/getSetting',
      ideaPublished: 'onboarding/ideaPublished',
    }),
    viewMode() {
      return this.getSetting('DESIGNS_VIEW_MODE');
    },
  },
  methods: {
    ...mapMutations({
      setFilter: 'ideas/setFilter',
    }),
    ...mapActions({
      setSetting: 'settings/setSetting',
      selectAll: 'ideas/selectAll',
      deselectAll: 'ideas/deselectAll',
    }),
    applyFilter(filter) {
      this.displayFilterPanel = false;
      this.setFilter(filter);
      this.$emit('change');
    },
    search() {
      if (this.searchTerm !== this.$store.state.ideas.filter.q) {
        analytics.events.searchIdeas(this.searchTerm);
      }

      this.setFilter({
        q: this.searchTerm,
        page: 0,
      });
      this.$emit('change');
    },
    clearSearchTerm() {
      this.searchTerm = null;
      this.search();
    },
    bulkSelection() {
      if (this.allSelected) {
        this.deselectAll();
      } else {
        this.selectAll();
      }
    },
    setViewMode(viewMode) {
      this.setSetting({
        key: 'DESIGNS_VIEW_MODE',
        value: viewMode,
      });
    },
    openStatusGlossary() {
      dialogService.openDialog(IdeaStatusGlossaryDialog);
    },
    preventBlur(e) {
      e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/colors';

.idea-list-header {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 24px;
}

.level1,
.level2 {
  display: flex;
  align-items: center;
}

.banner {
  margin-bottom: 24px;
}

.level1 {
  h1 {
    margin: 24px 0;
  }

  .view-mode-selection {
    margin-left: auto;
    display: flex;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $grey60;
      padding: 8px;
      background-color: $white;

      .icon {
        width: 16px;
        height: 16px;
      }

      &:first-child {
        border-right: none;
        border-radius: $border-radius-medium 0 0 $border-radius-medium;
      }

      &:last-child {
        border-left: none;
        border-radius: 0 $border-radius-medium $border-radius-medium 0;
      }

      &.selected {
        border-color: $pa-color-main;
        color: $pa-color-main;
        border-width: 2px;
      }
    }

    .view-mode-devider {
      background-color: $pa-color-main;
      width: 2px;
    }
  }
}

.level2 > button {
  height: 40px;
}

.search-field,
.advanced {
  margin-left: 12px;
  border-radius: $border-radius-medium;
  overflow: hidden;
}

.glossary-link {
  margin-left: auto;
}
</style>
