<template>
  <div class="detail-page page-wrapper" :class="{ fullWidth }">
    <nav class="page-header navbar-secondary navbar-default">
      <div class="nav">
        <slot name="left-nav"></slot>
        <div class="heading-wrapper">
          <h2>{{ $t(props.heading) }}</h2>
        </div>
        <div class="actions">
          <slot name="right-nav"></slot>
        </div>
      </div>
    </nav>
    <div class="page-content">
      <div class="overflow-y">
        <div class="detail-page-content">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  heading: {
    type: String,
  },
  fullWidth: {
    type: Boolean,
  },
});
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';

.page-header {
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  border: none;
}

.detail-page {
  position: relative;
  display: flex;
  height: 100vh;

  &.fullWidth {
    .detail-page-content,
    .nav {
      max-width: none !important;
    }
  }

  .overflow-y {
    width: 100%;
    display: flex;
  }
}

.page-content {
  background-color: $grey5;
}

.detail-page-content {
  flex: 1;
  display: flex;
}

.nav {
  display: flex;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  align-items: center;
  padding: 0 24px;

  .actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .heading-wrapper {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      margin: 0;
    }
  }

  :deep(.btn) {
    height: 36px;
  }
}
</style>
