<template>
  <div :class="`register-page login ${mode}`">
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0, viewport-fit=cover"
    />
    <div class="top">
      <MarketplaceLogo />
    </div>
    <div class="register-container">
      <CentralAuth mode="mp" additional-context="partner-area" />
    </div>
  </div>
</template>

<script>
import CentralAuth from 'src/app/components/centralAuth/CentralAuth.vue';
import MarketplaceLogo from '@/logos/MarketplaceLogo.vue';

export default {
  name: 'Register',
  components: {
    CentralAuth,
    MarketplaceLogo,
  },
};
</script>

<style lang="scss">
#application.state-partnerarea-register {
  min-width: 0;
  overflow-y: initial;

  #main-content {
    overflow: initial;
  }

  .help-panel-wrapper {
    display: none;
  }
}
</style>
