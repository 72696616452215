<template>
  <div>
    <div v-if="initialized">
      <div v-if="mode === 'LIST'" class="list">
        <h1 class="heading">
          {{ $t('DESIGNS.CONFIGURATION.CREATE_PRODUCTS') }}
        </h1>
        <p class="subheading">
          {{ $t('DESIGNS.CONFIGURATION.CREATE_PRODUCTS_SUBHEADING') }}
        </p>

        <Banner
          v-if="multipleDesigns"
          class="banner"
          heading="DESIGNS.CONFIGURATION.ASSORTMENT.MULTIPLE_DESIGNS.HEADING"
          subheading="DESIGNS.CONFIGURATION.ASSORTMENT.MULTIPLE_DESIGNS.SUBHEADING"
        >
          <template #left>
            <CondensedIdeaPreviewImage :idea="idea" />
          </template>
        </Banner>

        <Banner
          v-if="!ideaPublished"
          class="banner"
          heading="ONBOARD.ASSORTMENT.HEADING"
          subheading="ONBOARD.ASSORTMENT.SUBHEADING"
        />

        <div class="actions">
          <button class="btn btn-light icon-btn" @click="openCatalog">
            <Icon icon="star-product" />
            {{ $t('DESIGNS.CONFIGURATION.ALL_PRODUCTS') }}
          </button>
          <button
            v-if="templateFeatureEnabled"
            class="btn btn-light icon-btn"
            @click="openTemplateDialog"
          >
            <Icon icon="templating" />
            {{ $t('DESIGNS.CONFIGURATION.PRODUCT_TEMPLATES.TEASER.HEADING') }}
          </button>
          <p class="sellable-count">
            {{ $t('DESIGNS.CONFIGURATION.SELECTED_PRODUCTS') }}:
            <strong>{{ products.length }}</strong
            >/{{ totalSellableCount }}
          </p>
        </div>

        <transition name="fade" mode="out-in">
          <ul v-if="products.length" class="product-list">
            <li v-for="product in products" :key="product.filterId">
              <ProductCard
                :product="product"
                :options="{
                  showRemove: true,
                  disableRemove: !groupCanBeDeleted(product),
                  disableRemoveMsg:
                    'DESIGNS.CONFIGURATION.ASSORTMENT.MAIN_DELETE_DISABLED_HINT',
                }"
                :hover-overlay="designerEnabled(product)"
                @on-remove="onRemove(product)"
                @on-edit="openDesigner(product)"
              />
            </li>
            <li
              v-for="i in [1, 2, 3, 4, 5, 6]"
              :key="`placeholder-${i}`"
              class="placeholder-tile"
            ></li>
          </ul>
          <div v-else class="product-list empty">
            <h2>{{ $t('DESIGNS.CONFIGURATION.ADD_PRODUCTS_CTA') }}</h2>
            <p>
              {{ $t('DESIGNS.CONFIGURATION.ADD_PRODUCTS_SUBHEADING') }}
            </p>
            <img src="/images/empty-assortment.svg" alt="empty assortment" />
          </div>
        </transition>
      </div>
      <div v-if="mode === 'CATALOG'">
        <AssortmentCatalog
          :idea="idea"
          @done="closeCatalog"
          @cancel="cancelCatalog"
          @update-group="updateGroup"
          @filter="filterAssortment"
          @reset-filter="resetAssortmentFilter"
          @update-color="updateSellableColor"
        />
      </div>
      <div v-if="mode === 'DESIGNER'">
        <DesignConfiguration
          :key="group.filterId"
          :group="group"
          :idea="idea"
          @save="saveDesigner"
          @cancel="cancelDesigner"
        />
      </div>
    </div>
    <div v-else class="loader">
      <img src="/images/loader.svg" class="sprd-loader" />
    </div>
  </div>
</template>

<script>
import AssortmentCatalog from './catalog/AssortmentCatalog.vue';
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import ProductCard from './productCard/ProductCard.vue';
import productIdeaService from 'src/app/commons/api/productIdeaService/productIdeaService';
import assortmentHelper from '@/assortmentHelper/assortmentHelper';
import ideaHelper from '@/ideaHelper/ideaHelper';
import DesignConfiguration from '../designer/designConfiguration.vue';
import Banner from 'src/app/components/banners/Banner.vue';
import CondensedIdeaPreviewImage from 'src/app/partnerarea/idea/previewImage/CondensedIdeaPreviewImage.vue';
import { deepCopy } from '@/utils';
import dialogService from '@/dialogs/wrapper/dialogService';
import TemplateDialog from 'src/app/partnerarea/idea/configuration/assortment/templates/TemplateDialog.vue';
import { DMS_PROPERTIES } from '@/api/models/dms/dmsStates';

export default {
  name: 'AssortmentConfiguration',
  components: {
    AssortmentCatalog,
    ProductCard,
    DesignConfiguration,
    Banner,
    CondensedIdeaPreviewImage,
  },
  data() {
    return {
      mode: 'LIST',
      products: null,
      group: null,
      prevScrollPos: 0,
      initialized: false,
      originalAssortment: null,
    };
  },
  computed: {
    ...mapState({
      idea: (state) => state.idea.current,
    }),
    ...mapGetters({
      multipleDesigns: 'idea/multipleDesigns',
      designs: 'idea/designs',
      ideaPublished: 'onboarding/ideaPublished',
      getDmsProperty: 'dms/getProperty',
    }),
    totalSellableCount() {
      return assortmentHelper.getTotalAvailableSellables(this.idea.assortment)
        .length;
    },
    templateFeatureEnabled() {
      return (
        !this.multipleDesigns &&
        (!ideaHelper.isEmbroideryIdea(this.idea) ||
          this.getDmsProperty(DMS_PROPERTIES.EMBROIDERY_TEMPLATES).value)
      );
    },
  },
  async created() {
    await this.applyDefaultAssortmentAndUpdate();

    this.updateProducts();

    this.initialized = true;
  },
  methods: {
    ...mapMutations({
      updateGroup: 'idea/updateGroup',
      filterAssortment: 'idea/filterAssortment',
      resetAssortmentFilter: 'idea/resetAssortmentFilter',
      updateSellableColor: 'idea/updateSellableColor',
      updateSellableImage: 'idea/updateSellableImage',
      updateSellableColors: 'idea/updateSellableColors',
      updateOriginalIdeaAssortment: 'idea/updateOriginalIdeaAssortment',
      setAssortment: 'idea/setAssortment',
    }),
    ...mapActions({
      applyDefaultAssortmentAndUpdate: 'idea/applyDefaultAssortmentAndUpdate',
      fetchAssortment: 'idea/fetchAssortment',
    }),
    openCatalog() {
      this.originalAssortment = deepCopy(this.idea.assortment);
      this.mode = 'CATALOG';
      this.updateProducts();
    },
    closeCatalog() {
      this.mode = 'LIST';
      this.updateProducts();
    },
    cancelCatalog() {
      this.setAssortment(this.originalAssortment);

      this.closeCatalog();
    },
    onRemove(group) {
      this.updateGroup({
        groupId: group.filterId,
        changes: {
          included: false,
        },
      });
      this.updateProducts();
    },
    openDesigner(group) {
      if (!this.designerEnabled(group)) {
        this.mode = 'LIST';
        return;
      }

      this.saveScrollPosition();

      this.group = group;
      this.mode = 'DESIGNER';
    },
    saveDesigner({ config, continueSequentialEdit }) {
      this.updateSellableColor({
        groupId: this.group.filterId,
        appearanceId: config.template.appearanceId,
      });
      this.updateAssortment();

      if (continueSequentialEdit) {
        const editableProducts = this.products.filter(this.designerEnabled);
        const index = editableProducts.indexOf(this.group);
        if (index >= 0 && editableProducts.length > index + 1) {
          this.openDesigner(editableProducts[index + 1]);
        } else if (editableProducts.length > 1) {
          this.openDesigner(editableProducts[0]);
        }
      } else {
        this.mode = 'LIST';
        this.restoreScrollPosition();
      }
    },
    async cancelDesigner() {
      this.mode = 'LIST';

      this.restoreScrollPosition();
    },
    updateAssortment() {
      return productIdeaService.getAssortment(this.idea).then((assortment) => {
        assortmentHelper
          .getTotalAvailableSellables(assortment)
          .filter((sellable) => Boolean(sellable.primarySellable))
          .forEach((sellable) => {
            this.updateSellableColors({
              groupId: sellable.filterId,
              colors: sellable.primarySellable.colors,
            });
            this.updateSellableImage({
              groupId: sellable.filterId,
              imageUrl: sellable.primarySellable.defaultImageUrl,
            });
          });
        this.updateProducts();
      });
    },
    designerEnabled(group) {
      return !assortmentHelper.isPosterAssortmentGroup(group);
    },
    openTemplateDialog() {
      dialogService
        .openDialog(TemplateDialog, {
          idea: this.idea,
        })
        .then(({ action }) => {
          if (action === 'load') {
            return this.fetchAssortment().then(() => {
              this.updateProducts();
              this.updateOriginalIdeaAssortment();
            });
          } else if (action === 'save') {
            return this.updateOriginalIdeaAssortment();
          }
        });
    },
    updateProducts() {
      this.products = assortmentHelper
        .getSelectedSellables(this.idea.assortment)
        .sort((a, b) => {
          if (a.primarySellable.weight > b.primarySellable.weight) {
            return 1;
          } else if (a.primarySellable.weight < b.primarySellable.weight) {
            return -1;
          } else {
            return 0;
          }
        });
    },
    async restoreScrollPosition() {
      await this.$nextTick();
      const scrollElement = document.querySelector('#detail-page-scroller');
      if (scrollElement) {
        scrollElement.scrollTop = this.prevScrollPos;
      }
    },
    saveScrollPosition() {
      const scrollElement = document.querySelector('#detail-page-scroller');
      if (scrollElement) {
        this.prevScrollPos = scrollElement.scrollTop;
      }
    },
    groupCanBeDeleted(group) {
      return !assortmentHelper.getVariants(this.idea.assortment, group).length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 220px 0;

  img {
    height: 100px;
    width: 100px;
  }
}

.list {
  padding: 8px 0;
}

.subheading {
  color: $sprd-color-grey-60;
}

.product-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  li {
    flex: 1 1 200px;
    padding: 10px;
  }

  :deep(.product-name) {
    font-weight: bold;
  }

  &.empty {
    flex-direction: column;
    align-items: center;
    padding-top: 80px;

    h2 {
      margin: 0 0 16px 0;
    }

    p {
      margin: 0 0 24px 0;
      color: $grey60;
    }

    img {
      width: 256px;
    }
  }
}

.product {
  :deep(.product-name) {
    font-weight: bold;
  }
}

.actions {
  display: flex;
  align-items: center;
  margin: 24px 0 0 0;

  .btn {
    height: 48px;
    margin-right: 24px;
  }

  .sellable-count {
    margin: 0 0 0 auto;
    color: $grey60;
  }
}

.banner {
  margin: 24px 0 0 0;
}
</style>
