<template>
  <div class="main-container">
    <div class="toaster">
      <Toast
        v-for="toast in toasts"
        :key="`${toast.variant}-${toast.message}`"
        :variant="toast.variant"
        :timeout="toast.timeout"
        :dismissible="toast.dismissible"
        @dismiss="() => removeToast(toast)"
      >
        {{ toast.message }}
      </Toast>
    </div>
    <div class="topBtnContainer">
      <h1 class="subheading">
        {{ $t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.PROGRESS_3') }}
      </h1>
      <button
        class="btnDownloadAll btn btn-primary icon-btn"
        @click="downloadAll"
      >
        <Icon icon="download-file" class="channelIcon" />
        {{ $t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.DOWNLOAD_ALL_BTN_CAPTION') }}
      </button>
      <SocialMediaToolHubspotUploader
        class="btnPublish btn"
        :get-canvas-refs="getCanvasRefs"
        @push-toast="pushToast"
        @remove-toast="removeToast"
      />
      <Icon
        v-tooltip="{
          content: $t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.HUBSPOT_HINT'),
        }"
        icon="question"
        class="hubspot-info"
      />
    </div>
    <div class="top-box">
      <p>
        {{
          $t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.FINAL_STEP_EXPLANATORY_TEXT')
        }}
      </p>
      <a class="link-main icon-link" :href="shopUrl" target="_blank">
        <Icon icon="globe_light"></Icon>
        {{ shopUrl }}
      </a>
    </div>
    <div class="image-card-container">
      <div v-for="imgMetaCol in imgMetaColumns" class="canvasHolderColumn">
        <div
          v-for="img in imgMetaCol"
          :key="img.name"
          class="sharingCardContainer"
        >
          <div class="sharingCard">
            <h4 class="sharingTitle">{{ img.displayName }}</h4>
            <p class="sharingDimensions">{{ img.width }} x {{ img.height }}</p>
            <div class="canvasHolder">
              <canvas
                :ref="img.name"
                class="canvas"
                :width="img.width"
                :height="img.height"
              ></canvas>
            </div>
            <div class="btns">
              <div v-if="img.error" class="error channel">
                <Icon icon="error" class="channelIcon" />
                <span>{{ img.error }}</span>
              </div>
              <button
                v-for="btn in Object.values(
                  channels[img.channel].buttons,
                ).filter((b) => b.show)"
                :key="btn.caption"
                class="action-btn channel"
                type="button"
                @click="btn.onclick(img)"
              >
                <Icon :icon="btn.icon" class="channelIcon" />
                <span>{{ btn.caption }}</span>
              </button>
            </div>
          </div>
          <LoadingHeart v-if="img.isLoading" class="loader" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/api/client/apiClient';
import { downloadCanvas, drawImage } from './socialMediaToolCore';
import { shopImageUpload } from '@/api/shop/shopImageUpload';

import { getSpreadshopSharingImageUrl } from '@/api/shop/urlBuilder';
import SocialMediaToolHubspotUploader from './SocialMediaToolHubspotUploader.vue';
import Toast from 'src/app/components/toast/Toast.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import UrlQRCodeDialog from 'src/app/partnerarea/pos/shop/socialMediaTool/UrlQRCodeDialog.vue';
import LoadingHeart from '@/LoadingHeart/LoadingHeart.vue';

const openPopup = (url, name) => {
  const newwindow = window.open(
    `${url}${encodeURIComponent(window.location.href)}`,
    name,
    'height=400,width=700,resizable=1',
  );
  if (window.focus) {
    newwindow.focus();
  }
  return false;
};

const stripModelIdSuffix = (pseudoAppearanceId) => {
  if (pseudoAppearanceId && pseudoAppearanceId.includes('-')) {
    return pseudoAppearanceId.split('-')[0];
  }
  return pseudoAppearanceId;
};

export default {
  name: 'SocialMediaToolResultsPage',
  components: {
    LoadingHeart,
    Toast,
    SocialMediaToolHubspotUploader,
  },
  props: {
    canvasData: {
      type: Object,
      required: true,
    },
    ideaId: {
      type: String,
      required: false,
    },
    sellableId: {
      type: String,
      required: false,
    },
    appearanceId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      toasts: [],
      imgMeta: [
        {
          name: 'fbstory',
          displayName: 'Facebook & Insta story',
          channel: 'fbstory',
          width: 1080,
          height: 1920,
          isLoading: false,
          error: null,
        },
        {
          name: 'instafeed',
          displayName: 'Instagram feed',
          channel: 'instagram',
          width: 1080,
          height: 1080,
          isLoading: false,
          error: null,
        },
        {
          name: 'fbfeed',
          displayName: 'Facebook feed',
          channel: 'fbfeed',
          width: 1200,
          height: 630,
          isLoading: false,
          error: null,
        },
        {
          name: 'twitter',
          displayName: 'Twitter',
          channel: 'twitter',
          width: 1024,
          height: 512,
          isLoading: false,
          error: null,
        },
        {
          name: 'pinterest1',
          displayName: 'Pinterest',
          channel: 'pinterestSmall',
          width: 600,
          height: 600,
          isLoading: false,
          error: null,
        },
        {
          name: 'pinterest2',
          displayName: 'Pinterest',
          channel: 'pinterestLarge',
          width: 600,
          height: 900,
          isLoading: false,
          error: null,
        },
      ],
      channels: {
        fbstory: {
          buttons: {
            download: {
              onclick: (img) => this.download(img),
              icon: 'download-file',
              caption: this.$t(
                'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.FINAL_STEP_DOWNLOAD_BUTTON',
              ),
              show: true,
            },
            hootsuite: {
              onclick: this.hootsuiteUpload,
              icon: 'upload',
              caption: this.$t(
                'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.HOOTSUITE_UPLOAD',
              ),
              show: false,
            },
          },
        },
        fbfeed: {
          buttons: {
            download: {
              onclick: (img) => this.download(img),
              icon: 'download-file',
              caption: this.$t(
                'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.FINAL_STEP_DOWNLOAD_BUTTON',
              ),
              show: true,
            },
            hootsuite: {
              onclick: this.hootsuiteUpload,
              icon: 'upload',
              caption: this.$t(
                'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.HOOTSUITE_UPLOAD',
              ),
              show: false,
            },
          },
        },
        instagram: {
          buttons: {
            download: {
              onclick: (img) => this.download(img),
              icon: 'download-file',
              caption: this.$t(
                'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.FINAL_STEP_DOWNLOAD_BUTTON',
              ),
              show: true,
            },
            mobile: {
              onclick: (img) => this.uploadOrDisplayImage(img),
              icon: 'mobile',
              caption: this.$t(
                'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.FINAL_STEP_MOBILE_BUTTON',
              ),
              show: true,
            },
            hootsuite: {
              onclick: this.hootsuiteUpload,
              icon: 'upload',
              caption: this.$t(
                'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.HOOTSUITE_UPLOAD',
              ),
              show: false,
            },
          },
        },
        twitter: {
          buttons: {
            download: {
              onclick: (img) => this.download(img),
              icon: 'download-file',
              caption: this.$t(
                'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.FINAL_STEP_DOWNLOAD_BUTTON',
              ),
              show: true,
            },
            mobile: {
              onclick: (img) => this.uploadOrDisplayImage(img),
              icon: 'mobile',
              caption: this.$t(
                'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.FINAL_STEP_MOBILE_BUTTON',
              ),
              show: true,
            },
            hootsuite: {
              onclick: this.hootsuiteUpload,
              icon: 'upload',
              caption: this.$t(
                'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.HOOTSUITE_UPLOAD',
              ),
              show: false,
            },
          },
        },
        pinterestSmall: {
          buttons: {
            download: {
              onclick: (img) => this.download(img),
              icon: 'download-file',
              caption: this.$t(
                'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.FINAL_STEP_DOWNLOAD_BUTTON',
              ),
              show: true,
            },
            mobile: {
              onclick: (img) => this.uploadOrDisplayImage(img),
              icon: 'mobile',
              caption: this.$t(
                'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.FINAL_STEP_MOBILE_BUTTON',
              ),
              show: true,
            },
            pinterest: {
              onclick: (img) =>
                this.uploadImage(img).then((data) =>
                  this.onPinterestSharing(data.url),
                ),
              icon: 'pinterest',
              caption: 'Pinterest',
              show: true,
            },
            hootsuite: {
              onclick: this.hootsuiteUpload,
              icon: 'upload',
              caption: this.$t(
                'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.HOOTSUITE_UPLOAD',
              ),
              show: false,
            },
          },
        },
        pinterestLarge: {
          buttons: {
            download: {
              onclick: (img) => this.download(img),
              icon: 'download-file',
              caption: this.$t(
                'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.FINAL_STEP_DOWNLOAD_BUTTON',
              ),
              show: true,
            },
            mobile: {
              onclick: (img) => this.uploadOrDisplayImage(img),
              icon: 'mobile',
              caption: this.$t(
                'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.FINAL_STEP_MOBILE_BUTTON',
              ),
              show: true,
            },
            pinterest: {
              onclick: (img) =>
                this.uploadImage(img).then((data) =>
                  this.onPinterestSharing(data.url),
                ),
              icon: 'pinterest',
              caption: 'Pinterest',
              show: true,
            },
            hootsuite: {
              onclick: this.hootsuiteUpload,
              icon: 'upload',
              caption: this.$t(
                'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.HOOTSUITE_UPLOAD',
              ),
              show: false,
            },
          },
        },
      },
      uploadedImages: [],
    };
  },
  computed: {
    imgMetaColumns() {
      return [
        [this.imgMeta[0]],
        [this.imgMeta[2], this.imgMeta[3]],
        [this.imgMeta[4]],
        [this.imgMeta[5]],
        [this.imgMeta[1]],
      ];
    },
    shopUrl() {
      return this.$store.getters['shop/getSpreadshopUrl']('', false);
    },
  },
  mounted() {
    this.getHootsuiteStatus();
    this.imgMeta.forEach((m) =>
      drawImage(
        Object.assign({}, this.canvasData, { canvas: this.$refs[m.name][0] }),
      ),
    );
  },
  methods: {
    download(imgMeta) {
      downloadCanvas(this.$refs[imgMeta.name][0], imgMeta.name);
    },
    downloadAll() {
      this.imgMeta.forEach((imgm) => this.download(imgm));
    },
    uploadOrDisplayImage(imgMeta) {
      if (this.uploadedImages[imgMeta.name]) {
        dialogService.openDialog(UrlQRCodeDialog, {
          targetUrl: this.uploadedImages[imgMeta.name].shareUrl,
          displayName: imgMeta.displayName,
        });
      } else {
        this.uploadImage(imgMeta).then(() => {
          dialogService.openDialog(UrlQRCodeDialog, {
            targetUrl: this.uploadedImages[imgMeta.name].shareUrl,
            displayName: imgMeta.displayName,
          });
        });
      }
    },
    uploadBlob(filename, imageBlob, tag) {
      imageBlob.name = filename;
      return shopImageUpload
        .uploadImage(this.$store, imageBlob, 'social_media_tool', () => {}, tag)
        .then((data) => {
          return data;
        });
    },
    shortenUrl(url) {
      return new Promise((resolve) => {
        apiClient
          .request({
            method: 'POST',
            url: `https://sprd.co/api/links`,
            withCredentials: false,
            params: {
              locale: null,
            },
            data: { to: url },
          })
          .then((res) => {
            if (res.data && res.data.link) {
              resolve(res.data.link);
            }
            resolve(url);
          })
          .catch((e) => {
            console.error('Failed to create short URL with halblang', e);
            resolve(url);
          });
      });
    },
    hootsuiteUpload(imgMeta) {
      if (!this.uploadedImages[imgMeta.name]) {
        this.uploadImage(imgMeta).then(() => {
          const hootsuiteButton =
            this.channels[imgMeta.channel].buttons.hootsuite;
          hootsuiteButton.caption = this.$t(
            'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.UPLOAD_SUCCESSFUL',
          );
          hootsuiteButton.icon = 'accept';
        });
      } else {
        const hootsuiteButton =
          this.channels[imgMeta.channel].buttons.hootsuite;
        hootsuiteButton.caption = this.$t(
          'POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.UPLOAD_SUCCESSFUL',
        );
        hootsuiteButton.icon = 'accept';
      }
    },
    uploadImage(imgMeta) {
      imgMeta.isLoading = true;
      imgMeta.error = null;
      return new Promise((resolve) => {
        const canvas = this.$refs[imgMeta.name][0];
        const fileName = imgMeta.name;
        canvas.toBlob(
          (blob) => {
            const imageTag = Math.random().toString().substring(2);
            this.uploadBlob(fileName, blob, imageTag)
              .then((f) => {
                const shopId = this.$store.state.shop.currentShop.id;
                this.shortenUrl(
                  getSpreadshopSharingImageUrl(
                    shopId,
                    this.$store.state.shop.currentShop.domain,
                    imageTag,
                    this.ideaId,
                    this.sellableId,
                    stripModelIdSuffix(this.appearanceId),
                  ),
                ).then((shareUrl) => {
                  this.uploadedImages[imgMeta.name] = {
                    name: fileName,
                    url: f.url,
                    shareUrl,
                  };
                  resolve(f);
                });
              })
              .catch(() => {
                imgMeta.error = this.$t(
                  'SA.imageUpload.message.genericUploadError',
                );
              })
              .finally(() => {
                imgMeta.isLoading = false;
              });
          },
          'image/jpeg',
          0.95,
        );
      });
    },
    onPinterestSharing(imageUrl) {
      const shopUrl = this.$store.getters['shop/getSpreadshopUrl']('', false);
      openPopup(
        `https://www.pinterest.com/pin/create/link/?media=${encodeURIComponent(
          imageUrl,
        )}&url=${encodeURIComponent(shopUrl)}`,
        'Share on Pinterest',
      );
    },
    getCanvasRefs() {
      return Object.fromEntries(
        this.imgMeta.map((im) => {
          return [im.name, this.$refs[im.name]];
        }),
      );
    },
    pushToast(toast) {
      this.toasts = [...this.toasts, toast];
    },
    removeToast(toast) {
      this.toasts = this.toasts.filter((t) => t !== toast);
    },
    async getHootsuiteStatus() {
      const res = await apiClient.request({
        method: 'GET',
        url: '/shopsettings/hootsuite/status',
      });

      Object.values(this.channels).forEach((channel) => {
        channel.buttons.hootsuite.show = res.data.connected;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';

.error {
  color: $pa-color-red;
  margin-bottom: 6px;
}

.main-container {
  display: flex;
  flex-wrap: wrap;
}

a.icon-link {
  display: flex;
  align-items: center;
  line-height: 1;

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}

.top-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: $border-radius-medium;
  padding: 1em;
  margin-bottom: 8px;
  width: 100%;

  p {
    margin: 0;
  }

  a {
    white-space: nowrap;
    text-align: right;
    margin: 0 1em 0 2em;
  }
}

.toaster {
  width: 100%;
  margin-bottom: 1rem;
}

.image-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: fit-content;
}

.canvasHolderColumn {
  display: flex;
  flex-direction: column;
  flex: 0 1 calc(20% - 8px);
}

.canvasHolderColumn + .canvasHolderColumn {
  margin-left: 8px;
}

.canvasHolder {
}

.canvas {
  width: 100%;
  height: 100%;
}

.btns {
  margin-top: 12px;
  width: 100%;
}

.channel {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
}

.action-btn {
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 6px;
  border: none;
  background: none;
  user-select: none;
  outline: none;

  &:hover {
    color: $pa-color-main-dark;
  }
}

.channelIcon {
  width: 32px;
  height: 32px;
}

.loader {
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
}

.sharingCardContainer {
  position: relative;
  box-sizing: border-box;
  margin: 0 0 8px 0;
  border-radius: $border-radius-medium;
  overflow: hidden;
}

.sharingCard {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
}

.sharingTitle {
  margin: 0 0 4px 0 !important; // fucking _dialog.scss
  font-size: 1em;
  color: $grey50;
}

.sharingDimensions {
  margin: 0 0 8px 0 !important; // fucking _dialog.scss
  font-size: 0.75em;
  color: $grey50;
}

.topBtnContainer {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 1rem;
}

.subheading {
  align-self: center;
}

.btnDownloadAll {
  margin-left: auto;
  margin-right: 1rem;
  padding: 0 0.5rem;
  border-radius: $border-radius-medium;

  &:disabled {
    background-color: $sprd-color-darker-grey;
  }
}

.btnPublish {
  margin-right: 1rem;
  padding: 0.5rem;

  color: $sprd-color-blue;
  border-color: $sprd-color-blue;
  border-radius: $border-radius-medium;

  &:disabled {
    border-color: $sprd-color-darker-grey;
    color: $sprd-color-darker-grey;
    cursor: not-allowed;
  }

  &:hover {
    color: $sprd-color-dark-blue;
    border-color: $sprd-color-dark-blue;
  }
}

.hubspot-info {
  width: 1.5rem;
  height: 1.5rem;
  color: $sprd-color-blue;
  align-self: center;
}
</style>
