import { PUBLISHING_SCOPE } from '@/publishing/publishingScope';

export default {
  namespaced: true,
  state: {
    currentScope: null,
  },
  getters: {
    isMpScope: (state) => state.currentScope?.key === PUBLISHING_SCOPE.MP.key,
    isShopScope: (state) =>
      state.currentScope?.key === PUBLISHING_SCOPE.SHOP.key,
    hasScope: (state, getters) => getters.isMpScope || getters.isShopScope,
  },
  mutations: {
    setCurrentScope(state, scope) {
      state.currentScope = scope;
    },
  },
};
