<template>
  <div>
    <Logo />

    <h4>
      {{ $t('POS.SHOP_SETTINGS.GENERAL.NAMEANDURL.FAVICON') }}
      <Tooltip :text="faviconDesc" />
    </h4>
    <ImageUpload
      image-use-case="favicon"
      file-input-id="hiddenFaviconFileInput"
      :active="false"
      active-image-name="favicon.png"
      :active-image-url="activeFaviconUrl"
      :disabled="false"
      :image="favicon"
      @file-deleted="onFaviconDeleted"
      @file-uploaded="onFaviconUploaded"
    />

    <h4>
      {{ $t('POS.SHOP_SETTINGS.GENERAL.NAMEANDURL.INVOICE_LOGO') }}
      <Tooltip :text="invoiceLogoDesc" />
    </h4>
    <ImageUpload
      image-use-case="invoiceLogo"
      file-input-id="hiddenInvoiceLogoFileInput"
      :active="false"
      :active-image-name="settings.invoiceLogoImage"
      :active-image-url="activeInvoiceLogoUrl"
      :disabled="false"
      :image="invoiceLogo"
      @file-deleted="onInvoiceLogoDeleted"
      @file-uploaded="onInvoiceLogoUploaded"
    />
  </div>
</template>

<script>
import Logo from './Logo.vue';
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import Tooltip from 'src/app/partnerarea/pos/shop/common/Tooltip.vue';
import ImageUpload from 'src/app/partnerarea/pos/shop/imageUpload/ImageUpload.vue';
import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';

import { favicon, invoiceLogo } from '../../imageAttributes';

export default {
  name: 'Logos',
  components: {
    Logo,
    ImageUpload,
    Tooltip,
  },
  mixins: [SettingsData],
  data: function () {
    return {
      activeFaviconUrl: null,
      favicon: favicon,
      invoiceLogo: invoiceLogo,
      activeInvoiceLogoUrl: null,
    };
  },
  computed: {
    invoiceLogoDesc() {
      return this.$t('POS.SHOP_SETTINGS.GENERAL.NAMEANDURL.INVOICE_LOGO_DESC');
    },
    faviconDesc() {
      return this.$t('POS.SHOP_SETTINGS.GENERAL.NAMEANDURL.FAVICON_DESC');
    },
  },
  created() {
    this.$store.commit('shop/setLivePreview', { path: '' });

    if (this.settings.customFavicon) {
      shopAPI
        .getShopImages(this.$store, 'favicon')
        .then((fileArray) => {
          const match = fileArray.find(
            (fileEntry) => fileEntry.filename === 'favicon.png',
          );
          if (match) {
            this.activeFaviconUrl = match.url;
          }
        })
        .catch((e) => console.error(e));
    }

    if (this.settings.invoiceLogoImage) {
      shopAPI
        .getShopImages(this.$store, 'invoicelogo')
        .then((fileArray) => {
          const match = fileArray.find(
            (fileEntry) =>
              fileEntry.filename === this.settings.invoiceLogoImage,
          );
          if (match) {
            this.activeInvoiceLogoUrl = match.url;
          }
        })
        .catch((e) => console.error(e));
    }
  },
  methods: {
    onFaviconUploaded(data) {
      this.settings.customFavicon = true;
      this.activeFaviconUrl = data.url;
    },
    onFaviconDeleted() {
      this.settings.customFavicon = false;
      this.activeFaviconUrl = null;
    },
    onInvoiceLogoUploaded(data) {
      this.settings.invoiceLogoImage = data.filename;
      this.activeInvoiceLogoUrl = data.url;
    },
    onInvoiceLogoDeleted() {
      this.settings.invoiceLogoImage = '';
      this.activeInvoiceLogoUrl = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.radiogroup {
  flex-direction: column;

  :deep(.radiobutton-container) {
    margin: 0.5em 0;
  }
}
</style>
