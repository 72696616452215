<template>
  <div class="labelInput" :class="{ disabled: disabled }">
    <div class="label-input-wrapper" :class="{ active, filled }">
      <slot></slot>
      <Icon icon="exclamation" class="error-icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LabelInput',
  props: {
    model: {
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputElement: null,
      inputValue: '',
      labelElement: null,
      placeholderExists: false,
      focused: false,
    };
  },
  computed: {
    active() {
      return this.focused || this.filled || this.placeholderExists;
    },
    filled() {
      return (
        this.inputValue ||
        this.value ||
        (this.model && String(this.model).length > 0)
      );
    },
  },
  watch: {
    disabled: function (newValue) {
      this.setDisabledState(newValue);
    },
  },
  mounted() {
    this.inputElement =
      this.$el.querySelector('input') ||
      this.$el.querySelector('textarea') ||
      this.$el.querySelector('select');

    if (!this.inputElement) {
      this.focused = true;
      return;
    }

    this.placeholderExists = !!this.inputElement.placeholder;
    this.inputValue = this.inputElement.value;
    this.inputElement.addEventListener('change', () => {
      this.inputValue = this.inputElement.value;
    });

    this.labelElement = this.$el.querySelector('label');
    if (this.labelElement) {
      this.labelElement.addEventListener('click', () => {
        this.inputElement.focus();
      });
    }

    this.focused = document.activeElement === this.inputElement;
    this.inputElement.addEventListener('focus', () => {
      this.focused = true;
    });
    this.inputElement.addEventListener('blur', () => {
      this.focused = false;
    });

    this.setDisabledState(this.disabled);
  },
  methods: {
    setDisabledState(isDisabled) {
      if (!this.inputElement) {
        return;
      }
      this.inputElement.disabled = isDisabled;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';

.labelInput {
  display: block;
  position: relative;

  :deep(label) {
    margin: 0;
    line-height: 1em;
    padding: 0 4px;
  }

  :deep(.label-input-wrapper) {
    label {
      margin: 0;
      position: absolute;
      top: 12px;
      left: 8px; //12px - 4px padding
      transition: top 0.2s linear;
      z-index: 2;
      color: $sprd-color-darkest-grey;

      &:before {
        content: ' ';
        background-color: #fff;
        position: absolute;
        width: 100%;
        height: 50%;
        z-index: -1;
        left: 0;
        top: 46%;
      }
    }

    &.active label {
      top: -0.5em;
    }

    .error-icon {
      position: absolute;
      right: 10px;
      top: 10px;

      width: 20px;
      height: 20px;

      color: $pa-color-red;

      display: none;
    }

    .tc-link + .error-icon {
      right: 40px;
    }

    placeholder {
      position: absolute;
      top: 2em;
      left: 8px;
      transition: top 0.2s linear;
      z-index: 2;
      margin: 0;
      text-align: left;
      padding-right: 3px;
      font-size: 0.9em;
    }

    &.active placeholder {
      top: 0.7em;
    }

    &.filled placeholder {
      display: none;
    }

    .field-info {
      display: flex;
      padding-top: 8px;
      color: $grey60;

      .info,
      .error-info {
        flex-grow: 1;
        padding-left: 12px;
        padding-right: 12px;
      }

      .error-info {
        color: $pa-color-red;
        font-weight: bold;
      }

      .char-info {
        padding-right: 12px;
        flex-shrink: 0;
        flex-grow: 1;
        text-align: right;
      }
    }
  }

  :deep(textarea) {
    padding-top: 16px;
  }

  &.invalid {
    .error-info {
      display: block;
    }

    input {
      border-color: $pa-color-red;

      &:focus,
      &:hover {
        border-color: $pa-color-red;
      }
    }
  }

  .label-input-wrapper :deep(.input-group) {
    display: flex;
    border: 1px solid $sprd-color-medium-grey;
    border-radius: $border-radius-medium;
    --dp-border-radius: 8px;

    input {
      border: none !important;
      height: 38px;

      &:hover,
      &:focus {
        border: none !important;
      }
    }

    label:before {
      height: 60%;
      top: 40%;
    }

    .addon {
      position: absolute;
      right: 16px;
      top: 25%;
      pointer-events: none;
    }

    & > .btn {
      border: none;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      border: 1px solid $pa-color-main;

      & > .btn,
      .addon {
        color: $pa-color-main;
      }
    }
  }

  .tag-input-container :deep(input) {
    padding-top: 0 !important;
    padding-left: 0 !important;
  }

  &.has-error,
  &.invalid,
  &.ng-invalid {
    .error-icon {
      display: block;
    }
  }

  &.disabled {
    :deep(label) {
      color: $grey20;
    }
  }
}
</style>
