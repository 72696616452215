<template>
  <div class="dialog-size-md idea-translations-dialog">
    <div class="modal-body">
      <h1>
        {{
          $t('DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.ADD_HEADING')
        }}
      </h1>
      <p class="text-grey">
        {{
          $t(
            'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.ADD_SUBHEADING',
          )
        }}
      </p>
      <div class="new-language-selection">
        <Dropdown
          :items="newLanguageOptions"
          :value="currentLanguage"
          :item-key="(entry) => entry.isoCode"
          :item-to-string="(entry) => entry.name"
          :disabled="!newLanguageOptions.length"
          :placeholder="
            $t(
              'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.SELECT_LANGUAGE',
            )
          "
          @change="setCurrentLanguage"
        />
        <button
          class="btn btn-primary"
          :disabled="!newLanguageOptions.length"
          @click="addLanguage"
        >
          {{ $t('GENERAL.ADD') }}
        </button>
      </div>
      <div class="autotranslations">
        <ToggleSwitch
          :model-value="requestedAutotranslations"
          @update:model-value="setRequestedAutotranslations"
        />
        <p>
          {{
            $t(
              'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.AUTOTRANSLATE',
            )
          }}
        </p>
      </div>

      <div class="added-translations-heading">
        <h2>
          {{
            $t(
              'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.MANAGE_HEADING',
            )
          }}
        </h2>
        <p class="counter">
          <strong>{{ addedTranslations.length }}</strong
          >/{{ languages.length }}
        </p>
      </div>
      <TransitionGroup name="fade" tag="table" class="data-table">
        <tbody>
          <tr
            v-for="translation in addedTranslations"
            :key="translation.locale"
          >
            <td>
              {{ translation.name }}
            </td>
            <td class="text-grey">
              {{ translation.hint }}
            </td>
            <td>
              <a
                href="#"
                :disabled="!translation.deletable"
                class="link-red"
                @click.prevent="deleteTranslation(translation)"
              >
                <Icon icon="bin" />
              </a>
            </td>
          </tr>
        </tbody>
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/Dropdown/Dropdown.vue';
import { mapMutations, mapState, mapGetters } from 'vuex';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';

export default {
  name: 'IdeaTranslationsDialog',
  components: {
    Dropdown,
    ToggleSwitch,
  },
  props: ['modalInstance', 'data'],
  data() {
    return {
      currentLanguage: null,
    };
  },
  computed: {
    ...mapGetters({
      defaultLanguage: 'idea/language',
      languageByIso: 'platform/languageByIso',
      requestedAutotranslations: 'idea/requestedAutotranslations',
      intPubDecision: 'idea/intPubDecision',
    }),
    ...mapState({
      idea: (state) => state.idea.current,
      languages: (state) => state.platform.languages,
    }),
    newLanguageOptions() {
      return this.languages.filter(
        (language) =>
          !this.addedTranslations.some(
            (translation) => translation.locale === language.isoCode,
          ),
      );
    },
    addedTranslations() {
      return this.languages
        .map((language) => ({
          locale: language.isoCode,
          name: this.languageByIso(language.isoCode).name,
          hint: this.getTranslationHint(language.isoCode),
          deletable: this.isLocaleDeletable(language.isoCode),
        }))
        .filter((translation) => {
          if (this.requestedAutotranslations) {
            return true;
          } else {
            return this.idea.translations.some(
              (ideaTranslation) =>
                ideaTranslation.locale === translation.locale,
            );
          }
        })
        .sort((x, y) =>
          x.locale === this.defaultLanguage
            ? -1
            : y.locale === this.defaultLanguage
              ? 1
              : 0,
        );
    },
  },
  created() {},
  methods: {
    ...mapMutations({
      addTranslation: 'idea/addTranslation',
      removeTranslation: 'idea/removeTranslation',
      setRequestedAutotranslations: 'idea/setRequestedAutotranslations',
    }),
    setCurrentLanguage(language) {
      this.currentLanguage = language;
    },
    addLanguage() {
      if (!this.currentLanguage || !this.currentLanguage.isoCode) {
        return;
      }

      this.addTranslation({ locale: this.currentLanguage.isoCode });
      this.setCurrentLanguage(null);
    },
    getTranslationHint(locale) {
      const ideaTranslation = this.idea.translations.find(
        (translation) => translation.locale === locale,
      );

      if (locale === this.defaultLanguage) {
        return this.$t(
          'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.DEFAULT',
        );
      } else if (ideaTranslation && ideaTranslation.autotranslated) {
        return this.$t(
          'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.AUTOTRANSLATED',
        );
      } else if (!ideaTranslation) {
        return this.$t(
          'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.AUTOTRANSLATE_PENDING',
        );
      } else {
        return this.$t(
          'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.MANUAL',
        );
      }
    },
    isLocaleDeletable(locale) {
      const isDefaultLocale = locale === this.defaultLanguage;
      const ideaTranslation = this.idea.translations.find(
        (translation) => translation.locale === locale,
      );
      const isRequestedAutoTranslation =
        this.requestedAutotranslations &&
        ideaTranslation &&
        ideaTranslation.autotranslated;
      const isRequestedAutoTranslationPending =
        this.requestedAutotranslations && !ideaTranslation;

      return (
        !isDefaultLocale &&
        !isRequestedAutoTranslation &&
        !isRequestedAutoTranslationPending
      );
    },
    deleteTranslation(translation) {
      if (translation.deletable) {
        this.removeTranslation({ locale: translation.locale });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.idea-translations-dialog {
  width: 460px;

  .modal-body {
    & > h1 {
      margin: 0;
    }

    & > p {
      margin: 12px 0 0 0;
    }

    & > h2 {
      margin: 0 0 12px 0;
    }
  }
}

.autotranslations {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  p {
    margin: 0 0 0 16px;
  }
}

.new-language-selection {
  display: flex;
  margin: 24px 0;

  .dropdown {
    width: 250px;
    margin-right: 8px;
  }
}

.added-translations-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  h2,
  p {
    margin: 0;
  }
}
</style>
