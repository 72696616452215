<template>
  <div class="dialog-size-md address-dialog">
    <div class="modal-body">
      <PdvAddressValidation @on-done="modalInstance.close" />
    </div>
  </div>
</template>

<script>
import PdvAddressValidation from '@/partnerDataValidation/addressValidation/PdvAddressValidation.vue';

export default {
  name: 'PdvDialog',
  components: {
    PdvAddressValidation,
  },
  props: {
    modalInstance: { type: Object, required: true },
    data: { type: Object, default: () => ({}) },
  },
};
</script>
