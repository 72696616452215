import localeService from '@/localeService/localeService';

const substituteLocales = {
  'no-NO': 'nb-NO',
};

export default (value, decimals) => {
  const locale = localeService.getIsoLocale();

  // Note: parseFloat cannot convert separator "," in string containing number
  let val = parseFloat(value) || 0;

  const hasDecimal = decimals !== undefined && decimals < 21;

  try {
    val = val.toLocaleString(substituteLocales[locale] || locale, {
      minimumFractionDigits: hasDecimal ? decimals : null,
      maximumFractionDigits: hasDecimal ? decimals : 3,
    });
  } catch (_) {
    val = String(val);
  }

  return val;
};
