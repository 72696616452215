<template>
  <div class="bestseller">
    <div ref="bestsellerList" class="bestseller-content">
      <StatisticsFilters
        heading="STATISTICS.BESTSELLERS.HEADING"
        display-type-filter
        display-pos-filter
        display-date-from-filter
        display-date-to-filter
        flush-bottom
        @filter-update="onFilterUpdate"
      />
      <img
        v-if="!statsData"
        class="sprd-loader loader"
        src="/images/loader.svg"
        alt="loader"
      />
      <BestsellerWorldMap
        v-if="
          statsData &&
          statsData.length &&
          mapData &&
          filters.type.value === 'shippingCountries'
        "
        :data="mapData"
      />
      <div v-if="statsData" class="list-content">
        <BestsellerList :data="statsData" />
        <div v-if="statsData.length" class="pagination-wrapper">
          <Pagination
            v-if="metaData.count > pagination.pageSize"
            :total="metaData.count"
            :page-size="pagination.pageSize"
            :current-page="pagination.currentPage"
            @page-change="onPageChange"
          ></Pagination>

          <div v-if="statsData.length > 10" class="scroll">
            <a href="#" class="link-main" @click.prevent="scrollToTop()">
              <Icon class="rotate-icon-270" icon="arrow-end"></Icon>
              {{ $t('STATISTICS.TO_TOP') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { endOfDay } from 'date-fns/endOfDay';
import { parseISO } from 'date-fns/parseISO';

import statisticsService from '@/api/statisticsService/statisticsService';
import localeService from '@/localeService/localeService';

import Pagination from '@/pagination/Pagination.vue';
import BestsellerList from './BestsellerList.vue';
import BestsellerWorldMap from './BestsellerWorldMap.vue';
import StatisticsFilters from '../StatisticsFilters.vue';

export default {
  name: 'Bestseller',
  components: {
    Pagination,
    BestsellerList,
    StatisticsFilters,
    BestsellerWorldMap,
  },
  data() {
    return {
      pagination: {
        pageSize: 20,
        currentPage: 0,
        maxSize: 5,
      },
      metaData: {
        count: 0,
        limit: 0,
        offset: 0,
        listSize: 0,
      },
      filters: null,
      statsData: null,
      mapData: null,
    };
  },
  methods: {
    async fetchBestsellerData() {
      try {
        this.statsData = null;
        this.mapData = null;

        if (this.filters.type.value === 'shippingCountries') {
          statisticsService
            .getBestsellerMapData(this.getOptions())
            .then((data) => {
              this.mapData = data.countries;
            });
        }

        const data = await statisticsService.getBestsellers(
          this.getOptions({ pagination: true }),
        );

        this.metaData = {
          count: data.count,
          limit: data.limit,
          offset: data.offset,
          listSize: data.list.length,
        };

        this.statsData = data.list;
      } catch (e) {
        console.error(e);
      }
    },
    getOptions(opts = {}) {
      const options = {
        locale: localeService.getLocale(),
        dateFrom: this.filters.dateFrom,
        dateTo: this.filters.dateTo
          ? endOfDay(parseISO(this.filters.dateTo))
          : null,
        pos: this.filters.pointOfSale.value,
        type: this.filters.type.value,
      };

      if (opts.pagination) {
        options.limit = this.pagination.pageSize;
        options.offset = this.pagination.currentPage;
      }

      return options;
    },
    onFilterUpdate(filters) {
      this.pagination.currentPage = 0;

      this.filters = filters;
      this.fetchBestsellerData();
    },
    scrollToTop() {
      this.$refs.bestsellerList.scrollIntoView({ behavior: 'smooth' });
    },
    async onPageChange(newPage) {
      this.pagination.currentPage = newPage;
      await this.fetchBestsellerData();
      this.scrollToTop();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/type';

:deep(.svgMap-map-wrapper) {
  background: $grey0;
}

.bestseller {
  width: 100%;
  margin: 0 auto;

  .bestseller-content {
    max-width: 1400px;

    .loader {
      width: 100%;
      height: 120px;
      margin-top: 48px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .stat-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 16px 8px;
    background-color: #fff;
    border-radius: $border-radius-medium;

    small {
      display: block;
      padding-bottom: 8px;
    }

    .rank {
      display: flex;
      justify-content: center;
    }

    .rank,
    .sales {
      width: 60px;
    }

    .sales {
      text-align: right;
      margin-right: 30px;
    }

    .pos-label {
      margin-right: 24px;
    }

    .description {
      display: flex;
      align-items: center;
      flex: 1;

      .image {
        position: relative;

        .bg-toggle-link {
          position: absolute;
          bottom: 6px;
          right: 2px;
        }
      }

      img {
        width: 80px;
        height: auto;

        &.countries {
          border: 1px solid $grey10;
        }

        &.design {
          padding: 15px;
          width: 100px;
        }
      }

      & > span {
        margin: 0 16px;
      }
    }

    .pos {
      flex: 1;
    }

    .sale-detail-names {
      padding-left: 10px;
    }

    .sale-detail {
      margin-bottom: 10px;
    }
  }
}
</style>
