<template>
  <div v-if="display" class="shop-promos">
    <div class="header">
      <h3>
        {{ $t('DASHBOARD.CALENDAR') }}
      </h3>
    </div>
    <div class="promo-list">
      <div v-if="promotionMonths">
        <ul v-if="promotionMonths.length">
          <li
            v-for="promotionMonth in promotionMonths"
            :key="promotionMonth.month.key"
            class="month"
          >
            <p class="month-label">{{ promotionMonth.month.formatted }}</p>
            <ul>
              <li
                v-for="promotion in promotionMonth.promotions"
                :key="`${promotion.id}-${promotion.shopId}`"
                class="promo"
              >
                <h2 class="days">{{ promotion.formattedTimeframe }}</h2>
                <div class="text">
                  <p class="promo-text">{{ promotion.text }}</p>
                  <p class="shop">
                    {{ promotion.shopName || promotion.shopId }}
                  </p>
                </div>
                <div class="actions">
                  <div v-if="promotion.historic" class="active">
                    <div class="active-indicator"></div>
                    <p>LIVE</p>
                  </div>
                  <router-link
                    v-if="promotion.moveable"
                    v-trackClickEvent="{
                      name: 'promotion-module_edit-clicked',
                      details: {
                        eventArea: 'promotion-module',
                      },
                    }"
                    :to="`/shop-area/${promotion.shopId}/promos`"
                    class="link-main"
                    :title="$t('GENERAL.EDIT')"
                  >
                    <Icon icon="pencil" />
                  </router-link>
                  <router-link
                    v-trackClickEvent="{
                      name: 'promotion-module_share-clicked',
                      details: {
                        eventArea: 'promotion-module',
                      },
                    }"
                    :to="{
                      path: `/shop-area/${promotion.shopId}/social-media-tool`,
                      query: {
                        action: 'promo',
                        text: `${promotion.formattedTimeframeIncludingMonth}`,
                        defaultPromo: promotion.id,
                      },
                    }"
                    class="link-main"
                    :title="$t('MARKETING.LINK_SHARE')"
                  >
                    <Icon icon="share" />
                  </router-link>
                </div>
              </li>
            </ul>
          </li>
        </ul>
        <div v-else class="empty">
          <img src="/images/calendar.svg" />
          <p>{{ $t('DASHBOARD.SHOP_PROMOTIONS.EMPTY.HEADING') }}</p>
          <router-link
            v-if="shopsToDisplay.length"
            :to="{
              path: `/shop-area/generic/promos`,
            }"
            class="link-main"
          >
            {{ $t('DASHBOARD.SHOP_PROMOTIONS.EMPTY.CTA') }}
          </router-link>
        </div>
      </div>
      <LoadingHeart v-else />
    </div>
    <SpreadshopLogo class="logo" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import shopService from '@/api/shopService/shopService';
import { parseISO } from 'date-fns/parseISO';
import { isPast } from 'date-fns/isPast';
import LoadingHeart from 'src/app/commons/LoadingHeart/LoadingHeart.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import SocialMediaToolPromoDialog from 'src/app/partnerarea/pos/shop/socialMediaTool/SocialMediaToolPromoDialog.vue';
import date from '@/date/date';
import { format } from 'date-fns/format';
import { isEqual } from 'date-fns/isEqual';
import SpreadshopLogo from '@/logos/SpreadshopLogo.vue';
import localeService from '@/localeService/localeService';

export default {
  name: 'ShopPromotionsModule',
  components: {
    LoadingHeart,
    SpreadshopLogo,
  },
  data() {
    return {
      promotions: null,
      promotionMonths: null,
    };
  },
  computed: {
    ...mapGetters({
      hasShops: 'user/hasShops',
    }),
    ...mapState({
      shops: (s) => s.user.shops,
    }),
    shopsToDisplay() {
      // fall back to inactive shop if no active shop is available
      const activeShops = this.shops.filter((shop) => !shop.hidden);
      return activeShops.length ? activeShops : this.shops;
    },
    display() {
      return this.hasShops;
    },
  },
  watch: {
    shopsToDisplay: {
      handler(newValue) {
        if (newValue?.length) {
          this.fetchShopPromoData();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    formatTimeframe(start, end) {
      const dot = ['de', 'fi', 'nl', 'no'].includes(localeService.getLanguage())
        ? '.'
        : '';

      const timeframe = `${date(start, 'd')}${dot}`;

      if (!isEqual(parseISO(start), parseISO(end))) {
        return `${timeframe}-${date(end, 'd')}${dot}`;
      } else {
        return timeframe;
      }
    },
    async fetchShopPromoData() {
      const data = await Promise.all(
        this.shopsToDisplay.map((shop) =>
          shopService.getPromotionsForShop(shop.id),
        ),
      );

      const promotions = data
        .reduce((prev, current, index) => {
          const promotionsWithShopRef = current.promotionsData.promotions.map(
            (promotion) => ({
              ...promotion,
              active: current.promotionsActive && promotion.active,
              shopId: this.shopsToDisplay[index].id,
              shopName: this.shopsToDisplay[index].shopName,
              startDate: parseISO(promotion.start),
              endDate: parseISO(promotion.end),
              formattedTimeframe: this.formatTimeframe(
                promotion.start,
                promotion.end,
              ),
              formattedTimeframeIncludingMonth: `${date(
                promotion.start,
                'shortDateWithoutYear',
              )} - ${date(promotion.end, 'shortDateWithoutYear')}`,
            }),
          );
          return [...prev, ...promotionsWithShopRef];
        }, [])
        .filter((promotion) => !isPast(promotion.endDate) && promotion.active)
        .sort((a, b) => a.startDate - b.startDate);

      const promotionsByMonth = promotions.reduce((prev, current) => {
        const month = format(current.startDate, 'yyyy-MM');
        return {
          ...prev,
          [month]: [...(prev[month] || []), current],
        };
      }, {});

      this.promotionMonths = Object.entries(promotionsByMonth).reduce(
        (result, [month, promotions]) => [
          ...result,
          {
            month: {
              key: month,
              formatted: date(month, 'LLLL yyyy'),
            },
            promotions,
          },
        ],
        [],
      );
    },
    openPromoDialog() {
      dialogService.openDialog(SocialMediaToolPromoDialog, null, {
        fullSize: true,
        displayClose: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/mixins';
@import 'src/scss/styleguide/type';
@import 'src/scss/utils';

.shop-promos {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  position: relative;
  padding: 24px;
}

.header {
  display: flex;
  align-items: center;
  padding: 0 24px 16px 0;

  h3 {
    display: block;
    margin: 0 16px 0 0;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.days {
  width: 90px;
  flex-shrink: 0;

  @include small-desktop {
    width: 78px;
  }
}

.text {
  margin-right: 24px;
}

.promo-list {
  padding-top: 24px;
  flex-grow: 1;
  position: relative;

  & > div {
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-right: 24px;
  }
}

.month:not(:last-child) {
  margin-bottom: 12px;
}

.month-label {
  border-bottom: 1px solid $grey5;
  padding-bottom: 8px;
}

.promo {
  padding: 12px;
  border-radius: $border-radius-medium;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    overflow: hidden;
  }

  h2 {
    padding: 0;
    margin-right: 16px;
  }

  p {
    margin: 0;
  }

  .promo-text {
    font-weight: bold;
    padding: 0 0 4px 0;
  }

  .shop {
    @extend .text-truncate;
    padding: 0;
    color: $grey60;
  }

  .active {
    background-color: $grey5;
    border-radius: $border-radius-medium;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    .active-indicator {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $pa-color-red;
    }

    p {
      margin: 0 0 0 8px;
    }
  }

  .actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    a {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;

      .icon {
        width: 20px;
        height: 20px;
      }

      &:hover {
        background-color: $pa-color-main;

        .icon {
          color: $white;
        }
      }
    }
  }

  &:hover {
    background-color: $grey5;

    .active {
      background-color: $white;
    }
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 100px;
    margin: 24px 0;
  }

  p {
    text-align: center;
  }
}

.logo {
  position: absolute;
  top: 24px;
  right: 24px;
  height: 12px;
}
</style>
