import localeService from '@/localeService/localeService';
import { PUBLISHING_SCOPE } from '@/publishing/publishingScope';

const domain = localeService.getSprdUrl();

const contactForm = {
  MP: {
    EU: {
      default: `${domain}/-C1336`,
    },
    NA: {
      default: `${domain}/-C1336`,
    },
  },
  SHOP: {
    EU: {
      de: 'https://www.spreadshop.de/kontakt/',
      fr: 'https://www.spreadshop.fr/contact/',
      default: 'https://www.spreadshop.net/contact/',
    },
    NA: {
      default: 'https://www.spreadshop.com/contact/',
    },
  },
};

const mpMailByPlatform = {
  EU: 'partner@spreadshirt.net',
  NA: 'partner@spreadshirt.com',
};

const shopMailByPlatform = {
  EU: 'service@spreadshop.com',
  NA: 'help@spreadshop.com',
};

export default {
  getMpMail() {
    return mpMailByPlatform[SPRD.PLATFORM];
  },
  getShopMail() {
    return shopMailByPlatform[SPRD.PLATFORM];
  },
  getMailForPublishingScope(scope) {
    if (scope?.key === PUBLISHING_SCOPE.SHOP.key) {
      return this.getShopMail();
    } else {
      return this.getMpMail();
    }
  },
  getMpContactForm() {
    return (
      contactForm.MP[SPRD.PLATFORM][localeService.getLanguage()] ||
      contactForm.MP[SPRD.PLATFORM].default
    );
  },
  getShopContactForm() {
    return (
      contactForm.SHOP[SPRD.PLATFORM][localeService.getLanguage()] ||
      contactForm.SHOP[SPRD.PLATFORM].default
    );
  },
  getBestMailForPartnerType(partnerType) {
    if (partnerType === 'SHOP') {
      return this.getShopMail();
    } else {
      return this.getMpMail();
    }
  },
  getBestContactFormForPartnerType(partnerType) {
    if (partnerType === 'SHOP') {
      return this.getShopContactForm();
    } else {
      return this.getMpContactForm();
    }
  },
};
