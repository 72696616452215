<template>
  <div class="pdv-failed-msg">
    <Icon class="main-icon" icon="sad"></Icon>
    <h1 v-html="$t(modeSetting.heading)"></h1>
    <p class="subheading" v-html="$t('PDV.FAILED.SUBHEADING')"></p>

    <div class="email-contact">
      <div class="icon-outer-wrapper">
        <div class="icon-wrapper">
          <Icon icon="envelope"></Icon>
        </div>
      </div>

      <a :href="`mailto:${mail}`" class="email">{{ mail }}</a>
      <p class="contact-explanation" v-html="$t('PDV.FAILED.ACTION')"></p>

      <strong v-html="$t('PDV.FAILED.LAST_ADDRESS')"></strong>
      <p class="previous-address">
        {{ addressData.street }}, {{ addressData.postalCode }}
        {{ addressData.city }}, {{ addressData.country }}
      </p>
    </div>

    <p class="or" v-html="$t('GENERAL.OR')"></p>

    <div class="contact">
      <img src="/images/customer-service.svg" />
      <div class="contact-form">
        <a :href="contactForm" target="_blank" class="link-main">{{
          $t('HELP.CONTACT_FORM.HEADING')
        }}</a>
        <p>{{ $t('HELP.CONTACT_FORM.EXPLANATION') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import contactData from '@/contactData/contactData';

const MODES = {
  DEFAULT: 'DEFAULT',
  DESIGN: 'DESIGN',
  SHOP: 'SHOP',
};

const MODE_SETTINGS = {
  DEFAULT: {
    heading: 'PDV.FAILED.HEADING',
  },
  DESIGN: {
    heading: 'PDV.FAILED.HEADING_DESIGN',
  },
  SHOP: {
    heading: 'PDV.FAILED.HEADING_SHOP',
  },
};

export default {
  name: 'PdvFailedMsg',
  props: {
    address: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: MODES.DEFAULT,
    },
  },
  data() {
    return {
      modeSetting: null,
    };
  },
  computed: {
    ...mapGetters({
      partnerType: 'user/partnerType',
    }),
    addressData() {
      if (!this.address.houseNumber) {
        return this.address;
      } else {
        if (['US', 'FR', 'GB'].includes(this.address.countryCode)) {
          return {
            ...this.address,
            street: `${this.address.houseNumber} ${this.address.street}`,
          };
        } else {
          return {
            ...this.address,
            street: `${this.address.street} ${this.address.houseNumber}`,
          };
        }
      }
    },
    mail() {
      return contactData.getMpMail();
    },
    contactForm() {
      return contactData.getBestContactFormForPartnerType(this.partnerType);
    },
  },
  created() {
    this.modeSetting = MODE_SETTINGS[this.mode];
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/_type.scss';

.pdv-failed-msg {
  display: flex;
  flex-direction: column;
  align-items: center;

  .main-icon {
    height: 50px;
    width: 50px;
    color: $pa-color-red;
  }

  h1 {
    color: $pa-color-red;
    text-align: center;
  }

  & > p {
    text-align: center;
  }

  .email-contact {
    border: 2px solid $pa-color-main;
    padding: 40px 15px 15px 15px;
    position: relative;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon-outer-wrapper {
      position: absolute;
      top: -30px;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;

      .icon-wrapper {
        border-radius: 100%;
        background-color: lighten($pa-color-main, 40%);
        color: $pa-color-main;
        padding: 15px;

        .icon {
          width: 35px;
          height: 35px;
        }
      }
    }

    .email {
      @extend .text-xxl;
      color: $pa-color-main;
    }

    .contact-explanation {
      margin: 15px 0 20px 0;
      text-align: center;
    }

    .previous-address {
      margin: 0;
    }
  }

  .or {
    color: $sprd-color-grey;
    margin: 15px 0;
    @extend .text-xxl;
  }

  .contact {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    img {
      width: 60px;
    }

    .contact-form {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;

      p {
        color: $grey60;
        @extend .text-sm;
        margin: 0;
      }
    }
  }
}
</style>
