<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="overview" :class="{ 'overflow-y': !isEmptyList }">
        <div>
          <!-- Header -->
          <PublishingListHeader
            v-if="totalCompositionCount"
            @change="getCompositions"
            @create="create"
          />

          <!-- Loading State -->
          <LoadingHeart v-if="!initialized" class="loading-heart-container" />

          <!-- Empty State -->
          <PublishingEmptyList v-else-if="isEmptyList" @create="create" />

          <!-- Composition List -->
          <div v-else>
            <PublishingEmptySearchResult v-if="unsuccessfulSearch" />
            <div class="list">
              <ul>
                <li v-for="composition in compositions" :key="composition.id">
                  <PublishingTile
                    :composition="composition"
                    display-pos-status
                    :relevant-pos-types="relevantPosTypes"
                    @edit="editComposition(composition.id)"
                  />
                </li>
              </ul>
            </div>

            <!-- Pagination -->
            <div class="pagination-wrapper">
              <Pagination
                id="design-pagination"
                :total="totalCompositionCount"
                :page-size="pageSize"
                :current-page="page"
                @page-change="onPageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { onMounted, ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { COMPOSITION_POS_TYPES } from '@/api/models/publishingCore/compositionPosStates';
import dialogService from '@/dialogs/wrapper/dialogService';
import PublishCreateDialog from '../create/PublishingCreateDialog.vue';
import PublishingTile from './PublishingTile.vue';
import Pagination from '@/pagination/Pagination.vue';
import LoadingHeart from '@/LoadingHeart/LoadingHeart.vue';
import PublishingListHeader from './PublishingListHeader.vue';
import PublishingEmptySearchResult from './PublishingEmptySearchResult.vue';
import PublishingEmptyList from 'src/app/partnerarea/publishing/list/PublishingEmptyList.vue';

const store = useStore();
const router = useRouter();
const currentRoute = useRoute();

const initialized = ref(false);

const isShopScope = computed(() => store.getters['publishing/isShopScope']);
const totalCompositionCount = computed(
  () => store.getters['compositions/totalCompositionCount'],
);
const unsuccessfulSearch = computed(
  () => store.getters['compositions/unsuccessfulSearch'],
);
const searched = computed(() => store.getters['compositions/searched']);
const isEmptyList = computed(
  () => !totalCompositionCount.value && !searched.value,
);

const currentScope = computed(() => store.state.publishing.currentScope);
const compositions = computed(() => store.state.compositions.list);
const page = computed(() => store.state.compositions.filter.page);
const pageSize = computed(() => store.state.compositions.filter.pageSize);

const relevantPosTypes = computed(() => {
  if (isShopScope.value) {
    return [COMPOSITION_POS_TYPES.SHOP];
  } else {
    return [COMPOSITION_POS_TYPES.MP];
  }
});

const getCompositions = async () => {
  initialized.value = false;
  await store.dispatch('compositions/fetchCompositions');
  initialized.value = true;
};

const create = async () => {
  await dialogService.openDialog(PublishCreateDialog).then(() => {
    store.commit('compositions/resetFilter');
    store.dispatch('compositions/fetchCompositions');
  });
};

const editComposition = (compositionId) => {
  router.push({
    name: currentScope.value?.routes?.detailPage,
    params: { compositionId },
  });
};

const onPageChange = (page) => {
  store.commit('compositions/setFilter', { page });
  getCompositions();
};

const updateUrlParams = () => {
  router
    .replace({
      query: null,
    })
    .catch(() => {});
};

const initializeCompositions = async () => {
  const restoreState =
    currentRoute.query.restoreState && compositions.value.length;

  if (!restoreState) {
    store.commit('compositions/resetFilter');
    await getCompositions();
  } else {
    updateUrlParams();
  }
  initialized.value = true;
};

onMounted(async () => {
  await store.state.publishingCore.onDataLoaded;
  await initializeCompositions();
});
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.overview > div {
  padding: 24px;
  width: 100%;
}

ul {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 24px;
  padding: 0 0 24px 0;
  margin: 0;
}

.pagination-wrapper {
  padding: 0 0 24px 0;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin: 0 0 16px 0;
  }

  p {
    margin: 0 0 24px 0;
    color: $grey65;
  }

  & > div {
    max-width: 700px;
  }
}

.loading-heart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
}
</style>
