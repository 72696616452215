import store from '@/store/Store';
import apiClient from '@/api/client/apiClient';
import localeService from '@/localeService/localeService';

export async function checkTerm({ field, terms, locale }) {
  if (!terms) {
    return null;
  }
  const data = Array.isArray(terms) ? terms : [terms];
  if (data.length === 0) {
    return null;
  } else {
    return apiClient
      .request({
        method: 'POST',
        url: `/api/v1/users/${store.state.user.data.id}/legalcheck`,
        data: {
          list: [
            {
              locale: localeService.convertToSprdLanguage(
                locale || store.state.user.locale,
              ),
              field: field === 'tags' ? 'tag' : field,
              terms: data,
            },
          ],
        },
      })
      .then((res) => res.data);
  }
}
