<template>
  <div :class="`register-page login shop overflow-y`">
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0, viewport-fit=cover"
    />
    <CentralAuth
      mode="shop"
      template="CLASSIC"
      :additional-context="ctxParam"
    />
  </div>
</template>

<script>
import CentralAuth from 'src/app/components/centralAuth/CentralAuth.vue';

export default {
  name: 'ShopRegister',
  components: {
    CentralAuth,
  },
  data: function () {
    return {
      ctxParam: this.$route.params.ctx || 'spread-app',
    };
  },
};
</script>

<style lang="scss">
#application.state-partnerarea-shopregistermobile {
  min-width: 0;
  background-color: #fff;
  overflow-y: initial;

  #main-content {
    overflow: initial;
  }

  .help-panel-wrapper {
    display: none;
  }
}
</style>
