<template>
  <div class="loading-bar-section">
    <Icon :icon="iconName" :class="loadingIconStyling" />
    <div class="progress-bar-text">{{ loadingMessage }}</div>
    <LoadingBar :execute-fn="executeLoading" />
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import LoadingBar from 'src/app/components/loadingBar/LoadingBar.vue';
import { delay } from '@/utils';
import { useStore } from 'vuex';

const store = useStore();
const composition = computed(() => store.state.composition.current);
const isLoaded = ref(false);

const props = defineProps({
  time: {
    type: Number,
    default: 1000,
  },
});

const emit = defineEmits(['loadingDone']);

const loadingMessage = computed(() =>
  isLoaded.value
    ? `${composition.value.sellables.length} products created`
    : 'Creating Products',
);
const iconName = computed(() => (isLoaded.value ? 'checkmark' : 'hourglass'));
const loadingIconStyling = computed(() => ({
  'hourglass-icon': isLoaded.value === false,
  'checkmark-icon': isLoaded.value === true,
}));

const executeLoading = async () => {
  await delay(props.time);
  isLoaded.value = true;
};

watch(isLoaded, async () => {
  await delay(1500);
  emit('loadingDone');
});
</script>

<style scoped lang="scss">
.loading-bar-section {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .progress-bar-text {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 16px;
  }

  .loading-bar {
    width: 90%;
    height: 16px;
  }
}

.icon {
  &.hourglass-icon {
    width: 24px;
    height: 24px;
    margin-bottom: 16px;
  }

  &.checkmark-icon {
    width: 32px;
    height: 32px;
    margin-bottom: 8px;
  }
}
</style>
