<template>
  <div class="tracking-metrics">
    <div class="tracking-metrics-content">
      <div v-if="!hasError" class="tracking-metrics-heading">
        <h2>{{ $t('STATISTICS.TRACKING_METRICS.HEADING') }}</h2>
        <TrackingMetricsFilter @filter-update="updateFilters" />
      </div>
      <div v-if="shopMetrics" class="tracking-metrics-body">
        <div v-if="shopMetrics.length">
          <p class="text-grey">
            {{ $t('STATISTICS.TRACKING_METRICS.VISITS_DESCRIPTION') }}
          </p>
          <h3></h3>
          <TrackingMetricsGraph />
          <FewMetricsHint v-if="belowThreshold" />
          <GoogleAnalyticsHint v-else :shop="currentPos" />
        </div>
        <ActivateShop
          v-else-if="currentShopHidden"
          class="empty-metrics"
          :shop="currentPos"
        />
        <EmptyMetrics v-else class="empty-metrics" />
      </div>
      <TrackingMetricsError v-else-if="hasError" />
      <div v-else class="loading-wrapper">
        <LoadingHeart />
      </div>
    </div>
  </div>
</template>

<script>
import TrackingMetricsFilter from './TrackingMetricsFilter.vue';
import TrackingMetricsGraph from './chart/TrackingMetricsGraph.vue';
import ActivateShop from './chart/ActivateShop.vue';
import EmptyMetrics from './chart/EmptyMetrics.vue';
import LoadingHeart from '@/LoadingHeart/LoadingHeart.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import TrackingMetricsError from './TrackingMetricsError.vue';
import FewMetricsHint from './hints/FewMetricsHint.vue';
import GoogleAnalyticsHint from './hints/GoogleAnalyticsHint.vue';

export default {
  name: 'TrackingMetrics',
  components: {
    TrackingMetricsFilter,
    TrackingMetricsGraph,
    ActivateShop,
    EmptyMetrics,
    LoadingHeart,
    TrackingMetricsError,
    FewMetricsHint,
    GoogleAnalyticsHint,
  },
  computed: {
    ...mapState({
      userShops: (s) => s.user.shops,
      filters: (s) => s.metrics.filters,
      hasError: (s) => s.metrics.hasError,
    }),
    ...mapGetters({
      shopMetrics: 'metrics/shopMetrics',
      totalVisits: 'metrics/totalVisits',
    }),
    currentPos() {
      return this.filters.pos.value;
    },
    currentShopHidden() {
      return this.userShops.find((shop) => shop.id === this.currentPos)?.hidden;
    },
    belowThreshold() {
      // check if the amount of traffic metrics entries is atleast the amount of days selected
      return this.totalVisits < this.filters.time.duration;
    },
  },
  methods: {
    ...mapActions({
      fetchTrackingMetrics: 'metrics/fetchTrackingMetrics',
    }),
    ...mapMutations({
      setFilters: 'metrics/setFilters',
    }),
    async updateFilters(filters) {
      this.setFilters(filters);
      await this.fetchTrackingMetrics();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';

.tracking-metrics {
  width: 100%;
  margin: 0 auto;
}

.tracking-metrics-content {
  max-width: 1400px;
  transition: margin-top 0.15s linear;

  & > div {
    padding-right: 24px;
    padding-left: 24px;
    background-color: $white;
  }

  h2,
  h3 {
    margin: 0;
  }
}

.tracking-metrics-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.tracking-metrics-body {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.visits-filter {
  display: flex;

  & > button {
    width: 30%;
    border: 1px solid $grey20;
    border-radius: $border-radius-medium;
    min-width: 285px;
    padding: 16px;

    & + button {
      margin-left: 16px;
    }

    &.selected {
      border-color: $pa-color-main;
      box-shadow: 0 0 0 1px $pa-color-main;
    }

    h2,
    h3 {
      color: $grey80;
    }

    p {
      margin: 8px 0 24px;
    }
  }
}

.loading-wrapper {
  padding: 48px;
}

.empty-metrics {
  padding-block: 24px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
