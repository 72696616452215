<template>
  <div>
    <ToggleSwitch
      class="sprd-toggle-switch--first bold"
      label="SA.designer.active"
      :model-value="settings.designer"
      @update:model-value="toggleDesigner"
    />

    <p class="subTitle" :class="{ disabledText: !settings.listPage }">
      {{ $t('POS.SHOP_SETTINGS.DESIGNER.OPTIONS.LABEL') }}
    </p>

    <p class="paragraph" :class="{ disabledText: !settings.listPage }">
      {{ $t('POS.SHOP_SETTINGS.DESIGNER.OPTIONS.DESCRIPTION') }}
    </p>

    <ToggleSwitch
      label="SA.designer.uploadImages"
      :model-value="settings.tablomatConfiguration.showDesignUpload"
      :disabled="!settings.designer"
      @update:model-value="toggleOther('showDesignUpload', $event)"
    />

    <ToggleSwitch
      label="SA.designer.editText"
      :model-value="settings.tablomatConfiguration.showAddText"
      :disabled="!settings.designer"
      @update:model-value="toggleOther('showAddText', $event)"
    />

    <ToggleSwitch
      label="SA.designer.marketplaceDesigns"
      :model-value="settings.tablomatConfiguration.showMarketplaceDesigns"
      :disabled="!settings.designer"
      @update:model-value="toggleOther('showMarketplaceDesigns', $event)"
    />
  </div>
</template>

<script>
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';

export default {
  name: 'ShopCyoSettings',
  components: {
    ToggleSwitch,
  },
  mixins: [SettingsData],
  created: function () {
    this.$store.commit('shop/setLivePreview', { path: 'create' });
  },
  methods: {
    toggleDesigner(isActive) {
      this.$store.commit('shop/updateSectionData', {
        key: 'settings',
        data: {
          designer: isActive,
          tablomatConfiguration: {
            showMarketplaceDesigns: false,
            showDesignUpload: isActive,
            showAddText: isActive,
          },
        },
      });
    },
    toggleOther(key, value) {
      const tablomatConfiguration = Object.assign(
        {},
        this.settings.tablomatConfiguration,
      );
      tablomatConfiguration[key] = value;
      this.$store.commit('shop/updateSectionData', {
        key: 'settings',
        data: {
          tablomatConfiguration,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.paragraph {
  padding-bottom: 4px;
}
</style>
