<template>
  <div class="colorPicker">
    <div class="ui">
      <div
        ref="variations"
        class="variations"
        :style="variationStyle"
        @mousedown="startDragVariation"
      >
        <div
          v-if="colorCursorStyle"
          class="variationCursor"
          :style="colorCursorStyle"
        ></div>
      </div>
      <div ref="hues" class="hues" @mousedown="startDragHue">
        <div
          v-if="hueCursorStyle"
          class="hueCursor"
          :style="hueCursorStyle"
        ></div>
      </div>
    </div>
    <div class="form">
      <ValidLabelInput
        class="input"
        maxlength="7"
        label="HEX"
        :disabled="disabled"
        :model-value="modelValue"
        :validators="validateColor"
        @update:model-value="$emit('update:modelValue', $event)"
      />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { getLengthValidator } from './validators/lengthValidator';
import ValidLabelInput from './ValidLabelInput.vue';

export default {
  name: 'ColorPicker',
  components: {
    ValidLabelInput,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data: function () {
    return {
      hsv: hexToHsv(this.modelValue),
      validateColor: [
        getLengthValidator({ min: 4, max: 7 }),
        hexColorValidator,
      ],
    };
  },
  computed: {
    variationStyle: function () {
      return {
        backgroundColor: hsvToHex(this.hsv.h, 1, 1),
      };
    },
    colorCursorStyle: function () {
      if (this.disabled) {
        return null;
      }

      return {
        left: `${this.hsv.s * 100}%`,
        top: `${(1 - this.hsv.v) * 100}%`,
      };
    },
    hueCursorStyle: function () {
      return {
        left: `${this.hsv.h * 100}%`,
      };
    },
  },
  watch: {
    modelValue: function (newValue) {
      const newHsv = hexToHsv(newValue);

      if (newHsv.s === 0 || newHsv.v === 0) {
        // keep the hue if the new color is black/white/grey or it defaults to red
        newHsv.h = this.hsv.h;
      }

      this.hsv = newHsv;
    },
  },
  methods: {
    startDragHue: function (event) {
      this.updateProperties = ['h'];
      this.startDrag(this.$refs.hues, event);
    },
    startDragVariation: function (event) {
      this.updateProperties = ['s', 'v'];
      this.startDrag(this.$refs.variations, event);
    },
    startDrag: function (target, event) {
      if (this.disabled) {
        return;
      }

      const rect = target.getBoundingClientRect();
      this.dimensions = {
        x: rect.left,
        y: rect.top,
        width: rect.right - rect.left,
        height: rect.bottom - rect.top,
      };

      window.addEventListener('mousemove', this.drag);
      window.addEventListener('touchmove', this.drag);
      window.addEventListener('mouseup', this.endDrag);

      this.drag(event);
    },
    endDrag: function () {
      window.removeEventListener('mousemove', this.drag);
      window.removeEventListener('touchmove', this.drag);
      window.removeEventListener('mouseup', this.endDrag);
    },
    drag: function (event) {
      event.preventDefault();

      const hsv = this.getHsvFromDragPosition(
        event.targetTouches ? event.targetTouches[0] : event,
      );

      this.updateProperties.forEach(
        (property) => (this.hsv[property] = hsv[property]),
      );

      this.$emit('update:modelValue', hsvToHex(this.hsv));
    },
    getHsvFromDragPosition: function (dragEvent) {
      const x = Math.max(
        Math.min(dragEvent.clientX - this.dimensions.x, this.dimensions.width),
        0,
      );
      const y = Math.max(
        Math.min(dragEvent.clientY - this.dimensions.y, this.dimensions.height),
        0,
      );

      const horizontalPercentage = x / this.dimensions.width;
      const verticalPercentage = y / this.dimensions.height;

      return {
        h: horizontalPercentage,
        s: horizontalPercentage,
        v: 1 - verticalPercentage,
      };
    },
  },
};

export function hexToRgb(hexColor) {
  const tokens = (function () {
    if (hexColor.length === 4) {
      return hexColor
        .split('')
        .slice(1)
        .map((hex) => hex + hex);
    }

    if (hexColor.length === 7) {
      return hexColor.split(/(\w{2})/).filter((_, i) => i % 2);
    }

    // fallback for invalid colors
    return ['00', '00', '00'];
  })();

  return tokens.map((hex) => parseInt(hex, 16) / 255); // Normalize to 1
}

function hexColorValidator(value) {
  const isHex =
    (value.length === 4 && /#[\da-f]{3}/i.test(value)) ||
    (value.length === 7 && /#[\da-f]{6}/i.test(value));
  if (!isHex) {
    throw new Error(this.$t('SA.shared.form.error.pattern'));
  }
}

function hsvToHex(h, s, v) {
  if (typeof h === 'object') {
    s = h.s;
    v = h.v;
    h = h.h;
  }

  let i = Math.floor(h * 6),
    f = h * 6 - i,
    p = v * (1 - s),
    q = v * (1 - f * s),
    t = v * (1 - (1 - f) * s);

  let r, g, b;

  switch (i % 6) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    case 5:
      r = v;
      g = p;
      b = q;
      break;
  }

  r = Math.floor(r * 255) + 256;
  g = Math.floor(g * 255) + 256;
  b = Math.floor(b * 255) + 256;

  return (
    '#' +
    r.toString(16).slice(1) +
    g.toString(16).slice(1) +
    b.toString(16).slice(1)
  );
}

function hexToHsv(hexColor) {
  const rgb = hexToRgb(hexColor);

  let r = rgb[0],
    g = rgb[1],
    b = rgb[2],
    h,
    s,
    v = Math.max(r, g, b),
    diff = v - Math.min(r, g, b),
    diffc = function (c) {
      return (v - c) / 6 / diff + 1 / 2;
    };

  if (diff === 0) {
    h = s = 0;
  } else {
    s = diff / v;

    let rr = diffc(r),
      gg = diffc(g),
      bb = diffc(b);

    if (r === v) {
      h = bb - gg;
    } else if (g === v) {
      h = 1 / 3 + rr - bb;
    } else if (b === v) {
      h = 2 / 3 + gg - rr;
    }

    if (h < 0) {
      h += 1;
    } else if (h > 1) {
      h -= 1;
    }
  }

  return {
    h: h,
    s: s,
    v: v,
  };
}
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.colorPicker {
  width: 100%;
}

.form {
  margin-top: 1.25em;
}

.variations {
  flex: 1;
  position: relative;
  border: 1px solid $sprd-color-light-grey;
  height: 200px;
  transition: background-color 250ms;
  background-image: linear-gradient(to bottom, transparent 0, #000 100%),
    linear-gradient(to right, #fff 0, transparent 100%);
  border-radius: 0.25em;
}

.variationCursor {
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  margin: -0.75em 0 0 -0.75em;
  background: radial-gradient(circle at center, #fff 1px, transparent 1px);
  border: 2px solid #fff;
  border-radius: 50%;
}

.hues {
  border: 1px solid $sprd-color-light-grey;
  position: relative;
  height: 1.5em;
  margin-top: 6px;
  background: linear-gradient(
    to right,
    #ff0000 0%,
    #ffff00 17%,
    #00ff00 33%,
    #00ffff 50%,
    #0000ff 67%,
    #ff00ff 83%,
    #ff0000 100%
  );
  border-radius: 0.25em;
}

.hueCursor {
  position: absolute;
  top: 0.25em;
  width: 1em;
  height: 1em;
  margin: -1px 0 0 -0.5em;
  border: 2px solid #fff;
  border-radius: 50%;
}
</style>
