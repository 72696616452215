<template>
  <div class="dialog-size-md mfa-setup-dialog">
    <div class="modal-body">
      <MfaSetup
        @on-done="modalInstance.close"
        @on-cancel="modalInstance.dismiss"
      />
    </div>
  </div>
</template>

<script>
import MfaSetup from './MfaSetup.vue';

export default {
  name: 'MfaSetupDialog',
  components: {
    MfaSetup,
  },
  props: ['modalInstance'],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
</style>
