import router from 'src/app/router/router';

export default {
  namespaced: true,
  state: {
    initialized: false,
  },
  getters: {
    ideaPublished: (state, getters, rootState) => {
      return rootState.ideas.hasAtLeastOneIdeaWithIntent;
    },
    mpProductPublished: (state, getters, rootState, rootGetters) => {
      if (rootGetters['settings/newPublishingJourneyAvailabilityMp']) {
        return rootGetters['publishingCore/hasMpIntent'];
      }

      if (rootGetters['settings/oldPublishingJourneyAvailabilityMp']) {
        return getters.ideaPublished;
      }
    },
    shopProductPublished: (state, getters, rootState, rootGetters) => {
      if (rootGetters['settings/newPublishingJourneyAvailabilityShop']) {
        return rootGetters['publishingCore/hasShopIntent'];
      }

      if (rootGetters['settings/oldPublishingJourneyAvailabilityShop']) {
        return getters.ideaPublished;
      }
    },
    atLeastOneShopConfigured: (state, getters, rootState) => {
      return rootState.user.shops.some((shop) => shop.setupWizardDone);
    },
    atLeastOneShopLaunched: (state, getters, rootState) => {
      return rootState.user.shops.some((shop) => !shop.hidden);
    },
    firstNotConfiguredShop: (state, getters, rootState) => {
      return rootState.user.shops.find((shop) => !shop.setupWizardDone);
    },
    firstOfflineShop: (state, getters, rootState) => {
      return rootState.user.shops.find((shop) => shop.hidden);
    },
    showroomConfigured: (state, getters, rootState, rootGetters) => {
      return rootGetters['identity/usernameSetup'];
    },
    steps: (state, getters, rootState, rootGetters) => {
      return state.initialized
        ? [
            {
              key: 'CREATE_PRODUCT',
              name: 'ONBOARD.CHECKLIST.STEP.CREATE_PRODUCT',
              done: getters.ideaPublished,
              onClick: () => router.push({ name: 'partnerarea.ideas' }),
              scope: ['SHOP', 'MP', 'BOTH'],
              condition: () =>
                rootGetters['settings/oldPublishingJourneyAvailabilityBoth'],
            },
            {
              key: 'CREATE_PRODUCT_MP',
              name: 'ONBOARD.CHECKLIST.STEP.CREATE_PRODUCT_MP',
              done: getters.mpProductPublished,
              onClick: () =>
                rootGetters['settings/newPublishingJourneyAvailabilityMp']
                  ? router.push({ name: 'partnerarea.mp.publishing' })
                  : router.push({ name: 'partnerarea.ideas' }),
              scope: ['MP', 'BOTH'],
              condition: () =>
                !rootGetters['settings/oldPublishingJourneyAvailabilityBoth'],
            },
            {
              key: 'CREATE_PRODUCT_SHOP',
              name: 'ONBOARD.CHECKLIST.STEP.CREATE_PRODUCT_SHOP',
              done: getters.shopProductPublished,
              onClick: () =>
                rootGetters['settings/newPublishingJourneyAvailabilityShop']
                  ? router.push({ name: 'partnerarea.shop.publishing' })
                  : router.push({ name: 'partnerarea.ideas' }),
              scope: ['SHOP', 'BOTH'],
              condition: () =>
                !rootGetters['settings/oldPublishingJourneyAvailabilityBoth'],
            },
            {
              key: 'SETUP_SHOWROOM',
              name: 'ONBOARD.CHECKLIST.STEP.SETUP_SHOWROOM',
              done: getters.showroomConfigured,
              onClick: () =>
                router.push({
                  name: 'partnerarea.account',
                  params: { subpage: 'personal' },
                  query: {
                    editProfile: true,
                  },
                }),
              scope: ['MP', 'BOTH'],
            },
            {
              key: 'SETUP_SHOP',
              name: 'ONBOARD.CHECKLIST.STEP.SETUP_SHOP',
              done: getters.atLeastOneShopConfigured,
              onClick: () =>
                router.push({
                  name: 'shop-area',
                  params: {
                    shopId: getters.firstNotConfiguredShop.id,
                    reload: true,
                  },
                }),
              scope: ['SHOP', 'BOTH'],
            },
            {
              key: 'LAUNCH_SHOP',
              name: 'ONBOARD.CHECKLIST.STEP.LAUNCH_SHOP',
              done: getters.atLeastOneShopLaunched,
              onClick: () =>
                router.push({
                  name: 'shop-area.general.shop-status',
                  params: {
                    shopId: getters.firstOfflineShop.id,
                    reload: true,
                  },
                }),
              scope: ['SHOP', 'BOTH'],
            },
          ]
        : null;
    },
    relevantSteps: (state, getters, rootState, rootGetters) => {
      return getters.steps
        ? getters.steps.filter(
            (step) =>
              step.scope.includes(rootGetters['user/partnerType']) &&
              (!step.condition || step.condition()),
          )
        : null;
    },
    finishedRelevantSteps: (state, getters, rootState, rootGetters) => ({
      scope: rootGetters['user/partnerType'],
      steps: getters.relevantSteps
        ? getters.relevantSteps.filter((step) => step.done)
        : null,
    }),
    unfinishedRelevantSteps: (state, getters, rootState, rootGetters) => ({
      scope: rootGetters['user/partnerType'],
      steps: getters.relevantSteps
        ? getters.relevantSteps.filter((step) => !step.done)
        : null,
    }),
    firstUnfinishedRelevantStep: (state, getters) => {
      return getters.relevantSteps
        ? getters.relevantSteps.find((step) => !step.done)
        : null;
    },
    finished: (state, getters) => {
      return (
        !getters.unfinishedRelevantSteps.steps ||
        !getters.unfinishedRelevantSteps.steps.length
      );
    },
  },
  mutations: {
    setInitialized(state, value) {
      state.initialized = value;
    },
  },
  actions: {
    async initialize({ commit }) {
      commit('setInitialized', true);
    },
  },
};
