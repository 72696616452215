import compositionHelper from '@/composition/compositionHelper';

function checkMinAndMax(value, options) {
  let valid = true;
  if (options.min || options.min === 0) {
    valid = valid && value >= options.min;
  }
  if (options.max || options.max === 0) {
    valid = valid && value <= options.max;
  }

  return valid;
}

const _defaultOptions = {
  tags: {
    min: 3,
    max: 25,
    maxlength: 50,
  },
  name: {
    min: 1,
    max: 50,
  },
  description: {
    max: 200,
  },
};

export default {
  getDefaultOptions: function () {
    return _defaultOptions;
  },
  validateTags(composition, options = {}) {
    options = { ..._defaultOptions.tags, ...options };

    const tagsMetaData = compositionHelper.getMetaDataPart({
      composition,
      part: 'tags',
      language: options.language,
    });

    if (!tagsMetaData || tagsMetaData.autoTranslated) {
      return true;
    }

    return checkMinAndMax(tagsMetaData.values?.length, options);
  },
  validateName(composition, options) {
    options = { ..._defaultOptions.name, ...options };

    const nameMetaData = compositionHelper.getMetaDataPart({
      composition,
      part: 'name',
      language: options.language,
    });

    if (!nameMetaData || nameMetaData.autoTranslated) {
      return true;
    }

    return checkMinAndMax(nameMetaData.value?.length, options);
  },
  validateDescription(composition, options) {
    options = { ..._defaultOptions.description, ...options };
    const descriptionMetaData = compositionHelper.getMetaDataPart({
      composition,
      part: 'description',
      language: options.language,
    });
    if (!descriptionMetaData || descriptionMetaData.autoTranslated) {
      return true;
    }

    return checkMinAndMax(descriptionMetaData.value?.length, options);
  },
  validateLanguage: function (composition, options, language) {
    let valid = true;
    let nameValid = true;
    let tagsValid = true;
    let descriptionValid = true;

    options = options || {};

    nameValid = this.validateName(composition, {
      ...options.name,
      language,
    });
    tagsValid = this.validateTags(composition, { ...options.tags, language });
    descriptionValid = this.validateDescription(composition, {
      ...options.description,
      language,
    });

    valid = tagsValid && nameValid && descriptionValid;

    return {
      result: valid,
      language,
      reasons: {
        tags: !tagsValid,
        name: !nameValid,
        description: !descriptionValid,
      },
    };
  },
  validate: function (composition, options = {}) {
    const languages = composition.metaData.name.map((val) => val.language);
    const languageValidation = languages.map((language) =>
      this.validateLanguage(composition, options, language),
    );

    return {
      result: !languageValidation.some((validation) => !validation.result),
      languages: languageValidation,
    };
  },
};
