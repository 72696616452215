<template>
  <div class="custom-domain-configuration">
    <section>
      <h2>{{ $t('SA.customDomain.configured.title') }}</h2>
      <p>{{ $t('SA.customDomain.configured.description') }}</p>
    </section>
    <section>
      <div class="shop-custom-domain" :class="{ [customDomain.status]: true }">
        <div class="host-information">
          <span class="status" :class="{ [customDomain.status]: true }">
            {{ $t(statusTranslation) }}
          </span>
          <span class="hostname">
            {{ customDomain.hostname }}
          </span>
          <div class="actions">
            <button
              v-if="isIssued"
              class="btn btn-light btn-lg view-records"
              @click="toggleRecordsTable"
            >
              {{ $t('SA.action.viewRecords') }}
            </button>
            <a class="remove" @click="remove">
              {{ $t('GENERAL.REMOVE') }}
            </a>
          </div>
        </div>
        <span v-if="!isIssued" class="status-information">
          {{ $t(statusInformationTranslation) }}
        </span>
      </div>
    </section>
    <section v-if="!isIssued">
      <h3>{{ $t('SA.customDomain.configured.step1.title') }}</h3>
      <p>{{ $t('SA.customDomain.configured.step1.description') }}</p>
    </section>
    <section v-if="!isIssued">
      <h3>{{ $t('SA.customDomain.configured.step2.title') }}</h3>
      <p>
        {{
          $t('SA.customDomain.configured.step2.description', {
            hostname: customDomain.hostname,
          })
        }}
      </p>
    </section>
    <section v-if="!isIssued || showRecordsTable">
      <div class="cname-table">
        <table>
          <thead>
            <tr class="cname-table-header">
              <th>{{ $t('SA.customDomain.configured.table.type') }}</th>
              <th>{{ $t('SA.customDomain.configured.table.value') }}</th>
              <th>{{ $t('SA.customDomain.configured.table.target') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CNAME</td>
              <td>{{ cnames.validation.value }}</td>
              <td>{{ cnames.validation.target }}</td>
            </tr>
            <tr>
              <td>CNAME</td>
              <td>{{ cnames.routing.value }}</td>
              <td>{{ cnames.routing.target }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <section v-if="!isIssued">
      <button class="btn btn-primary btn-lg" @click="refresh">
        <Icon icon="refresh" class="refresh-icon" />
        {{ $t('GENERAL.REFRESH') }}
      </button>
    </section>
  </div>
</template>
<script>
export default {
  name: 'ShopCustomDomainConfiguration',
  props: {
    customDomain: {
      type: Object,
      required: true,
    },
    shopId: {
      type: String,
      required: true,
    },
  },
  emits: ['refresh', 'remove'],
  data: () => ({
    showRecordsTable: false,
    statusTranslationKeys: {
      pending: 'SA.customDomain.configured.statuses.pending.label',
      processing: 'SA.customDomain.configured.statuses.processing.label',
      issued: 'SA.customDomain.configured.statuses.issued.label',
      failed: 'SA.customDomain.configured.statuses.failed.label',
    },
    statusInformationTranslationKeys: {
      pending: 'SA.customDomain.configured.statuses.pending.information',
      processing: 'SA.customDomain.configured.statuses.processing.information',
      issued: 'SA.customDomain.configured.statuses.issued.information',
      failed: 'SA.customDomain.configured.statuses.failed.information',
    },
  }),
  computed: {
    isIssued() {
      return this.customDomain.status === 'issued';
    },
    cnames() {
      const platform = SPRD.PLATFORM;
      const hostname = this.customDomain.hostname;

      return {
        validation: {
          value: '_acme-challenge.' + hostname,
          target:
            platform.toLowerCase() +
            this.shopId +
            '.myspreadshop-validations.com',
        },
        routing: {
          value: hostname,
          target: 'cdn.myspreadshop.com',
        },
      };
    },
    statusTranslation() {
      return this.statusTranslationKeys[this.customDomain.status] || '';
    },
    statusInformationTranslation() {
      return (
        this.statusInformationTranslationKeys[this.customDomain.status] || ''
      );
    },
  },
  methods: {
    toggleRecordsTable() {
      this.showRecordsTable = !this.showRecordsTable;
    },
    refresh() {
      this.$emit('refresh');
    },
    remove() {
      this.$emit('remove');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/_colors';

.custom-domain-configuration {
  display: flex;
  flex-direction: column;
  gap: 32px;

  section {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  button {
    width: max-content;
  }

  .shop-custom-domain {
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border: 1px solid $sprd-color-lightest-grey;
    border-radius: $border-radius-medium;

    &.pending,
    &.processing {
      background-color: $sprd-color-lightest-grey;
    }

    .host-information {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;

      .status {
        font-size: $font-size-normal;
        padding: 4px 8px;
        border-radius: $border-radius-small;
        font-weight: 400;

        &.pending,
        &.processing {
          background-color: $pa-color-yellow;
        }

        &.issued {
          background-color: $pa-color-green-light;
        }
      }

      .hostname {
        font-size: $font-size-normal;
        font-weight: 600;
      }

      .actions {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 16px;

        .remove {
          color: $pa-color-main;
          text-decoration: underline;
        }
      }
    }
  }

  .cname-table {
    border: 1px solid $sprd-color-lightest-grey;
    border-radius: $border-radius-large;

    table {
      width: 100%;
    }
    th,
    td {
      padding: 16px 24px;
    }

    .cname-table-header {
      border-bottom: 1px solid $sprd-color-lightest-grey;
      width: 100%;
    }
  }

  .refresh-icon {
    height: 20px;
    width: 20px;
    margin-right: 5px;
  }

  .help-icon {
    height: 20px;
    width: 20px;

    &.active {
      color: $pa-color-main;
    }
    &.disabled {
      color: #aaa;
    }
  }
}
</style>
