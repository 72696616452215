<template>
  <div class="sales-earnings-graph">
    <div class="sales-earnings-graph-header">
      <div class="heading">
        <h3>{{ $t(heading) }}</h3>
        <p class="text-grey text-sm">{{ $t(subheading) }}</p>
      </div>

      <SalesEarningsFilter
        :default-time-filter="defaultTimeFilter"
        :start-date="startDate"
        @change="setFilters"
      />
    </div>
    <div v-if="initialized">
      <div v-if="noData" class="no-data">
        <EmptySales />
      </div>
      <div v-else>
        <div class="sale-earning-stats">
          <div v-if="data" class="sales-earnings">
            <a
              href="#"
              class="sales"
              :class="{ selected: currentMode === MODES.SALES }"
              @click.prevent="setMode(MODES.SALES)"
            >
              <div class="heading">
                <h3>{{ $t('DASHBOARD.SALES') }}</h3>
                <p class="text-grey text-sm">
                  {{ $t(salesSubheading) }}
                </p>
              </div>
              <h1>
                {{ sprdNumber(data.quantity.amount) }}
              </h1>
            </a>
            <a
              href="#"
              class="earnings"
              :class="{ selected: currentMode === MODES.EARNINGS }"
              @click.prevent="setMode(MODES.EARNINGS)"
            >
              <div class="heading">
                <h3>{{ $t('DASHBOARD.COMMISSION') }}</h3>
                <p class="text-grey text-sm">
                  {{ $t(earningsSubheading) }}
                </p>
              </div>
              <h1>
                {{ sprdCurrency(data.earnings.amount, data.currencyId) }}
              </h1>
            </a>
          </div>
          <div v-if="timelineData" class="chart">
            <h4 v-if="displayChartHeading">
              {{ $t('DESIGNS.DETAILS.STATISTICS.CHART_HEADING') }}
            </h4>
            <TimelineChart
              :time-filter="filters.time"
              :timeline-data="timelineData"
              :height="300"
              :currency-id="
                currentMode === MODES.EARNINGS ? data.currencyId : null
              "
            />
          </div>
          <div
            v-if="
              displayPosAggregate &&
              aggregateData &&
              aggregateData.perPointOfSale.length > 1
            "
            class="aggregations"
          >
            <PosAggregation
              :values-by-pos="aggregateData.perPointOfSale"
              :currency-id="
                currentMode === MODES.EARNINGS ? data.currencyId : null
              "
              :value-label="
                currentMode === MODES.SALES
                  ? 'DASHBOARD.SALES'
                  : 'DASHBOARD.COMMISSION'
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading-wrapper">
      <LoadingHeart />
    </div>
  </div>
</template>

<script>
import PosAggregation from 'src/app/components/charts/sales/PosAggregation.vue';
import SalesEarningsFilter from 'src/app/components/charts/sales/SalesEarningsFilter.vue';
import TimelineChart from 'src/app/components/charts/timeline/TimelineChart.vue';
import EmptySales from 'src/app/components/charts/sales/EmptySales.vue';
import LoadingHeart from 'src/app/commons/LoadingHeart/LoadingHeart.vue';

export default {
  name: 'SalesEarningsGraph',
  components: {
    PosAggregation,
    SalesEarningsFilter,
    TimelineChart,
    EmptySales,
    LoadingHeart,
  },
  props: {
    heading: {
      type: String,
    },
    subheading: {
      type: String,
    },
    salesSubheading: {
      type: String,
    },
    earningsSubheading: {
      type: String,
    },
    data: {
      type: Object,
    },
    initialized: {
      type: Boolean,
    },
    displayPosAggregate: {
      type: Boolean,
    },
    displayChartHeading: {
      type: Boolean,
    },
    defaultTimeFilter: {
      type: Object,
    },
    startDate: {
      type: Date,
    },
  },
  emits: ['onLoadData'],
  data() {
    return {
      bestsellerData: null,
      filters: {
        pointOfSale: null,
        days: null,
      },
      MODES: {
        SALES: 'SALES',
        EARNINGS: 'EARNINGS',
      },
      currentMode: 'SALES',
    };
  },
  computed: {
    timelineData() {
      if (!this.data) {
        return null;
      }

      if (this.currentMode === this.MODES.SALES) {
        return this.data.timeline.map((d) => ({
          date: d.saleDate,
          value: d.sales || 0,
        }));
      } else {
        return this.data.timeline.map((d) => ({
          date: d.saleDate,
          value: d.earnings || 0,
        }));
      }
    },
    aggregateData() {
      if (!this.data) {
        return null;
      }

      let aggregate;

      if (this.currentMode === this.MODES.SALES) {
        aggregate = this.data.quantity;
      } else {
        aggregate = this.data.earnings;
      }

      aggregate.perPointOfSale.sort((a, b) => b.amount - a.amount);

      return aggregate;
    },
    noData() {
      return !this.data;
    },
  },
  methods: {
    setFilters(filters) {
      this.filters.pointOfSale = filters.pos;
      this.filters.time = filters.time;
      this.$emit('onLoadData', filters);
    },
    setMode(mode) {
      this.currentMode = mode;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/type';

.sales-earnings-graph {
  border-radius: $border-radius-medium;
  background-color: $white;
  padding: 0 !important;
}

.sales-earnings-graph-header {
  display: flex;
  justify-content: space-between;

  .heading {
    h2,
    h3 {
      margin: 0 0 8px 0;
    }

    p {
      margin: 0;
    }
  }

  .filters {
    display: flex;

    & > div {
      margin-left: 16px;
    }
  }
}

.no-data {
  border-radius: 0 0 $border-radius-medium $border-radius-medium;
  padding: 48px;
}

.sales-earnings-graph-header,
.sale-earning-stats {
  padding: 24px 24px 16px;
}

.sale-earning-stats {
  margin-bottom: 4px;
}

.sales-earnings {
  display: flex;

  & > a {
    flex: 1 1 300;
    min-width: 300px;
    border-radius: $border-radius-medium;
    border: 1px solid $grey20;
    padding: 16px;

    &.selected {
      border-color: $pa-color-main;
      box-shadow: 0 0 0 1px $pa-color-main;
    }

    h1,
    h3 {
      color: $grey80;
    }

    h1 {
      margin: 36px 0 0 0;
    }

    h3 {
      margin: 0;
    }

    p {
      margin: 4px 0 0 0;
    }

    &:first-child {
      margin-right: 24px;
    }
  }
}

.chart {
  margin-top: 24px;

  h4 {
    margin: 0 0 18px 0;
  }
}

.aggregations {
  max-width: 400px;
  margin-top: 24px;
}

.loading-wrapper {
  border-radius: 0 0 $border-radius-medium $border-radius-medium;
  padding: 48px;
}
</style>
