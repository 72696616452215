<template>
  <div>
    <h4>
      {{ $t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.CTA_QUESTION') }}
    </h4>
    <div class="btnContainer">
      <button
        v-trackClickEvent="{
          name: 'sharing-product-clicked',
          details: {
            eventArea: 'sharing-tool',
          },
        }"
        class="smBtn btn btn-light"
        :disabled="!sellablesAvailable"
        @click="openSellableDialog"
      >
        {{ $t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.CTA_DESIGN') }}
      </button>
      <button
        v-trackClickEvent="{
          name: 'sharing-promo-clicked',
          details: {
            eventArea: 'sharing-tool',
          },
        }"
        class="smBtn btn btn-light"
        :disabled="!promosAvailable"
        @click="openPromoDialog"
      >
        {{ $t('POS.SHOP_SETTINGS.SOCIAL_MEDIA_TOOL.CTA_PROMO') }}
      </button>
    </div>
  </div>
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import { PromotionsData } from 'src/app/partnerarea/pos/shop/shopSavable/PromotionsData';
import dialogService from '@/dialogs/wrapper/dialogService';
import SocialMediaToolPromoDialog from 'src/app/partnerarea/pos/shop/socialMediaTool/SocialMediaToolPromoDialog.vue';
import SocialMediaToolSellableDialog from 'src/app/partnerarea/pos/shop/socialMediaTool/SocialMediaToolSellableDialog.vue';

export default {
  name: 'SocialMediaToolNav',
  mixins: [SettingsData, PromotionsData],
  computed: {
    sellablesAvailable() {
      return !this.config.shopCurrencyEditable;
    },
    promosAvailable() {
      return (
        this.promotions &&
        this.promotions.promotions &&
        this.promotions.promotions.length > 0
      );
    },
  },
  created() {
    this.$store.commit('shop/setLivePreviewVisible', true);

    if (this.$route.query.action === 'promo') {
      this.openPromoDialog({
        text: this.$route.query.text,
        defaultPromo: this.$route.query.defaultPromo,
      });
    }
  },
  methods: {
    openPromoDialog(options) {
      dialogService.openDialog(SocialMediaToolPromoDialog, options, {
        fullSize: true,
        displayClose: false,
      });
    },
    openSellableDialog() {
      dialogService.openDialog(SocialMediaToolSellableDialog, null, {
        fullSize: true,
        displayClose: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.smBtn {
  min-height: 40px;
  width: calc(50% - 8px);
}

.btnContainer {
  display: flex;
  justify-content: space-between;
}
</style>
