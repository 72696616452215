<template>
  <div class="publishing-empty-list-header">
    <p class="header-text design-text">Designs</p>
    <MarketplaceLogo v-if="isMp" class="logo-small" />
    <SpreadshopLogo v-else class="logo-small" />
  </div>
  <div class="publishing-empty-list-content-wrapper">
    <div class="publishing-empty-list-content">
      <p class="header-text">
        {{ $t('PUBLISHING.LIST.EMPTY.HEADING') }}
      </p>
      <p class="info-text">
        {{ $t('PUBLISHING.LIST.EMPTY.TEXT') }}
      </p>
      <button class="btn btn-primary btn-lg create-btn" @click="create">
        {{ $t('PUBLISHING.LIST.EMPTY.CTA') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import MarketplaceLogo from '@/logos/MarketplaceLogo.vue';
import SpreadshopLogo from '@/logos/SpreadshopLogo.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { PUBLISHING_SCOPE } from '@/publishing/publishingScope';

const store = useStore();
const emits = defineEmits(['create']);
const isMp = computed(
  () => store.state.publishing.currentScope?.key === PUBLISHING_SCOPE.MP.key,
);
const create = () => emits('create');
</script>

<style scoped lang="scss">
@import 'src/scss/styleguide/colors';

.header-text {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.32px;

  .design-text {
    color: $grey90;
  }
}

.publishing-empty-list-header {
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 24px;
}

.logo-small {
  width: 125px;
  height: 20px;
  flex-shrink: 0;
}

.publishing-empty-list-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20%;
  padding: 24px;

  .publishing-empty-list-content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .info-text {
      color: $grey80;
      max-width: 603px;
    }

    .create-btn {
      width: fit-content;
    }
  }
}
</style>
