import CryptoJS from './sha1.js';
import axios from 'axios';
import store from '@/store/Store.js';

export const requestSuccessInterceptor = (config) => {
  if (
    config.url.indexOf(SPRD.API_PATH) !== -1 ||
    config.url.indexOf('/terms-and-conditions') !== -1
  ) {
    const time = new Date().getTime();
    const sig = CryptoJS.SHA1(
      `${config.method.toUpperCase()} ${config.url} ${time} ${SPRD.API_SECRET}`,
    );

    if (config.params === undefined) {
      config.params = {};
    }
    config.params.apiKey = SPRD.API_KEY;
    config.params.sig = `${sig}`;
    config.params.time = time;

    config.headers['Sprd-Href'] = config.url;
  }
  return config;
};

export const responseErrorInterceptor = (error) => {
  if (
    error?.response?.status === 401 &&
    error?.response?.config?.url?.indexOf('/users/current') === -1 &&
    error?.response?.config?.url?.indexOf('/sessions') === -1 &&
    error?.response?.config?.url?.indexOf('/identities') === -1 &&
    !store.state.user.isUserLoggingOut
  ) {
    store.dispatch('user/logout');
  }

  return Promise.reject(error?.response);
};

axios.interceptors.request.use(requestSuccessInterceptor);

axios.interceptors.response.use(
  (response) => response,
  responseErrorInterceptor,
);
