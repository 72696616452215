<template>
  <div class="digital-products-config-inventory-files">
    <ul>
      <li>
        <p>
          {{
            $t('DIGITAL_PRODUCTS.DETAILS.PRICING.CUSTOMER_PRICE.LABEL', {
              currency: currencySymbol,
            })
          }}
        </p>
        <LabelInput class="price" :class="{ 'has-error': priceError }">
          <input
            type="text"
            :value="customerPrice"
            :placeholder="$t('DIGITAL_PRODUCTS.DETAILS.PRICING.PLACEHOLDER')"
            @change="changePrice"
          />
          <div v-if="priceError" class="field-info">
            <small class="error-info error-info-name">
              {{ priceError.reason }}
            </small>
          </div>
        </LabelInput>
      </li>
      <template v-if="customerPrice">
        <li>
          <p>
            {{ $t('DIGITAL_PRODUCTS.DETAILS.PRICING.TRANSACTION_FEE.LABEL') }}
            <VDropdown placement="top">
              <a href="#" class="link-main icon-link" @click.prevent>
                <Icon icon="question" />
              </a>
              <template #popper>
                <p>
                  {{
                    $t(
                      'DIGITAL_PRODUCTS.DETAILS.PRICING.TRANSACTION_FEE.DESCRIPTION',
                    )
                  }}
                </p>
              </template>
            </VDropdown>
          </p>
          <p class="monetary-amount negative">
            -
            {{
              sprdCurrency(
                transactionFee,
                digitalProduct.contentPrice.currencyId,
              )
            }}
          </p>
        </li>
        <li>
          <div>
            <p>
              {{ $t('DIGITAL_PRODUCTS.DETAILS.PRICING.PROCESSING_FEE.LABEL') }}
              ({{ processingFeePercent }}%)
              <VDropdown placement="top">
                <a href="#" class="link-main icon-link" @click.prevent>
                  <Icon icon="question" />
                </a>
                <template #popper>
                  <p>
                    {{
                      $t(
                        'DIGITAL_PRODUCTS.DETAILS.PRICING.PROCESSING_FEE.DESCRIPTION',
                      )
                    }}
                  </p>
                </template>
              </VDropdown>
            </p>
            <Badge class="discount" type="dark">{{
              $t('DIGITAL_PRODUCTS.DETAILS.PRICING.BETA_DISCOUNT')
            }}</Badge>
          </div>
          <div>
            <p class="monetary-amount negative disabled">
              -
              {{
                sprdCurrency(
                  processingFee,
                  digitalProduct.contentPrice.currencyId,
                )
              }}
            </p>
            <p class="monetary-amount">
              {{ sprdCurrency(0, digitalProduct.contentPrice.currencyId) }}
            </p>
          </div>
        </li>
        <li>
          <p>
            {{ $t('DIGITAL_PRODUCTS.DETAILS.PRICING.EARNINGS.LABEL') }}
            <VDropdown placement="top">
              <a href="#" class="link-main icon-link" @click.prevent>
                <Icon icon="question" />
              </a>
              <template #popper>
                <p>
                  {{
                    $t('DIGITAL_PRODUCTS.DETAILS.PRICING.EARNINGS.DESCRIPTION')
                  }}
                </p>
              </template>
            </VDropdown>
          </p>
          <p class="monetary-amount earnings">
            {{ sprdCurrency(earnings, digitalProduct.contentPrice.currencyId) }}
          </p>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import currencyFormatService from '@/currency/service/currencyFormatService';
import LabelInput from '@/labelInput/LabelInput.vue';
import Badge from 'src/app/components/badge/Badge.vue';

export default {
  name: 'DigitalProductConfigPrice',
  components: {
    LabelInput,
    Badge,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      digitalProduct: (s) => s.digitalProduct.current,
      validation: (state) => state.digitalProduct.validation,
    }),
    ...mapGetters({
      earnings: 'digitalProduct/earnings',
      customerPrice: 'digitalProduct/customerPrice',
      transactionFee: 'digitalProduct/transactionFee',
      processingFee: 'digitalProduct/processingFee',
      processingFeePercent: 'digitalProduct/processingFeePercent',
      currencyById: 'platform/currencyById',
    }),
    priceError() {
      return this.validation.errors.price;
    },
    currencySymbol() {
      return this.currencyById(this.digitalProduct.contentPrice.currencyId)
        .symbol;
    },
  },
  methods: {
    ...mapActions({
      updatePrice: 'digitalProduct/updatePrice',
    }),
    changePrice(evt) {
      let price = currencyFormatService.parseValue(
        evt.target.value,
        this.digitalProduct.contentPrice.currencyId,
      );

      this.updatePrice(price);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

ul {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $grey10;
    }

    input {
      width: 80px;
    }

    p:first-child {
      margin: 0;
      display: flex;
      align-items: center;

      & > div {
        display: flex;
        margin-left: 8px;

        .icon {
          margin: 0 !important;
        }
      }
    }
  }
}

.price {
  text-align: right;
}

.monetary-amount {
  margin: 0;
  text-align: right;

  &.negative {
    color: $pa-color-red;
  }

  &.disabled {
    color: $grey30;
    text-decoration: line-through;
  }

  &.earnings {
    font-weight: bold;
  }

  & + .monetary-amount {
    margin-top: 8px;
  }
}

.discount {
  display: inline-block;
  margin: 8px 0 0 0;
}
</style>
