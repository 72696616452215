import store from '@/store/Store';
import NpsDialog from 'src/app/components/nps/NpsDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import { parseISO } from 'date-fns/parseISO';
import { formatISO } from 'date-fns/formatISO';
import { differenceInDays } from 'date-fns/differenceInDays';
import analytics from '@/tracking/analytics';

export const npsActive = () => {
  return (
    !store.getters['account/isAdmin'] &&
    store.getters['dialogs/dialogCount'] <= 0 &&
    differenceInDays(new Date(), parseISO(store.state.user.data.created)) >
      28 &&
    (!store.getters['settings/lastNpsSubmit'] ||
      differenceInDays(
        new Date(),
        parseISO(store.getters['settings/lastNpsSubmit']),
      ) > 90)
  );
};

export const triggerNps = async ({ delayInMs } = {}) => {
  if (delayInMs) {
    await new Promise((resolve) => setTimeout(resolve, delayInMs));
  }
  await store.state.settings.onDataLoaded;

  if (npsActive()) {
    let score = null;
    analytics.logEvent('pa_nps_display');
    dialogService
      .openDialog(NpsDialog, {
        submitScore: (s) => {
          score = s;
          analytics.logEvent('pa_nps_submit', {
            value: score,
          });
          store.dispatch('settings/setSetting', {
            key: 'LAST_PA_NPS_SUBMIT',
            value: formatISO(new Date()),
          });
        },
      })
      .finally(() => {
        if (typeof score !== 'number') {
          analytics.logEvent('pa_nps_skip');
          store.dispatch('settings/setSetting', {
            key: 'LAST_PA_NPS_SUBMIT',
            value: formatISO(new Date()),
          });
        }
      });
  }
};
