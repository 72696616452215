<template>
  <div class="dialog-size-full dialog-container">
    <div class="modal-header header">
      <div class="flex-center-vertical header-container">
        <div>
          <h4>
            {{ $t('POS.SHOP_SETTINGS.NAVIGATION.SELFORDER_DIALOG.HEADING') }}
          </h4>
          <p class="subheading">
            {{ $t('POS.SHOP_SETTINGS.NAVIGATION.SELFORDER_DIALOG.SUBHEADING') }}
          </p>
        </div>
        <a
          type="button"
          class="flex-align-right link-main icon-link"
          @click="modalInstance.dismiss"
        >
          <Icon icon="close" /><span class="modal-close-label">
            {{ $t('GENERAL.CLOSE') }}
          </span>
        </a>
      </div>
    </div>
    <LoadingHeart v-if="loading" class="loader" />
    <iframe v-if="url" :src="url" class="page" @load="onIframeLoaded" />
  </div>
</template>

<script>
import LoadingHeart from '@/LoadingHeart/LoadingHeart.vue';

export default {
  name: 'ShopSelfOrderDialog',
  components: {
    LoadingHeart,
  },
  props: ['modalInstance', 'data'],
  data() {
    return {
      loading: true,
      url:
        this.$store.getters['shop/getSpreadshopUrl'](
          this.$store.state.shop.livePreview.path,
          true,
        ) + '&useShopOwnerPrices=true',
    };
  },
  methods: {
    onIframeLoaded() {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.header-container {
  // the header div gets styled to 80% on large devices by a class with high specificity
  width: 100% !important;
  max-width: 1400px;
  padding-left: 12px;
  padding-right: 12px;
}

.dialog-container {
  position: relative;
  padding: 0;
}

.header {
  // make sure the modal-header box-shadow is always on top
  position: relative;
  z-index: 5;

  border-bottom: 2px solid #f2f2f2;
}

.loader {
  position: absolute;
  flex-grow: 1;
  top: 0;
  bottom: 0;
}

.page {
  width: 100%;
  flex-grow: 1;
  border: none;
  z-index: 1;
}

.subheading {
  color: $sprd-color-grey-60;
  font-size: 0.875em;
}
</style>
