<template>
  <div class="ai-design-generator-limit">
    <template v-if="usageLimitReached">
      <p>
        {{ $t('AI_DESIGN_GENERATOR.LIMIT.REACHED') }}
      </p>
      <button class="link-main" @click="$emit('buyMore')">
        {{ $t('AI_DESIGN_GENERATOR.LIMIT.BUY_MORE') }}
      </button>
    </template>
    <template v-else>
      <p
        v-html="
          $t('AI_DESIGN_GENERATOR.LIMIT.USAGE_LEFT', { openUsage, usageLimit })
        "
      ></p>
    </template>
  </div>
</template>

<script>
export default {
  name: 'AiDesignGeneratorLimit',
  props: {
    usageLimit: {
      type: Number,
      required: true,
    },
    openUsage: {
      type: Number,
      required: true,
    },
  },
  emits: ['buyMore'],
  computed: {
    usageLimitReached() {
      return !this.openUsage;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.ai-design-generator-limit {
  display: flex;
}

p {
  color: $grey80;
  margin: 0;
}

button {
  margin: 0 0 0 4px;
}
</style>
