import { deepCopy } from '@/utils';
import digitalProductsService from '@/api/digitalProducts/digitalProductsService';
import { isEqual } from '@/utils';
import digitalProductValidator from '@/api/validators/digitalProduct/digitalProductValidator';
import currencyFormatService from '@/currency/service/currencyFormatService';

const defaultState = {
  current: null,
  original: null,
  validate: false,
  validation: {
    errors: {},
    result: true,
  },
  pricingData: null,
};

function resetValidationErrorForField(validation, field) {
  if (validation.errors[field]) {
    delete validation.errors[field];
  }
  return validation;
}

function validateFields(state, field) {
  if (!state.validate) {
    return;
  }

  if (Array.isArray(field)) {
    field.forEach((f) => validateFields(state, f));
  } else {
    resetValidationErrorForField(state.validation, field);
    const validationResult = digitalProductValidator.validateByFieldName(
      field,
      state.current,
    );
    state.validation = digitalProductValidator.mergeResults(
      state.validation,
      validationResult,
    );
  }
}

export default {
  namespaced: true,
  state: deepCopy(defaultState),
  getters: {
    unsavedChanges: (state) => !isEqual(state.current, state.original),
    transactionFee: (state) =>
      state.current.contentPrice.currencyId
        ? state.pricingData.transactionFeeAmount[
            state.current.contentPrice.currencyId
          ]
        : 0,
    processingFee: (state) =>
      state.current.contentPrice.amount * state.pricingData.sprdCut,
    processingFeePercent: (state) => state.pricingData.sprdCut * 100,
    priceLimit: (state) =>
      state.current.contentPrice.currencyId
        ? state.pricingData.limit[state.current.contentPrice.currencyId]
        : 500,
    earnings: (state) => state.current.contentPrice.amount, // * (1 - state.pricingData.sprdCut),
    customerPrice: (state, getters) =>
      state.current.contentPrice.amount !== null
        ? currencyFormatService.formatValue(
            state.current.contentPrice.amount + getters.transactionFee,
            state.current.contentPrice.currencyId,
            undefined,
            true,
          )
        : null,
  },
  mutations: {
    setPricingData(state, { pricingData }) {
      // processing fee is 0% for now, set it manually to 20% for smoke test
      state.pricingData = { ...pricingData, sprdCut: 0.2 };
    },
    setDigitalProduct(state, { digitalProduct }) {
      state.current = digitalProduct;
      state.original = deepCopy(digitalProduct);
      state.validate = false;
      state.validation = {
        errors: {},
        result: true,
      };
    },
    updateOriginal(state) {
      state.original = deepCopy(state.current);
    },
    resetToOriginal(state) {
      state.current = deepCopy(state.original);
    },
    setName(state, name) {
      state.current.workingState.name = name;
      validateFields(state, 'name');
    },
    setDescription(state, description) {
      state.current.workingState.description = description;
      validateFields(state, 'description');
    },
    addTag(state, tag) {
      if (!state.current.workingState.tags.includes(tag)) {
        state.current.workingState.tags.push(tag);
      }
      validateFields(state, 'tags');
    },
    removeTag(state, tag) {
      state.current.workingState.tags = state.current.workingState.tags.filter(
        (t) => t !== tag,
      );
      validateFields(state, 'tags');
    },
    setPreviewImages(state, previewImages) {
      state.current.workingState.images = previewImages;
      validateFields(state, 'previewImages');
    },
    movePreviewImageToFront(state, previewImageId) {
      state.current.workingState.images.sort((file) =>
        file.id === previewImageId ? -1 : 0,
      );
    },
    updatePreviewImages(state, previewImages) {
      // filter out files that no longer exist
      state.current.workingState.images =
        state.current.workingState.images.filter((imageFile) =>
          previewImages.some(
            (previewImage) => previewImage.id === imageFile.id,
          ),
        );

      // add new files to the end
      const newFiles = previewImages.filter(
        (previewImage) =>
          !state.current.workingState.images.some(
            (imageFile) => imageFile.id === previewImage.id,
          ),
      );
      state.current.workingState.images =
        state.current.workingState.images.concat(newFiles);

      // original should represent current previewImages without clientside ordering
      state.original.workingState.images = deepCopy(previewImages);
      validateFields(state, 'previewImages');
    },
    updateInventoryFiles(state, inventoryFiles) {
      state.current.workingState.payloadFiles = inventoryFiles;
      state.original.workingState.payloadFiles = deepCopy(
        state.current.workingState.payloadFiles,
      );
      validateFields(state, 'inventoryFiles');
    },
    updateHasUnpublishedChanges(state, hasUnpublishedChanges) {
      state.current.workingState.hasUnpublishedChanges = hasUnpublishedChanges;
      state.original.workingState.hasUnpublishedChanges = hasUnpublishedChanges;
    },
    toggleShop(state, shopId) {
      if (state.current.shopIds.includes(shopId)) {
        state.current.shopIds = state.current.shopIds.filter(
          (id) => id !== shopId,
        );
      } else {
        state.current.shopIds.push(shopId);
      }
    },
    setShopIds(state, shopIds) {
      state.current.shopIds = [...shopIds];
    },
    addShopId(state, shopId) {
      if (!state.current.shopIds.includes(shopId)) {
        state.current.shopIds.push(shopId);
      }
    },
    removeShopId(state, shopId) {
      state.current.shopIds = state.current.shopIds.filter((s) => s !== shopId);
    },
    setPrice(state, priceAmount) {
      state.current.contentPrice.amount = priceAmount;
      validateFields(state, 'price');
    },
    validate(state) {
      state.validate = true;
      const fieldsToValidate = Object.keys(
        digitalProductValidator.singleValidate,
      );
      state.validation = digitalProductValidator.validateMultipleFieldNames(
        fieldsToValidate,
        state.current,
      );
    },
  },
  actions: {
    fetchDigitalProductPricingData: async ({ state, commit }) => {
      if (state.pricingData) {
        return;
      }

      const pricingData =
        await digitalProductsService.getDigitalProductsPricingData();

      commit('setPricingData', { pricingData });
    },
    fetchDigitalProduct: async ({ commit }, digitalProductId) => {
      const digitalProduct =
        await digitalProductsService.getDigitalProduct(digitalProductId);

      commit('setDigitalProduct', { digitalProduct });
    },
    saveCurrentDigitalProduct: async ({ state, commit }) => {
      const digitalProduct = await digitalProductsService.updateDigitalProduct(
        state.current,
      );
      commit('setDigitalProduct', { digitalProduct });
    },
    publishDigitalProduct: async ({ state, commit }) => {
      commit('validate');

      if (!state.validation.result) {
        return Promise.reject();
      }

      const digitalProduct = await digitalProductsService.publishDigitalProduct(
        state.current,
      );
      commit('setDigitalProduct', { digitalProduct });
    },
    deleteDigitalProduct: async ({ state }) => {
      await digitalProductsService.deleteDigitalProduct(state.current);
    },
    uploadDigitalProductPreviewImages: async ({ state, commit }, files) => {
      await digitalProductsService.uploadDigitalProductImages(
        state.current,
        files,
      );

      const updatedDigitalProduct =
        await digitalProductsService.getDigitalProduct(state.current.id);

      commit('updatePreviewImages', updatedDigitalProduct.workingState.images);
      commit(
        'updateHasUnpublishedChanges',
        updatedDigitalProduct.workingState.hasUnpublishedChanges,
      );
    },
    uploadDigitalProductInventoryFiles: async ({ state, commit }, files) => {
      await digitalProductsService.uploadDigitalProductFiles(
        state.current,
        files,
      );

      const updatedDigitalProduct =
        await digitalProductsService.getDigitalProduct(state.current.id);

      commit(
        'updateInventoryFiles',
        updatedDigitalProduct.workingState.payloadFiles,
      );
      commit(
        'updateHasUnpublishedChanges',
        updatedDigitalProduct.workingState.hasUnpublishedChanges,
      );
    },
    deleteDigitalProductPreviewImage: async ({ state, commit }, fileId) => {
      await digitalProductsService.deleteDigitalProductImage(
        state.current,
        fileId,
      );

      const updatedDigitalProduct =
        await digitalProductsService.getDigitalProduct(state.current.id);

      commit('updatePreviewImages', updatedDigitalProduct.workingState.images);
      commit(
        'updateHasUnpublishedChanges',
        updatedDigitalProduct.workingState.hasUnpublishedChanges,
      );
    },
    deleteDigitalProductInventoryFile: async ({ state, commit }, fileId) => {
      await digitalProductsService.deleteDigitalProductFile(
        state.current,
        fileId,
      );

      const updatedDigitalProduct =
        await digitalProductsService.getDigitalProduct(state.current.id);

      commit(
        'updateInventoryFiles',
        updatedDigitalProduct.workingState.payloadFiles,
      );
      commit(
        'updateHasUnpublishedChanges',
        updatedDigitalProduct.workingState.hasUnpublishedChanges,
      );
    },
    updatePrice: ({ commit, getters }, priceAmount) => {
      if (!isNaN(priceAmount)) {
        commit(
          'setPrice',
          Math.max(
            Math.min(priceAmount - getters.transactionFee, getters.priceLimit),
            digitalProductValidator.defaultOptions.price.min,
          ),
        );
      }
    },
  },
};
