export default {
  namespaced: true,
  state: {
    helpPanelExpanded: false,
    helpPanelHighlighted: false,
    isMobileApp: false,
    routeViewKey: null,
  },
  mutations: {
    setHelpPanelExpansion(state, expanded) {
      state.helpPanelExpanded = expanded;
      if (!expanded) {
        state.helpPanelHighlighted = false;
      }
    },
    setHelpPanelHighlighted(state, highlighted) {
      state.helpPanelHighlighted = highlighted;
    },
    setIsMobileApp(state, isMobileApp) {
      state.isMobileApp = isMobileApp;
    },
    setRouteViewKey(state, key) {
      state.routeViewKey = key;
    },
  },
  actions: {
    processUrlParams({ commit }) {
      if (
        window.location.search &&
        window.location.search.includes('isApp=true')
      ) {
        commit('setIsMobileApp', true);
      }
    },
  },
};
