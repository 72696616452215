<template>
  <InternationalPublishingStatusTable
    v-bind="{ ...$props, ...$attrs }"
    :default-language="defaultLanguage"
    :languages="languagesToInclude"
    :online-status-text="onlineStatusText"
  />
</template>

<script setup>
import InternationalPublishingStatusTable from 'src/app/components/publishing/InternationalPublishingStatusTable.vue';
import INTPUB_DECISION_STATES from '@/states/intpubDecisionStates';
import { computed } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  getDeeplinkForLocale: {
    required: true,
    type: Function,
  },
  requestDeeplinkForPendingTranslations: {
    type: Boolean,
  },
  displayNoneExistingTranslations: {
    type: Boolean,
  },
  displayUnavailableDeeplinks: {
    type: Boolean,
  },
});

const store = useStore();
const languages = computed(() => store.state.platform.languages);
const idea = computed(() => store.state.idea.current);
const requestedAutotranslations = computed(
  () => store.getters['idea/requestedAutotranslations'],
);
const defaultLanguage = computed(() => store.getters['user/language']);
const intPubDecision = computed(() => store.getters['idea/intPubDecision']);
const onlineStatusText = computed(() =>
  idea.value.approvalPending
    ? 'DESIGNS.STATUS.ONLINE_FIRST'
    : 'DESIGNS.STATUS.PUBLISHED',
);

const languagesToInclude = languages.value
  .filter((language) => {
    if (
      props.displayNoneExistingTranslations ||
      (requestedAutotranslations.value &&
        intPubDecision.value !== INTPUB_DECISION_STATES.REJECTED)
    ) {
      return true;
    } else {
      return idea.value.translations.some(
        (ideaTranslation) => ideaTranslation.locale === language.isoCode,
      );
    }
  })
  .map((language) => ({
    ...language,
    pending: !idea.value.translations.find(
      (translation) => translation.locale === language.isoCode,
    ),
  }));
</script>
