<template>
  <button v-tooltip="text" type="button" class="text-btn" tabindex="-1">
    <Icon class="tooltipIcon" icon="info-circle" />
  </button>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.tooltipIcon {
  margin-left: 10px;
  color: $pa-color-main;
  font-size: 1.4em;
}
</style>
