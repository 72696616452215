<template>
  <div class="printareas" :class="{ dragging: dragging }">
    <SmartFaqLink :link="smartFaqLinks.PRINT_AREAS_PRICING" class="help-link" />
    <div
      v-for="printArea in printAreas"
      :key="printArea.id"
      class="printarea"
      @click="$emit('onClick', printArea)"
    >
      <strong>{{ $t(printArea.name) }}</strong>
      <div class="printarea-content">
        <div
          v-if="printArea.motive"
          class="motive"
          draggable="true"
          @dragstart="dragStart($event, printArea)"
          @dragend="dragEnd"
        >
          <Icon icon="drag" />
          <div
            class="image design-checked-background"
            :class="{ dark: printArea.motive.backgroundColor }"
          >
            <SprdImage
              :src="`${printArea.motive.href},height=50,width=50`"
              alt="Design Thumbnail"
            />
          </div>
          <p v-if="printArea.motive.name" class="name text-truncate">
            {{ printArea.motive.name }}
          </p>
          <div v-if="!printArea.motive.name" class="name">
            <p>{{ $t('DESIGNS.ID') }}</p>
            <strong class="text-truncate">{{
              printArea.motive.designId
            }}</strong>
          </div>
          <div class="actions">
            <button
              v-if="removable"
              class="btn icon-only-btn link-red"
              @click="$emit('remove', printArea.motive)"
            >
              <Icon icon="bin" draggable="false" />
            </button>
            <button
              v-if="mainDesignSelection"
              v-tooltip="{
                content: $t('DESIGNS.MULTI_CREATION.MAIN_DESIGN_EXPLANATION'),
                delay: 400,
              }"
              class="btn icon-only-btn link-main main-design"
              @click="$emit('mainDesignSelected', printArea.motive)"
            >
              <Icon
                :icon="printArea.motive.main ? 'star-filled' : 'star'"
                draggable="false"
              />
            </button>
          </div>
        </div>
        <div
          v-if="!printArea.motive"
          class="placeholder"
          draggable="false"
        ></div>
        <div
          v-if="
            dragging &&
            (!printArea.motive ||
              draggedMotive.designId !== printArea.motive.designId)
          "
          class="placeholder dragging-placeholder"
          :class="{ invalid: !possibleDropPrintAreas[printArea.id].possible }"
          @dragover.prevent
          @drop.prevent.stop="drop(printArea)"
        >
          <div class="possible">
            {{
              printArea.motive
                ? $t('DESIGNS.PRINTAREA.FEEDBACK.EXCHANGE')
                : $t('DESIGNS.PRINTAREA.FEEDBACK.CHANGE')
            }}
          </div>
          <div class="impossible">
            {{
              possibleDropPrintAreas[printArea.id].reason === 'exchange'
                ? $t('DESIGNS.PRINTAREA.FEEDBACK.CANNOT_EXCHANGE')
                : $t('DESIGNS.PRINTAREA.FEEDBACK.NOT_SUITABLE')
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SprdImage from '@/image/SprdImage.vue';
import { mapGetters } from 'vuex';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';

/*
printAreas prop structure:

printAreas: [{
    id: <printAreaIdentifier>,
    name: <translationkey>,
    motive: {
        designId: '',
        href: '',
        name: '' || null
        locations: [{
            location: <printAreaIdentifier>
        }]
    } || null
}]
*/

export default {
  name: 'PrintAreaSelector',
  components: {
    SprdImage,
    SmartFaqLink,
  },
  props: {
    printAreas: {
      required: true,
    },
    removable: {
      required: false,
    },
    mainDesignSelection: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['change', 'remove', 'mainDesignSelected', 'onClick'],
  data() {
    return {
      draggedMotive: null,
      draggedMotiveOrigin: null,
      possibleDropPrintAreas: {},
      smartFaqLinks,
    };
  },
  computed: {
    ...mapGetters({
      hasShops: 'user/hasShops',
      hasMarketplace: 'user/hasMarketplace',
    }),
    dragging() {
      return !!this.draggedMotive;
    },
  },
  methods: {
    getPrintAreaForMotive(motive) {
      return this.printAreas.find(
        (printArea) =>
          printArea.motive && printArea.motive.designId === motive.designId,
      );
    },
    changePrintAreaOfMotive(motive, printArea, previousPrintArea) {
      this.$emit('change', { motive, printArea, previousPrintArea });
    },
    dragStart(event, printArea) {
      event.dataTransfer.setData('text', '');

      this.draggedMotive = printArea.motive;
      this.draggedMotiveOrigin = printArea;
      this.possibleDropPrintAreas = {};
      const currentLocation = printArea.id;

      this.printAreas
        .filter((pa) => currentLocation !== pa.id)
        .forEach((pa) => {
          this.possibleDropPrintAreas[pa.id] = {};

          if (
            !printArea.motive.locations.some(
              (location) => location.location === pa.id,
            )
          ) {
            this.possibleDropPrintAreas[pa.id] = {
              possible: false,
              reason: 'location',
            };
          } else if (!pa.motive) {
            this.possibleDropPrintAreas[pa.id] = {
              possible: true,
            };
          } else if (
            !pa.motive.locations.some(
              (location) => location.location === currentLocation,
            )
          ) {
            this.possibleDropPrintAreas[pa.id] = {
              possible: false,
              reason: 'exchange',
            };
          } else {
            this.possibleDropPrintAreas[pa.id] = {
              possible: true,
            };
          }
        });
    },
    drop(printArea) {
      if (this.possibleDropPrintAreas[printArea.id].possible) {
        this.changePrintAreaOfMotive(
          this.draggedMotive,
          printArea,
          this.draggedMotiveOrigin,
        );
      } else {
        console.log('invalid drop');
      }

      this.draggedMotive = null;
    },
    dragEnd() {
      this.draggedMotive = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.help-link {
  margin-top: 16px;
}

.printarea {
  margin-top: 16px;

  strong {
    display: block;
    margin-bottom: 4px;
  }
}

.printarea-content {
  position: relative;
  height: 80px;
}

.motive {
  display: flex;
  align-items: center;
  border: 1px solid $grey20;
  border-radius: $border-radius-medium;
  padding: 8px;
  cursor: move;
  background-color: $grey0;

  .image {
    padding: 4px;
    margin-left: 8px;
    pointer-events: none;
  }

  .icon {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
  }

  .name {
    flex-grow: 1;
    margin-left: 8px;
    margin-bottom: 0;
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 62px;

    .icon {
      width: 18px;
      height: 18px;
    }
  }
}

.placeholder {
  border: 1px dashed $grey20;
  border-radius: $border-radius-medium;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: $grey0;
}

.dragging .motive {
  opacity: 0.5;
}

.dragging-placeholder {
  background-color: transparentize($grey0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: $pa-color-green;
  color: $pa-color-green;

  .impossible {
    display: none;
  }

  &.invalid {
    border-color: $pa-color-red;
    color: $pa-color-red;

    .possible {
      display: none;
    }

    .impossible {
      display: block;
    }
  }
}
</style>
