<template>
  <li class="draggable">
    <button
      type="button"
      class="move handle"
      :class="{ 'handle--disabled': disabled }"
      :title="$t('POS.SHOP_SETTINGS.COLLECTIONS.DRAG')"
    >
      <Icon icon="drag" />
    </button>
    <div class="data" :class="{ inactive: !active }" @click="editCollection">
      <div class="name text-truncate">{{ label }}</div>
      <div class="subheading">
        <p v-if="subheading" class="paragraph text-truncate">
          {{ subheading }}
        </p>
        <p v-if="!active" class="paragraph">
          <Icon icon="eye-crossed" />
          {{ $t('POS.SHOP_SETTINGS.COLLECTIONS.STATUS.HIDDEN') }}
        </p>
      </div>
    </div>
    <button type="button" class="link edit" @click="editCollection">
      <Icon icon="pen" />
    </button>
  </li>
</template>

<script>
export default {
  name: 'DraggableElement',
  props: {
    label: {
      required: true,
      type: String,
    },
    subheading: {
      required: false,
      type: String,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['edit'],
  methods: {
    editCollection() {
      this.$emit('edit');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';

.draggable {
  height: 64px;

  @include small-desktop {
    height: 57px;
  }
}

li {
  display: flex;
  background: $grey10;
  border-radius: $border-radius-medium;
  padding: 0 8px;

  & + li {
    margin-top: 12px;
  }

  button {
    padding: 0;
    background: none;
    border: 0;
  }
}

.handle--disabled {
  pointer-events: none;
  color: $grey40;
}

.edit {
  color: $grey80;
}

.icon {
  font-size: 1.8em;
}

.data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  flex-grow: 1;
  cursor: pointer;
  overflow: hidden;
  vertical-align: middle;
}

.subheading {
  display: inline;
  vertical-align: middle;

  .icon {
    margin-right: 8px;
    height: 0.6em;
    width: 0.6em;
  }
}

p.paragraph {
  display: flex;
  align-items: center;
  float: left;
  margin: 4px 0 0;
}

p.paragraph ~ p.paragraph {
  padding-left: 16px;
}

.sortable-chosen {
  background-color: $grey5;
}
</style>
