<template>
  <div v-if="hasDms" class="dms" :class="{ condensed: props.condensed }">
    <div class="dms-box">
      <div class="dms-level">
        <img :src="currentGroupImgSrc" alt="Star Class" />
        <div>
          <p v-if="starCount" class="text-grey text-sm">
            {{ $t('DMS.STAR_CLASS', { number: starCount }) }}
          </p>
          <h1>{{ $t(currentGroup.meta.name) }}</h1>
        </div>
      </div>
      <div class="dms-features">
        <DmsPropertyList condensed />
        <SmartFaqLink :link="smartFaqLink" icon-only class="help-link" />
      </div>
      <MarketplaceLogo v-if="!condensed" class="logo" />
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { DMS_PROPERTIES } from '@/api/models/dms/dmsStates';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import DmsPropertyList from '../../components/dms/DmsPropertyList.vue';
import MarketplaceLogo from '@/logos/MarketplaceLogo.vue';

const store = useStore();
const props = defineProps({
  condensed: {
    type: Boolean,
  },
});

const hasMarketplace = computed(() => store.getters['user/hasMarketplace']);
const currentGroup = computed(() => store.getters['dms/currentGroup']);
const currentGroupImgSrc = computed(() => {
  return currentGroup.value.meta.image;
});
const hasDms = computed(() => !!hasMarketplace.value && !!currentGroup.value);
const getProperty = computed(() => store.getters['dms/getProperty']);
const starCount = computed(() => {
  return getProperty.value(DMS_PROPERTIES.STAR_COUNT).value;
});
const smartFaqLink = computed(() => {
  return currentGroup.value.meta.helpLinkTarget;
});
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/mixins/media';

.dms {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  padding: 0 !important;

  &.condensed {
    .dms-box {
      flex-direction: row;
      align-items: center;
      justify-content: stretch;
      padding: 0;
    }

    .dms-level {
      padding: 24px 24px 16px 24px;

      img {
        width: 48px;
        height: 48px;
      }
    }

    .dms-features {
      align-items: center;
      justify-content: flex-end;
      padding: 24px;
      gap: 48px;
      flex-grow: 1;

      .help-link {
        margin-left: 0;
      }
    }
  }
}

.dms-box {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 24px;
  background-color: $white;
  border-radius: $border-radius-medium;
  position: relative;
}

.dms-level {
  display: flex;
  padding: 0 0 24px 0;
  align-items: center;
  flex-grow: 1;
  position: relative;

  img {
    width: 56px;
    height: 56px;
    margin-right: 16px;
  }

  h1 {
    margin: 0;
  }
}

.dms-features {
  display: flex;
  align-items: flex-end;

  .help-link {
    margin-left: auto;
  }
}

.logo {
  position: absolute;
  top: 24px;
  right: 24px;
  height: 12px;
}
</style>
