<template>
  <AdvancedPopover
    heading="BETA_BADGE.HEADING"
    description="BETA_BADGE.DESCRIPTION"
    :plain-link="link"
    :popover-container="popoverContainer"
  >
    <template #default>
      <Indicator label="BETA" class="beta-indicator" />
    </template>
  </AdvancedPopover>
</template>

<script>
import { mapState } from 'vuex';
import Indicator from 'src/app/components/indicator/Indicator.vue';
import AdvancedPopover from 'src/app/components/popover/AdvancedPopover.vue';
import contactData from '@/contactData/contactData';

export default {
  name: 'BetaIndicator',
  components: {
    Indicator,
    AdvancedPopover,
  },
  props: {
    popoverContainer: {
      type: [Boolean, String],
    },
  },
  data() {
    return {
      mail: contactData.getMpMail(),
    };
  },
  computed: {
    ...mapState({
      userId: (s) => s.user.data.id,
    }),
    mailToLink() {
      return `mailTo:${this.mail}?bcc=partnerapp@spreadshirt.net&subject=Partner Area Beta Feedback (Spread-ID ${this.userId})`;
    },
    link() {
      return {
        label: this.mail,
        href: this.mailToLink,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.beta-indicator {
  :deep(.indicator-button) {
    background-color: $grey80;
    color: $grey0;
    border-radius: 24px;
  }
}
</style>
