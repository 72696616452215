<template>
  <div class="credit-details-volume">
    <div class="header">
      <h3>
        {{ $t('STATISTICS.CREDIT_DETAILS.ENTRY_TYPE.VOLUME_COMMISSION') }}
      </h3>
      <SmartFaqLink :link="helpTarget" class="help-link" />
    </div>
    <div class="subheader">
      <p class="text-sm text-grey">
        {{ date(data.dateBooked, 'longDate') }}
      </p>
      <a class="link link-main" @click="openPerformanceSummary">
        <p class="text-sm">
          {{ $t('STATISTICS.VOLUME_COMMISSION.SUMMARY') }}
        </p>
      </a>
    </div>
    <div class="bottom-info">
      <h1>
        {{ sprdCurrency(data.payoutAmount, data.currencyId) }}
      </h1>
    </div>
  </div>
</template>

<script>
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';

export default {
  name: 'CreditDetailsVolume',
  components: {
    SmartFaqLink,
  },
  props: ['data'],
  emits: ['open-performance-summary'],
  data() {
    return {
      helpTarget: smartFaqLinks.VOLUME_COMMISSION_HELP,
    };
  },
  methods: {
    async openPerformanceSummary() {
      this.$emit('open-performance-summary');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/utils';

.credit-details-volume {
  h1,
  h3 {
    margin: 0;
  }

  .header {
    display: flex;

    .help-link {
      margin-left: auto;
    }
  }

  .subheader {
    display: flex;
    margin-top: 4px;

    .link {
      margin-left: 16px;
    }
  }

  .bottom-info {
    margin-top: 24px;
  }
}
</style>
