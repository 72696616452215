<template>
  <div class="catalog-list" :class="`depth-${depth}`">
    <div v-if="depth === 0" class="header">
      <a
        v-if="enableBulkSelection"
        :class="{ 'all-selected': allSelected(entries) }"
        class="link-main"
        @click="selectAll(entries)"
      >
        {{
          allSelected(entries)
            ? $t('GENERAL.DESELECT_ALL')
            : $t('GENERAL.SELECT_ALL')
        }}
      </a>
    </div>

    <div v-if="entries.subFilters && entries.available">
      <div
        :class="{
          'catalog-list-content':
            entries.filterId !== 'root' &&
            !entries.subFilters[Object.keys(entries.subFilters)[0]].subFilters,
        }"
      >
        <CatalogList
          v-for="subEntry in orderedSubEntries"
          :key="subEntry.filterId"
          :entries="subEntry"
          :depth="depth + 1"
          :disable-selection="disableSelection"
          @on-toggle="onToggle"
          @on-set-selection="onSetSelection"
          @on-color-change="onColorChange"
        />
        <div
          v-for="i in [1, 2, 3, 4, 5]"
          :key="`placeholder-${depth}-${i}`"
          class="placeholder-tile"
        ></div>
      </div>
    </div>
    <div v-if="!entries.subFilters && entries.available">
      <div>
        <div>
          <ProductCard
            :product="entries"
            :options="{ showToggle: true, showColors: true, lazy: true }"
            :disable-selection="disableSelection"
            @on-toggle="onToggle(entries)"
            @on-color-change="(color) => onColorChange(color, entries)"
          />
        </div>
        <div
          v-for="i in placeholders"
          :key="`placeholder-${depth}-${i}`"
          class="placeholder-tile"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from '../productCard/ProductCard.vue';
import assortmentHelper from '@/assortmentHelper/assortmentHelper';

export default {
  name: 'CatalogList',
  components: {
    ProductCard,
  },
  props: {
    entries: {},
    depth: {
      default: 0,
    },
    enableBulkSelection: {
      type: Boolean,
    },
    disableSelection: {
      type: Boolean,
    },
  },
  emits: ['onToggle', 'onSetSelection', 'onColorChange'],
  computed: {
    name() {
      if (this.entries.filterId === 'root') {
        return this.$t('SA.pages.listmode.productlist.label');
      } else {
        return this.entries.name;
      }
    },
    orderedSubEntries() {
      return Object.keys(this.entries.subFilters)
        .map((subFilter) => this.entries.subFilters[subFilter])
        .sort((a, b) => {
          if (a.primarySellable && b.primarySellable) {
            if (a.primarySellable.weight > b.primarySellable.weight) {
              return 1;
            } else if (a.primarySellable.weight < b.primarySellable.weight) {
              return -1;
            } else {
              return 0;
            }
          } else if (a.orderWeight && b.orderWeight) {
            if (a.orderWeight > b.orderWeight) {
              return 1;
            } else if (a.orderWeight < b.orderWeight) {
              return -1;
            } else {
              return 0;
            }
          }
        })
        .filter((subEntry) => !subEntry.$filtered && subEntry.available);
    },
    placeholders() {
      return this.depth === 0 ? [1, 2, 3, 4, 5] : [];
    },
  },
  methods: {
    onToggle(entry) {
      this.$emit('onToggle', entry);
    },
    onSetSelection(entry, selectionState) {
      this.$emit('onSetSelection', entry, selectionState);
    },
    onColorChange(color, entry) {
      this.$emit('onColorChange', color, entry);
    },
    selectAll(entry) {
      let selectionState = true;
      if (this.allSelected(entry)) {
        selectionState = false;
      }
      assortmentHelper.getTotalAvailableSellables(entry).forEach((sellable) => {
        this.onSetSelection(sellable, selectionState);
      });
    },
    allSelected(entry) {
      return (
        assortmentHelper.getSelectedSellables(entry).filter((s) => !s.$filtered)
          .length ===
        assortmentHelper
          .getTotalAvailableSellables(entry)
          .filter((s) => !s.$filtered).length
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.header {
  margin: 16px 0 10px 0;
}
</style>
