<template>
  <div class="error-page">
    <img src="/images/zombie_light.png" />
    <h3>{{ $t('STATISTICS.CREDIT_DETAILS.ERROR_HEADING') }}</h3>
    <p class="text-grey">
      {{ $t('STATISTICS.CREDIT_DETAILS.ERROR_DESCRIPTION') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'DialogBaseError',
};
</script>

<style lang="scss" scoped>
.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: auto;
    width: 256px;
    margin-top: 8px;
  }

  h3 {
    margin: 24px 0 18px 0;
  }
}
</style>
