<template>
  <div>
    <DomainVerification
      :model-value="settings.pinterestDomainVerificationCode"
      regex="^[A-Za-z0-9]*$"
      heading="POS.SHOP_SETTINGS.PINTEREST_MKT.DOMAIN_VERIFICATION_HEADING"
      subheading="POS.SHOP_SETTINGS.PINTEREST_MKT.DOMAIN_VERIFICATION_SUBHEADING"
      label="POS.SHOP_SETTINGS.PINTEREST_MKT.DOMAIN_VERIFICATION_LABEL"
      help-link-target="https://help.pinterest.com/business/article/claim-your-website"
      @on-change="settings.pinterestDomainVerificationCode = $event"
    />

    <ShopTracking
      :toggle-value="tracking.pinterestEnabled"
      :tracking-id-value="tracking.pinterestTagId"
      toggle-label="SA.tracking.pinterest.label"
      paragraph-text="POS.SHOP_SETTINGS.PINTEREST_MKT.TRACKING_PARAGRAPH"
      input-label="SA.tracking.pinterest.label"
      help-link-target="https://help.pinterest.com/business/article/install-the-pinterest-tag"
      regex="^[0-9]+$"
      @toggle-input="tracking.pinterestEnabled = $event"
      @tracking-id-input="tracking.pinterestTagId = $event"
    />
  </div>
</template>

<script>
import { TrackingData } from 'src/app/partnerarea/pos/shop/shopSavable/TrackingData';
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import DomainVerification from './DomainVerification.vue';
import ShopTracking from './ShopTracking.vue';

export default {
  name: 'Tracking',
  components: {
    ShopTracking,
    DomainVerification,
  },
  mixins: [TrackingData, SettingsData],
  created() {
    this.$store.commit('shop/setLivePreview', { path: '' });
  },
};
</script>
