<template>
  <Banner
    class="embroidery-banner"
    heading="DESIGNS.UPLOAD.EMBROIDERY.HEADING"
    subheading="DESIGNS.UPLOAD.EMBROIDERY.TEXT"
    link="DESIGNS.UPLOAD.EMBROIDERY.CTA"
    :inverted="inverted"
    :condensed="condensed"
    :link-icon="linkIcon"
    :no-icon="condensed"
    @on-link-click="goToEmbroideryUpload"
  />
</template>

<script>
import localeService from '@/localeService/localeService';
import Banner from './Banner.vue';
import embroideryServiceLinks from '@/externalLinks/embroideryServiceLinks';

export default {
  name: 'GraphicsServiceBanner',
  components: {
    Banner,
  },
  props: {
    inverted: {
      type: Boolean,
    },
    condensed: {
      type: Boolean,
    },
    linkIcon: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    goToEmbroideryUpload() {
      const links = embroideryServiceLinks[SPRD.PLATFORM];
      window.open(links[localeService.getLocale()] || links.default, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  margin: 24px 0;

  :deep(a) {
    min-width: 150px;
  }
}
</style>
