<template>
  <div class="box">
    <div class="box-header">
      <h2>{{ $t('ACCOUNT.FINANCE.HEADING') }}</h2>
    </div>
    <MfaRequired
      v-slot="{ mfaRequirementPassed }"
      :show-content-without-pass="!paymentDataMissing"
    >
      <div class="box-content">
        <RadioButtonGroup
          :items="paymentTypes"
          :model-value="currentPaymentType"
          :item-key="(type) => type.id"
          :item-to-string="(type) => type.name"
          :disabled="!mfaRequirementPassed"
          @update:model-value="(type) => (data.type = type.id)"
        >
        </RadioButtonGroup>

        <div v-if="data.type === 'BANK'" class="payment-info columns">
          <div>
            <LabelInput
              :class="{ 'has-error': validation.errors.bankAccountHolder }"
              :disabled="!mfaRequirementPassed"
            >
              <label for="bankAccountHolder">{{
                $t('ACCOUNT.FINANCE.ACCOUNT_HOLDER')
              }}</label>
              <DebounceInput
                id="bankAccountHolder"
                type="text"
                :model-value="data.accountHolder"
                @update:model-value="updateBankAccountHolder"
              />
              <AccountError
                v-if="validation.errors.bankAccountHolder"
                :reason="validation.errors.bankAccountHolder.reason"
              ></AccountError>
            </LabelInput>

            <div class="bank-info columns">
              <LabelInput
                :class="{ 'has-error': validation.errors.bankName }"
                :disabled="!mfaRequirementPassed"
              >
                <label for="bankName">{{
                  $t('ACCOUNT.FINANCE.BANK_NAME')
                }}</label>
                <DebounceInput
                  id="bankName"
                  type="text"
                  :model-value="data.bankName"
                  @update:model-value="updateBankName"
                />
                <AccountError
                  v-if="validation.errors.bankName"
                  :reason="validation.errors.bankName.reason"
                ></AccountError>
              </LabelInput>

              <LabelInput
                :class="{ 'has-error': validation.errors.bankLocation }"
                :disabled="!mfaRequirementPassed"
              >
                <label for="bankLocation">{{
                  $t('ACCOUNT.FINANCE.BANK_LOCATION')
                }}</label>
                <Dropdown
                  id="bankLocation"
                  :items="data.bankCountries"
                  :value="currentBankLocation"
                  :item-key="(country) => country.countryCode"
                  :item-to-string="(country) => country.name"
                  @change="setBankCountry"
                >
                </Dropdown>
                <AccountError
                  v-if="validation.errors.bankLocation"
                  :reason="validation.errors.bankLocation.reason"
                ></AccountError>
              </LabelInput>
            </div>
          </div>

          <div>
            <LabelInput
              :class="{ 'has-error': validation.errors.accountId }"
              :disabled="!mfaRequirementPassed"
            >
              <div class="position-relative">
                <label for="accountId">{{
                  $t('ACCOUNT.FINANCE.ACCOUNT_ID')
                }}</label>
                <DebounceInput
                  id="accountId"
                  type="text"
                  :model-value="data.accountId"
                  :placeholder="obfuscatedData.accountId"
                  @update:model-value="updateAccountId"
                />
              </div>
              <AccountError
                v-if="validation.errors.accountId"
                :reason="validation.errors.accountId.reason"
              ></AccountError>
            </LabelInput>

            <LabelInput
              :class="{ 'has-error': validation.errors.bankId }"
              :disabled="!mfaRequirementPassed"
            >
              <div class="position-relative">
                <label for="bankId">{{ $t('ACCOUNT.FINANCE.BANK_ID') }}</label>
                <DebounceInput
                  id="bankId"
                  type="text"
                  :model-value="data.bankId"
                  :placeholder="obfuscatedData.bankId"
                  @update:model-value="updateBankId"
                />
              </div>
              <AccountError
                v-if="validation.errors.bankId"
                :reason="validation.errors.bankId.reason"
              ></AccountError>
            </LabelInput>
          </div>
        </div>

        <div v-if="data.type === 'PAYPAL'" class="payment-info columns">
          <LabelInput
            :class="{ 'has-error': validation.errors.paypalAccountHolder }"
            :disabled="!mfaRequirementPassed"
          >
            <label for="paypalAccountHolder">{{
              $t('ACCOUNT.FINANCE.ACCOUNT_HOLDER')
            }}</label>
            <DebounceInput
              id="paypalAccountHolder"
              type="text"
              :model-value="data.accountHolder"
              @update:model-value="updatePaypalAccountHolder"
            />
            <AccountError
              v-if="validation.errors.paypalAccountHolder"
              :reason="validation.errors.paypalAccountHolder.reason"
            ></AccountError>
          </LabelInput>

          <LabelInput
            :class="{ 'has-error': validation.errors.paypalEmail }"
            :disabled="!mfaRequirementPassed"
          >
            <label for="paypalEmail">{{ $t('ACCOUNT.CONTACT.EMAIL') }}</label>
            <DebounceInput
              id="paypalEmail"
              type="text"
              :model-value="data.email"
              @update:model-value="updatePaypalEmail"
            />
            <AccountError
              v-if="validation.errors.paypalEmail"
              :reason="validation.errors.paypalEmail.reason"
            ></AccountError>
          </LabelInput>
        </div>
        <AccountPageActions
          :save="savePaymentInfo"
          :disabled="!hasChanged"
          @on-revert="reset"
        />
      </div>
    </MfaRequired>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import RadioButtonGroup from '@/RadioButtonGroup/RadioButtonGroup.vue';
import LabelInput from '@/labelInput/LabelInput.vue';
import AccountError from '../errorMessage/AccountError.vue';
import Dropdown from '@/Dropdown/Dropdown.vue';
import DebounceInput from 'src/app/components/input/DebounceInput.vue';
import AccountPageActions from 'src/app/partnerarea/account/AccountPageActions.vue';
import MfaRequired from '../security/mfa/MfaRequired.vue';

export default {
  name: 'Financial',
  components: {
    RadioButtonGroup,
    LabelInput,
    AccountError,
    Dropdown,
    AccountPageActions,
    DebounceInput,
    MfaRequired,
  },
  data() {
    return {
      paymentTypes: [
        { id: 'BANK', name: this.$t('ACCOUNT.FINANCE.BANK_ACCOUNT') },
        { id: 'PAYPAL', name: 'PayPal' },
      ],
    };
  },
  computed: {
    ...mapState({
      data: (state) => state.userPaymentInfo.data,
      obfuscatedData: (state) => state.userPaymentInfo.obfuscatedData,
      validation: (state) => state.userPaymentInfo.validation,
    }),
    ...mapGetters({
      hasChanged: 'userPaymentInfo/hasChanged',
      paymentDataMissing: 'userPaymentInfo/paymentDataMissing',
    }),
    currentPaymentType() {
      return this.paymentTypes.find((p) => p.id === this.data.type);
    },
    currentBankLocation() {
      return this.data.bankCountries.find(
        (country) => country.countryCode === this.data.bankLocation,
      );
    },
  },
  methods: {
    ...mapMutations({
      updateBankAccountHolder: 'userPaymentInfo/setBankAccountHolder',
      updateBankName: 'userPaymentInfo/setBankName',
      updateAccountId: 'userPaymentInfo/setAccountId',
      updateBankId: 'userPaymentInfo/setBankId',
      updateBankCountry: 'userPaymentInfo/setBankCountry',
      updatePaypalAccountHolder: 'userPaymentInfo/setPaypalAccountHolder',
      updatePaypalEmail: 'userPaymentInfo/setPaypalEmail',
      reset: 'userPaymentInfo/reset',
    }),
    ...mapActions({
      savePaymentInfo: 'userPaymentInfo/savePaymentInfo',
    }),
    setBankCountry(country) {
      this.updateBankCountry(country.countryCode);
    },
  },
};
</script>

<style lang="scss" scoped>
.labelInput,
.radiobutton-group {
  &:not(:last-child) {
    margin-bottom: 16px !important;
  }
}

.payment-info.columns > div {
  min-width: 350px;
}

.bank-info.columns > div {
  min-width: 200px;
}
</style>
