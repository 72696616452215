import apiClient from '@/api/client/apiClient';

const intersection = function () {
  return Array.from(arguments).reduce((previous, current) => {
    return previous.filter((element) => {
      return current.indexOf(element) > -1;
    });
  });
};

export default {
  async getTagsByPrefix(prefix, options = {}) {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `/api/v1/tags`,
      params: Object.assign(
        {
          prefix,
          simple: true,
        },
        options,
      ),
    });

    return data;
  },
  async getRelatedTagsByPrefix(term, options = {}) {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `/api/v1/relatedtags`,
      params: Object.assign(
        {
          term,
          simple: true,
        },
        options,
      ),
    });

    return data;
  },
  async checkIfTagsExists(tags, options) {
    const result = [];

    if (!tags || tags.length === 0) {
      return Promise.resolve(result);
    } else {
      const promises = tags.map((tag) => {
        return this.getTagsByPrefix(tag, options);
      });

      const tagResults = await Promise.all(promises);
      const tagResultsLowerCase = tagResults.map((tagResult) => {
        return tagResult.toString().toLowerCase().split(',');
      });

      for (let i = 0, len = tagResults.length; i < len; i++) {
        const index = tagResultsLowerCase[i].indexOf(tags[i].toLowerCase());
        if (index > -1) {
          result.push(tagResults[i][index]);
        }
      }
      return result;
    }
  },
  async calculateRelatedTags(translation, options) {
    // TODO: optimize performance
    const ideaTags = translation.tags || [];
    const ideaTagsLowerArray = ideaTags.join(',').toLowerCase().split(',');

    // separate single words of idea name, remove words that exist in tags
    let ideaName = translation.name;
    if (ideaName) {
      ideaName = ideaName.split(' ').filter((ideaNameWord) => {
        return ideaTagsLowerArray.indexOf(ideaNameWord.toLowerCase()) < 0;
      });
    }
    let ideaNameTags = [];

    // check if idea name words exist as tags
    try {
      ideaNameTags = await this.checkIfTagsExists(ideaName, options);
    } catch (_) {
      // nothing
    }

    if (!ideaTags.length) {
      return Promise.resolve([...new Set(ideaNameTags)]);
    } else {
      // get related tags for all current tags
      // todo: performance: only use last idea tag for related tags
      const promises = [ideaTags[ideaTags.length - 1]].map((ideaTag) => {
        return this.getRelatedTagsByPrefix(ideaTag, options);
      });
      const relatedTagsResults = await Promise.all(promises);
      const combinedRelatedTags = relatedTagsResults.map(
        (relatedTagsResult) => {
          let simpleRelatedTags = [];
          if (relatedTagsResult.relatedTags) {
            simpleRelatedTags = relatedTagsResult.relatedTags.map(
              (relatedTag) => {
                return relatedTag.name.toLowerCase();
              },
            );
          }
          return simpleRelatedTags;
        },
      );
      let commonRelatedTags = intersection.apply(this, combinedRelatedTags);
      commonRelatedTags = commonRelatedTags.filter(
        (tag) => !ideaTags.includes(tag),
      );

      //unions
      return [
        ...new Set([
          ...ideaNameTags,
          ...commonRelatedTags,
          ...combinedRelatedTags[combinedRelatedTags.length - 1],
        ]),
      ];
    }
  },
};
