<template>
  <div>
    <div class="entry sp-subheader">
      {{ $t('POS.SHOP_SETTINGS.STARTPAGE.FOLLOW_US.SUBHEADING') }}
    </div>
    <ToggleSwitch
      v-model="startPage.followUsEnabled"
      label="POS.SHOP_SETTINGS.STARTPAGE.FOLLOW_US.ENABLE_FOLLOW_US"
      :disabled="!followUsPossible"
    />
    <LegalLabelInput
      v-model="startPage.followUsCaption"
      class="sprd-shop-input"
      maxlength="50"
      :disabled="disabled"
      :label="$t('POS.SHOP_SETTINGS.STARTPAGE.CAPTION_LABEL')"
    />
    <LegalLabelInput
      v-model="startPage.followUsText"
      class="sprd-shop-input"
      type="textarea"
      maxlength="300"
      :disabled="disabled"
      :label="$t('POS.SHOP_SETTINGS.STARTPAGE.TEXT_LABEL')"
    />
  </div>
</template>

<script>
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import LegalLabelInput from '../../common/LegalLabelInput.vue';
import { StartPageData } from 'src/app/partnerarea/pos/shop/shopSavable/StartPageData';

export default {
  name: 'FollowUs',
  components: {
    ToggleSwitch,
    LegalLabelInput,
  },
  mixins: [StartPageData],
  computed: {
    followUsPossible() {
      const { followUsPossible } = this.startPage;
      return typeof followUsPossible !== 'boolean' || followUsPossible;
    },
    followUsEnabled() {
      return this.startPage.followUsEnabled;
    },
    disabled() {
      return !this.followUsPossible || !this.followUsEnabled;
    },
  },
  watch: {
    channels: {
      handler() {
        this.$store.commit('shop/setLivePreview', {
          path: '',
          section: 'sprd-startpage-follow-us',
          visible: this.followUsPossible,
        });
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../startPageCommon.scss';
</style>
