<template>
  <div class="feature-feedback">
    <h3>{{ $t('FEATURE_FEEDBACK.HEADING') }}</h3>
    <div class="options" :class="{ selected: !!selectedOption }">
      <button
        class="sprd-btn-light option"
        :class="{ selected: selectedOption === OPTIONS.POSITIVE }"
        @click="submitFeedback(OPTIONS.POSITIVE)"
      >
        👍
      </button>
      <button
        class="sprd-btn-light option"
        :class="{ selected: selectedOption === OPTIONS.NEGATIVE }"
        @click="submitFeedback(OPTIONS.NEGATIVE)"
      >
        👎
      </button>
      <p v-if="selectedOption === OPTIONS.POSITIVE">
        {{ $t('FEATURE_FEEDBACK.POSITIVE_FOLLOW_UP') }}
      </p>
      <div v-if="selectedOption === OPTIONS.NEGATIVE">
        <p>{{ $t('FEATURE_FEEDBACK.NEGATIVE_FOLLOW_UP') }}</p>
        <a :href="mailToLink" class="link-underlined">{{
          $t('GENERAL.GIVE_FEEDBACK')
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import analytics from '@/tracking/analytics';
import contactData from '@/contactData/contactData';

export default {
  name: 'FeatureFeedback',
  props: {
    featureName: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      OPTIONS: {
        POSITIVE: 'POSITIVE',
        NEGATIVE: 'NEGATIVE',
      },
      selectedOption: null,
      mail: contactData.getMpMail(),
    };
  },
  computed: {
    ...mapState({
      userId: (s) => s.user.data.id,
    }),
    mailToLink() {
      return `mailTo:${this.mail}?bcc=partnerapp@spreadshirt.net&subject=Partner Area Feedback ${this.featureName} (Spread-ID ${this.userId})`;
    },
  },
  methods: {
    submitFeedback(option) {
      if (this.selectedOption) {
        return;
      }
      this.selectedOption = option;

      analytics.logEvent('feature_feedback', {
        feature: this.featureName.replace(/\s+/g, '-').toLowerCase(),
        result: this.selectedOption,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/type';

h3 {
  margin: 0 0 12px 0;
}

.options {
  display: flex;
  align-items: center;

  button {
    font-size: 20px;
    padding: 6px 15px;
    border-radius: $border-radius-medium;
    margin: 0 8px 0 0;

    &.selected {
      border-color: $pa-color-green;
    }
  }

  &.selected {
    button:not(.selected) {
      border-color: $grey30;
      background-color: $grey5;
      filter: grayscale(80%);
    }
  }

  p {
    margin: 0;
  }

  div {
    display: flex;
    align-items: center;

    a {
      margin: 0 0 0 4px;
      color: $pa-color-main;
    }
  }
}
</style>
