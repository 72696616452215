<template>
  <div v-if="availableHotspots.length" class="product-settings-hotspots">
    <SelectionTiles
      v-slot="{ item }"
      :items="availableHotspots"
      :item-key="(hotspot) => hotspot.key"
      :item-selected="(hotspot) => hotspot.key === currentHotspot.key"
      :item-to-string="(item) => item.name"
      :min-item-width="130"
      @change="selectHotspot"
    >
      <img :src="item.image" />
    </SelectionTiles>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import compositionCategoryHelper from '@/composition/compositionCategoryHelper';
import compositionHelper from '@/composition/compositionHelper';
import { HOTSPOTS_ORDERED, HOTSPOTS } from '@/states/hotspots';
import { useStore } from 'vuex';
import SelectionTiles from 'src/app/components/selectionTiles/SelectionTiles.vue';

const store = useStore();

const emit = defineEmits(['onSelect']);
const props = defineProps({
  composition: {
    type: Object,
    required: true,
  },
  selectedHotspot: {
    type: String,
  },
});

const availableHotspots = computed(() => {
  const compositionHotspots = compositionCategoryHelper.getAllAvailableHotspots(
    store.state.publishingCore.categories,
  );
  return HOTSPOTS_ORDERED.filter((hotspot) =>
    compositionHotspots.includes(hotspot.key),
  );
});
const currentHotspot = computed(
  () =>
    HOTSPOTS[
      props.selectedHotspot ||
        compositionHelper.getDefaultHotspot(props.composition)
    ],
);
const selectHotspot = (hotspot) => {
  emit('onSelect', hotspot.key);
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';

.hotspot {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  min-width: 100px;

  span {
    text-align: center;
  }
}
</style>
