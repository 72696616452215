<template>
  <div>
    <Toast v-if="relevantBlockedPeriod" variant="black" class="promo-toast">
      {{
        $t('POS.SHOP_SETTINGS.PROMOTIONS.BLOCKED_PERIOD_HINT', {
          from: date(relevantBlockedPeriod.start, 'shortDate'),
          to: date(relevantBlockedPeriod.end, 'shortDate'),
        })
      }}
    </Toast>
    <table class="promoTable">
      <thead>
        <tr>
          <th>{{ $t('DASHBOARD.PROMOTION') }}</th>
          <th>{{ $t('SA.shopDashboard.shopStatus.active') }}</th>
          <th>{{ $t('POS.SHOP_SETTINGS.PROMOTIONS.DATES') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="promo in promotions.promotions" :key="promo.id">
          <td
            class="promoDescr"
            :class="{
              promoDescrHistoric:
                promo.historic && !promo.activeStateChangeable,
            }"
          >
            {{ promo.text }}
          </td>
          <td class="toggleSwitch">
            <ToggleSwitch
              :model-value="promo.active && settings.promoChecked"
              :disabled="!settings.promoChecked || !promo.activeStateChangeable"
              @update:model-value="togglePromoActivationState(promo.id)"
            />
          </td>
          <td>
            <div class="datesWrapper">
              <div class="dateContainer">
                <button
                  class="datesBtn"
                  type="button"
                  :disabled="!promo.moveable"
                  @click="togglePromoMoveDialog(promo.id)"
                >
                  <Icon icon="calendar"></Icon>
                  {{ date(promo.start) }} - {{ date(promo.end) }}
                </button>

                <div
                  v-if="openedPromoId === promo.id"
                  class="datePickerWrapper"
                >
                  <VueDatePicker
                    v-click-outside="closePromoDatePicker"
                    :model-value="openedPromo.start"
                    inline
                    utc
                    auto-apply
                    menu-class-name="customCalendar"
                    :highlight="openedPromo.currentTimeframe"
                    :enable-time-picker="false"
                    :disabled-dates="openedPromo.disabledDates"
                    @internal-model-change="
                      (event) => movePromo(promo.id, event)
                    "
                  >
                    <template #input-icon />
                    <template #clear-icon />
                  </VueDatePicker>
                </div>
              </div>
              <div
                v-if="promoIdsWithOverlaps.includes(promo.id)"
                v-tooltip="{
                  content: $t('POS.SHOP_SETTINGS.PROMOTIONS.OVERLAP_WARNING'),
                }"
              >
                <Icon icon="attention" class="overlapIcon" />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import { differenceInDays } from 'date-fns/differenceInDays';
import { addDays } from 'date-fns/addDays';
import { subDays } from 'date-fns/subDays';
import { parseISO } from 'date-fns/parseISO';
import { isAfter } from 'date-fns/isAfter';
import { isBefore } from 'date-fns/isBefore';
import { PromotionsData } from 'src/app/partnerarea/pos/shop/shopSavable/PromotionsData';
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import Toast from 'src/app/components/toast/Toast.vue';

const toDateString = (date) => date.toISOString().substring(0, 10);

export default {
  name: 'Promotions',
  components: {
    ToggleSwitch,
    VueDatePicker,
    Toast,
  },
  mixins: [PromotionsData, SettingsData],
  data() {
    return {
      openedPromoId: '',
    };
  },
  computed: {
    relevantBlockedPeriod() {
      if (!this.promotions.blockedPeriods?.length) {
        return null;
      }

      if (
        isAfter(
          new Date(),
          subDays(parseISO(this.promotions.blockedPeriods[0].start), 60),
        ) &&
        isBefore(new Date(), parseISO(this.promotions.blockedPeriods[0].end))
      ) {
        return this.promotions.blockedPeriods[0];
      } else {
        return null;
      }
    },
    openedPromo() {
      const match = this.promotions.promotions.find(
        (p) => p.id === this.openedPromoId,
      );
      if (!match) {
        return {};
      }
      const days = differenceInDays(new Date(match.end), new Date(match.start));
      const occupiedTimeframes = [...this.promotions.blockedPeriods];
      this.promotions.promotions
        .filter((promo) => promo.id !== this.openedPromoId)
        .forEach((promo) => occupiedTimeframes.push(promo));
      return {
        start: match.start,
        disabledDates: (thisDate) => {
          if (
            isAfter(thisDate, new Date(match.latestMoveDate)) ||
            isAfter(new Date(match.earliestMoveDate), addDays(thisDate, 1))
          ) {
            return true;
          }

          const thisStart = toDateString(addDays(thisDate, 1));
          const thisEnd = toDateString(addDays(thisDate, days + 1));
          // from https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
          // we rely on string comparision because date-fns sucks
          return occupiedTimeframes.some(
            (timeframe) =>
              thisStart <= timeframe.end && thisEnd >= timeframe.start,
          );
        },
        currentTimeframe: (d) => {
          return (
            isAfter(d, new Date(match.start)) && isAfter(new Date(match.end), d)
          );
        },
      };
    },
    promoIdsWithOverlaps() {
      return this.promotions.promotions
        .filter((promo) => !promo.historic)
        .filter((promo) =>
          this.promotions.promotions
            .filter((promo2) => promo2.id !== promo.id)
            // from https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
            .some(
              (promo2) =>
                promo.start <= promo2.end && promo.end >= promo2.start,
            ),
        )
        .map((promo) => promo.id);
    },
  },
  created() {
    this.$store.commit('shop/setLivePreviewVisible', false);
  },
  methods: {
    togglePromoActivationState(promoId) {
      const match = this.promotions.promotions.find((p) => p.id === promoId);
      match.active = !match.active;
    },
    togglePromoMoveDialog(promoId) {
      this.openedPromoId = this.openedPromoId === promoId ? '' : promoId;
    },
    movePromo(promoId, date) {
      if (!this.openedPromoId) {
        return;
      }
      const match = this.promotions.promotions.find((p) => p.id === promoId);
      const days = differenceInDays(new Date(match.end), new Date(match.start));
      const end = addDays(date, days);
      match.start = toDateString(date);
      match.end = toDateString(end);
    },
    closePromoDatePicker() {
      this.openedPromoId = '';
    },
  },
};
</script>

<style lang="scss">
@import 'src/scss/styleguide/colors';

#application.state-shop-area-pricing-and-promos-promos {
  .context {
    .settings {
      background-color: $grey5;
    }
  }
}
</style>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';

.promoTable {
  width: 100%;
  margin-top: 16px;
  background-color: white;

  th {
    padding: 16px;
  }

  td {
    padding: 0 15px;
    height: 70px;
  }

  thead {
    background-color: $grey5;
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: $grey5;
      }
    }
  }
}

.toggleSwitch {
  .sprd-toggle-switch {
    margin: 0;
  }
  width: 20%;
}

.dateContainer {
  position: relative;
  display: inline-block;
}

.datesWrapper {
  display: flex;
  align-items: center;
}

.datesBtn {
  display: flex;
  align-items: center;
  background: none;
  border: 1px solid $sprd-color-medium-grey;
  border-radius: $border-radius-medium;
  padding: 9px 12px;
  white-space: nowrap;

  &:disabled {
    color: $sprd-color-medium-grey;
  }

  > svg {
    margin-right: 6px;
  }
}

.overlapIcon {
  width: 32px;
  height: 32px;
  margin-left: 0.5rem;
  color: $raspberry;
}

.promoDescr {
  width: 60%;
}

.promoDescrHistoric {
  color: $grey20;
}

:deep(.datePickerWrapper) {
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 10;

  .dp__menu {
    border-radius: $border-radius-medium !important;
  }
}

:deep(.customCalendar) {
  .dp__action_row {
    display: none;
  }

  > header {
    .day__month_btn {
      font-weight: bold;
    }
    .prev::after {
      border-right: 10px solid $pa-color-main;
    }
    .next::after {
      border-left: 10px solid $pa-color-main;
    }
  }
  .highlighted {
    background: $pa-color-main-light;
    color: $white;
  }
  .highlight-start {
    background: $pa-color-main !important;
    border-radius: 16px 0 0 16px;
  }
  .highlight-end {
    border-radius: 0 16px 16px 0;
  }
}

:deep(.promo-toast),
:deep(.toast) {
  height: auto;
}
</style>
