<template>
  <div
    :id="`idea${idea.id}`"
    class="idea-list-entry"
    :class="`${selected ? 'selected' : null} ${
      isProcessing ? 'processing' : null
    } ${state.class}`"
  >
    <div v-if="isSelectable" class="checkbox-wrapper">
      <Checkbox
        :model-value="selected"
        @update:model-value="$emit('toggleSelection')"
      />
    </div>
    <a href="#" @click.prevent="!isProcessing && $emit('edit')">
      <div class="image">
        <IdeaPreviewImage
          class="preview-image design-preview"
          :idea="idea"
          :checked-background="true"
          :width="150"
          :height="150"
        />
      </div>
      <div class="meta-data">
        <h4 class="text-truncate">{{ currentTranslation.name }}</h4>
        <ul v-if="tags.length" class="tag-list">
          <li v-for="tag in tags" :key="tag">{{ tag }}</li>
        </ul>
        <div v-if="!tags.length" class="placeholder">
          {{ $t('DESIGNS.NO_TAGS') }}
        </div>
      </div>
      <div class="pos">
        <ul class="pos-state-list">
          <li v-for="posState in posByState" :key="posState.state.key">
            <IdeaStatus :state="posState.state" />

            <ul>
              <li
                v-for="publishingDetail in posState.publishingDetails"
                :key="publishingDetail.id"
                class="pos-entry"
              >
                <p class="pos-name">
                  {{
                    publishingDetail.pointOfSale.name ||
                    $t('GENERAL.MARKETPLACE')
                  }}
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <div v-if="!getPublishingDetails(idea).length" class="placeholder">
          <IdeaStatus :state="UNPUBLISHED_STATE" />
          <p class="pos-name">{{ $t('DESIGNS.BULK.NO_POS') }}</p>
        </div>
      </div>
      <div class="status-text">
        <IdeaStatus :state="state" />
        <p class="pos-name">{{ $t(state.description) }}</p>
        <IdeaStuckInProcressing
          v-if="stuckInProcessing"
          :idea="idea"
          class="processing-stuck"
        />
      </div>
    </a>
  </div>
</template>

<script>
import Checkbox from '@/checkbox/Checkbox.vue';
import IdeaPreviewImage from 'src/app/partnerarea/idea/previewImage/IdeaPreviewImage.vue';
import ideaHelper from '@/ideaHelper/ideaHelper';
import { mapGetters } from 'vuex';
import IDEA_STATES from '@/api/models/ideaStates/ideaStates';
import IdeaStatus from 'src/app/partnerarea/idea/detail/IdeaStatus.vue';
import IdeaStuckInProcressing from '../IdeaStuckInProcressing.vue';

export default {
  name: 'IdeaListEntry',
  components: {
    Checkbox,
    IdeaPreviewImage,
    IdeaStatus,
    IdeaStuckInProcressing,
  },
  props: ['idea', 'displayOnboarding'],
  emits: ['toggleSelection', 'edit'],
  data() {
    return {
      UNPUBLISHED_STATE: IDEA_STATES.UNPUBLISHED,
      stuckInProcessing: ideaHelper.isStuckInProcessing(this.idea),
    };
  },
  computed: {
    ...mapGetters({
      isSelected: 'ideas/isSelected',
      isMpScope: 'publishing/isMpScope',
      isShopScope: 'publishing/isShopScope',
    }),
    selected() {
      return this.isSelected(this.idea);
    },
    tags() {
      if (!this.currentTranslation.tags) {
        return [];
      }

      return this.currentTranslation.tags;
    },
    publishingDetails() {
      return this.getPublishingDetails(this.idea);
    },
    currentTranslation() {
      return ideaHelper.getCurrentTranslation(this.idea) || {};
    },
    isSelectable() {
      return ideaHelper.isSelectable(this.idea);
    },
    isProcessing() {
      return ideaHelper
        .determineIdeaState(this.idea.processingState)
        .isProcessing();
    },
    state() {
      return ideaHelper.getState(this.idea);
    },
    posByState() {
      return ideaHelper.getPublishingDetailsSortedByState(
        this.idea,
        ideaHelper.getRelevantPosTypesForScope(
          this.$store.state.publishing.currentScope,
        ),
      );
    },
  },
  methods: {
    getNumberOfTagsToDisplay() {
      if (!this.currentTranslation.tags) {
        return 0;
      }

      const tagLengthPerWindowSize = {
        1000: 30,
        1200: 40,
        1400: 50,
        1600: 50,
        [Number.MAX_SAFE_INTEGER]: 50,
      };

      let result;
      for (let size in tagLengthPerWindowSize) {
        if (window.innerWidth < size) {
          result = this.currentTranslation.tags
            .concat('temp')
            .join(',')
            .substr(
              0,
              tagLengthPerWindowSize[size] +
                this.currentTranslation.tags.length -
                1,
            )
            .split(',')
            .slice(0, -1).length;
          break;
        }
      }
      return result;
    },
    getPublishingDetails: ideaHelper.getPublishingDetails,
    getPublishingStatus(publishingDetail) {
      return ideaHelper.determineIdeaState(publishingDetail.status);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/utils';

.idea-list-entry {
  position: relative;

  & > a {
    display: flex;
    padding: 16px 0 16px 52px;

    & > div {
      margin-right: 16px;
    }
  }

  &:hover {
    box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.24);
  }

  &.processing {
    box-shadow: none;

    & > a {
      cursor: default;
    }
  }

  h4 {
    margin: 0;
  }

  .checkbox-wrapper {
    position: absolute;
    left: 16px;
    top: 56px;
  }

  .image {
    .preview-image {
      width: 100px;
      height: 100px;
      position: relative;
      display: block;
      border-radius: $border-radius-medium;
      overflow: hidden;
    }
  }

  .meta-data {
    flex-grow: 1;
    width: 45%;
    max-width: 45%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    h4 {
      margin-bottom: 8px;
    }
  }

  .pos {
    flex-grow: 1;
    width: 20%;
    display: flex;
    flex-direction: column;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .pos-state-list > li:not(:first-child) {
      margin-top: 16px;
    }
  }

  .pos-name {
    margin: 0 0 0 28px;
    @extend .text-sm;
    margin-top: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .status-text {
    display: none;
  }

  &.idea-state-processing,
  &.idea-state-rejected,
  &.idea-state-attention {
    .pos {
      display: none;
    }

    .meta-data {
      h4 {
        margin: 0;
      }

      ul,
      div,
      a {
        display: none;
      }
    }

    .status-text {
      display: block;
    }
  }

  &.idea-state-processing {
    .edit-link {
      visibility: hidden;
    }
  }

  .processing-stuck {
    margin: 16px 0 0 28px;
  }
}
</style>
