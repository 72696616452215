<template>
  <div class="modal-container dms-group-change-dialog">
    <div class="modal-body">
      <div class="intro">
        <h3>{{ $t(currentGroup.meta.changeHeading) }}</h3>
        <p>
          {{ $t(currentGroup.meta.changeDescription) }}
        </p>
        <SmartFaqLink
          :link="currentGroup.meta.helpLinkTarget"
          class="help-link"
        />
      </div>
      <div class="divider"></div>
      <div class="new-group">
        <img :src="currentGroup.meta.image" alt="Star Class" />
        <div>
          <p v-if="starCount">Star Class {{ starCount }}</p>
          <h2>{{ $t(currentGroup.meta.name) }}</h2>
          <div v-if="starCount" class="stars">
            <Icon
              v-for="index in 5"
              :icon="index <= starCount ? 'star-filled' : 'star'"
            />
          </div>
          <DmsPropertyList />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DmsPropertyList from 'src/app/components/dms/DmsPropertyList.vue';
import { DMS_PROPERTIES } from '@/api/models/dms/dmsStates';
import confetti from '@/confetti/confetti.js';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';

export default {
  name: 'DmsGroupChangeDialog',
  components: {
    DmsPropertyList,
    SmartFaqLink,
  },
  props: ['modalInstance', 'data'],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      hasMarketplace: 'user/hasMarketplace',
      propertyList: 'dms/propertyList',
      getProperty: 'dms/getProperty',
      currentGroup: 'dms/currentGroup',
      publishingCount: 'dms/publishingCount',
    }),
    starCount() {
      return this.getProperty(DMS_PROPERTIES.STAR_COUNT).value;
    },
  },
  created() {
    if (this.starCount) {
      setTimeout(() => {
        confetti.randomCanons();
      }, 1500);
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/buttons';
@import 'src/scss/utils';

.dms-group-change-dialog {
  max-width: 620px;
  width: 70vw;
}

.intro {
  h3 {
    margin: 0 0 12px 0;
  }

  p {
    color: $grey60;
    margin: 0 0 16px 0;
  }
}

.divider {
  background-color: $grey5;
  height: 2px;
  margin: 16px -40px;
}

.new-group {
  display: flex;
  align-items: flex-start;

  img {
    margin-right: 24px;
    width: 128px;
  }

  p {
    @extend .text-xs;
    color: $grey60;
  }

  h2 {
    margin: 0 0 8px 0;
  }

  .stars {
    display: flex;
    margin-bottom: 16px;

    .icon {
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
  }
}
</style>
