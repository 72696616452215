import eventBus from '@/eventBus/eventBus';

export default {
  namespaced: true,
  state: {
    currentDialogs: [],
  },
  getters: {
    dialogCount: (state) => state.currentDialogs.length,
  },
  mutations: {
    addDialog(state, dialog) {
      state.currentDialogs.push(dialog);

      eventBus.$emit('dialogs:added');
    },
    removeDialog(state, id) {
      if (!state.currentDialogs?.length || !id) {
        return;
      }

      state.currentDialogs.splice(
        state.currentDialogs.indexOf(
          state.currentDialogs.find((dialog) => dialog.id === id),
        ),
        1,
      );

      eventBus.$emit('dialogs:removed');
    },
  },
};
