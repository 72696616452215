<template>
  <div class="assortment-catalog-content">
    <div class="filters overflow-y">
      <AssortmentFilter
        :assortment="assortment"
        :active-filter="activeFilter"
        :flags="flags"
        @set-filter="(...args) => $emit('setFilter', ...args)"
      />
      <ColorFilter
        v-if="activeColorFilter"
        :assortment="assortment"
        :active-filter="activeFilter"
        :active-color-filter="activeColorFilter"
        @on-color-select="(...args) => $emit('onColorSelect', ...args)"
        @on-bulk-apply="(...args) => $emit('onColorBulkApply', ...args)"
      />
    </div>
    <div class="catalog-content">
      <div class="filtered-content overflow-y">
        <div>
          <CatalogList
            :enable-bulk-selection="enableBulkSelection"
            :entries="activeFilter"
            :disable-selection="selectionLimitReached"
            @on-toggle="(...args) => $emit('onToggle', ...args)"
            @on-set-selection="(...args) => $emit('onSetSelection', ...args)"
            @on-color-change="(...args) => $emit('onColorChange', ...args)"
          />
          <UnavailableCatalogList :entries="activeFilter" :flags="flags" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssortmentFilter from '../filter/AssortmentFilter.vue';
import ColorFilter from '../filter/ColorFilter.vue';
import CatalogList from './CatalogList.vue';
import UnavailableCatalogList from './UnavailableCatalogList.vue';
import assortmentHelper from '@/assortmentHelper/assortmentHelper';

export default {
  name: 'AssortmentCatalogContent',
  components: {
    AssortmentFilter,
    ColorFilter,
    CatalogList,
    UnavailableCatalogList,
  },
  props: {
    assortment: {},
    activeFilter: {},
    flags: {},
    activeColorFilter: {},
    enableBulkSelection: {
      type: Boolean,
    },
    selectionLimit: {
      type: Number,
    },
  },
  emits: [
    'setFilter',
    'onColorSelect',
    'onColorBulkApply',
    'onToggle',
    'onSetSelection',
    'onColorChange',
  ],
  data() {
    return {};
  },
  computed: {
    selectionLimitReached() {
      if (!this.selectionLimit) {
        return false;
      }

      return (
        assortmentHelper.getSelectedSellables(this.assortment).length >=
        this.selectionLimit
      );
    },
  },
  watch: {
    activeFilter: function () {
      this.scrollFilterContentToTop();
    },
  },
  methods: {
    scrollFilterContentToTop() {
      const rootCatalogList = document.querySelector('.catalog-list.depth-0');
      if (rootCatalogList) {
        rootCatalogList.scrollIntoView(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/animations';

.assortment-catalog-content {
  display: flex;
  width: 100%;
}

.catalog-content {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  opacity: 1;
  animation: fadein 0.5s linear both;
  animation-delay: 1s;
}

.filters {
  background-color: #fff;
  width: 270px;
  flex-shrink: 0;
  border-right: 2px solid $grey5;
  animation: slidein 0.25s linear both;
  animation-delay: 0.5s;

  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
}

.filtered-content {
  padding: 0 16px 0 16px;
  flex-grow: 1;

  & > div {
    max-width: 1400px;
    margin: 0 auto;
  }
}

:deep(.catalog-list-content) {
  list-style: none;
  padding: 0 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;

  & > div {
    padding: 8px;
    flex: 1 1 215px;
  }
}
</style>
