<template>
  <div class="custom-domain-creation">
    <section>
      <h2>{{ $t('SA.customDomain.create.title') }}</h2>
      <p>{{ $t('SA.customDomain.create.description') }}</p>
    </section>
    <section>
      <ValidLabelInput
        v-model="hostnameInput"
        class="hostname-input"
        label=""
        :disabled="disabled"
        :validators="[isValidHostname]"
        placeholder="www.myspreadshopdomain.com"
      />
      <span v-if="disabled" class="disabled-label">
        {{ $t('SA.customDomain.create.disabledLabel') }}
      </span>
    </section>
    <section>
      <button
        class="btn btn-primary btn-lg"
        :disabled="disabled || !isValid"
        @:click="create"
      >
        {{ $t('SA.customDomain.create.connectDomain') }}
      </button>
    </section>
  </div>
</template>
<script>
import ValidLabelInput from 'src/app/partnerarea/pos/shop/common/ValidLabelInput.vue';

export default {
  name: 'ShopCustomDomainCreation',
  components: {
    ValidLabelInput,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    language: {
      type: Object,
      required: false,
    },
  },
  emits: ['create'],
  data: () => ({
    hostnameInput: '',
    isValid: false,
  }),
  methods: {
    create() {
      this.$emit('create', this.hostnameInput);
    },
    isValidHostname(hostname) {
      if (
        /^(?!-)([a-zA-Z0-9-]{1,63}\.){1,}[a-zA-Z0-9-]{1,63}\.[a-zA-Z]{2,}$/.test(
          hostname,
        )
      ) {
        this.isValid = true;
        return;
      }

      this.isValid = false;
      throw new Error(this.$t('SA.customDomain.create.invalidFormat'));
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/_colors';

.custom-domain-creation {
  display: flex;
  flex-direction: column;
  gap: 32px;

  section {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  button {
    width: max-content;
  }

  .hostname-input {
    width: 400px;
  }

  .disabled-label {
    color: $pa-color-red;
  }
}
</style>
