<template>
  <div class="box">
    <div class="box-header">
      <h2 v-html="$t('ACCOUNT.TAXATION.HEADING')"></h2>
    </div>
    <div v-if="hasSavedAddress" class="box-content">
      <div class="taxation">
        <h4>{{ $t('ACCOUNT.TAXATION.SELECT_TYPE_HEADING') }}</h4>
        <p class="text-grey">
          {{ $t('ACCOUNT.TAXATION.EXPLANATION1') }}
        </p>
        <SmartFaqLink :link="taxationInfoLink" />
        <RadioButtonGroup
          class="taxation-options"
          :items="taxationTypes"
          :item-key="(item) => item.key"
          :item-to-string="(item) => $t(item.name)"
          :item-disabled="(item) => !possibleTaxationTypes.includes(item.key)"
          :model-value="currentTaxationType"
          disabled-tooltip="ACCOUNT.TAXATION.NOT_MATCHING_PERSONAL_DATA"
          @update:model-value="changeTaxationType"
        />
      </div>
      <div v-if="taxationConsistent">
        <div class="taxation-data">
          <component
            :is="currentTaxationType.component"
            :validation="validation"
          />
        </div>
        <TaxFormEU
          v-if="platform === 'EU'"
          :taxation-type="currentTaxationType.key"
        />
        <TaxFormNA
          v-if="
            platform === 'NA' &&
            (!accountData.user.taxationValid ||
              accountData.user.isTaxationFormAboutToExpire)
          "
          :taxation-type="currentTaxationType.key"
        />
      </div>
      <AccountPageActions
        :save="saveTaxationData"
        :disabled="!hasChanged"
        @on-revert="reset"
      />
    </div>
    <div v-else class="box-content no-personal-data">
      <img src="/images/checklist.svg" />
      <h3>
        {{ $t('ACCOUNT.TAXATION.PERSONAL_DATA_MISSING.HEADING') }}
      </h3>
      <p>
        {{ $t('ACCOUNT.TAXATION.PERSONAL_DATA_MISSING.TEXT') }}
      </p>
      <router-link to="/account/personal" class="link-main">
        {{ $t('ACCOUNT.TAXATION.PERSONAL_DATA_MISSING.CTA') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import AccountPageActions from 'src/app/partnerarea/account/AccountPageActions.vue';
import taxationDataValidator from '@/api/validators/userAccount/taxationDataValidator';

import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';
import CollapseAnimation from '@/CollapseAnimation/CollapseAnimation.vue';
import RadioButtonGroup from '@/RadioButtonGroup/RadioButtonGroup.vue';
import PrivateEU from './taxationTypes/PrivateEU.vue';
import SmallBusiness from './taxationTypes/SmallBusiness.vue';
import CompanyDE from './taxationTypes/CompanyDE.vue';
import ArtistDE from './taxationTypes/ArtistDE.vue';
import CompanyEU from './taxationTypes/CompanyEU.vue';
import CompanyNonEU from './taxationTypes/CompanyNonEU.vue';
import PrivateNA from './taxationTypes/PrivateNA.vue';
import NonUS from './taxationTypes/NonUS.vue';
import CompanyNA from './taxationTypes/CompanyNA.vue';
import TaxFormEU from './TaxFormEU.vue';
import TaxFormNA from './TaxFormNA.vue';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';

const taxationTypeContent = {
  EU: [
    {
      key: 'private',
      name: 'ACCOUNT.TAXATION.EU_PRIVATE.SELECTION',
      component: 'PrivateEU',
    },
    {
      key: 'DE small business',
      name: 'ACCOUNT.TAXATION.EU_SMALL_BUSINESS.SELECTION',
      component: 'SmallBusiness',
    },
    {
      key: 'DE company',
      name: 'ACCOUNT.TAXATION.EU_DE_COMPANY.SELECTION',
      component: 'CompanyDE',
    },
    {
      key: 'DE artist',
      name: 'ACCOUNT.TAXATION.EU_DE_ARTIST.SELECTION',
      component: 'ArtistDE',
    },
    {
      key: 'EU company',
      name: 'ACCOUNT.TAXATION.EU_COMPANY.SELECTION',
      component: 'CompanyEU',
    },
    {
      key: 'No EU',
      name: 'ACCOUNT.TAXATION.EU_NO_EU.SELECTION',
      component: 'CompanyNonEU',
    },
  ],
  NA: [
    {
      key: 'private',
      name: 'ACCOUNT.TAXATION.NA_PRIVATE.SELECTION',
      component: 'PrivateNA',
    },
    {
      key: 'no tax',
      name: 'ACCOUNT.TAXATION.NA_NO_TAX.SELECTION',
      component: 'NonUS',
    },
    {
      key: 'cooperation',
      name: 'ACCOUNT.TAXATION.NA_COOPERATION.SELECTION',
      component: 'CompanyNA',
    },
  ],
};

export default {
  name: 'Taxation',
  components: {
    CollapseAnimation,
    RadioButtonGroup,
    PrivateEU,
    SmallBusiness,
    CompanyDE,
    ArtistDE,
    CompanyEU,
    CompanyNonEU,
    TaxFormEU,
    PrivateNA,
    NonUS,
    CompanyNA,
    TaxFormNA,
    SmartFaqLink,
    AccountPageActions,
  },
  data() {
    return {
      taxationInfoLink: smartFaqLinks.TAXATION_INFO,
      taxationTypes: null,
      currentTaxationType: null,
      platform: SPRD.PLATFORM,
    };
  },
  computed: {
    ...mapState({
      accountData: (state) => state.account.current,
      taxationData: (state) => state.userTaxation.data,
      types: (state) => state.userTaxation.types,
      validation: (state) => state.userTaxation.validation,
      obfuscatedData: (state) => state.userTaxation.obfuscatedData,
    }),
    ...mapGetters({
      hasSavedAddress: 'account/hasSavedAddress',
      hasChanged: 'userTaxation/hasChanged',
      taxationConsistent: 'userTaxation/taxationConsistent',
    }),
    possibleTaxationTypes() {
      return taxationDataValidator.getValidTaxationTypes(this.types);
    },
  },
  created() {
    this.taxationTypes = taxationTypeContent[this.platform];
    this.currentTaxationType = this.taxationTypes.find(
      (t) => t.key === this.taxationData.type,
    );
  },
  methods: {
    ...mapMutations({
      setTaxationType: 'userTaxation/setTaxationType',
      validate: 'userTaxation/validate',
      reset: 'userTaxation/reset',
    }),
    ...mapActions({
      saveTaxationData: 'userTaxation/saveTaxationData',
    }),
    changeTaxationType(taxationType) {
      this.currentTaxationType = taxationType;
      this.setTaxationType({ taxationType: taxationType.key });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.actions {
  margin-top: 24px;
}

.taxation,
.taxation-data {
  :deep(p) {
    max-width: 700px;
  }
}

.taxation-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px 0;

  & > :deep(label:not(:last-child)) {
    margin-bottom: 12px;
  }
}

.no-personal-data {
  img {
    width: 256px;
  }

  h3 {
    margin-top: 24px;
  }

  p {
    @extend .text-grey;
    margin-bottom: 16px;
    max-width: 650px;
  }
}
</style>
