<template>
  <div class="dialog-size-full idea-sorting-dialog">
    <SaveCancelModalHeader
      :can-confirm="dirty"
      :on-confirm="save"
      :on-cancel="modalInstance.dismiss"
    />
    <div class="modal-body">
      <div>
        <h4>{{ $t('POS.SHOP_SETTINGS.LISTPAGE.SORT_DESIGNS') }}</h4>
        <p>
          {{ $t('POS.SHOP_SETTINGS.LISTPAGE.SORT_DESIGNS_DESCRIPTION') }}
        </p>
        <div v-if="currentPageIdeas.length > 0" class="tiles">
          <ItemDragger
            class="dragger"
            :ordered-items="currentPageIdeas"
            :map-item="mapItem"
            :pages="pages"
            :current-page="currentPage"
            @change:position="positionChanged"
            @change:page="moveToPage"
          />
          <div class="text-center pagination-wrapper">
            <Pagination
              v-if="pages > 1"
              :total="currentSorting.length"
              :page-size="pageSize"
              :current-page="currentPage"
              @page-change="pageChange"
            />
            <div class="page-size">
              <p class="page-size-cap">
                {{ $t('DESIGNS.LIST.TOOLBAR.ITEMS_PER_PAGE') }}
              </p>
              <Dropdown
                :items="pageSizes"
                :value="pageSize"
                :direction="'up'"
                :disabled="dirty"
                @change="onPageSizeChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoaderOverlay
      v-if="isLoading"
      headline="POS.SHOP_SETTINGS.LISTPAGE.SORT_DESIGNS_SAVE_HEADING"
      description="POS.SHOP_SETTINGS.LISTPAGE.SORT_DESIGNS_SAVE_DESCRIPTION"
    />
  </div>
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import { shopBackendAPI } from 'src/app/commons/api/shop/shopBackendAPI';
import Pagination from '@/pagination/Pagination.vue';
import ItemDragger from '../common/itemDragger/ItemDragger.vue';
import LoaderOverlay from '../common/LoaderOverlay.vue';
import Dropdown from '@/Dropdown/Dropdown.vue';
import SaveCancelModalHeader from '../common/SaveCancelModalHeader.vue';

const SORTING_PAGE_SIZE = 36;

export default {
  name: 'IdeaSortingDialog',
  components: {
    ItemDragger,
    LoaderOverlay,
    Pagination,
    SaveCancelModalHeader,
    Dropdown,
  },
  mixins: [SettingsData],
  props: ['modalInstance', 'data'],
  data() {
    return {
      sortedIdeas: [],
      pageSize: SORTING_PAGE_SIZE,
      pageSizes: [12, 24, 36, 48, 96],
      pages: 1,
      currentSorting: [], // frontend state of sort order
      currentPage: 0,
      changes: [],
      isLoading: false,
      //fetchedPages: [], not reactive
    };
  },
  computed: {
    pageIdeaOffsets() {
      const start = this.pageStartOffset();
      return this.currentSorting.slice(start, start + this.pageSize);
    },
    currentPageIdeas() {
      return this.pageIdeaOffsets.map(
        (ideaOffset) =>
          this.sortedIdeas[ideaOffset] || {
            ideaId: `ph-${ideaOffset}`,
            name: '\xa0' /* nbsp */,
          },
      );
    },
    dirty() {
      return this.changes.length > 0;
    },
    userId() {
      return this.$store.state.user.data.id;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    onPageSizeChange(newSize) {
      this.pageSize = newSize;
      this.initialize();
    },
    async initialize() {
      const startOffset = this.pageStartOffset();
      const { count, ideas } = await shopBackendAPI.getIdeaSorting(
        this.userId,
        this.shopId,
        startOffset,
        this.pageSize,
      );
      this.sortedIdeas = Array.from({ length: count });
      this.sortedIdeas.splice(startOffset, ideas.length, ...ideas);
      this.currentSorting = this.sortedIdeas.map((_, i) => i);
      this.pages = Math.ceil(this.sortedIdeas.length / this.pageSize);
      this.fetchedPages = [this.currentPage];
      this.changes = [];
    },
    async save() {
      this.isLoading = true;

      try {
        await shopBackendAPI.saveIdeaSorting(
          this.userId,
          this.shopId,
          this.changes,
        );
        this.modalInstance.dismiss();
      } catch (e) {
        console.error(e);
      }

      this.isLoading = false;
    },
    mapItem({ ideaId: id, name, imageUrl, backgroundColor }) {
      return {
        id,
        imageUrl,
        name,
        class: `design-checked-background${backgroundColor ? ' dark' : ''}`,
      };
    },
    pageStartOffset(page = this.currentPage) {
      return page * this.pageSize;
    },
    async pageChange(page, updateSorting) {
      const start = this.pageStartOffset(page);
      if (
        start < 0 || // negative page
        start > this.currentSorting.length - 1 // empty page
      ) {
        // show error invalid page (probable reason move input)
        throw new Error('invalid page' + page);
      }

      if (!this.fetchedPages.includes(page)) {
        const { ideas } = await shopBackendAPI.getIdeaSorting(
          this.userId,
          this.shopId,
          start,
          this.pageSize,
        );
        this.sortedIdeas.splice(start, ideas.length, ...ideas);
      }

      this.currentPage = page;

      if (updateSorting) {
        updateSorting();
      }

      const missingOffsets = this.pageIdeaOffsets
        .filter((ideaOffset) => !this.sortedIdeas[ideaOffset])
        .sort();

      if (missingOffsets.length > 0) {
        // optimize requests by fetching sequential ideas together
        const requests = [];
        let requestParams = [null, 0];
        missingOffsets.forEach((ideaOffset) => {
          const [startOffset, limit] = requestParams;
          if (startOffset === ideaOffset - limit) {
            requestParams[1]++;
          } else {
            if (startOffset !== null) {
              requests.push([startOffset, limit]);
            }

            requestParams = [ideaOffset, 1];
          }
        });

        requests.push(requestParams);
        requests.forEach(([startOffset, limit]) => {
          shopBackendAPI
            .getIdeaSorting(this.userId, this.shopId, startOffset, limit)
            .then(({ ideas }) => {
              this.sortedIdeas.splice(startOffset, ideas.length, ...ideas);
            });
        });
      }
    },
    positionChanged({ id, position }) {
      const newPosition = position + this.pageStartOffset();
      const lastChange = this.changes[this.changes.length - 1];
      if (lastChange && lastChange.ideaId === id) {
        lastChange.position = newPosition;
      } else {
        this.changes.push({ ideaId: id, position: newPosition });
      }

      const startPosition = this.sortedIdeas.findIndex(
        ({ ideaId } = {}) => ideaId === id,
      );
      const currentPosition = this.currentSorting.indexOf(startPosition);
      this.currentSorting.splice(
        newPosition,
        0,
        ...this.currentSorting.splice(currentPosition, 1),
      );
    },
    moveToPage({ ids, page }) {
      const { currentPage } = this;

      this.pageChange(page, () => {
        // needs to be a callback or vue wastes a render which also breaks our selected state
        if (page <= currentPage) {
          ids
            .slice()
            .reverse()
            .forEach((id) => this.positionChanged({ id, position: 0 }));
        } else {
          const insertAt =
            Math.min(ids.length, this.pageIdeaOffsets.length) - 1;
          ids.forEach((id) => this.positionChanged({ id, position: insertAt }));
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.dragger :deep(.name) {
  background-color: $white;
}

.tiles {
  margin-bottom: 3em;
}

.pagination-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  height: 60px;
  margin: 25px 0;

  .page-size {
    display: flex;
    align-items: center;
    position: absolute;

    p {
      margin: 0 10px 0 0;
    }
  }
}
</style>
