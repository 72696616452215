<template>
  <div v-if="bestsellerData" class="bestseller-list">
    <ul v-if="bestsellerData.length">
      <li class="stat-item bestseller-list-heading">
        <div class="rank">{{ $t('GENERAL.RANK') }}</div>
        <div class="description">
          {{ bestsellerTypeName }}
        </div>
        <div class="pos">{{ $t('STATISTICS.CREDIT_DETAILS.POS') }}</div>
        <div class="sales">{{ $t('DASHBOARD.SALES') }}</div>
      </li>
      <li
        v-for="(statItem, index) in bestsellerData"
        :key="statItem.objectName"
        class="stat-item"
      >
        <div class="rank">
          {{ index + 1 + offset }}
        </div>
        <div class="description">
          <div
            class="image"
            :class="[statItem.objectType, isDarkBgItem(statItem) ? 'dark' : '']"
          >
            <img :src="statItem.imageUrl" alt="Besteller Image" />
            <a
              v-if="statItem.objectType === 'design'"
              class="bg-toggle-link"
              href
              @click.prevent="toggleDarkBg(statItem)"
            >
              <Icon icon="background-color"></Icon>
            </a>
          </div>
          <span>{{ statItem.name }}</span>
        </div>
        <div class="pos">
          <p>
            {{ statItem.posLabel }}
            <a
              v-if="statItem.groupedDetails.length > 1"
              href="#"
              class="link-main"
              @click.prevent="toggleItemExpansion(statItem)"
            >
              <span v-if="!isItemExpanded(statItem)">{{
                $t('GENERAL.SHOW_MORE')
              }}</span>
              <span v-if="isItemExpanded(statItem)">{{
                $t('GENERAL.SHOW_LESS')
              }}</span>
            </a>
          </p>
          <CollapseAnimation>
            <ul v-show="isItemExpanded(statItem)" class="sale-detail-names">
              <li
                v-for="(detail, key) in statItem.groupedDetails"
                :key="key"
                class="sale-detail"
              >
                {{ detail.name }}
              </li>
            </ul>
          </CollapseAnimation>
        </div>
        <div class="sales">
          <p>
            {{ sprdNumber(statItem.quantity) }}
          </p>
          <CollapseAnimation>
            <ul v-show="isItemExpanded(statItem)">
              <li
                v-for="(detail, key) in statItem.groupedDetails"
                :key="key"
                class="sale-detail"
              >
                {{ sprdNumber(detail.quantity) }}
              </li>
            </ul>
          </CollapseAnimation>
        </div>
      </li>
    </ul>
    <div v-if="!bestsellerData.length" class="empty-message">
      <Icon icon="x"></Icon>
      <strong>{{ $t('STATISTICS.BESTSELLERS.NO_RESULTS') }}</strong>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  getDesignImageUrl,
  getProductTypeImageUrl,
  getCountryImageUrl,
} from '@/image/imageUrlBuilder';
import statisticsService from '@/api/statisticsService/statisticsService';

import CollapseAnimation from '@/CollapseAnimation/CollapseAnimation.vue';
import { pointOfSaleName } from '@/api/models/pointsOfSale/pointsOfSale';

export default {
  name: 'BestsellerList',
  components: {
    CollapseAnimation,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pointOfSales: statisticsService.getPointOfSales(),
      getStatsName: (stat) => this.$t(`STATISTICS.BESTSELLERS.${stat.key}`),
      expandedItems: [],
      darkBgItems: [],
    };
  },
  computed: {
    ...mapGetters({
      shippingCountryByIso: 'platform/shippingCountryByIso',
    }),
    bestsellerData() {
      if (!this.data) {
        return null;
      }

      return this.data.map((item) => ({
        ...item,
        imageUrl: this.getImageUrlForItem(item),
        name: this.getItemName(item),
        posLabel: this.getPosLabelForItem(item),
        groupedDetails: this.groupItemDetails(item),
      }));
    },
    bestsellerTypeName() {
      const objectType = Object.values(this.bestsellerData)[0].objectType;
      switch (objectType) {
        case 'productType':
          return this.$t(`STATISTICS.BESTSELLERS.TOP_PRODUCT_TYPES`);
        case 'design':
          return this.$t(`STATISTICS.BESTSELLERS.TOP_DESIGNS`);
        case 'shippingCountry':
          return this.$t(`STATISTICS.BESTSELLERS.TOP_COUNTRIES`);
        default:
          return null;
      }
    },
  },
  methods: {
    getImageUrlForItem(item) {
      switch (item.objectType) {
        case 'productType':
          return `${getProductTypeImageUrl(
            item.objectName,
          )},width=100,height=100`;
        case 'shippingCountry':
          return getCountryImageUrl(item.objectName);
        case 'design':
          return `${getDesignImageUrl(item.objectName)},width=100,height=100`;
        default:
          return null;
      }
    },
    getItemName(item) {
      switch (item.objectType) {
        case 'shippingCountry':
          return this.shippingCountryByIso(item.objectName);
        case 'productType':
        case 'design':
          return item.translation;
        default:
          return 'Name still missing';
      }
    },
    getPosLabelForItem(item) {
      const hasMpSales = item.details.some(
        (detail) => detail.pos === this.pointOfSales.MP,
      );
      const hasCyoSales = item.details.some(
        (detail) => detail.pos === this.pointOfSales.CYO,
      );
      const hasSpreadshirtSales = hasMpSales || hasCyoSales;
      const shopSales = item.details.filter(
        (detail) =>
          detail.pos === this.pointOfSales.OWN_SHOP ||
          detail.pos === this.pointOfSales.FOREIGN_SHOP,
      );
      const hasShopSales = Boolean(shopSales.length);
      const hasEmpSales = item.details.some(
        (detail) => detail.pos === this.pointOfSales.EMP,
      );

      if (hasSpreadshirtSales && hasShopSales && hasEmpSales) {
        return this.$t('DASHBOARD.POS_FILTER.ALL');
      } else if (hasSpreadshirtSales && hasShopSales) {
        return `${this.$t('DASHBOARD.POS_FILTER.SPREADSHIRT')} & ${this.$t(
          'DASHBOARD.POS_FILTER.SHOPS',
        )}`;
      } else if (hasSpreadshirtSales && hasEmpSales) {
        return `${this.$t('DASHBOARD.POS_FILTER.SPREADSHIRT')} & ${this.$t(
          'DASHBOARD.POS_FILTER.EMP',
        )}`;
      } else if (hasShopSales && hasEmpSales) {
        return `${this.$t('DASHBOARD.POS_FILTER.SHOPS')} & ${this.$t(
          'DASHBOARD.POS_FILTER.EMP',
        )}`;
      } else if (hasMpSales) {
        return this.$t('DASHBOARD.POS_FILTER.SPREADSHIRT');
      } else if (hasCyoSales) {
        return this.$t('GENERAL.CYO');
      } else if (hasEmpSales) {
        return this.$t('DASHBOARD.POS_FILTER.EMP');
      } else if (shopSales.length > 1) {
        return this.$t('DASHBOARD.POS_FILTER.SHOPS');
      } else {
        const singleSellingShop = shopSales[0];
        if (singleSellingShop.pos === this.pointOfSales.FOREIGN_SHOP) {
          return `${this.$t('GENERAL.FOREIGN_SHOP')}: ${
            singleSellingShop.shopName || singleSellingShop.shopId
          }`;
        } else {
          return singleSellingShop.shopName || singleSellingShop.shopId;
        }
      }
    },
    groupItemDetails(item) {
      const grouping = {
        MP: ['MP'],
        CYO: ['CYO'],
        EMP: ['EMP'],
        FOREIGN_SHOP: ['FOREIGN_SHOP'],
      };

      const groupedData = item.details.reduce((result, detail) => {
        const { pos, quantity, shopId, shopName } = detail;

        const mappedPosItem = Object.keys(grouping).find((key) =>
          grouping[key].includes(pos),
        );
        const mappedPosKey = mappedPosItem || shopId;
        const mappedPosType = mappedPosItem || pos;

        if (!result[mappedPosKey]) {
          const item = {
            pos: mappedPosType,
            quantity,
            shopName: shopName || `${this.$t('GENERAL.SHOP')} ${shopId}`,
          };
          item.name = pointOfSaleName(item);
          result[mappedPosKey] = item;
        } else {
          result[mappedPosKey].quantity += detail.quantity;
        }

        return result;
      }, {});

      return Object.values(groupedData).sort(
        (item1, item2) => item2.quantity - item1.quantity,
      );
    },
    toggleItemExpansion(item) {
      if (this.expandedItems.includes(item.objectName)) {
        this.expandedItems = this.expandedItems.filter(
          (expandedItem) => expandedItem !== item.objectName,
        );
      } else {
        this.expandedItems.push(item.objectName);
      }
    },
    isItemExpanded(item) {
      return this.expandedItems.includes(item.objectName);
    },
    toggleDarkBg(item) {
      if (this.darkBgItems.includes(item.objectName)) {
        this.darkBgItems = this.darkBgItems.filter(
          (darkBgItem) => darkBgItem !== item.objectName,
        );
      } else {
        this.darkBgItems.push(item.objectName);
      }
    },
    isDarkBgItem(item) {
      return this.darkBgItems.includes(item.objectName);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/mixins';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/type';

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.stat-item.bestseller-list-heading {
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;

  & > div {
    padding: 0;
  }
}

.stat-item {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  padding: 0 24px;
  background-color: #fff;

  &:last-child {
    border-radius: 0 0 $border-radius-medium $border-radius-medium;
  }

  small {
    display: block;
    padding-bottom: 8px;
  }

  .rank,
  .pos,
  .sales {
    padding: 24px 0;
  }

  .rank {
    display: flex;
  }

  .rank {
    width: 50px;
  }

  .sales {
    width: 60px;
  }

  .sales {
    text-align: right;
    margin-right: 30px;

    p {
      margin-bottom: 4px;
    }
  }

  .pos-label {
    margin-right: 24px;
  }

  .description {
    display: flex;
    align-items: center;
    flex: 1;

    .image {
      position: relative;
      display: flex;

      .bg-toggle-link {
        position: absolute;
        bottom: 6px;
        right: 2px;
      }

      &.shippingCountry {
        border: 1px solid $grey10;
      }

      &.design {
        img {
          padding: 15px;
          width: 100px;
        }

        @include tiled-background-light;

        &.dark {
          @include tiled-background-dark;
        }
      }

      &.dark .bg-toggle-link {
        color: $white;
      }
    }

    img {
      width: 80px;
      height: auto;
    }

    & > span {
      margin: 0 16px;
    }
  }

  .pos {
    flex: 1;

    p {
      margin-bottom: 4px;
    }

    a {
      margin-left: 4px;
    }
  }

  .sale-detail-names {
    padding-left: 10px;
  }

  .sale-detail {
    margin-bottom: 4px;
  }
}
</style>
