<template>
  <div>
    <SprdImage v-if="intersects" :src="src" :alt="alt" />
  </div>
</template>

<script>
import 'intersection-observer';
import SprdImage from './SprdImage.vue';

export default {
  name: 'SprdLazyImage',
  components: {
    SprdImage,
  },
  props: {
    src: {
      required: true,
    },
    alt: {
      required: false,
    },
  },
  data() {
    return {
      intersects: false,
      observer: null,
    };
  },
  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      const image = entries[0];
      if (image.isIntersecting) {
        this.intersects = true;
        this.observer.disconnect();
      }
    });

    this.observer.observe(this.$el);
  },
  unmounted() {
    this.observer.disconnect();
  },
};
</script>
