import { isEmpty } from '@/utils';
import { mergeResults } from '../genericValidator';

const taxationTypes = {
  EU_PRIVATE_PERSON: 'private',
  EU_EU_COMPANY_VAT: 'EU company',
  EU_DE_COMPANY_VAT: 'DE company',
  EU_DE_SMALL_BUSINESS: 'DE small business',
  EU_NOT_EU_NO_VAT: 'No EU',
  EU_DE_ARTIST: 'DE artist',
  US_NOT_TAXABLE: 'no tax',
  US_PRIVATE_TAXABLE: 'private',
  US_COOPERATION_TAXABLE: 'cooperation',
};

const taxationDataValidator = {
  validate(data, obfuscatedData) {
    return Object.keys(taxationDataValidator.singleValidate)
      .map((field) =>
        taxationDataValidator.singleValidate[field](data, obfuscatedData),
      )
      .reduce((previous, current) => mergeResults(previous, current), {});
  },
  singleValidate: {
    taxId: function (data, obfuscatedData) {
      return validateTaxId(data, obfuscatedData);
    },
    vatId: function (data, obfuscatedData) {
      return validateVatId(data, obfuscatedData);
    },
    ssn: function (data) {
      return validateSSN(data);
    },
    taxCountry: function (data) {
      return validateTaxCountry(data);
    },
  },
  validateByFieldName(fieldName, data, obfuscatedData) {
    if (taxationDataValidator.singleValidate[fieldName]) {
      return taxationDataValidator.singleValidate[fieldName](
        data,
        obfuscatedData,
      );
    } else {
      return {};
    }
  },
  mergeResults,
  getValidTaxationTypes,
};

export default taxationDataValidator;

function validateVatId(data, obfuscatedData) {
  let result = {
    result: true,
    errors: {},
  };

  const { type, vatId, taxId } = data;

  if (
    [taxationTypes.EU_DE_COMPANY_VAT, taxationTypes.EU_DE_ARTIST].includes(
      type,
    ) &&
    isEmpty(vatId) &&
    isEmpty(taxId) &&
    (!obfuscatedData || isEmpty(obfuscatedData.taxId))
  ) {
    // EU_DE_COMPANY_VAT
    if (obfuscatedData && isEmpty(vatId) && !isEmpty(obfuscatedData.vatId)) {
      return result;
    }

    result.result = false;
    result.errors.vatId = {
      reason: 'REQUIRED',
    };
  }

  return result;
}

function validateTaxId(data, obfuscatedData) {
  let result = {
    result: true,
    errors: {},
  };

  const { type, taxId, vatId } = data;

  if (
    [taxationTypes.EU_DE_COMPANY_VAT, taxationTypes.EU_DE_ARTIST].includes(
      type,
    ) &&
    isEmpty(taxId) &&
    isEmpty(vatId) &&
    (!obfuscatedData || isEmpty(obfuscatedData.vatId))
  ) {
    if (obfuscatedData && isEmpty(taxId) && !isEmpty(obfuscatedData.taxId)) {
      return result;
    }

    result.result = false;
    result.errors.taxId = {
      reason: 'REQUIRED',
    };
  } else if (
    [
      taxationTypes.US_NOT_TAXABLE,
      taxationTypes.US_COOPERATION_TAXABLE,
    ].includes(type) &&
    isEmpty(taxId)
  ) {
    // US_COOPERATION_TAXABLE
    // US_NOT_TAXABLE
    result.result = false;
    result.errors.taxId = {
      reason: 'REQUIRED',
    };
  } else if (
    type === taxationTypes.US_COOPERATION_TAXABLE &&
    !/^\d{2}-\d{7}$/.test(taxId) &&
    !/^\*{2}-\*{3}\d{4}$/.test(taxId)
  ) {
    // US_COOPERATION_TAXABLE
    result.result = false;
    result.errors.taxId = {
      reason: 'INVALID_FORMAT',
    };
  }

  return result;
}

function validateSSN(data) {
  const { type, ssn } = data;
  if (
    type === taxationTypes.US_PRIVATE_TAXABLE &&
    SPRD.PLATFORM === 'NA' &&
    isEmpty(ssn)
  ) {
    return {
      result: false,
      errors: {
        ssn: {
          reason: 'REQUIRED',
        },
      },
    };
  }
  if (
    type === taxationTypes.US_PRIVATE_TAXABLE &&
    SPRD.PLATFORM === 'NA' &&
    !/^\d{3}-\d{2}-\d{4}$/.test(ssn) &&
    !/^\*{3}-\*{2}-\d{4}$/.test(ssn)
  ) {
    return {
      result: false,
      errors: {
        ssn: {
          reason: 'INVALID_FORMAT',
        },
      },
    };
  } else {
    return {
      result: true,
      errors: {},
    };
  }
}

function validateTaxCountry(data) {
  let result = {
    result: true,
    errors: {},
  };

  const { type, country } = data;
  if (
    [
      taxationTypes.EU_DE_COMPANY_VAT,
      taxationTypes.EU_DE_ARTIST,
      taxationTypes.EU_EU_COMPANY_VAT,
    ].includes(type) &&
    SPRD.PLATFORM === 'EU' &&
    isEmpty(country)
  ) {
    result.result = false;
    result.errors.taxCountry = {
      reason: 'REQUIRED',
    };
  }

  return result;
}

function getValidTaxationTypes(types) {
  const applicableTypes = types
    .filter((type) => type.applicable)
    .map((type) => type.name);

  return Object.values(taxationTypes).filter((taxationType) =>
    applicableTypes.includes(taxationType),
  );
}
