<template>
  <div>
    <h5>{{ $t('POS.SHOP_SETTINGS.STARTPAGE.ABOUT_US.SUBHEADING') }}</h5>
    <ToggleSwitch
      v-model="aboutUsEnabled"
      label="POS.SHOP_SETTINGS.STARTPAGE.ABOUT_US.ENABLE_ABOUT_US"
    />
    <LegalLabelInput
      v-model="startPage.aboutUsCaption"
      class="sprd-shop-input"
      maxlength="50"
      :disabled="!aboutUsEnabled"
      :label="$t('POS.SHOP_SETTINGS.STARTPAGE.CAPTION_LABEL')"
    />
    <LegalLabelInput
      v-model="startPage.aboutUsText"
      class="sprd-shop-input"
      type="textarea"
      maxlength="300"
      :disabled="!aboutUsEnabled"
      :label="$t('POS.SHOP_SETTINGS.STARTPAGE.TEXT_LABEL')"
    />
    <MediaSelector
      :media="media"
      :image="image"
      :disabled="!aboutUsEnabled"
      :on-change="onMediaChange"
    />
  </div>
</template>

<script>
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import LegalLabelInput from '../../common/LegalLabelInput.vue';
import MediaSelector from '../../common/mediaSelector/MediaSelector.vue';
import { aboutUs } from '../../imageAttributes';
import { StartPageData } from 'src/app/partnerarea/pos/shop/shopSavable/StartPageData';

export default {
  name: 'AboutUs',
  components: {
    ToggleSwitch,
    LegalLabelInput,
    MediaSelector,
  },
  mixins: [StartPageData],
  data() {
    return {
      image: aboutUs,
    };
  },
  computed: {
    aboutUsEnabled: {
      get() {
        return this.startPage.aboutUsEnabled;
      },
      set(enabled) {
        return (this.startPage.aboutUsEnabled = enabled);
      },
    },
    media() {
      const startPage = this.startPage;

      return [
        {
          media: startPage.aboutUsMedia,
          mediaType: startPage.aboutUsMediaType,
          previewImage: startPage.aboutUsMediaPreviewImage,
        },
      ];
    },
  },
  created() {
    this.$store.commit('shop/setLivePreview', {
      path: '',
      section: 'sprd-startpage-about-us',
    });
  },
  methods: {
    onMediaChange({ media, mediaType, previewImage }) {
      this.startPage.aboutUsMedia = media;
      this.startPage.aboutUsMediaType = mediaType;
      this.startPage.aboutUsMediaPreviewImage = previewImage;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../startPageCommon.scss';
.button {
  margin-bottom: 12px;
  padding: 12px;
  width: 100%;
}
.icon-container-right {
  right: 5px;
}
.active-img {
  width: 100%;
  img {
    width: 100%;
    height: 100%;
  }
  position: relative;
}
</style>
