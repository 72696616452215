<template>
  <div
    class="selection-tiles"
    :class="{ disabled: props.disabled }"
    :style="`--min-column-width: ${props.minItemWidth || 100}px`"
  >
    <div
      v-for="item in props.items"
      :key="props.itemKey(item)"
      class="selection-tile"
      :class="{ selected: props.itemSelected(item) }"
      @click="() => handleChange(item)"
    >
      <div class="top">
        A
        <div class="top-content">
          <Checkbox
            v-if="props.multiSelection"
            :model-value="props.itemSelected(item)"
            @click.prevent
          />
          <RadioButton v-else :selected="props.itemSelected(item)" />
        </div>
      </div>
      <div class="middle">
        <slot :item="item"></slot>
      </div>
      <div
        v-tooltip.bottom="{
          content: $t(props.itemToString(item)),
          delay: { show: 500, hide: 0 },
        }"
        class="bottom"
      >
        {{ $t(itemToString(item)) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import RadioButton from '@/RadioButtonGroup/RadioButton.vue';
import Checkbox from '@/checkbox/Checkbox.vue';

type Props<T> = {
  items: T[];
  itemKey: (item: T) => string;
  itemSelected: (item: T) => boolean;
  itemToString: (item: T) => string;
  disabled?: boolean;
  multiSelection?: boolean;
  minItemWidth?: number;
};

const props = defineProps<Props<any>>();

const emit = defineEmits<{
  change: [item: any];
}>();

const handleChange = (item: any) => {
  emit('change', item);
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';

.selection-tiles {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--min-column-width), 1fr)
  );
  grid-gap: 8px;

  &.disabled {
    .selection-tile {
      cursor: not-allowed;
      color: $grey50;
      border-color: $grey20;
      box-shadow: none;

      .top-content {
        display: none;
      }
    }
  }
}

.selection-tile {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  border: 1px solid $grey40;
  border-radius: $border-radius-medium;
  color: $grey80;
  background-color: transparent;
  cursor: pointer;
  min-width: 100px;

  &:hover {
    border-color: $pa-color-main;
    color: $pa-color-main;
  }

  &.selected {
    border-color: $pa-color-main;
    color: $pa-color-main;
    box-shadow: 0 0 0 1px $pa-color-main;
    font-weight: bold;
  }

  &:hover,
  &.selected {
    .top-content {
      display: block;
    }
  }
}

.middle {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 24px;

  :deep(.icon) {
    width: 32px;
    height: 32px;
  }
}

.top {
  color: transparent;
  padding-bottom: 16px;

  .top-content {
    display: none;
    position: absolute;
    top: 4px;
    left: 4px;
  }
}

.bottom {
  text-align: center;
  padding: 4px 4px 12px 4px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}
</style>
