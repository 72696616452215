<template>
  <div class="pos-card" :class="{ selected }">
    <div class="pos-selection" @click="$emit('toggle')">
      <div class="pos-selection-header">
        <div class="pos-description">
          <component :is="logoComponent" :disabled="!selected" class="logo" />
          <div :class="{ selected }" class="heading3">
            {{ $t(heading) }}
          </div>
        </div>
        <div class="toggle-switch">
          <ToggleSwitch :model-value="!!selected" :disabled="!selectable" />
        </div>
      </div>
      <div class="pos-selection-hint">
        <slot name="hint" />
      </div>
      <div class="pos-selection-footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import CollapseAnimation from '@/CollapseAnimation/CollapseAnimation.vue';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import MarketplaceLogo from '@/logos/MarketplaceLogo.vue';
import SpreadshopLogo from '@/logos/SpreadshopLogo.vue';

export default {
  name: 'PosCard',
  components: {
    ToggleSwitch,
    MarketplaceLogo,
    SpreadshopLogo,
    CollapseAnimation,
  },
  props: {
    logoComponent: {
      type: String,
      validator(value) {
        return ['MarketplaceLogo', 'SpreadshopLogo'].includes(value);
      },
    },
    selected: {
      type: Boolean,
    },
    selectable: {
      type: Boolean,
    },
    heading: {
      type: String,
    },
  },
  emits: ['toggle'],
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/type';
@import 'src/scss/utils';

.pos-card {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  z-index: 1;
  min-height: 270px;

  .logo {
    height: 40px;
    margin-bottom: 16px;
  }

  &.selected .pos-selection {
    border-color: $pa-color-main;
    box-shadow: 0 0 0 1px $pa-color-main;
  }

  .pos-selection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $grey0;
    padding: 32px;
    height: 100%;
    border: 1px solid $grey0;
    border-radius: $border-radius-medium;
    cursor: pointer;

    .pos-selection-header {
      display: flex;
      justify-content: space-between;
    }

    &:hover {
      border-color: $pa-color-main;
    }

    .marketplace-pos-description .selected {
      color: $pa-color-main;
    }

    .shop-pos-description .selected {
      color: $sunset-dark50;
    }

    .toggle-switch {
      position: relative;
      padding: 6px 0 6px 10px;
      pointer-events: none;

      .sprd-toggle-switch {
        margin: 0;
      }
    }

    .pos-selection-hint {
      margin-top: 12px;
    }
  }
}

.pos-selection-footer {
  margin-top: 16px;
  text-align: left;
}
</style>
