<template>
  <NoStuffPlaceholder
    :display="display"
    heading="POS.SHOP_SETTINGS.STARTPAGE.EMPTY.HEADING"
    sub-heading="POS.SHOP_SETTINGS.STARTPAGE.EMPTY.SUBHEADING"
    faq="POS.SHOP_SETTINGS.STARTPAGE.EMPTY.FAQ"
    :faq-link="faqLink"
  />
</template>

<script>
import NoStuffPlaceholder from 'src/app/partnerarea/pos/shop/common/NoStuffPlaceholder.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';
import {
  StartPageData,
  atLeastOneSectionEnabled,
} from 'src/app/partnerarea/pos/shop/shopSavable/StartPageData';

export default {
  name: 'StartPageImpossiblePlaceholder',
  components: {
    NoStuffPlaceholder,
  },
  mixins: [StartPageData],
  data() {
    return {
      faqLink: smartFaqLinks.SHOP_START_PAGE_HELP,
    };
  },
  computed: {
    display() {
      return (
        Object.keys(this.startPage).length > 0 &&
        !atLeastOneSectionEnabled(this.startPage)
      );
    },
  },
};
</script>
