<template>
  <div v-if="data && data.length" class="sales-summary">
    <div>
      <h3>{{ $t('STATISTICS.TOTAL_SALES.HEADING') }}</h3>
      <p class="text-grey">
        {{ $t('STATISTICS.TOTAL_SALES.DESCRIPTION') }}
      </p>
      <h2>{{ sprdNumber(totalSales) }}</h2>
    </div>
    <div class="order-status">
      <div class="heading">
        <h3>{{ $t('STATISTICS.ORDER_STATUS_HEADING') }}</h3>
        <div class="status-link">
          <a href class="link-main" @click.prevent="openSalesStatusDialog">
            <Icon icon="info-circle"></Icon>
            {{ $t('STATISTICS.SALE_DETAILS.STATUS_EXPLANATION') }}
          </a>
        </div>
      </div>
      <p class="text-grey">
        {{ $t('STATISTICS.ORDER_STATUS_DESCRIPTION') }}
      </p>
      <ul>
        <li v-for="status in relevantSaleStates" :key="status.key">
          <AdvancedPopover
            :heading="status.name"
            :description="status.description"
            :icon="status.icon"
          >
            <div
              class="order-status__item sales-status"
              :style="{ color: status.color }"
            >
              <Icon class="status" :icon="status.icon" />
              {{ sprdNumber(status.salesSummary.salesAmount) }}
            </div>
          </AdvancedPopover>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import statisticsService from 'src/app/commons/api/statisticsService/statisticsService';
import AdvancedPopover from 'src/app/components/popover/AdvancedPopover.vue';
import SalesStatusDialog from 'src/app/partnerarea/statistics/sales/statusDialog/SalesStatusDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';

export default {
  name: 'SalesSummary',
  components: {
    AdvancedPopover,
  },
  props: {
    data: {
      required: false,
    },
  },
  computed: {
    relevantSaleStates() {
      return Object.values(statisticsService.getSaleStates())
        .filter((saleState) => this.getSummaryForStatus(saleState.key))
        .map((saleStatus) => ({
          ...saleStatus,
          salesSummary: this.getSummaryForStatus(saleStatus.key),
        }));
    },
    totalSales() {
      return this.relevantSaleStates.reduce((result, current) => {
        return result + current.salesSummary.salesAmount;
      }, 0);
    },
  },
  methods: {
    getSummaryForStatus(status) {
      return this.data
        .filter((entry) => entry.salesAmount > 0)
        .find((entry) => entry.status === status);
    },
    openSalesStatusDialog() {
      dialogService.openDialog(SalesStatusDialog);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/type';

.sales-summary {
  display: flex;
  margin-bottom: 24px;
  padding: 0 24px 24px;
  background-color: $white;

  & > div {
    padding: 16px;
    border: 1px solid $sprd-color-medium-grey;
    border-radius: $border-radius-medium;
    display: flex;
    flex-direction: column;
    min-width: 20%;
  }

  .order-status {
    flex-grow: 1;
    margin-left: 16px;

    .heading {
      display: flex;
      justify-content: space-between;
    }

    &__item {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 8px;
        width: 16px;
        height: 16px;
      }
    }
  }

  .status-link {
    display: flex;

    a {
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        margin-right: 5px;
        height: 1.5em;
        width: 1.5em;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: auto 0 0 0;
    display: flex;
    flex-wrap: wrap;

    li {
      margin-right: 16px;
      margin-top: 16px;
    }
  }

  h2 {
    margin: 24px 0 0 0;
  }

  h3 {
    margin: 0 0 8px 0;
  }
}
</style>
