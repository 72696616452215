<template>
  <div>
    <div class="entry sp-subheader">
      {{ $t('POS.SHOP_SETTINGS.STARTPAGE.CYO_INTRO.SUBHEADING') }}
    </div>
    <ToggleSwitch
      v-model="startPage.cyoIntroEnabled"
      label="POS.SHOP_SETTINGS.STARTPAGE.CYO_INTRO.ENABLE_CYO_INTRO"
      :disabled="!designerEnabled"
    />
    <LegalLabelInput
      v-model="startPage.cyoIntroCaption"
      class="sprd-shop-input"
      maxlength="50"
      :disabled="disabled"
      :label="$t('POS.SHOP_SETTINGS.STARTPAGE.CAPTION_LABEL')"
    />
    <LegalLabelInput
      v-model="startPage.cyoIntroText"
      class="sprd-shop-input"
      maxlength="300"
      type="textarea"
      :disabled="disabled"
      :label="$t('POS.SHOP_SETTINGS.STARTPAGE.TEXT_LABEL')"
    />
    <LegalLabelInput
      v-model="startPage.cyoIntroButtonCaption"
      class="sprd-shop-input"
      maxlength="50"
      :disabled="disabled"
      :label="$t('POS.SHOP_SETTINGS.STARTPAGE.BUTTON_LABEL')"
    />
  </div>
</template>

<script>
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import LegalLabelInput from '../../common/LegalLabelInput.vue';
import { StartPageData } from 'src/app/partnerarea/pos/shop/shopSavable/StartPageData';
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';

export default {
  name: 'CYOIntro',
  components: {
    ToggleSwitch,
    LegalLabelInput,
  },
  mixins: [StartPageData, SettingsData],
  computed: {
    designerEnabled() {
      return this.settings.designer;
    },
    disabled() {
      return !this.startPage.cyoIntroEnabled || !this.designerEnabled;
    },
  },
  created() {
    this.$store.commit('shop/setLivePreview', {
      path: '',
      section: 'sprd-startpage-cyo-intro',
      visible: this.settings.designer,
    });
  },
};
</script>

<style lang="scss" scoped>
@import '../startPageCommon.scss';
</style>
