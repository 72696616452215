<template>
  <div class="page-wrapper idea-wizard">
    <nav class="page-header wizard-toolbar nav navbar-secondary navbar-default">
      <div>
        <ul>
          <li
            v-for="(step, index) in stepData"
            :key="step.key"
            :class="{ 'step-done': step.done, 'current-step': step.current }"
          >
            <div class="stepicon checkmark">
              <Icon icon="accept" />
            </div>

            <div class="stepicon number">
              <Icon :icon="`${!step.current ? 'future' : ''}apc${index + 1}`" />
            </div>
            <div class="step-name">
              {{ $t('DESIGNS.CONFIGURATION.' + step.key + '.WIZARD') }}
            </div>
          </li>
        </ul>
        <a
          href="#"
          type="button"
          class="link-main icon-link"
          @click.prevent="back"
        >
          <Icon icon="left" />
          {{ $t('GENERAL.BACK') }}
        </a>
        <ProgressButton
          id="account-settings-save-button"
          :key="currentStep"
          class="btn btn-primary icon-btn icon-right"
          :label="
            currentStep === lastStep
              ? $t('GENERAL.PUBLISH')
              : $t('GENERAL.NEXT')
          "
          :icon="currentStep === lastStep ? 'rocket' : 'right'"
          :on-click="next"
        />
      </div>
    </nav>

    <div
      id="detail-page-scroller"
      class="page-content wizard-content overflow-y"
    >
      <div>
        <component
          :is="currentComponent"
          :show-pos-not-selected-warning="triggerPosMessageHighlight"
          :strict-validation="strictValidation"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import IdeaConfigPointOfSale from 'src/app/partnerarea/idea/configuration/pointsOfSale/IdeaConfigPointOfSale.vue';
import AssortmentConfiguration from 'src/app/partnerarea/idea/configuration/assortment/AssortmentConfiguration.vue';
import IdeaConfigMetaData from 'src/app/partnerarea/idea/configuration/metaData/IdeaConfigMetaData.vue';
import ProgressButton from '@/btnProgress/ProgressButton.vue';
import assortmentHelper from '@/assortmentHelper/assortmentHelper';
import analyticsService from '@/tracking/analytics';

const stepByComponent = {
  ASSORTMENT: AssortmentConfiguration,
  META_DATA: IdeaConfigMetaData,
  POINTS_OF_SALE: IdeaConfigPointOfSale,
};

export default {
  name: 'IdeaWizard',
  components: {
    ProgressButton,
  },
  props: {
    edit: {
      type: Function,
    },
    strictValidation: {
      type: Boolean,
    },
  },
  emits: ['cancel', 'done'],
  data() {
    return {
      steps: ['ASSORTMENT', 'META_DATA'],
      currentStep: null,
      triggerPosMessageHighlight: false,
    };
  },
  computed: {
    ...mapGetters({
      userShops: 'user/shops',
      userMp: 'user/marketplace',
      getSetting: 'settings/getSetting',
      publishingDetails: 'idea/publishingDetails',
      hasShops: 'idea/hasShops',
      hasMarketplace: 'idea/hasMarketplace',
      mpPublishingLimitReached: 'dms/publishingLimitReached',
    }),
    ...mapState({
      idea: (state) => state.idea.current,
    }),
    stepData() {
      return this.steps
        .filter((step) => step !== 'DONE')
        .map((key) => ({
          key,
          done: this.currentStepIndex > this.steps.indexOf(key),
          current: key === this.currentStep,
        }));
    },
    currentStepIndex() {
      return this.steps.indexOf(this.currentStep);
    },
    lastStep() {
      return this.steps[this.steps.length - 1];
    },
    currentComponent() {
      return stepByComponent[this.currentStep];
    },
    nextDisabled() {
      return (
        this.currentStep === 'POINTS_OF_SALE' &&
        !this.hasShops &&
        !this.hasMarketplace
      );
    },
  },
  async created() {
    if (
      this.userShops.length > 1 ||
      (this.getSetting('MP_ENABLED') && this.userShops.length > 0) ||
      (this.getSetting('MP_ENABLED') && this.mpPublishingLimitReached)
    ) {
      this.steps.unshift('POINTS_OF_SALE');
    }
    this.currentStep = this.steps[0];

    analyticsService.logEvent('apc-start', { eventArea: 'APC' });
  },
  methods: {
    ...mapMutations({
      updateOriginalIdeaAssortment: 'idea/updateOriginalIdeaAssortment',
      setPointOfSale: 'idea/setPointOfSale',
    }),
    back() {
      if (this.currentStepIndex <= 0) {
        this.$emit('cancel');
        return;
      }

      this.currentStep = this.steps[this.currentStepIndex - 1];
    },
    async next() {
      if (this.currentStepIndex >= this.steps.length) {
        return;
      }

      if (
        this.currentStep === 'POINTS_OF_SALE' &&
        !this.publishingDetails.length
      ) {
        this.triggerPosMessageHighlight = true;
        return Promise.reject();
      }

      if (
        this.currentStep === this.lastStep &&
        !this.publishingDetails.length
      ) {
        if (this.getSetting('MP_ENABLED')) {
          this.setPointOfSale(this.userMp);
        } else {
          this.setPointOfSale(this.userShops[0]);
        }
      }

      if (this.currentStep !== 'POINTS_OF_SALE') {
        try {
          await this.edit(this.currentStep);
        } catch (error) {
          return Promise.reject(error);
        }
      }

      analyticsService.logEvent(`apc-${this.currentStep}`, {
        eventArea: 'APC',
        pos:
          this.currentStep === 'POINTS_OF_SALE'
            ? [
                ...new Set(
                  this.publishingDetails.map((p) => p.pointOfSale.type),
                ),
              ].join(',')
            : null,
        productTypes:
          this.currentStep === 'ASSORTMENT'
            ? assortmentHelper
                .getSelectedSellables(this.idea.assortment)
                .map((group) => group.primarySellable.productType)
            : null,
      });

      if (this.currentStep === this.lastStep) {
        this.$emit('done');
      } else {
        this.currentStep = this.steps[this.currentStepIndex + 1];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.idea-wizard {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.wizard-content {
  position: initial;

  & > div {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto 0 auto;
    padding: 24px;
  }
}

.wizard-toolbar {
  height: 56px;

  & > div {
    display: flex;
    align-items: center;
    height: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 24px;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  li {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 24px;

    .stepicon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      border: 2px solid $grey20;
      width: 24px;
      height: 24px;
      border-radius: 100%;

      .icon {
        width: 10px;
        height: 10px;
      }
    }

    .number {
      display: flex;
    }

    .checkmark {
      display: none;
    }

    .step-name {
      margin-left: 8px;
    }

    &.step-done {
      .stepicon {
        background-color: $pa-color-main;
        border-color: $pa-color-main;
        color: $white;
      }

      .number {
        display: none;
      }

      .checkmark {
        display: flex;
      }

      .step-name {
        display: none;
      }
    }

    &.current-step {
      font-weight: bold;

      .stepicon {
        background-color: $pa-color-main;
        border-color: $pa-color-main;
        color: $white;
      }
    }
  }

  .btn {
    margin-left: 16px;
    height: 40px;
  }
}
</style>
