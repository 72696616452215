<template>
  <div
    class="design"
    :class="{
      selected: options.showToggle && idea.selected,
      selectable: options.showToggle,
    }"
  >
    <ToggleSwitch
      v-if="options.showToggle"
      :model-value="idea.selected"
      condensed
      class="design-toggle-switch"
      @update:model-value="$emit('onToggle')"
    />
    <a v-if="options.showRemove" class="close-link" @click="$emit('onRemove')">
      <div class="icon-wrapper">
        <Icon icon="close" />
      </div>
    </a>
    <div class="image-container" @click="onImageClick">
      <SprdLazyImage :src="`${idea.mainDesignImageUrl},width=190,height=190`" />
    </div>
    <div class="footer text-truncate">
      <div class="name">
        {{ idea.localizedName }}
      </div>
    </div>
  </div>
</template>

<script>
import SprdLazyImage from 'src/app/commons/image/SprdLazyImage.vue';
import ToggleSwitch from 'src/app/commons/toggleSwitch/ToggleSwitch.vue';

export default {
  name: 'DesignCard',
  components: {
    SprdLazyImage,
    ToggleSwitch,
  },
  props: {
    idea: {
      required: true,
    },
    options: {
      default() {
        return {
          showRemove: false,
          showToggle: false,
        };
      },
    },
  },
  emits: ['onToggle', 'onRemove'],
  methods: {
    onImageClick() {
      if (this.options.showToggle) {
        this.$emit('onToggle');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.design {
  position: relative;
  background-color: white;
  padding: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid $sprd-color-light-grey;
}

.selectable {
  .product-image-container :deep(img) {
    opacity: 0.5;
  }

  &.selected {
    border-color: $pa-color-main;
    box-shadow: 0 0 0 1px $pa-color-main;
  }

  &.selected,
  &:hover {
    .product-image-container :deep(img) {
      opacity: 1;
    }
  }
}

.footer {
  padding: 16px 0 11px 0;
  text-align: center;

  name {
    width: 180px;
  }
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  background-color: $sprd-color-lightest-grey;
  height: 200px;
  padding: 5px;

  :deep(img) {
    width: 100%;
    opacity: 0.6;
    transition: opacity 0.2s linear;
  }
}

.design:hover,
.design.selected {
  :deep(img) {
    opacity: 1;
  }
}

.design :deep(.sprd-toggle-switch) {
  position: absolute;
  top: 1px;
  left: 1px;
  padding: 8px 5px;
  background-color: white;
  z-index: 1;
  margin: 0;
}

.close-link {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;

  .icon-wrapper {
    border-radius: 100%;
    border: 1px solid $sprd-color-lightest-grey;
    background-color: $sprd-color-lightest-grey;
    color: $sprd-color-grey-60;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }

  .icon {
    width: 12px;
    height: 12px;
  }

  &:hover {
    .icon-wrapper {
      background-color: #fff;
      border-color: $pa-color-red;
      color: $pa-color-red;
    }
  }
}
</style>
