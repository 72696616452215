<template>
  <VDropdown
    :triggers="popoverTrigger"
    :popper-triggers="popoverTrigger"
    :placement="popoverPlacement"
    :container="popoverContainer"
    :disabled="disabled"
    :popper-class="popperClass"
  >
    <slot />
    <template #popper>
      <div class="advanced-popover">
        <h4>
          <Icon v-if="icon" :icon="icon" />
          {{ $t(heading) }}
        </h4>
        <p v-if="description">
          {{ $t(description) }}
        </p>
        <HelpLink
          v-if="helpLinkTarget"
          :href="helpLinkTarget"
          class="help-link"
        />
        <SmartFaqLink
          v-if="smartFaqLink"
          :link="smartFaqLink"
          class="help-link"
        />
        <div v-if="plainLink">
          <a
            class="link-main text-default"
            :href="plainLink.href"
            :target="plainLink.target || '_blank'"
          >
            {{ plainLink.label }}
          </a>
        </div>
      </div>
    </template>
  </VDropdown>
</template>

<script>
import HelpLink from '@/helpLink/HelpLink.vue';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';

export default {
  name: 'AdvancedPopover',
  components: {
    HelpLink,
    SmartFaqLink,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
    },
    helpLinkTarget: {
      type: String,
    },
    smartFaqLink: {
      type: Object,
    },
    plainLink: {
      type: Object,
    },
    popoverTrigger: {
      type: Array,
      default: () => ['hover', 'focus'],
    },
    popoverPlacement: {
      type: String,
      default: 'top',
    },
    disabled: {
      type: Boolean,
    },
    popoverContainer: {
      type: [Boolean, String],
      default: false,
    },
    popperClass: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/mixins/media';

.advanced-popover {
  text-align: left;

  h4 {
    display: flex;
    align-items: center;
    margin: 0;

    .icon {
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
  }

  p {
    margin: 8px 0 0 0;
    color: $grey60;
  }

  .help-link {
    margin-top: 8px;
  }
}
</style>
