import store from '@/store/Store';
import { Upload } from 'tus-js-client';
import apiClient from '../client/apiClient';

const _fileLimit = 20;
const _retryDelays = [0, 1000, 3000, 5000];
const _acceptedFileTypes = [
  {
    format: 'Bitmap',
    extensions: ['.jpeg', '.jpg'],
    types: ['image/jpeg'],
  },
  {
    format: 'Bitmap',
    extensions: ['.png'],
    types: ['image/png'],
  },
  {
    format: 'Bitmap',
    extensions: ['.gif'],
    types: ['image/gif'],
  },
  {
    format: 'Vector',
    extensions: ['.svg'],
    types: ['image/svg+xml'],
  },
  {
    format: 'Vector',
    extensions: ['.ai'],
    types: ['application/illustrator'],
  },
  {
    format: 'Vector',
    extensions: ['.cdr'],
    types: [
      'application/cdr',
      'application/coreldraw',
      'application/x-cdr',
      'application/x-coreldraw',
      'image/cdr',
      'image/x-cdr',
      'application/zz-winassoc-cdr',
      'application/vnd.corel-draw',
    ],
  },
];

async function pollUploadUrl(uploadUrl) {
  const res = await apiClient.request({
    method: 'GET',
    url: uploadUrl,
  });
  if (res.status === 202) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(pollUploadUrl(uploadUrl));
      }, 1000);
    });
  } else {
    return res.data;
  }
}

export default {
  uploadFile(file) {
    let upload;

    const promise = new Promise((resolve, reject) => {
      upload = new Upload(file, {
        endpoint: `/api/uploads/v2/partners/${store.state.user.data.id}/designs`,
        retryDelays: _retryDelays,
        metadata: {
          filename: file.name || 'unnamed',
        },
        resume: false,
        chunkSize: 5 * 1024 * 1024,
        onError(error) {
          let errorPayload;
          try {
            errorPayload = {
              ...error,
              status: error?.originalResponse?.getStatus(),
            };
          } catch (_) {
            errorPayload = {};
          }
          reject(errorPayload);
        },
        async onSuccess() {
          resolve(upload.url);
        },
      });

      upload.start();
    });

    return promise;
  },
  async waitForUploadProcessing(uploadUrl) {
    const res = await pollUploadUrl(uploadUrl);
    return res;
  },
  getAcceptedFileTypes() {
    return _acceptedFileTypes;
  },
  getFileLimit: function () {
    return _fileLimit;
  },
};
