<template>
  <LabelInput
    :class="{ 'has-error': errorMessage }"
    :model="temp"
    :disabled="disabled"
  >
    <label>{{ label }}</label>
    <input
      v-if="!safeValue && !isTextarea"
      v-model="temp"
      :disabled="disabled"
      :maxlength="maxlength"
      :placeholder="placeholder"
      :type="type"
    />
    <textarea
      v-if="!safeValue && isTextarea"
      v-model="temp"
      :disabled="disabled"
      :maxlength="maxlength"
      :placeholder="placeholder"
    ></textarea>
    <safe-input
      v-if="safeValue && !isTextarea"
      v-model="temp"
      :disabled="disabled"
      :maxlength="maxlength"
      :placeholder="placeholder"
      :type="type"
    />
    <safe-text-area
      v-if="safeValue && isTextarea"
      v-model="temp"
      :disabled="disabled"
      :maxlength="maxlength"
      :placeholder="placeholder"
    />
    <div v-if="errorMessage" class="field-info">
      <small class="error-info error-info-name">{{ errorMessage }}</small>
    </div>
    <Icon
      v-if="isValidating"
      class="icon error-icon waitIcon"
      icon="hourglass"
    />
  </LabelInput>
</template>

<script>
import Icon from '../../../../commons/icon/component/Icon.vue';
import LabelInput from '../../../../commons/labelInput/LabelInput.vue';
import SafeInput from './SafeInput.vue';
import SafeTextArea from './SafeTextArea.vue';

export default {
  name: 'ValidLabelInput',
  components: {
    LabelInput,
    SafeInput,
    SafeTextArea,
    Icon,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: '',
    },
    validators: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    safeValue: {
      type: Boolean,
      default: true,
    },
    maxlength: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data: function () {
    return {
      temp: this.modelValue ? this.modelValue.toString() : '',
      errorMessage: '',
      isValidating: false,
    };
  },
  computed: {
    isTextarea() {
      return this.type === 'textarea';
    },
  },
  watch: {
    temp: async function (value) {
      this.errorMessage = '';
      if (this.delayValidationFlag) {
        clearTimeout(this.delayValidationFlag);
      }
      if (this.isValidating) {
        this.isValidating = false;
      }

      if (this.ignoreChange) {
        this.ignoreChange = false;
        return;
      }

      const isCurrentValue = () => value === this.temp;

      // validation delay so we don't check every character the user is typing
      await new Promise((resolve) => setTimeout(resolve, 500));
      if (!isCurrentValue()) {
        return;
      }

      const delayValidationFlag = setTimeout(
        () => (this.isValidating = true),
        500,
      );
      this.delayValidationFlag = delayValidationFlag;

      try {
        await this.validators.reduce(
          (chain, validator) => chain.then(() => validator(value)),
          Promise.resolve(),
        );
        if (isCurrentValue()) {
          this.$emit('update:modelValue', value);
        }
      } catch (error) {
        if (isCurrentValue()) {
          this.errorMessage = error.message;
        }
      }

      if (delayValidationFlag === this.delayValidationFlag) {
        clearTimeout(delayValidationFlag);
        this.delayValidationFlag = null;
        if (this.isValidating) {
          this.isValidating = false;
        }
      }
    },
    modelValue: function (newValue) {
      if (newValue !== this.temp) {
        // property has been updated
        this.ignoreChange = true;
        this.temp = newValue;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.labelInput .waitIcon {
  display: block;
  color: currentColor;
  animation: rotate linear 0.5s infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
