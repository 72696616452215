<template>
  <Tile
    :name="digitalProduct.name || 'Unnamed Product'"
    icon="download-file"
    clickable
    :image="digitalProduct.productImageUrl"
    class="digital-product-tile"
    @on-click="edit"
  >
    <template #status>
      <p class="category">{{ $t(category.name) }}</p>
      <DigitalProductStatus
        :digital-product="digitalProduct"
        class="digital-product-status"
      />
      <DigitalProductUpdateBadge
        :digital-product="digitalProduct"
        class="update-badge"
      />
    </template>
  </Tile>
</template>

<script>
import Tile from '@/tileView/Tile.vue';
import DigitalProductStatus from './DigitalProductStatus.vue';
import { getDigitalProductCategory } from '@/api/models/digitalProducts/digitalProductsCategories';
import DigitalProductUpdateBadge from './DigitalProductUpdateBadge.vue';

export default {
  name: 'DigitalProductTile',
  components: {
    Tile,
    DigitalProductStatus,
    DigitalProductUpdateBadge,
  },
  props: {
    digitalProduct: {
      required: true,
      type: Object,
    },
  },
  emits: ['edit'],
  computed: {
    category() {
      return getDigitalProductCategory(this.digitalProduct.productClass);
    },
  },
  methods: {
    edit() {
      this.$emit('edit');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/type';

.digital-product-tile {
  :deep(.card-footer) {
    height: 116px;
  }
}

.category {
  @extend .text-sm;
  color: $grey65;
  margin-bottom: 16px;
}

.update-badge {
  position: absolute;
  right: 0;
  bottom: 16px;
}
</style>
