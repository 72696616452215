<template>
  <div class="custom-domain-settings">
    <div v-if="loading" class="loading-overlay">
      <LoadingHeart />
    </div>
    <div v-else>
      <div v-if="error" class="error">
        <h3>{{ $t('SA.customDomain.error.title') }}</h3>
        <p>{{ $t('SA.customDomain.error.description') }}</p>
      </div>
      <div v-else>
        <ShopCustomDomainConfiguration
          v-if="isCustomDomainConfigured"
          :custom-domain="customDomain"
          :shop-id="shopId"
          @refresh="getCustomDomain"
          @remove="deleteCustomDomain"
        />
        <ShopCustomDomainCreation
          v-else
          :disabled="isInternationShop"
          :language="shopLanguage"
          @create="createCustomDomain"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';
import ShopCustomDomainConfiguration from 'src/app/partnerarea/pos/shop/customDomain/ShopCustomDomainConfiguration.vue';
import ShopCustomDomainCreation from 'src/app/partnerarea/pos/shop/customDomain/ShopCustomDomainCreation.vue';
import LoadingHeart from 'src/app/commons/LoadingHeart/LoadingHeart.vue';
import localeService from 'src/app/commons/localeService/localeService.js';
import ConfirmDialog from '@/dialogs/confirm/ConfirmDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';

export default {
  name: 'ShopCustomDomain',
  components: {
    ShopCustomDomainConfiguration,
    ShopCustomDomainCreation,
    LoadingHeart,
  },
  mixins: [SettingsData],
  data: () => {
    return {
      shopLanguage: '',
      locale: '',
    };
  },
  computed: {
    loading() {
      return this.$store.state.shop.customDomain.loading;
    },
    error() {
      return this.$store.state.shop.customDomain.error;
    },
    isInternationShop() {
      return this.settings.international;
    },
    isCustomDomainConfigured() {
      return this.$store.state.shop.customDomain.data !== undefined;
    },
    customDomain() {
      return this.$store.state.shop.customDomain.data;
    },
  },
  async created() {
    this.$store.commit('shop/setLivePreviewVisible', false);
    shopAPI
      .getLocalization(this.$store)
      .then((localization) => {
        const shopSprdLanguage = localization.languages.find(
          (l) => l.id === this.config.shopLanguage,
        );
        const shopLanguage = localeService.convertFromSprdLanguage(
          shopSprdLanguage.isoCode,
        );
        this.shopLanguage = shopLanguage;
        this.locale = localeService.convertLanguageToLocale(shopLanguage);
      })
      .catch((e) => console.error('Failed to load localization', e));
    await this.$store.dispatch('shop/getCustomDomain');
  },
  unmounted() {
    this.$store.commit('shop/setLivePreviewVisible', true);
  },
  methods: {
    async getCustomDomain() {
      await this.$store.dispatch('shop/getCustomDomain');
    },
    async createCustomDomain(hostname) {
      await this.$store.dispatch('shop/createCustomDomain', {
        hostname,
        locale: this.locale,
      });
    },
    async deleteCustomDomain() {
      dialogService
        .openDialog(ConfirmDialog, {
          heading: 'SA.customDomain.deleteDialog.title',
          cancel: 'GENERAL.CANCEL',
          confirm: 'GENERAL.REMOVE',
          danger: true,
        })
        .then(() => {
          this.$store.dispatch('shop/deleteCustomDomain');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-overlay {
  height: 100%;

  .loader {
    margin: auto;
  }
}
</style>
