<template>
  <div class="pos-aggregation">
    <ul>
      <li class="header">
        <h4>{{ $t('STATISTICS.CREDIT_DETAILS.POS') }}</h4>
        <h4>{{ $t(valueLabel) }}</h4>
      </li>
      <li v-for="pos in valuesByPos">
        <p class="text-truncate">{{ pointOfSaleName(pos) }}</p>
        <p class="value">
          {{
            currencyId
              ? sprdCurrency(pos.amount, currencyId)
              : sprdNumber(pos.amount)
          }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import { pointOfSaleName } from '@/api/models/pointsOfSale/pointsOfSale';

export default {
  name: 'PosAggregation',
  components: {},
  props: {
    currencyId: {
      type: Number,
      required: false,
    },
    valuesByPos: {
      type: Array,
      required: true,
    },
    valueLabel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pointOfSaleName,
    };
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

ul {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding: 8px 0;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid $grey5;

    &.header {
      font-weight: bold;

      h4 {
        margin: 0;
      }
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border: none;
    }

    & > p:first-child {
      margin-right: 24px;
    }

    p {
      margin: 0;
    }

    .value {
      flex-shrink: 0;
    }
  }
}
</style>
