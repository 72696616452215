<template>
  <div>
    <h4>{{ $t('POS.SHOP_SETTINGS.DESIGN.TYPOGRAPHY.HEADLINE') }}</h4>
    <FontSelector
      :label="$t('POS.SHOP_SETTINGS.DESIGN.TYPOGRAPHY.FONT_HEADING')"
      :value="settings.fontHeadline"
      :padded="true"
      :options="settings.availableFontsHeadline"
      :is-open="'HDL' === openFont"
      @toggle="toggle('HDL')"
      @change="changeHeadline($event)"
    />

    <FontSelector
      :label="$t('POS.SHOP_SETTINGS.DESIGN.TYPOGRAPHY.FONT_BODY')"
      :value="settings.fontText"
      :padded="true"
      :options="settings.availableFontsText"
      :is-open="'BDY' === openFont"
      @toggle="toggle('BDY')"
      @change="changeBody($event)"
    />
  </div>
</template>

<script>
import { SettingsData } from 'src/app/partnerarea/pos/shop/shopSavable/SettingsData';
import FontSelector from '../shopStyles/FontSelector.vue';

export default {
  name: 'Fonts',
  components: {
    FontSelector,
  },
  mixins: [SettingsData],
  data() {
    return {
      openFont: '',
    };
  },
  methods: {
    toggle(fontLabel) {
      this.openFont = this.openFont === fontLabel ? '' : fontLabel;
    },
    changeHeadline(value) {
      this.openFont = '';
      this.settings.fontHeadline = value;
    },
    changeBody(value) {
      this.openFont = '';
      this.settings.fontText = value;
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  padding-left: 25px;
}
</style>
