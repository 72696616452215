import { deepCopy } from '@/utils';
import productIdeaService from '@/api/productIdeaService/productIdeaService';
import ideaHelper from '@/ideaHelper/ideaHelper';
import ideaTemplateService from 'src/app/commons/api/ideaTemplateService/ideaTemplateService';

function filterAssortment(entry, filterFn) {
  entry.$filtered = false;

  if (entry.subFilters) {
    Object.keys(entry.subFilters).forEach((subFilter) => {
      filterAssortment(entry.subFilters[subFilter], filterFn);
    });

    if (
      !Object.keys(entry.subFilters).some(
        (subFilter) => !entry.subFilters[subFilter].$filtered,
      )
    ) {
      entry.$filtered = true;
    }
  } else {
    if (filterFn(entry)) {
      entry.$filtered = true;
    }
  }
}

function resetAssortmentFilter(entry) {
  entry.$filtered = false;
  if (entry.subFilters) {
    Object.keys(entry.subFilters).forEach((subFilter) => {
      resetAssortmentFilter(entry.subFilters[subFilter]);
    });
  }
}

function isPublishedOn(state, posId) {
  if (!state.current) {
    return false;
  } else {
    return !!state.current.publishingDetails.find(
      (detail) => posId === detail.pointOfSale.id,
    );
  }
}

export default {
  namespaced: true,
  state: {
    current: null,
    original: null,
    lastDesignerTab: null,
  },
  getters: {
    language: (state) => state.current.language,
    description: (state, getters) => getters.currentTranslation.description,
    name: (state, getters) => getters.currentTranslation.name,
    tags: (state, getters) => getters.currentTranslation.tags,
    getTranslation: (state) => (locale) =>
      ideaHelper.getTranslation(state.current, locale),
    localizedDescription: (state, getters) => (locale) =>
      getters.getTranslation(locale)
        ? getters.getTranslation(locale).description
        : '',
    localizedName: (state, getters) => (locale) =>
      getters.getTranslation(locale) ? getters.getTranslation(locale).name : '',
    localizedTags: (state, getters) => (locale) =>
      getters.getTranslation(locale) ? getters.getTranslation(locale).tags : [],
    publishingDetails: (state) =>
      state.current ? state.current.publishingDetails : [],
    selectedMarketplace: (state, getters) =>
      getters.publishingDetails.find(
        (detail) => detail.pointOfSale.type === 'MARKETPLACE',
      ),
    hasMarketplace: (state, getters) => !!getters.selectedMarketplace,
    selectedShops: (state, getters) =>
      getters.publishingDetails.filter(
        (detail) => detail.pointOfSale.type === 'SHOP',
      ),
    hasShops: (state, getters) => !!getters.selectedShops.length,
    selectedYoutubeShops: (state, getters, rootState) =>
      getters.selectedShops.filter(
        (selectedShop) =>
          rootState.user.shops.find(
            (shop) => shop.id === selectedShop.pointOfSale.target.id,
          ).youtubeConnected,
      ),
    hasYoutubeShops: (state, getters) => !!getters.selectedYoutubeShops.length,
    isPublishedOn: (state) => (posId) => isPublishedOn(state, posId),
    legalState: (state) => (pointOfSaleType) =>
      state.current
        ? state.current.legalStates.find(
            (lg) => lg.pointOfSaleType === pointOfSaleType,
          )
        : {},
    firstRejectionReason: (state, getters) => (pointOfSaleType) => {
      const rejectionReasons =
        getters.legalState(pointOfSaleType).rejectionReason;
      if (rejectionReasons) {
        const reasons = rejectionReasons.filter(
          (reason) => !['SILENT', 'REJECTED_IN_OPOSSUM'].includes(reason),
        );
        if (reasons.length > 0) {
          return reasons[0];
        }
      }
    },
    currentTranslation: (state) =>
      ideaHelper.getCurrentTranslation(state.current),
    designImages: (state) =>
      state.current
        ? state.current.resources.filter((r) => r.type !== 'default')
        : [],
    multipleDesigns: (state, getters) => getters.designImages.length > 1,
    meetsPosterRequirements: (state) => state.current.flags.includes('POSTERS'),
    designs: (state) => {
      if (!state.current || !state.current.parents) {
        return [];
      }

      return state.current.parents;
    },
    initialAssortment: (state) =>
      state.current.assortment &&
      state.current.assortment.hints &&
      state.current.assortment.hints.includes('INITIAL_ASSORTMENT'),
    relevantTemplateLabels: (state, getters, rootState, rootGetters) => {
      const labels = [];

      if (getters.hasMarketplace) {
        labels.push('MARKETPLACE');
      }
      if (getters.hasShops) {
        labels.push('SHOP');
      }
      if (getters.hasYoutubeShops) {
        labels.push('MERCHANT_YOUTUBE');
      }

      if (!labels.length) {
        // if we could not figure out a label via the idea we fall back to user settings
        if (rootGetters['user/hasMarketplace']) {
          labels.push('MARKETPLACE');
        }
        if (rootGetters['user/hasShops']) {
          labels.push('SHOP');
        }
        if (rootGetters['user/hasYoutubeShops']) {
          labels.push('MERCHANT_YOUTUBE');
        }
      }

      return labels;
    },
    requestedAutotranslations: (state) =>
      state.current.intPubDetails &&
      state.current.intPubDetails.userRequestedAutoTranslations,
    intPubDecision: (state) =>
      state.current.intPubDetails && state.current.intPubDetails.asmDecision,
    designDownloadLink: (state, getter, rootState) =>
      `/api/v1/users/${rootState.user.data.id}/design-downloads/${state.current.mainDesignId}`,
  },
  mutations: {
    setIdea(state, { idea }) {
      state.current = idea;
      state.original = deepCopy(idea);

      state.lastDesignerTab = null;
    },
    updateGroup(state, { groupId, changes }) {
      const group = ideaHelper.getAssortmentGroupById(
        state.current.assortment,
        groupId,
      );
      if (group) {
        Object.assign(group, changes);
      }
    },
    updateSellableColor(state, { groupId, appearanceId }) {
      const group = ideaHelper.getAssortmentGroupById(
        state.current.assortment,
        groupId,
      );
      if (group) {
        group.primarySellable.appearanceId = appearanceId;
      }
    },
    updateSellableImage(state, { groupId, imageUrl }) {
      const group = ideaHelper.getAssortmentGroupById(
        state.current.assortment,
        groupId,
      );
      if (group) {
        group.primarySellable.defaultImageUrl = imageUrl;
      }
    },
    updateSellableColors(state, { groupId, colors }) {
      const group = ideaHelper.getAssortmentGroupById(
        state.current.assortment,
        groupId,
      );
      if (group) {
        group.primarySellable.colors = colors;
      }
    },
    filterAssortment(state, { filterFn }) {
      filterAssortment(state.current.assortment, filterFn);
    },
    resetAssortmentFilter(state) {
      resetAssortmentFilter(state.current.assortment);
    },
    changeTranslationToManual(state, locale) {
      if (ideaHelper.getTranslation(state.current, locale)) {
        ideaHelper.getTranslation(state.current, locale).autotranslated = false;
      }
    },
    updateName(state, { name, locale }) {
      ideaHelper.getTranslation(state.current, locale).name = name.trim();
      ideaHelper.getTranslation(state.current, locale).autotranslated = false;
    },
    updateDescription(state, { description, locale }) {
      ideaHelper.getTranslation(state.current, locale).description =
        description.trim();
      ideaHelper.getTranslation(state.current, locale).autotranslated = false;
    },
    addTag(state, { tag, locale }) {
      const translation = ideaHelper.getTranslation(state.current, locale);
      if (!translation.tags.includes(tag)) {
        translation.tags.push(tag);
      }
      ideaHelper.getTranslation(state.current, locale).autotranslated = false;
    },
    removeTag(state, { tag, locale }) {
      const translation = ideaHelper.getTranslation(state.current, locale);
      if (!translation || !translation.tags) {
        return;
      }

      translation.tags = translation.tags.filter((t) => t !== tag);
      ideaHelper.getTranslation(state.current, locale).autotranslated = false;
    },
    addTranslation(state, { locale }) {
      state.current.translations.push({
        name: '',
        description: '',
        tags: [],
        locale,
      });
    },
    removeTranslation(state, { locale }) {
      state.current.translations = state.current.translations.filter(
        (translation) => translation.locale !== locale,
      );
    },
    removeAllTranslationsExceptLocale(state, { locale }) {
      state.current.translations = state.current.translations.filter(
        (translation) => translation.locale === locale,
      );
    },
    addBlacklistedTerms(state, terms) {
      state.current.enteredBlacklistedTags = [
        ...new Set((state.current.enteredBlacklistedTags || []).concat(terms)),
      ];
    },
    toggleBackgroundColor(state) {
      if (state.current.backgroundColor) {
        state.current.backgroundColor = '';
      } else {
        state.current.backgroundColor = '#666666';
      }
    },
    setPointOfSale(state, publishingDetail) {
      if (!isPublishedOn(state, publishingDetail.pointOfSale.id)) {
        const details = state.current.publishingDetails;
        details.push(publishingDetail);
        state.current.publishingDetails = details;
      }
    },
    unsetPointOfSale(state, publishingDetail) {
      if (isPublishedOn(state, publishingDetail.pointOfSale.id)) {
        const details = state.current.publishingDetails.filter(
          (pos) => publishingDetail.pointOfSale.id !== pos.pointOfSale.id,
        );
        state.current.publishingDetails = details;
      }
    },
    unsetPointOfSaleType(state, type) {
      const details = state.current.publishingDetails.filter(
        (pos) => type !== pos.pointOfSale.type,
      );
      state.current.publishingDetails = details;
    },
    updateOriginal(state) {
      state.original = deepCopy(state.current);
    },
    resetToOriginal(state) {
      state.current = deepCopy(state.original);
    },
    updateOriginalIdeaAssortment(state) {
      state.original.assortment = deepCopy(state.current.assortment);
    },
    clearPublishingDetails(state) {
      state.current.publishingDetails = [];
    },
    setConfigurationProperty(state) {
      if (!state.current.properties) {
        state.current.properties = {};
      }
      state.current.properties.configuration = `${Date.now()}`;
    },
    setRequestedAutotranslations(state, value) {
      if (!state.current.intPubDetails) {
        state.current.intPubDetails = {
          userRequestedAutoTranslations: null,
        };
      }

      state.current.intPubDetails.userRequestedAutoTranslations = value;
    },
    setAssortment(state, assortment) {
      state.current.assortment = assortment;
    },
    removePublishingDetail(state, publishingDetail) {
      state.current.publishingDetails = state.current.publishingDetails.filter(
        (pos) => publishingDetail.id !== pos.id,
      );
    },
    addPublishingDetail(state, publishingDetail) {
      state.current.publishingDetails.push(publishingDetail);
    },
    updateProcessingState(state, processingState) {
      state.current.processingState = processingState;
    },
    updatePublishingDetails(state, publishingDetails) {
      state.current.publishingDetails = publishingDetails;
    },
    setMpTransformerMode(state, mode) {
      state.current.mpTransformerMode = mode;
    },
    setDefaultLanguage(state, language) {
      state.current.language = language;
    },
    setLastDesignerTab(state, value) {
      state.lastDesignerTab = value;
    },
  },
  actions: {
    changeDefaultLanguage({ commit, getters }, language) {
      if (!getters.getTranslation(language)) {
        commit('addTranslation', { locale: language });
      }
      commit('setDefaultLanguage', language);
      commit('changeTranslationToManual', language);
      commit('removeAllTranslationsExceptLocale', { locale: language });
    },
    togglePos: ({ commit, getters }, publishingDetail) => {
      const commitFunction = getters.isPublishedOn(
        publishingDetail.pointOfSale.id,
      )
        ? 'unsetPointOfSale'
        : 'setPointOfSale';
      commit(commitFunction, publishingDetail);
    },
    fetchIdea: async ({ commit, dispatch }, ideaId) => {
      const idea = await productIdeaService.getIdeaOfUserById(ideaId);
      commit('setIdea', { idea });
      await dispatch('fetchAssortment');
      await commit('updateOriginalIdeaAssortment');
    },
    fetchAssortment: async ({ commit, state }) => {
      const assortment = await productIdeaService.getAssortment(state.current);
      await commit('setAssortment', assortment);
    },
    updateName: ({ commit, rootState, getters }, { name, locale }) => {
      if (!getters.getTranslation(locale)) {
        commit('addTranslation', { locale });
      }
      commit('updateName', { name, locale: locale || rootState.user.locale });
    },
    updateDescription: (
      { commit, rootState, getters },
      { description, locale },
    ) => {
      if (!getters.getTranslation(locale)) {
        commit('addTranslation', { locale });
      }

      commit('updateDescription', {
        description,
        locale: locale || rootState.user.locale,
      });
    },
    addTag: ({ commit, rootState, getters }, { tag, locale }) => {
      if (!getters.getTranslation(locale)) {
        commit('addTranslation', { locale });
      }

      commit('addTag', { tag, locale: locale || rootState.user.locale });
    },
    removeTag: ({ commit, rootState }, { tag, locale }) =>
      commit('removeTag', { tag, locale: locale || rootState.user.locale }),
    applyDefaultAssortmentAndUpdate: async ({ state, getters, dispatch }) => {
      if (
        !getters.initialAssortment ||
        getters.meetsPosterRequirements ||
        getters.multipleDesigns
      ) {
        return await dispatch('fetchAssortment');
      }

      try {
        const templates = await ideaTemplateService.getTemplatesForIdea(
          state.current.id,
          getters.relevantTemplateLabels,
        );
        const recommendedTemplate = templates.list.find(
          (template) => template.phenotype.recommended,
        );

        if (recommendedTemplate) {
          await ideaTemplateService.applyTemplateForIdeas({
            templateId: recommendedTemplate.phenotype.id,
            ideaIds: [state.current.id],
            parts: ['ASSORTMENT'],
          });
        }
      } catch (_) {
        // loading of templates did not work, we still need to trigger an assortment update to get rid of the INITIAL_ASSORTMENT flag
        await dispatch('updateIdeaAssortment', { forceUpdate: true });
      }

      await dispatch('fetchAssortment');
    },
    saveCurrentIdea: async ({ commit, dispatch }, options = {}) => {
      await dispatch('updateIdea', options);
      await dispatch('updateIdeaAssortment');
      await commit('updateOriginal');
    },
    updateIdea: async ({ commit, state, rootState }, options = {}) => {
      if (
        options.forcePublishingDetailsUpdate ||
        !ideaHelper.equals(state.current, state.original, {
          ignorePublishingDetails: options.skipPublishingDetails,
          ignoreAssortment: true,
        })
      ) {
        const cyo = rootState.user.publishingDetails.find(
          (publishingDetail) => publishingDetail.pointOfSale.type === 'CYO',
        );
        if (
          ideaHelper.isPublishedOnCYO(state.current) &&
          !ideaHelper.isPublishedOnMarketplace(state.current)
        ) {
          await commit('removePublishingDetail', cyo);
        } else if (
          !ideaHelper.isPublishedOnCYO(state.current) &&
          ideaHelper.isPublishedOnMarketplace(state.current)
        ) {
          await commit('addPublishingDetail', cyo);
        }

        const updatedIdea = await productIdeaService.updateIdea(state.current, {
          updatePublishing: !options.skipPublishingDetails,
        });
        await commit('updateProcessingState', updatedIdea.processingState);
        await commit('updatePublishingDetails', updatedIdea.publishingDetails);
      }
    },
    updateIdeaAssortment: async ({ commit, state }, options = {}) => {
      if (
        options.forceUpdate ||
        !ideaHelper.assortmentEquals(state.current, state.original)
      ) {
        await productIdeaService.updateAssortment(state.current);
        await commit('updateOriginalIdeaAssortment');
      } else {
        return Promise.resolve();
      }
    },
  },
};
