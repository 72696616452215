<template>
  <div v-if="imageUrls.length && imageUrlToDisplay" class="random-image">
    <transition name="fade" mode="out-in">
      <img
        :key="imageUrlToDisplay"
        :src="getImageUrlWithWidth(imageUrlToDisplay)"
        alt=""
      />
    </transition>
  </div>
</template>

<script>
import sample from 'lodash.sample';
import SprdImage from '@/image/SprdImage.vue';

export default {
  name: 'RandomImage',
  props: {
    imageUrls: {
      type: Array,
      required: true,
    },
    width: {
      type: String,
    },
    intervalTime: {
      type: Number,
      default: 3000,
    },
  },
  comonents: {
    SprdImage,
  },
  data() {
    return {
      imageUrlToDisplay: null,
      nextImageUrl: null,
      interval: null,
    };
  },
  computed: {
    imageUrlsNotDisplayed() {
      return this.imageUrls.filter(
        (imageUrl) => this.imageUrlToDisplay !== imageUrl,
      );
    },
  },
  created() {
    this.chooseInitialDisplayedProducts();
  },
  unmounted() {
    clearInterval(this.interval);
  },
  methods: {
    getImageUrlWithWidth(imageUrl) {
      return `${imageUrl}${this.width ? ',width=' + this.width : ''}`;
    },
    chooseInitialDisplayedProducts() {
      this.chooseNextImage();

      this.interval = setInterval(() => {
        this.chooseNextImage();
      }, this.intervalTime);
    },
    chooseNextImage() {
      this.imageUrlToDisplay = this.nextImageUrl || sample(this.imageUrls);
      this.nextImageUrl = sample(this.imageUrlsNotDisplayed);

      // preload image url
      const image = new Image();
      image.src = this.getImageUrlWithWidth(this.nextImageUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.random-image {
  display: flex;
}
</style>
