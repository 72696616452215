<template>
  <ValidLabelInput
    v-bind="$props"
    :validators="validators"
    @update:model-value="$emit('update:modelValue', $event)"
  />
</template>

<script>
import ValidLabelInput from './ValidLabelInput.vue';
import { getBlacklistValidator } from '../../shop/common/validators/blacklistValidator';

export default {
  name: 'LegalLabelInput',
  components: { ValidLabelInput },
  props: {
    label: {
      required: true,
      type: String,
    },
    modelValue: {
      type: String,
      default: '',
    },
    type: {
      required: false,
      type: String,
      default: 'text',
    },
    safeValue: {
      required: false,
      type: Boolean,
      default: true,
    },
    maxlength: {
      required: false,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  data: function () {
    return { validators: [getBlacklistValidator(this.field)] };
  },
};
</script>
