<template>
  <div class="welcome-page">
    <div class="welcome-page-content">
      <label id="upload-btn" for="hiddenFileInput">
        <p class="upload-specifications-label">
          {{ $t('DESIGNS.UPLOAD.FILE_TYPES') }}
        </p>
        <p>
          <strong class="file-type">
            {{ acceptedFileTypes }}
          </strong>
        </p>
        <div class="upload-button">
          <Icon icon="upload" />
          {{ $t('DESIGNS.LIST.CLICK_TO_UPLOAD') }}
        </div>
        <p class="copyright-text">
          <small v-html="$t('DESIGNS.UPLOAD.LIST.COPYRIGHT_LABEL')"></small>
        </p>
        <input
          id="hiddenFileInput"
          ref="hiddenFileInput"
          type="file"
          multiple
          class="hidden-file-input"
          @change="uploadFiles"
        />
      </label>
      <div class="welcome-page-txt">
        <h1>{{ $t('DESIGNS.LIST.UPLOAD_FIRST_DESIGN') }}</h1>

        <div v-if="!hasMarketplace" class="no-designs">
          <h3>{{ $t('NO_DESIGNS.HEADING') }}</h3>
          <AiButton
            v-if="aiEnabled"
            @ai-button-clicked="handleAiDesignGeneration"
          >
            {{ $t('AI.GENERATE_DESIGN') }}
          </AiButton>
          <button
            v-else
            class="btn btn-ghost ai-disabled-button"
            @click="autoGenerateDesign"
          >
            {{ $t('NO_DESIGNS.CTA') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import designUploadService from '@/api/designUploadService/designUploadService';
import { generateDesign } from '@/designGenerator/designGenerator';
import { mapGetters, mapState } from 'vuex';
import analytics from '@/tracking/analytics';
import dialogService from '@/dialogs/wrapper/dialogService';
import NoDesignsDialog from '@/dialogs/noDesigns/NoDesignsDialog.vue';
import AiButton from 'src/app/components/ai/AiButton.vue';
import AiDesignGeneratorDialog from '@/designGenerator/AiDesignGeneratorDialog.vue';

export default {
  name: 'IdeaListWelcome',
  components: { AiButton },
  emits: ['refresh', 'upload'],
  data() {
    return {
      acceptedFileTypes: designUploadService
        .getAcceptedFileTypes()
        .map((fileType) => fileType.extensions[0])
        .join(', '),
      fileLimit: designUploadService.getFileLimit(),
    };
  },
  computed: {
    ...mapGetters({
      hasMarketplace: 'user/hasMarketplace',
      aiEnabled: 'settings/aiEnabled',
    }),
    ...mapState({
      ideasCount: (s) => s.ideas.list.length,
    }),
  },
  watch: {
    ideasCount: function () {
      this.$emit('refresh');
    },
  },
  methods: {
    autoGenerateDesign() {
      analytics.logEvent('no-design-workflow-cta');

      dialogService.openDialog(NoDesignsDialog).then(async (choice) => {
        if (choice === 'GENERATE') {
          analytics.logEvent('no-design-workflow-generate-design');
          const file = await generateDesign();
          this.$emit('upload', [file], ['bubbles']);
        } else if (choice === 'AI') {
          analytics.logEvent('no-design-workflow-ai');
        } else {
          analytics.logEvent('no-design-workflow-graphics-service');
        }
      });
    },
    async handleAiDesignGeneration() {
      analytics.logEvent('no-design-workflow-ai');
      try {
        await dialogService.openDialog(
          AiDesignGeneratorDialog,
          {},
          { staticBackdrop: true, preventEsc: true },
        );
      } catch (_) {
        analytics.logEvent('ai_generator_skip');
      }
    },
    uploadFiles(evt) {
      this.$emit('upload', [...evt.target.files]);
      this.$refs.hiddenFileInput.value = '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/links';

.welcome-page {
  border: none !important;
  background-image: url('/images/upload-pattern.png');
  background-repeat: repeat;
  background-position: -15px -15px;
  padding: 24px;
}

.welcome-page-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

label {
  width: 50%;
  max-width: 340px;
  background-color: $grey5;
  border-radius: $border-radius-medium;
  display: flex;
  padding: 48px 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    .upload-button {
      color: $pa-color-main-dark;
    }
  }

  p {
    margin: 0;
    text-align: center;
    color: $grey60;

    :deep(strong) {
      color: $grey80;
    }
  }
}

.upload-button {
  color: $pa-color-main;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 90px 0;
  font-weight: bold;

  .icon {
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
  }
}

.copyright-text {
  @extend .text-xs;
  color: $grey40;
  text-align: center;
}

:deep(.copyright-text) {
  a {
    @extend .link-main;
  }
}

.welcome-page-txt {
  padding-left: 48px;
  width: 50%;

  h1 {
    max-width: 340px;
    margin: 0 0 80px 0;
  }

  .no-designs {
    h3 {
      margin: 0 0 16px 0;
    }

    .ai-disabled-button {
      height: 40px;
      padding: 0 48px;
    }
  }
}
</style>
