<template>
  <div class="chart-container"></div>
</template>

<script>
import Chartist from 'chartist';

export default {
  name: 'Chart',
  props: {
    type: {
      type: String,
      required: true,
      default: 'Line',
      validator: function (value) {
        return ['Line', 'Bar', 'Pie'].indexOf(value) !== -1;
      },
    },
    data: {
      type: Object,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    responsiveOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      chartist: null,
    };
  },
  watch: {
    data: {
      handler: 'renderChart',
      deep: true,
    },
    options: {
      handler: 'renderChart',
      deep: true,
    },
    responsiveOptions: {
      handler: 'renderChart',
      deep: true,
    },
  },
  mounted() {
    this.renderChart();
  },
  beforeUnmount() {
    if (this.chartist) {
      this.chartist.detach();
    }
  },
  methods: {
    renderChart() {
      if (this.chartist) {
        this.chartist.update(this.data, this.options, this.responsiveOptions);
      } else {
        this.chartist = new Chartist[this.type](
          this.$el,
          this.data,
          this.options,
          this.responsiveOptions,
        );
        this.chartist.on('draw', function (data) {
          if (data.type == 'bar') {
            data.element.animate({
              y2: {
                begin: 20 * data.index,
                dur: '0.5s',
                from: data.y1,
                to: data.y2,
              },
            });
          }
        });
      }
    },
  },
};
</script>
