<template>
  <div class="mfa">
    <p class="subheading">
      {{ $t('MFA.STATUS.SUBHEADING') }}
    </p>
    <LoadingHeart v-if="currentMfaState === MFA_STATES.LOADING" />
    <div v-if="currentMfaState === MFA_STATES.SETUP">
      <button
        v-tooltip.top-start="{
          content: isAdmin ? 'not allowed as admin' : null,
          hideOnTargetClick: false,
        }"
        class="btn btn-light"
        :disabled="isAdmin"
        @click="openMfaSetupDialog"
      >
        {{ $t('MFA.STATUS.START_SETUP') }}
      </button>
    </div>
    <div v-if="currentMfaState === MFA_STATES.ACTIVATED">
      <div class="connected">
        <div class="icon-wrapper">
          <Icon icon="accept" />
        </div>
        <p>{{ $t('MFA.STATUS.CONNECTED') }}</p>
      </div>
      <button
        v-tooltip.top-start="{
          content: isAdmin ? 'not allowed as admin' : null,
          hideOnTargetClick: false,
        }"
        class="btn btn-light-danger"
        :disabled="isAdmin"
        @click="openMfaDeactivateDialog"
      >
        {{ $t('MFA.STATUS.DEACTIVATE') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import dialogService from '@/dialogs/wrapper/dialogService';
import MfaSetupDialog from './MfaSetupDialog.vue';
import MFA_STATES from '@/states/mfaStates';
import LoadingHeart from '@/LoadingHeart/LoadingHeart.vue';
import MfaDeactivateDialog from './MfaDeactivateDialog.vue';

export default {
  name: 'MfaStatus',
  components: { LoadingHeart },
  data() {
    return {
      MFA_STATES,
    };
  },
  computed: {
    ...mapState({
      userId: (s) => s.user.data.id,
      currentMfaState: (s) => s.user.mfaStatus,
    }),
    ...mapGetters({
      isAdmin: 'account/isAdmin',
    }),
  },
  created() {
    this.updateMfaStatus();
  },
  methods: {
    ...mapActions({
      updateMfaStatus: 'user/updateMfaStatus',
    }),
    openMfaSetupDialog() {
      dialogService
        .openDialog(MfaSetupDialog, null, {
          displayClose: false,
          staticBackdrop: true,
          preventEsc: true,
        })
        .finally(() => {
          this.updateMfaStatus();
        });
    },
    openMfaDeactivateDialog() {
      dialogService.openDialog(MfaDeactivateDialog).finally(() => {
        this.updateMfaStatus();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/_type.scss';

.mfa {
  margin-top: 24px;

  .subheading {
    color: $grey60;
    margin: 0 0 16px 0;
    max-width: 500px;
  }

  .connected {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    p {
      margin: 0;
      font-weight: bold;
    }
  }

  .icon-wrapper {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    background-color: $pa-color-green;

    .icon {
      color: $white;
    }
  }
}
</style>
