<template>
  <div class="recap">
    <div v-if="initialized">
      <div v-if="stepData && stepData.length > 1" class="left-action">
        <a href="#" class="icon-link" @click.prevent="previousStep"
          ><Icon icon="left"
        /></a>
      </div>
      <div
        class="recap-content-container"
        @mousedown="onMouseDown"
        @mouseup="onMouseUp"
      >
        <Timeline
          :step-count="stepData.length"
          :current-step="currentStep"
          step-duration="10"
          :pause="paused"
          @step-done="nextStep"
        />
        <div :key="currentStepData.invertedStyle" class="recap-content">
          <div v-if="!currentStepData.invertedStyle" class="header">
            <img
              class="logo"
              :src="`/images/${
                partnerType === 'SHOP'
                  ? 'spreadshop-logo-white.svg'
                  : 'spreadshirt-logo-white.svg'
              }`"
            />
            <p class="text-white">{{ hashtag }}</p>
          </div>
          <Step
            :key="currentStep"
            :before-heading="currentStepData.beforeHeading"
            :heading="currentStepData.heading"
            :after-heading="currentStepData.afterHeading"
            :sub-heading="currentStepData.subHeading"
            :image="currentStepData.image"
            :tiled-image-background="currentStepData.tiledImageBackground"
            :data="currentStepData.data"
            :illustration="currentStepData.illustration"
            :inverted-style="currentStepData.invertedStyle"
            :full-center-style="currentStepData.fullCenterStyle"
            :restart="currentStepData.restart"
            :hashtag="hashtag"
            @on-restart="restart"
          />
        </div>
      </div>
      <div v-if="stepData && stepData.length > 1" class="right-action">
        <a href="#" class="icon-link" @click.prevent="nextStep"
          ><Icon icon="right"
        /></a>
      </div>
    </div>
    <div v-else class="loading">
      <LoadingHeart white />
      <h1><LoadingWords /></h1>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Timeline from './Timeline.vue';
import Step from './Step.vue';
import RecapService from './RecapService';
import LoadingHeart from '@/LoadingHeart/LoadingHeart.vue';
import { parseISO } from 'date-fns/parseISO';
import { isAfter } from 'date-fns/isAfter';
import LoadingWords from './LoadingWords.vue';

export default {
  name: 'Recap',
  components: {
    Timeline,
    Step,
    LoadingHeart,
    LoadingWords,
  },
  props: {
    year: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      initialized: false,
      currentStep: 0,
      stepData: [],
      paused: false,
      pausedSince: 0,
      hashtag: null,
    };
  },
  computed: {
    currentStepData() {
      return this.stepData[this.currentStep];
    },
    ...mapState({
      shippingCountries: (state) => state.platform.shippingCountries,
      registrationDate: (state) => state.user.data.created,
    }),
    ...mapGetters({
      partnerType: 'user/partnerType',
    }),
  },
  async created() {
    this.markRecapAsSeen();
    const recapData = await RecapService.getData(this.year);

    if (recapData.lifeTime.sales > 2) {
      this.hashtag = `#spreadSuccess${this.year}`;
      this.stepData = [
        {
          invertedStyle: true,
          heading: `${this.year}`,
          afterHeading: 'RECAP.INTRODUCTION.HEADING',
          subHeading: 'RECAP.INTRODUCTION.SUBHEADING',
          data: [
            {
              label: 'RECAP.GENERAL.TOTAL_SALES',
              value: `${this.sprdNumber(recapData.lifeTime.sales)}`,
            },
            {
              label: 'RECAP.GENERAL.TOTAL_EARNINGS',
              value: this.sprdCurrency(
                recapData.lifeTime.earnings,
                recapData.lifeTime.currencyId,
              ),
            },
            {
              label: 'RECAP.INTRODUCTION.PARTNER_SINCE',
              value: `${this.sprdNumber(
                recapData.lifeTime.registeredSinceDays,
              )} ${this.$t('GENERAL.DAYS')}`,
              valueClass: 'heading1',
            },
          ],
        },
        {
          heading: `${this.date(recapData.bestMonth.monthDate, 'MMMM')}`,
          subHeading: 'RECAP.BEST_MONTH.SUBHEADING',
          illustration: '/images/recap_month.svg',
          data: [
            {
              label: 'RECAP.GENERAL.TOTAL_SALES',
              value: `${this.sprdNumber(recapData.bestMonth.sales)}`,
            },
            {
              label: 'RECAP.GENERAL.TOTAL_EARNINGS',
              value: this.sprdCurrency(
                recapData.bestMonth.earnings,
                recapData.bestMonth.currencyId,
              ),
            },
          ],
        },
        {
          beforeHeading: `${this.date(recapData.bestDay.date, 'P')}`,
          heading: `${this.date(recapData.bestDay.date, 'EEEE')}`,
          subHeading: 'RECAP.BEST_DAY.SUBHEADING',
          illustration: '/images/recap_day.svg',
          data: [
            {
              label: 'RECAP.GENERAL.TOTAL_SALES',
              value: `${this.sprdNumber(recapData.bestDay.sales)}`,
            },
            {
              label: 'RECAP.GENERAL.TOTAL_EARNINGS',
              value: this.sprdCurrency(
                recapData.bestDay.earnings,
                recapData.bestDay.currencyId,
              ),
            },
          ],
        },
        {
          heading: `${
            this.shippingCountries[recapData.bestCountry.country] ||
            recapData.bestCountry.country
          }`,
          subHeading: 'RECAP.BEST_COUNTRY.SUBHEADING',
          illustration: '/images/recap_country.svg',
          data: [
            {
              label: 'RECAP.GENERAL.TOTAL_SALES',
              value: `${this.sprdNumber(recapData.bestCountry.sales)}`,
            },
            {
              label: 'RECAP.BEST_COUNTRY.COUNTRIES_REACHED',
              value: `${recapData.bestCountry.countryCount}`,
            },
          ],
        },
        {
          heading: 'RECAP.BEST_DESIGN.HEADING',
          afterHeading: 'RECAP.BEST_DESIGN.SUBHEADING',
          subHeading: recapData.bestDesign?.designName || '',
          image: recapData.bestDesign
            ? `${recapData.bestDesign?.designImageUrl},width=300`
            : '/images/brokenfile.png',
          tiledImageBackground: true,
          data: [
            {
              label: 'RECAP.GENERAL.TOTAL_SALES',
              value: `${this.sprdNumber(recapData.bestDesign?.sales || 0)}`,
            },
          ],
        },
        {
          heading: 'RECAP.BEST_PRODUCT.HEADING',
          afterHeading: 'RECAP.BEST_PRODUCT.SUBHEADING',
          subHeading: recapData.bestProduct.productName,
          image: `${recapData.bestProduct.productImageUrl},width=300`,
          data: [
            {
              label: 'RECAP.GENERAL.TOTAL_SALES',
              value: `${this.sprdNumber(recapData.bestProduct.sales)}`,
            },
          ],
        },
        {
          invertedStyle: true,
          fullCenterStyle: true,
          restart: true,
          heading: 'RECAP.THANK_YOU.SUCCESS.HEADING',
          afterHeading: 'RECAP.THANK_YOU.SUCCESS.SUBHEADING',
          subHeading: 'RECAP.THANK_YOU.SUCCESS.TEXT',
        },
      ];
    } else if (
      isAfter(parseISO(this.registrationDate), new Date(this.year, 9, 1))
    ) {
      this.stepData = [
        {
          invertedStyle: true,
          fullCenterStyle: true,
          restart: false,
          heading: 'RECAP.THANK_YOU.NEW_PARTNER.HEADING',
          afterHeading: 'RECAP.THANK_YOU.NEW_PARTNER.SUBHEADING',
          subHeading: 'RECAP.THANK_YOU.NEW_PARTNER.TEXT',
        },
      ];
    } else {
      this.stepData = [
        {
          invertedStyle: true,
          fullCenterStyle: true,
          restart: false,
          heading: 'RECAP.THANK_YOU.NO_SUCCESS.HEADING',
          afterHeading: 'RECAP.THANK_YOU.NO_SUCCESS.SUBHEADING',
          subHeading: 'RECAP.THANK_YOU.NO_SUCCESS.TEXT',
        },
      ];
    }

    this.initialized = true;
  },
  methods: {
    markRecapAsSeen() {
      if (!this.$store.getters['account/isAdmin']) {
        this.$store.dispatch('settings/setSetting', {
          key: 'LAST_RECAP',
          value: this.year,
        });
      }
    },
    nextStep() {
      if (this.stepData.length > this.currentStep + 1) {
        this.currentStep++;
      }
    },
    previousStep() {
      if (this.currentStep >= 1) {
        this.currentStep--;
      }
    },
    restart() {
      this.currentStep = 0;
    },
    onMouseDown() {
      this.paused = true;
      this.pausedSince = Date.now();
    },
    onMouseUp() {
      this.paused = false;
      if (Date.now() - this.pausedSince < 200) {
        this.nextStep();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';

.recap {
  width: 400px;
  height: 615px;

  & > div {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    .recap-content-container {
      border-radius: $border-radius-medium;
      overflow: hidden;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .recap-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      & > div:last-child {
        flex-grow: 1;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $grey80;
    padding: 0 12px 12px 12px;

    .logo {
      height: 16px;
    }

    p {
      margin: 0;
    }
  }

  .left-action,
  .right-action {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;

    .icon {
      height: 24px;
      width: 24px;
      margin: 0;
    }
  }

  .left-action {
    left: -48px;
  }

  .right-action {
    right: -48px;
  }

  .loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $grey80;
    border-radius: $border-radius-medium;
    overflow: hidden;

    .loader {
      height: auto;
    }

    h1 {
      margin: 32px 0 0 0;
      color: $white;
    }
  }
}
</style>
