<template>
  <div class="dialog-size-md default-translations-dialog">
    <div class="modal-body">
      <h1>
        {{
          $t(
            'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.DEFAULT_HEADING',
          )
        }}
      </h1>
      <p class="text-grey">
        {{
          $t(
            'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.DEFAULT_SUBHEADING',
          )
        }}
      </p>
      <table class="translations">
        <thead>
          <tr>
            <th>
              {{
                $t(
                  'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.TRANSLATION_LANGUAGES',
                )
              }}
            </th>
            <!-- <th>{{ $t('GENERAL.STATUS') }}</th> -->
            <th>
              {{
                $t(
                  'DESIGNS.CONFIGURATION.META_DATA.MANAGE_TRANSLATIONS.TRANSLATION_DEFAULT',
                )
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="translation in translations" :key="translation.locale">
            <td>
              {{ translation.name }}
            </td>
            <!-- <td class="text-grey">TODO: Translation Hint</td> -->
            <td>
              <RadioButton
                :selected="selectedDefaultTranslation === translation"
                name="defaultTranslation"
                @on-selected="onDefaultChange(translation)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="actions">
        <button
          type="button"
          class="btn btn-primary"
          :disabled="!defaultWasChanged"
          @click="apply"
        >
          {{ $t('GENERAL.APPLY') }}
        </button>
        <button type="button" class="btn btn-light" @click="cancel">
          {{ $t('GENERAL.CANCEL') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import RadioButton from '@/RadioButtonGroup/RadioButton.vue';

export default {
  name: 'PublishingDefaultTranslationDialog',
  components: {
    RadioButton,
  },
  props: ['modalInstance', 'data'],
  data() {
    return {
      currentLanguage: null,
      selectedDefaultTranslation: null,
      translations: null,
    };
  },
  computed: {
    ...mapGetters({
      languageByIso: 'platform/languageByIso',
    }),
    ...mapState({
      composition: (state) => state.composition.current,
      languages: (state) => state.platform.languages,
      defaultLanguage: (state) =>
        state.composition.current.metaData.originLanguageCode,
      requestedAutotranslations: (state) =>
        state.composition.metaData.autoTranslate,
    }),
    defaultTranslation() {
      return this.translations.find((translation) =>
        this.isDefaultTranslation(translation.locale),
      );
    },
    defaultWasChanged() {
      return this.defaultTranslation !== this.selectedDefaultTranslation;
    },
  },
  created() {
    this.translations = this.languages
      .map((language) => ({
        locale: language.isoCode,
        name: this.languageByIso(language.isoCode).name,
      }))
      .sort((x, y) =>
        x.locale === this.defaultLanguage
          ? -1
          : y.locale === this.defaultLanguage
            ? 1
            : 0,
      );
    this.selectedDefaultTranslation = this.defaultTranslation;
  },
  methods: {
    ...mapMutations({
      setDefaultLanguage: 'composition/setDefaultLanguage',
    }),
    isDefaultTranslation(locale) {
      return locale === this.defaultLanguage ? true : false;
    },
    onDefaultChange(newDefaultTranslation) {
      this.selectedDefaultTranslation = newDefaultTranslation;
    },
    cancel() {
      this.modalInstance.dismiss();
    },
    apply() {
      this.setDefaultLanguage({
        language: this.selectedDefaultTranslation.locale,
      });
      this.modalInstance.close();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';

.default-translations-dialog {
  width: 500px;

  .modal-body {
    & > h1 {
      margin: 0;
    }

    & > p {
      margin: 16px 0 0 0;
    }

    & > h2 {
      margin: 0 0 16px 0;
    }
  }
}

.translations {
  list-style: none;
  padding: 0;
  width: 100%;
  margin: 12px 0 -12px;
  table-layout: fixed;

  tr {
    border-bottom: 1px solid $grey20;

    td,
    th {
      padding: 12px 4px;
      text-align: left;

      &:last-child {
        display: flex;
        text-align: right;
        margin-left: 8px;
        justify-content: flex-end;
      }
    }
  }

  tbody {
    tr {
      &:last-child {
        border: none;
      }
    }
  }
}

.actions {
  display: flex;
  margin: 16px 0 0 0;

  button {
    height: 40px;
    flex: 1;

    &:first-child {
      margin-right: 16px;
    }
  }
}
</style>
