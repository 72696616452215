<template>
  <NoStuffPlaceholder
    :display="display"
    heading="POS.SHOP_SETTINGS.STARTPAGE.FOLLOW_US.EMPTY.HEADING"
    sub-heading="POS.SHOP_SETTINGS.STARTPAGE.FOLLOW_US.EMPTY.SUBHEADING"
    cta-icon="shop"
    cta-text="POS.SHOP_SETTINGS.STARTPAGE.FOLLOW_US.EMPTY.CONFIGURE"
    cta-target="shop-area.advanced.header-social-media-profile"
  />
</template>

<script>
import NoStuffPlaceholder from 'src/app/partnerarea/pos/shop/common/NoStuffPlaceholder.vue';
import { StartPageData } from 'src/app/partnerarea/pos/shop/shopSavable/StartPageData';

export default {
  name: 'NoSocialMediaProfilePlaceholder',
  components: {
    NoStuffPlaceholder,
  },
  mixins: [StartPageData],
  computed: {
    display() {
      return !this.startPage.followUsPossible;
    },
  },
};
</script>
