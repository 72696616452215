<template>
  <div class="personal-data-form">
    <div class="personal-form">
      <div
        class="company-container"
        :class="{
          active: data.isCompany,
          'has-error': validation.errors.company,
        }"
      >
        <Checkbox
          v-if="!data.isCompany"
          v-model="data.isCompany"
          class="company"
        >
          <span>{{ $t('ACCOUNT.PERSONAL.IS_COMPANY') }}</span>
        </Checkbox>

        <LabelInput v-if="data.isCompany">
          <Checkbox
            v-model="data.isCompany"
            class="company-checkbox-inside-input"
          >
          </Checkbox>
          <label :class="{ disabled: !data.isCompany }" for="companyName">{{
            $t('ACCOUNT.PERSONAL.COMPANY')
          }}</label>
          <DebounceInput
            id="companyName"
            type="text"
            name="companyName"
            :model-value="data.user.company"
            :disabled="!data.isCompany"
            @update:model-value="updateCompanyName"
          />
          <AccountError
            v-if="validation.errors.company"
            :reason="validation.errors.company.reason"
          ></AccountError>
        </LabelInput>
      </div>

      <div class="full-name">
        <LabelInput :class="{ 'has-error': validation.errors.firstName }">
          <label for="firstName">{{ $t('ACCOUNT.PERSONAL.FIRSTNAME') }}</label>
          <DebounceInput
            id="firstName"
            type="text"
            :model-value="data.user.firstName"
            @update:model-value="updateFirstName"
          />
          <AccountError
            v-if="validation.errors.firstName"
            :reason="validation.errors.firstName.reason"
          ></AccountError>
        </LabelInput>

        <LabelInput :class="{ 'has-error': validation.errors.lastName }">
          <label for="lastName">{{ $t('ACCOUNT.PERSONAL.LASTNAME') }}</label>
          <DebounceInput
            id="lastName"
            type="text"
            :model-value="data.user.lastName"
            @update:model-value="updateLastName"
          />
          <AccountError
            v-if="validation.errors.lastName"
            :reason="validation.errors.lastName.reason"
          ></AccountError>
        </LabelInput>
      </div>

      <LabelInput v-if="!hidePhoneInput">
        <label for="contactPhone">{{ $t('ACCOUNT.CONTACT.PHONE') }}</label>
        <DebounceInput
          id="contactPhone"
          type="text"
          :model-value="data.user.phone"
          @update:model-value="updatePhone"
        />
      </LabelInput>
    </div>
    <div class="pdv">
      <div v-if="currentPdvState === PDV_STATES.FAILED" class="failed-address">
        <PdvFailedMsg :address="lastAddress"></PdvFailedMsg>
      </div>
      <div
        v-if="currentPdvState === PDV_STATES.CODE_INPUT && !manualAddressEdit"
        class="hc-code-input"
      >
        <h3 v-html="$t('PDV.HARDCHECK.HEADING')"></h3>
        <p
          v-if="!showCodeErrorMsg && pdvState.hardcheck.attemptsLeft < 5"
          v-html="
            $t('PDV.ATTEMPTS_LEFT', {
              count: pdvState.hardcheck.attemptsLeft,
            })
          "
        ></p>
        <div v-if="showCodeErrorMsg" class="hc-failed-msg">
          <div class="symbol">!</div>
          <div>
            {{ $t('PDV.HARDCHECK.FAILED') }}
            <span
              class="attempts"
              v-html="
                $t('PDV.ATTEMPTS_LEFT', {
                  count: pdvState.hardcheck.attemptsLeft,
                })
              "
            ></span>
          </div>
        </div>

        <div class="code-input">
          <LabelInput>
            <label
              for="hc-code"
              v-html="$t('PDV.HARDCHECK.INPUT_LABEL')"
            ></label>
            <input id="hc-code" v-model="hcCode" type="text" />
          </LabelInput>
          <ProgressButton
            class="btn btn-primary btn-xl icon-btn"
            :on-click="confirmCode"
            :disabled="lastHcCode === hcCode"
            label="GENERAL.YES"
          />
        </div>
        <PdvExistingAddress
          :data="data"
          :pdv-state="pdvState"
          @edit-address="onEditAddress"
        ></PdvExistingAddress>
      </div>
      <div
        v-if="currentPdvState === PDV_STATES.VALID && !manualAddressEdit"
        class="verified-address"
      >
        <PdvExistingAddress
          :data="data"
          :pdv-state="pdvState"
          @edit-address="onEditAddress"
        ></PdvExistingAddress>
      </div>
      <div
        v-show="
          [PDV_STATES.ADDRESS_INPUT, PDV_STATES.DEACTIVATED].includes(
            currentPdvState,
          ) || manualAddressEdit
        "
        class="address-form"
        :class="{
          'sc-failed': softCheckFailed,
          'pdv-deactivated': currentPdvState === PDV_STATES.DEACTIVATED,
        }"
      >
        <div v-if="!hidePdvValidateMsg" class="validate-msg">
          <p v-html="$t('PDV.VALIDATE_INFO')"></p>
          <p
            v-if="pdvState.softcheck.attemptsLeft < 5"
            class="attempts"
            v-html="
              $t('PDV.ATTEMPTS_LEFT', {
                count: pdvState.softcheck.attemptsLeft,
              })
            "
          ></p>
        </div>
        <div class="sc-failed-msg">
          <div class="symbol">!</div>
          <div>
            {{ $t('PDV.VALIDATION_FAILED') }}
            <span
              v-if="pdvState.softcheck.attemptsLeft < 5"
              class="attempts"
              v-html="
                $t('PDV.ATTEMPTS_LEFT', {
                  count: pdvState.softcheck.attemptsLeft,
                })
              "
            ></span>
            <SmartFaqLink class="sc-failed-help-link" :link="pdvHelpLink" />
          </div>
        </div>
        <div class="country columns">
          <div :class="{ 'has-error': validation.errors.countryId }">
            <LabelInput>
              <label>{{ $t('ACCOUNT.PERSONAL.COUNTRY') }}</label>
              <Dropdown
                :items="data.user.countries"
                :item-key="(country) => country.countrycode"
                :item-to-string="(country) => country.name"
                :value="currentCountry"
                @change="setCountry"
              >
              </Dropdown>
              <AccountError
                v-if="validation.errors.countryId"
                :reason="validation.errors.countryId.reason"
              ></AccountError>
            </LabelInput>
          </div>

          <div
            v-if="states[data.user.countryId]"
            :class="{ 'has-error': validation.errors.stateId }"
          >
            <LabelInput>
              <label>{{ $t('ACCOUNT.PERSONAL.STATE') }}</label>
              <Dropdown
                :items="states[data.user.countryId]"
                :item-key="(state) => state.id"
                :item-to-string="(state) => state.name"
                :value="currentState"
                @change="setState"
              >
              </Dropdown>
              <AccountError
                v-if="validation.errors.stateId"
                :reason="validation.errors.stateId.reason"
              ></AccountError>
            </LabelInput>
          </div>
        </div>
        <LabelInput :class="{ 'has-error': validation.errors.street }">
          <label for="street">{{ $t('ACCOUNT.PERSONAL.STREET') }}</label>
          <DebounceInput
            id="street"
            type="text"
            :model-value="data.user.street"
            placeholder=""
            @update:model-value="updateStreet"
          />
          <AccountError
            v-if="validation.errors.street"
            :reason="validation.errors.street.reason"
          ></AccountError>
        </LabelInput>
        <LabelInput
          :class="{ 'has-error': validation.errors.street2 }"
          :model="data.user.street2"
        >
          <label for="street2">{{ $t('ACCOUNT.PERSONAL.STREET2') }}</label>
          <DebounceInput
            id="street2"
            ref="street2"
            type="text"
            :model-value="data.user.street2"
            @update:model-value="updateStreet2"
          />
          <AccountError
            v-if="validation.errors.street2"
            :reason="validation.errors.street2.reason"
          ></AccountError>
        </LabelInput>

        <div class="state">
          <LabelInput
            class="postal-code"
            :class="{ 'has-error': validation.errors.postalCode }"
            :model="data.user.postalCode"
          >
            <label for="zipCode">{{
              $t('ACCOUNT.PERSONAL.POSTAL_CODE')
            }}</label>
            <DebounceInput
              id="zipCode"
              ref="zipCode"
              type="text"
              :model-value="data.user.postalCode"
              @update:model-value="updatePostalCode"
            />
            <AccountError
              v-if="validation.errors.postalCode"
              :reason="validation.errors.postalCode.reason"
            ></AccountError>
          </LabelInput>
          <LabelInput
            class="city"
            :class="{ 'has-error': validation.errors.city }"
            :model="data.user.city"
          >
            <label for="city">{{ $t('ACCOUNT.PERSONAL.CITY') }}</label>
            <DebounceInput
              id="city"
              ref="city"
              type="text"
              :model-value="data.user.city"
              @update:model-value="updateCity"
            />
            <AccountError
              v-if="validation.errors.city"
              :reason="validation.errors.city.reason"
            ></AccountError>
          </LabelInput>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import pdvService from '@/partnerDataValidation/service/pdvService';

import DebounceInput from 'src/app/components/input/DebounceInput.vue';
import pdvAutoComplete from '@/partnerDataValidation/autoComplete/pdvAutoComplete';
import LabelInput from '@/labelInput/LabelInput.vue';
import AccountError from '../errorMessage/AccountError.vue';
import Dropdown from '@/Dropdown/Dropdown.vue';
import Checkbox from '@/checkbox/Checkbox.vue';
import PdvFailedMsg from '@/partnerDataValidation/failedMsg/PdvFailedMsg.vue';
import PdvExistingAddress from '@/partnerDataValidation/existingAddress/PdvExistingAddress.vue';
import ProgressButton from '@/btnProgress/ProgressButton.vue';
import SmartFaqLink from '@/helpLink/SmartFaqLink.vue';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';
import dialogService from '@/dialogs/wrapper/dialogService';
import PdvFailedDialog from '@/partnerDataValidation/failedDialog/PdvFailedDialog.vue';

export default {
  name: 'PersonalDataForm',
  components: {
    LabelInput,
    AccountError,
    Dropdown,
    Checkbox,
    PdvFailedMsg,
    ProgressButton,
    PdvExistingAddress,
    SmartFaqLink,
    DebounceInput,
  },
  props: {
    updatePdvState: {
      type: Function,
    },
    hidePdvValidateMsg: {
      type: Boolean,
    },
    hidePhoneInput: {
      type: Boolean,
    },
    skipPdvFailedDialog: {
      type: Boolean,
    },
  },
  emits: ['onEditAddress'],
  data() {
    return {
      currentCountry: null,
      currentState: null,
      PDV_STATES: pdvService.STATES,
      showCodeErrorMsg: false,
      hcCode: '',
      lastHcCode: '',
      manualAddressEdit: false,
      pdvHelpLink: smartFaqLinks.PDV_INFO,
      autoCompleteUpdater: null,
    };
  },
  watch: {
    pdvState: function (newState, oldState) {
      if (
        newState &&
        [
          pdvService.STATES.VALID,
          pdvService.STATES.FAILED,
          pdvService.STATES.CODE_INPUT,
        ].includes(this.currentPdvState)
      ) {
        this.manualAddressEdit = false;
      }

      if (
        pdvService.getCurrentState(oldState) !==
          pdvService.getCurrentState(newState) &&
        this.currentPdvState === pdvService.STATES.FAILED
      ) {
        if (!this.skipPdvFailedDialog) {
          dialogService.openDialog(PdvFailedDialog, {
            lastAddress: this.lastAddress,
          });
        }
      }
    },
  },
  computed: {
    ...mapState({
      data: (state) => state.account.current,
      validation: (state) => state.account.validation,
      pdvState: (state) => state.account.pdvState,
      states: (state) => state.platform.shippingStates || [],
    }),
    currentPdvState() {
      return pdvService.getCurrentState(this.pdvState);
    },
    softCheckFailed() {
      return pdvService.softCheckFailed(this.pdvState);
    },
    lastAddress() {
      return {
        street: this.data.user.street,
        postalCode: this.data.user.postalCode,
        city: this.data.user.city,
        country: this.data.user.countries.find(
          (county) => county.countrycode === this.data.user.countryId,
        ).name,
        countryCode: this.data.user.countryId,
      };
    },
  },
  created() {
    this.currentCountry = this.data.user.countries.find(
      (c) => c.countrycode === this.data.user.countryId,
    );
    this.currentState = this.states[this.data.user.countryId]
      ? this.states[this.data.user.countryId].find(
          (state) => state.id === this.data.user.stateId,
        )
      : null;
  },
  mounted() {
    pdvAutoComplete
      .init({
        elementId: 'street',
        countryId: this.data.user.countryId,
        onPlaceChange: (placeData) => {
          const placeCountry = this.data.user.countries.find(
            (country) => country.countrycode === placeData.country,
          );
          if (!this.currentCountry && placeCountry) {
            this.setCountry(placeCountry);
          }
          this.updateStreet(placeData.name);
          document.getElementById('street').value = placeData.name;

          this.updateStreet2(placeData.street2);
          this.updatePostalCode(placeData.postalCode);
          this.updateCity(placeData.city);
        },
      })
      .then((updater) => {
        this.autoCompleteUpdater = updater;
      });
  },
  methods: {
    ...mapMutations({
      updateCompanyName: 'account/setCompanyName',
      updateLastName: 'account/setLastName',
      updateFirstName: 'account/setFirstName',
      updatePhone: 'account/setPhone',
      updateCountry: 'account/setCountry',
      updateState: 'account/setState',
      updateStreet: 'account/setStreet',
      updateStreet2: 'account/setStreet2',
      updatePostalCode: 'account/setPostalCode',
      updateCity: 'account/setCity',
    }),
    setCountry(country) {
      this.currentCountry = country;
      this.updateCountry(country.countrycode);
      this.setState(null);
      if (this.autoCompleteUpdater) {
        this.autoCompleteUpdater.changeCountry(country.countrycode);
      }
    },
    setState(state) {
      this.currentState = state;
      this.updateState(state ? state.id : null);
    },
    confirmCode() {
      this.lastHcCode = this.hcCode;

      return pdvService.validateCode(this.hcCode).then(
        () => {
          this.updatePdvState();
        },
        () => {
          this.updatePdvState().then(() => {
            this.showCodeErrorMsg = true;
          });
        },
      );
    },
    onEditAddress() {
      this.manualAddressEdit = true;
      this.$emit('onEditAddress');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/_type.scss';

.labelInput,
.radiobutton-group,
.company-container {
  margin-bottom: 16px;
}

.code-input {
  .labelInput {
    margin-bottom: 0;
    flex-grow: 1;
    margin-right: 10px;
  }
}

.company-container {
  .company {
    padding-top: 12px;
    display: flex;
    align-items: center;

    & > label {
      margin-left: 8px;
    }
  }

  .label-input-wrapper {
    position: relative;
    display: flex;

    .company-checkbox-inside-input {
      position: absolute;
      left: 10px !important;
      top: 10px !important;
      padding: 0;
    }

    & > label {
      left: 40px;
    }

    & > input {
      padding-left: 44px;
    }
  }
}

.has-error .radio-button {
  border-color: $pa-color-red;
}

.personal-data-form {
  margin-bottom: -16px;
}

.personal-data-form,
.full-name {
  display: flex;
  flex-wrap: wrap;
  margin-right: -24px;

  & > div {
    min-width: 350px;
    flex: 1;
    margin-right: 24px;
  }
}

.full-name {
  & > div {
    min-width: 200px;
  }
}

.state {
  display: flex;

  .postal-code {
    width: 120px;
    margin-right: 10px;
  }

  .city {
    flex-grow: 1;
  }
}

.pdv {
  display: flex;
  flex-direction: column;
}

.sc-failed-msg,
.hc-failed-msg {
  display: flex;
  background-color: $pa-color-red;
  color: #fff;
  align-items: center;
  padding: 10px;
  margin-bottom: 15px;

  .symbol {
    font-size: 2em;
    padding: 0 20px 0 10px;
  }

  .attempts {
    font-weight: bold;
  }

  .sc-failed-help-link {
    color: white;
    font-weight: bold;
    margin-top: 4px;

    .icon {
      margin-bottom: 1px;
    }
  }
}

.address-form {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .validate-msg {
    margin-bottom: 15px;
    text-align: right;

    p {
      margin: 0;
      display: inline-block;

      @extend .text-sm;
    }

    .attempts {
      color: $pa-color-main;
    }
  }

  .sc-failed-msg {
    display: none;
  }

  &.sc-failed {
    .sc-failed-msg {
      display: flex;
    }

    .validate-msg {
      display: none;
    }
  }

  &.pdv-deactivated {
    .validate-msg {
      display: none;
    }
  }
}

.hc-code-input {
  padding-bottom: 20px;

  .code-input {
    display: flex;
    margin: 10px 0;
  }

  & > h3 {
    text-align: center;
    margin: 0 0 5px 0;
  }

  & > p {
    text-align: center;
    margin: 0;
    color: $sprd-color-medium-grey;
  }
}

.verified-address {
  margin-bottom: 16px;
}

.failed-address {
  .pdv-failed-msg {
    background-color: $sprd-color-lightest-grey;
    padding: 15px;
    margin-bottom: 15px;

    h1 {
      @extend .heading3;
    }

    .main-icon {
      width: 30px;
      height: 30px;
    }

    .icon-wrapper {
      padding: 10px;

      .icon {
        width: 20px !important;
        height: 20px !important;
      }
    }

    .icon-outer-wrapper {
      top: -20px;
    }

    .phone-contact .icon-wrapper {
      border: 1px solid $sprd-color-medium-grey;
    }

    .or {
      margin: 10px 0;
    }
  }
}
</style>
