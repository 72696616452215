<template>
  <div class="checklist" :class="{ compact }">
    <TransitionGroup v-if="initialized" name="list" tag="ul">
      <li
        v-for="step in sortedSteps"
        :key="step.key"
        :class="{
          done: step.done,
          next: step === nextStep && (!animateStep || animationFinished),
          animate: step.key === animateStep && !animationFinished,
        }"
        @click="onStepClicked(step)"
      >
        <div class="circle">
          <div class="checkmark">
            <Icon icon="checkmark" />
          </div>
        </div>
        <p>{{ $t(step.name) }}</p>
        <Icon v-if="!step.done" icon="right" />
      </li>
    </TransitionGroup>
    <div v-else class="loader">
      <img src="/images/loader.svg" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'OnboardingChecklist',
  components: {},
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
    animateStep: {
      type: String,
    },
  },
  data() {
    return {
      animationFinished: false,
    };
  },
  created() {
    if (this.animateStep) {
      setTimeout(() => {
        this.animationFinished = true;
      }, 1300);
    } else {
      this.animationFinished = true;
    }
  },
  methods: {
    onStepClicked(step) {
      if (step.done) {
        return;
      }

      step.onClick();
    },
  },
  computed: {
    ...mapState({
      initialized: (s) => s.onboarding.initialized,
    }),
    ...mapGetters({
      steps: 'onboarding/relevantSteps',
    }),
    sortedSteps() {
      const animationInProgress = this.animateStep && !this.animationFinished;

      return [...this.steps].sort((a, b) => {
        const aDone =
          animationInProgress && a.key === this.animateStep ? false : a.done;
        const bDone =
          animationInProgress && b.key === this.animateStep ? false : b.done;
        return Number(bDone) - Number(aDone);
      });
    },
    nextStep() {
      return this.steps.find((step) => !step.done);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/mixins';
@import 'src/scss/styleguide/type';
@import 'src/scss/animations';

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.checklist {
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      border: 1px solid $pa-color-main;
      border-radius: $border-radius-medium;
      padding: 12px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: transform 0.2s ease;

      &:hover {
        border-color: $pa-color-main-dark;
        transform: scale(1.02);
      }

      p {
        margin: 0;
        font-weight: 700;
      }

      & > .icon {
        margin-left: auto;
        color: $pa-color-main;
      }

      .circle {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        border: 1px solid $pa-color-main;
        margin-right: 8px;
        background-color: $white;
        position: relative;

        .checkmark {
          position: absolute;
          top: -2px;
          left: -2px;
          width: 32px;
          height: 32px;
          border-radius: 100%;
          border: 1px solid $pa-color-main;
          align-items: center;
          justify-content: center;
          background-color: $pa-color-main;
          display: none;

          .icon {
            width: 18px;
            height: 18px;
            color: $white;
          }
        }
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &.done {
        border-color: $grey20;
        cursor: initial;

        &:hover {
          border-color: $grey20;
          transform: none;
        }

        p {
          font-weight: normal;
        }

        .circle {
          .icon {
            display: block;
          }
        }

        .checkmark {
          display: flex;
        }

        &.animate .circle .checkmark {
          animation: fadein 0.5s linear both;
          animation-delay: 0.7s;
        }
      }
    }
  }

  &.compact {
    li {
      border: none;
      padding: 8px;
    }
  }
}
</style>
