<template>
  <div class="tips-and-tools-card-view">
    <a
      v-for="article in articles"
      :key="article.key"
      v-trackClickEvent="{
        name: 'tips-tools-click',
        details: {
          article: article.tracking,
          mp: showMarketPlaceLogo(article.pointOfSales),
          shop: showShopLogo(article.pointOfSales),
        },
      }"
      class="tips-and-tools-card"
      href="#"
      @click.prevent="article.onClick"
    >
      <div class="tips-and-tools-card-image-container">
        <img
          class="tips-and-tools-card-image"
          :src="article.imageSrc"
          :alt="article.headline"
        />
        <div class="tips-and-tools-card-image-overlay">
          <div class="tips-and-tools-card-read-button">
            <span>{{ $t('TIPS_AND_TOOLS.READ_ARTICLE') }}</span>
          </div>
        </div>
      </div>
      <div class="tips-and-tools-card-body">
        <div class="tips-and-tools-card-pos-logo">
          <MarketplaceLogo v-if="showMarketPlaceLogo(article.pointOfSales)" />
          <SpreadshopLogo v-if="showShopLogo(article.pointOfSales)" />
        </div>
        <h3>{{ article.headline }}</h3>
        <p class="text-sm">{{ article.description }}</p>
      </div>
    </a>
    <!-- add two invisible dummy elements to ensure a proper flex-grow for the items in the last row -->
    <a
      v-for="x in 2"
      :key="x"
      class="tips-and-tools-card-dummy tips-and-tools-card"
    />
  </div>
</template>
<script>
import MarketplaceLogo from 'src/app/commons/logos/MarketplaceLogo.vue';
import SpreadshopLogo from 'src/app/commons/logos/SpreadshopLogo.vue';
import {
  articleKeys,
  pointOfSaleKeys,
} from 'src/app/partnerarea/tipsAndTools/tipsAndToolsConfig';

export default {
  name: 'TipsAndToolsCards',
  components: {
    SpreadshopLogo,
    MarketplaceLogo,
  },
  props: {
    articles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      articleKeys: articleKeys,
    };
  },
  methods: {
    showMarketPlaceLogo(pointOfSales) {
      return pointOfSales.includes(pointOfSaleKeys.MP);
    },
    showShopLogo(pointOfSales) {
      return pointOfSales.includes(pointOfSaleKeys.SHOPS);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/mixins/media';
@import 'src/scss/styleguide/type';

.tips-and-tools-card-view {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
}

.tips-and-tools-card-dummy {
  min-height: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  visibility: hidden;
}

.tips-and-tools-card {
  min-width: 340px;
  min-height: 365px;
  box-sizing: border-box;
  background: #ffffff 0 0 no-repeat padding-box;
  border-radius: $border-radius-medium;
  opacity: 1;
  overflow-y: hidden;
  margin: 15px;

  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 340px;

  &:hover {
    .tips-and-tools-card-image-overlay {
      opacity: 1;
    }
  }

  @include small-desktop {
    min-width: 300px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 300px;
  }
}

.tips-and-tools-card-image-container {
  position: relative;
  display: flex;
}

.tips-and-tools-card-image {
  width: 100%;
  height: 100%;
}

.tips-and-tools-card-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparentize(white, 0.25);
  opacity: 0;
  transition: opacity 0.1s linear;
}

.tips-and-tools-card-read-button {
  min-width: 144px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: $pa-color-main;
  border-radius: $border-radius-medium;

  span {
    font-weight: bold;
    font-size: 14px;
  }
}

.tips-and-tools-card-body {
  padding: 0 16px;
  color: $sprd-color-grey;

  h3 {
    color: $grey80;
    margin-bottom: 8px;
  }
}

.tips-and-tools-card-pos-logo {
  margin-top: 5px;
  height: 12px;

  img {
    height: 100%;
    padding-right: 10px;
  }
}
</style>
