<template>
  <AccountPageActions
    :save="save"
    :disabled="!hasDataChanged"
    @on-revert="revert"
  />
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import unsavedChangesService from '@/unsavedChangesService/unsavedChangesService';
import AccountPageActions from './AccountPageActions.vue';

export default {
  name: 'AccountPageActionsAVS',
  components: {
    AccountPageActions,
  },
  computed: {
    ...mapGetters({
      hasDataChanged: 'account/hasDataChanged',
    }),
  },
  created() {
    unsavedChangesService.registerComponent({
      check: () => this.hasDataChanged,
      save: this.save,
      discard: this.revert,
    });
  },
  methods: {
    ...mapMutations({
      updateUserData: 'user/updateUserData',
    }),
    ...mapActions({
      save: 'account/save',
    }),
    revert() {
      this.$store.commit('account/revertChanges');
    },
  },
};
</script>
