<template>
  <div class="dialog-size-md account-delete-dialog">
    <div class="modal-header alert-danger">
      <h1 class="text-danger">
        {{ $t('GENERAL.WARNING') }}
      </h1>
      <a href class="modal-close-button" @click.prevent="modalInstance.dismiss">
        <Icon icon="close" />
      </a>
    </div>
    <div class="modal-body">
      <div class="account-settings-popover-delete-confirmation">
        <div class="form-group" :class="{ 'has-error has-feedback': error }">
          <p>{{ $t('ACCOUNT.DELETION_CONFIRMATION_TEXT') }}</p>
          <div class="account-popover-actions">
            <div>
              <input
                v-model="password"
                type="password"
                :placeholder="$t('LOGIN.PLACEHOLDER.PASSWORD')"
                required
                class="input"
              />
              <small v-if="error" class="error-info">{{
                $t('LOGIN.PASSWORD_MISSING')
              }}</small>
            </div>
            <DoubleConfirmButton
              class="btn btn-danger"
              @on-confirm="deleteAccount"
            >
              <template #default="{ confirm }">
                <span v-if="!confirm">{{ $t('ACCOUNT.ACCOUNT.DELETE') }}</span>
                <span v-else>{{
                  $t('ACCOUNT.DELETION_CONFIRMATION_ACTION')
                }}</span>
              </template>
            </DoubleConfirmButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import accountService from 'src/app/commons/api/accountService/accountService';
import DoubleConfirmButton from 'src/app/commons/doubleConfirmButton/DoubleConfirmButton.vue';

export default {
  name: 'AccountDeleteDialog',
  components: {
    DoubleConfirmButton,
  },
  props: ['modalInstance'],
  data() {
    return {
      error: false,
      password: null,
    };
  },
  methods: {
    async deleteAccount() {
      if (this.password && this.password.length) {
        try {
          await accountService.deleteAccount(this.password);
        } catch (_) {
          this.error = true;
        }
      } else {
        this.error = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
.btn {
  height: 40px;
}
.account-delete-dialog {
  .form-group {
    margin: 0;
  }
  .account-popover-actions {
    display: flex;
    & > div {
      text-align: left;
      flex-grow: 1;
      margin-right: 5px;
    }

    button {
      min-width: 150px;
    }
  }
}
.account-settings-popover-delete-confirmation {
  padding: 0 14px 9px 14px;
}

.has-error {
  .form-control-feedback {
    color: $pa-color-red;
    border-color: $pa-color-red;
  }
  .form-control,
  .dropdown-button {
    border-color: $pa-color-red !important;
  }

  .error-info {
    display: inline-block;
    color: $pa-color-red;
    font-weight: bold;
    padding-left: 12px;
  }
}

h1,
p {
  margin: 0 0 24px 0;
}
</style>
