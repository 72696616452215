<template>
  <div class="dialog-size-md shop-selection-dialog">
    <div class="modal-body">
      <h2>{{ $t('POS.SHOP_SELECTION.HEADING') }}</h2>
      <ul>
        <li v-for="shop in shops" :key="shop.id">
          <h3>{{ shop.shopName }}</h3>
          <p class="text-sm text-grey">
            {{ $t('SA.shopDashboard.shopId.label') }}: {{ shop.id }}
          </p>
          <div>
            <button class="btn btn-light" @click="goToShopSettings(shop)">
              {{ $t('POS.SHOP_SELECTION.CTA') }}
            </button>
            <p class="active-state" :class="{ offline: shop.hidden }">
              {{ $t(shop.hidden ? 'GENERAL.INACTIVE' : 'GENERAL.ACTIVE') }}
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ShopChoiceDialog',
  props: {
    modalInstance: { type: Object, required: true },
    data: { type: Object, required: true },
  },
  computed: {
    ...mapState({
      shops: (s) => s.user.shops,
    }),
  },
  methods: {
    goToShopSettings(shop) {
      this.modalInstance.close(shop.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';

.shop-selection-dialog {
  h2 {
    margin: 0 0 24px 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  li {
    padding: 16px;
    border-radius: $border-radius-medium;
    border: 1px solid $grey20;
    flex: 1;
    min-width: 300px;
    max-width: calc(50% - 12px);

    h3 {
      margin: 0 0 8px 0;
    }

    & > div {
      display: flex;
      align-items: center;
    }

    .active-state {
      color: $pa-color-green;
      margin: 0 0 0 16px;

      &.offline {
        color: $pa-color-red;
      }
    }
  }
}
</style>
