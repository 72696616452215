<template>
  <slot v-if="disableEffect"></slot>
  <div
    v-else
    class="ai-container"
    :class="styleOnHover"
    :style="{ borderRadius: `${borderRadius}px` }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AiContainer',
  props: {
    borderRadius: {
      type: Number,
      default: 8,
    },
    scaleOnHover: {
      type: Boolean,
      default: false,
    },
    disableEffect: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styleOnHover() {
      return this.scaleOnHover ? 'scale' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.ai-container {
  position: relative;
  display: inline-flex;
  z-index: 0;
}

.ai-container:before {
  content: '';
  border-radius: inherit;
  background: linear-gradient(
    45deg,
    #2a6ba5,
    #3685cc,
    #b0cfeb,
    #00b2a5,
    #d9e8f5,
    #ff8256,
    #b0cfeb,
    #3685cc,
    #2a6ba5
  );
  position: absolute;
  top: -1px;
  left: -1px;
  background-size: 300%;
  z-index: -1;
  filter: blur(3px);
  width: calc(100% + 3px);
  height: calc(100% + 3px);
  animation: glowing 30s linear infinite;
  transition: opactiy 1s ease-in-out;
}

.scale:hover {
  transform: scale(1.02);
  transition: transform 0.1s linear;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
</style>
