<template>
  <div>
    <ToggleSwitch
      v-model="isNonProfit"
      class="sprd-toggle-switch--first"
      :disabled="isNonProfitToggleDisabled"
    >
      <div class="non-profit-label">
        <h4>{{ $t('POS.SHOP_SETTINGS.PRICING.NON_PROFIT_LABEL') }}</h4>
        <p class="text-grey">
          {{ $t('POS.SHOP_SETTINGS.PRICING.NON_PROFIT_TEXT') }}
        </p>
      </div>
    </ToggleSwitch>
  </div>
</template>

<script>
import { PricingData } from 'src/app/partnerarea/pos/shop/shopSavable/PricingData';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';

export default {
  name: 'NonProfitNav',
  components: {
    ToggleSwitch,
  },
  mixins: [PricingData],
  computed: {
    config() {
      return this.$store.state.shop.shopSavable.clientData.config;
    },
    isNonProfit: {
      get() {
        return this.config.shopCommission === 'OFF';
      },
      set(isNonProfit) {
        this.config.shopCommission = isNonProfit ? 'OFF' : 'DEFAULT';
      },
    },
    isNonProfitToggleDisabled() {
      return this.config.shopCommission === 'CUSTOM';
    },
  },
};
</script>

<style lang="scss" scoped>
.non-profit-label {
  margin-right: 1.5em;
  margin-top: 21px;

  p {
    margin: 0;
  }

  h4 {
    margin: 0 0 10px;
  }

  a {
    margin-left: 4px;
  }
}
</style>
