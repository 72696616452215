<template>
  <div v-if="mainCategories.length" class="product-settings-categories">
    <SelectionTiles
      v-slot="{ item }: { item: PublishingCategoryConfig }"
      :items="mainCategories"
      :item-key="(category) => category.id"
      :item-selected="
        (category) =>
          props.selectedCategories.some(
            (selectedCategory) => selectedCategory.id === category.id,
          )
      "
      :item-to-string="(category) => category.name"
      :min-item-width="200"
      multi-selection
      @change="selectCategory"
    >
      <img :src="item.previewImage" />
    </SelectionTiles>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { PublishingCategoryConfig } from '@/composition/compositionCategoryHelper';
import { useStore } from 'vuex';
import SelectionTiles from 'src/app/components/selectionTiles/SelectionTiles.vue';

const store = useStore();
const mainCategories = computed(
  () =>
    Object.values(
      store.state.publishingCore.mainCategories,
    ) as PublishingCategoryConfig[],
);

const emit = defineEmits(['onSelect']);

interface Props {
  composition: any;
  selectedCategories: PublishingCategoryConfig[];
}
const props = defineProps<Props>();

const selectCategory = (category: PublishingCategoryConfig) => {
  emit('onSelect', category);
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';

.product-settings-categories {
  img {
    height: 70px;
  }
}
</style>
