<template>
  <div v-if="display" class="onboarding">
    <div class="checklist-wrapper">
      <h3>{{ $t('ONBOARD.CHECKLIST.HEADING') }}</h3>
      <p>{{ $t('ONBOARD.CHECKLIST.SUBHEADING') }}</p>
      <OnboardingChecklist />
    </div>
    <div class="help">
      <h3>{{ $t('ONBOARD.CHECKLIST.HELP_LINKS.HEADING') }}</h3>
      <p>{{ $t('ONBOARD.CHECKLIST.HELP_LINKS.SUBHEADING') }}</p>
      <OnboardingHelpCards light />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import OnboardingChecklist from 'src/app/components/onboarding/OnboardingChecklist.vue';
import OnboardingHelpCards from 'src/app/components/onboarding/OnboardingHelpCards.vue';

export default {
  name: 'OnboardingModule',
  components: {
    OnboardingChecklist,
    OnboardingHelpCards,
  },
  computed: {
    ...mapState({
      initialized: (s) => s.onboarding.initialized,
    }),
    ...mapGetters({
      unfinishedSteps: 'onboarding/unfinishedRelevantSteps',
    }),
    display() {
      return (
        this.initialized &&
        this.unfinishedSteps.steps &&
        this.unfinishedSteps.steps.length
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';
@import 'src/scss/styleguide/mixins';
@import 'src/scss/styleguide/type';

.onboarding {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding: 24px;
  background-color: $grey0;
  border-radius: $border-radius-medium;
  margin-bottom: 24px;

  h3 {
    margin: 0 0 8px 0;
  }

  p {
    display: block;
    color: $grey80;
    margin: 0 0 16px 0;
  }
}

.checklist-wrapper {
  flex: 1;
  flex-basis: 400px;
}

.help {
  background-color: $grey5;
  border-radius: $border-radius-medium;
  flex: 1;
  min-width: 400px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin-bottom: 24px;
  }
}
</style>
