import apiClient from '@/api/client/apiClient';
import localeService from '@/localeService/localeService';

function brightnessByColor(c) {
  let color = '' + c;
  let r, g, b;
  if (color.indexOf('rgb') === 0) {
    const m = color.match(/(\d+){3}/g);
    if (m) {
      r = m[0];
      g = m[1];
      b = m[2];
    }
  } else {
    if (color.indexOf('#') !== 0) {
      color = `#${color}`;
    }
    const m = color
      .substr(1)
      .match(color.length === 7 ? /(\S{2})/g : /(\S{1})/g);
    if (m) {
      r = parseInt(m[0], 16);
      g = parseInt(m[1], 16);
      b = parseInt(m[2], 16);
    }
  }

  if (typeof r !== 'undefined') {
    return (r * 299 + g * 587 + b * 114) / 1000;
  }
}

export default {
  isPosterAssortmentGroup: function (assortmentGroup) {
    return (
      assortmentGroup.hints &&
      assortmentGroup.hints.includes('ONLY_FOR_POSTERS')
    );
  },
  getVariants: function (assortment, assortmentGroup) {
    if (assortmentGroup.hints && assortmentGroup.hints.includes('VARIANT')) {
      return [];
    }

    return this.getSelectedSellables(assortment).filter(
      (group) =>
        group.primarySellable.productType ===
          assortmentGroup.primarySellable.productType &&
        group.hints &&
        group.hints.includes('VARIANT'),
    );
  },
  isBrightColor: function (c) {
    return brightnessByColor(c) > 210;
  },
  getSelectedSellables(filter) {
    return this.getTotalAvailableSellables(filter).filter((f) => f.included);
  },
  getSubGroupSummary(filter) {
    const result = [];
    Object.values(filter.subFilters)
      .filter((group) => group.available)
      .forEach((group) => {
        result.push({
          name: group.name,
          count: this.getSelectedSellables(group).length,
        });
      });
    return result;
  },
  getTotalSellables(filter) {
    if (filter.subFilters) {
      return Object.keys(filter.subFilters).reduce(
        (result, current) =>
          result.concat(this.getTotalSellables(filter.subFilters[current])),
        [],
      );
    } else {
      return [filter];
    }
  },
  getTotalAvailableSellables(filter) {
    return this.getTotalSellables(filter).filter((f) => f.available);
  },
  getTotalUnavailableSellables(filter) {
    return this.getTotalSellables(filter).filter((f) => !f.available);
  },
  async fetchMetaColors() {
    const { data } = await apiClient.request({
      method: 'GET',
      url: `/api/v1/assortment/colors`,
      params: {
        mediaType: 'json',
        locale: localeService.convertToSprdLocale(localeService.getLocale()),
      },
    });
    return data;
  },
  makeSelectedStatesConsistent(filter) {
    if (filter.subFilters) {
      const includesSelectedChild = Object.keys(filter.subFilters).reduce(
        (result, current) => {
          const r = this.makeSelectedStatesConsistent(
            filter.subFilters[current],
          );
          if (r) {
            result = r;
          }
          return result;
        },
        false,
      );
      if (includesSelectedChild) {
        filter.included = true;
      }
    }
    return filter.included;
  },
  getTranslationKeyForAssortmentHints(hints, flags, isAvailable) {
    if (isAvailable || !hints || !hints.length) {
      return null;
    }

    if (
      flags.includes('SAFE18') &&
      hints.includes('ADULT_CONTENT_NOT_ALLOWED')
    ) {
      return 'DESIGNS.DETAILS.PRODUCT_ASSORTMENT_18_PLUS_PARTLY';
    }

    if (!flags.includes('POSTERS') && hints.includes('ONLY_FOR_POSTERS')) {
      return 'DESIGNS.CONFIGURATION.POSTER_GROUP_NOT_ALLOWED';
    }

    if (flags.includes('POSTERS') && hints.includes('ONLY_FOR_POSTERS')) {
      return 'DESIGNS.CONFIGURATION.POSTER_SUBGROUP_NOT_ALLOWED';
    }

    const hintMapping = {
      EMBROIDERY: 'DESIGNS.CONFIGURATION.EMBROIDERY_ONLY',
      ASSORTMENT_IS_EMPTY: 'DESIGNS.CONFIGURATION.NOT_AVAILABLE',
      CONTRAST_ISSUE: 'DESIGNS.CONFIGURATION.TOO_LOW_CONTRAST',
      NO_PRINTABLE_FOUND: 'DESIGNS.CONFIGURATION.NO_PRINTABLE_FOUND',
      DEFAULT: 'DESIGNS.CONFIGURATION.NOT_AVAILABLE',
    };

    const mappedHintKey = Object.keys(hintMapping).find((key) =>
      hints.includes(key),
    );

    return mappedHintKey ? hintMapping[mappedHintKey] : hintMapping.DEFAULT;
  },
};
