<template>
  <div>
    <div class="entry sp-subheader">
      {{ $t('POS.SHOP_SETTINGS.STARTPAGE.TRUST_ELEMENTS.SUBHEADING') }}
    </div>
    <ToggleSwitch
      v-model="startPage.trustElementsEnabled"
      label="POS.SHOP_SETTINGS.STARTPAGE.TRUST_ELEMENTS.ENABLE_TRUST_ELEMENTS"
    />
  </div>
</template>

<script>
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import { StartPageData } from 'src/app/partnerarea/pos/shop/shopSavable/StartPageData';

export default {
  name: 'TrustElements',
  components: { ToggleSwitch },
  mixins: [StartPageData],
  created() {
    this.$store.commit('shop/setLivePreview', {
      path: '',
      section: 'sprd-startpage-trust-elements',
    });
  },
};
</script>

<style lang="scss" scoped>
@import '../startPageCommon.scss';
</style>
