<template>
  <Indicator
    :label="label"
    :hint-heading="label"
    :hint-body="body"
    :more-info-link="moreInfoLink"
  />
</template>

<script>
import Indicator from 'src/app/components/indicator/Indicator.vue';
import statisticsService from '@/api/statisticsService/statisticsService';
import smartFaqLinks from '@/store/smartFaq/smartFaqLinks';

export default {
  name: 'CustomizedIndicator',
  components: {
    Indicator,
  },
  props: {
    pos: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      label: 'STATISTICS.SALE_DETAILS.CUSTOMIZING.LABEL',
      body: 'STATISTICS.SALE_DETAILS.CUSTOMIZING.INFO',
      printAreasHelpLink: smartFaqLinks.PRINT_AREAS_PRICING_SHOP,
      customizedPricing: smartFaqLinks.CUSTOMIZED_PRICING,
      pointOfSales: statisticsService.getPointOfSales(),
    };
  },
  computed: {
    moreInfoLink() {
      switch (this.pos) {
        case this.pointOfSales.OWN_SHOP:
          return this.printAreasHelpLink;
        default:
          return this.customizedPricing;
      }
    },
  },
};
</script>
