<template>
  <div class="onboarding-help-cards" :class="{ light }">
    <a href="#" class="link-main" @click.prevent="openContact">
      <img src="/images/customer-service.svg" alt="contact" />
      <p>{{ $t('GENERAL.HELP') }}</p>
    </a>
    <router-link to="/tipsAndTools" class="link-main">
      <img src="/images/faq.svg" alt="tips and tools" />
      <p>{{ $t('NAV.TIPS_AND_TOOLS') }}</p>
    </router-link>
  </div>
</template>

<script>
import HelpDialog from 'src/app/components/helpDialog/HelpDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';

export default {
  name: 'OnboardingHelpCards',
  props: {
    light: {
      type: Boolean,
    },
  },
  methods: {
    goToTipsAndTools() {
      this.$router.push({ name: 'partnerarea.tipsAndTools' });
    },
    openContact() {
      dialogService.openDialog(HelpDialog);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/constants';

.onboarding-help-cards {
  display: flex;
  gap: 24px;
  width: 100%;

  a {
    background-color: $grey5;
    border-radius: $border-radius-medium;
    padding: 16px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 0px;
    width: 0;

    img {
      max-height: 120px;
      margin-bottom: 16px;
      transition: transform 0.2s ease;
    }

    p {
      margin: auto 0 0 0;
      text-align: center;
      font-weight: normal;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  &.light a {
    background-color: $white;
  }
}
</style>
