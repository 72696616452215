<template>
  <div class="page-wrapper overflow-y">
    <div v-if="initialized" class="admin">
      <h1>Partnerarea Admin</h1>

      <ul>
        <li v-for="setting in relevantSettings" :key="setting.key">
          <p>{{ setting.name }}</p>

          <ToggleSwitch
            v-if="!setting.options"
            :model-value="getSetting(setting.key)"
            @update:model-value="
              (value) => setSetting({ key: setting.key, value })
            "
          />
          <Dropdown
            v-if="setting.options"
            :items="setting.options"
            :value="getCurrentSettingOption(setting)"
            :item-key="(option) => option.key"
            :item-to-string="(option) => option.name"
            @change="({ value }) => setSetting({ key: setting.key, value })"
          ></Dropdown>
        </li>
      </ul>
    </div>
    <LoadingHeart v-else />
  </div>
</template>

<script>
import LoadingHeart from 'src/app/commons/LoadingHeart/LoadingHeart.vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import Dropdown from '@/Dropdown/Dropdown.vue';
import accountService from '@/api/accountService/accountService';

const settings = [
  {
    key: 'DIGITAL_PRODUCTS',
    name: 'Digital Products',
    platform: ['NA'],
  },
  {
    key: 'PA_BETA_OPTIN',
    name: 'Beta Opt In',
    platform: ['NA', 'EU'],
  },
  {
    key: 'AI_ENABLED',
    name: 'AI Enabled',
    platform: ['NA', 'EU'],
    options: [
      {
        key: '',
        name: 'No',
        value: '',
      },
      {
        key: 'ENABLED',
        name: 'Enabled',
        value: 'ENABLED',
      },
      {
        key: 'INTERNAL',
        name: 'Internal',
        value: 'INTERNAL',
      },
    ],
  },
  {
    key: 'PUBLISHING_JOURNEY_MP',
    name: 'MP Publishing Journey',
    platform: ['NA', 'EU'],
    options: [
      {
        key: 'FLEXI',
        name: 'Flexi Only',
        value: ['FLEXI'],
      },
      {
        key: 'PubC',
        name: 'PubC Only',
        value: ['PUBC'],
      },
      {
        key: 'BOTH',
        name: 'Both',
        value: ['FLEXI', 'PUBC'],
      },
    ],
  },
  {
    key: 'PUBLISHING_JOURNEY_SHOP',
    name: 'Shop Publishing Journey',
    platform: ['NA', 'EU'],
    options: [
      {
        key: 'FLEXI',
        name: 'Flexi Only',
        value: ['FLEXI'],
      },
      {
        key: 'PubC',
        name: 'PubC Only',
        value: ['PUBC'],
      },
      {
        key: 'BOTH',
        name: 'Both',
        value: ['FLEXI', 'PUBC'],
      },
    ],
  },
];

export default {
  name: 'AdminPage',
  components: {
    LoadingHeart,
    ToggleSwitch,
    Dropdown,
  },
  data() {
    return {
      initialized: false,
    };
  },
  computed: {
    ...mapState({
      accountData: (state) => state.account,
    }),
    ...mapGetters({
      hasShops: 'user/hasShops',
      getSetting: 'settings/getSetting',
      isAdmin: 'account/isAdmin',
    }),
    relevantSettings() {
      return settings.filter((setting) =>
        setting.platform.includes(SPRD.PLATFORM),
      );
    },
  },
  async created() {
    try {
      await this.$store.state.settings.onDataLoaded;

      const accountData = await accountService.getData();
      if (!accountData?.origin.session.isAdmin) {
        this.$router.push({ name: 'partnerarea.404' });
      } else {
        this.initialized = true;
      }
    } catch (_) {
      this.$router.push({ name: 'partnerarea.404' });
    }
  },
  methods: {
    ...mapActions({
      setSetting: 'settings/setSetting',
    }),
    getCurrentSettingOption(setting) {
      const currentSettingsValue = this.getSetting(setting.key);

      return setting.options.find((option) => {
        if (!option.value.sort) {
          return option.value === currentSettingsValue;
        } else if (option.value.sort && currentSettingsValue?.sort) {
          return (
            JSON.stringify(option.value.sort()) ===
            JSON.stringify(currentSettingsValue.sort())
          );
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/links';

.admin {
  max-width: 1400px;
  width: 100%;
  padding: 24px;
  margin: 0 auto;

  h1 {
    margin: 0 0 24px 0;
  }

  p {
    margin: 0;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey40;
    padding: 12px 0;

    &:last-child {
      border: none;
    }
  }
}
</style>
