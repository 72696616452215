<template>
  <div class="box security">
    <div class="box-header">
      <h2>{{ $t('MFA.STATUS.HEADING') }}</h2>
    </div>
    <div class="box-content">
      <MfaStatus />
    </div>
  </div>
  <div class="box security">
    <div class="box-header">
      <h2>{{ $t('ACCOUNT.ACCOUNT.PASSWORD_HEADING') }}</h2>
    </div>
    <div class="box-content">
      <div>
        <div class="password-inputs">
          <!-- hidden password field to prevent browser from autofilling the oldAccountPassword field -->
          <input type="password" style="display: none" />

          <LabelInput :class="{ 'has-error': errors.oldPassword }">
            <label for="oldAccountPassword">{{
              $t('ACCOUNT.ACCOUNT.OLD_PASSWORD')
            }}</label>
            <DebounceInput
              id="oldAccountPassword"
              :type="revealOldPassword ? 'text' : 'password'"
              :model-value="oldPassword"
              @update:model-value="setOldPassword"
            />
            <Icon
              class="reveal-password"
              :icon="revealOldPassword ? 'eye-crossed' : 'eye'"
              @click="toggleRevealOldPassword"
            ></Icon>
            <div v-if="errors.oldPassword" class="field-info">
              <small class="error-info">
                {{ $t(errors.oldPassword) }}
              </small>
            </div>
          </LabelInput>

          <LabelInput :class="{ 'has-error': errors.newPassword }">
            <label for="newAccountPassword">{{
              $t('ACCOUNT.ACCOUNT.NEW_PASSWORD')
            }}</label>
            <DebounceInput
              id="newAccountPassword"
              :type="revealNewPassword ? 'text' : 'password'"
              :model-value="newPassword"
              @update:model-value="setNewPassword"
            />
            <Icon
              class="reveal-password"
              :icon="revealNewPassword ? 'eye-crossed' : 'eye'"
              @click="toggleRevealNewPassword"
            ></Icon>
            <div v-if="errors.newPassword" class="field-info">
              <small class="error-info">
                {{ $t(errors.newPassword) }}
              </small>
            </div>
          </LabelInput>

          <div class="password-requirements">
            <Requirement
              v-for="requirement in passwordRequirements"
              :key="requirement.key"
              :text="requirement.text"
              :fullfilled="requirement.valid"
              :failed="strictValidation && !requirement.valid"
            />
          </div>

          <LabelInput :class="{ 'has-error': errors.newPasswordConfirmation }">
            <label for="newAccountPasswordConfirmation">{{
              $t('ACCOUNT.ACCOUNT.NEW_PASSWORD_CONFIRM')
            }}</label>
            <DebounceInput
              id="newAccountPasswordConfirmation"
              :type="revealNewPasswordConfirmation ? 'text' : 'password'"
              :model-value="newPasswordConfirmation"
              @update:model-value="setNewPasswordConfirmation"
            />
            <Icon
              class="reveal-password"
              :icon="revealNewPasswordConfirmation ? 'eye-crossed' : 'eye'"
              @click="toggleRevealNewPasswordConfirmation"
            ></Icon>
            <div v-if="errors.newPasswordConfirmation" class="field-info">
              <small class="error-info">
                {{ $t(errors.newPasswordConfirmation) }}
              </small>
            </div>
          </LabelInput>
        </div>
        <AccountPageActions
          :save="changePassword"
          :disabled="!dataChanged"
          @on-revert="revert"
        />
      </div>
    </div>
  </div>
  <div class="box account-deletion">
    <div class="box-header">
      <h2>{{ $t('ACCOUNT.ACCOUNT.DELETE') }}</h2>
    </div>
    <div class="box-content">
      <p>{{ $t('ACCOUNT.ACCOUNT.DELETE_HINT') }}</p>
      <button
        v-tooltip.top-start="{
          content: isAdmin ? 'not allowed as admin' : null,
          hideOnTargetClick: false,
        }"
        :disabled="isAdmin"
        class="link-red"
        @click.prevent="!isAdmin && deleteAccount()"
      >
        {{ $t('ACCOUNT.ACCOUNT.DELETE_CTA') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import authService from 'src/app/commons/api/authService/authService';
import AccountPageActions from '../AccountPageActions.vue';
import LabelInput from '@/labelInput/LabelInput.vue';
import AccountDeleteDialog from 'src/app/partnerarea/account/security/deleteDialog/AccountDeleteDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import MfaStatus from './mfa/MfaStatus.vue';
import DebounceInput from 'src/app/components/input/DebounceInput.vue';
import Requirement from 'src/app/components/requirement/Requirement.vue';

export default {
  name: 'Security',
  components: {
    LabelInput,
    AccountPageActions,
    MfaStatus,
    DebounceInput,
    Requirement,
  },
  data() {
    return {
      strictValidation: undefined,
      errors: {
        oldPassword: null,
        newPassword: null,
        newPasswordConfirmation: null,
      },
      revealOldPassword: false,
      revealNewPassword: false,
      revealNewPasswordConfirmation: false,
      oldPassword: null,
      newPassword: null,
      newPasswordConfirmation: null,
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: 'account/isAdmin',
    }),
    dataChanged() {
      return (
        this.oldPassword || this.newPassword || this.newPasswordConfirmation
      );
    },
    passwordRequirements() {
      return [
        {
          key: 'lowercase',
          text: 'ACCOUNT.PASSWORD.REQUIREMENTS.LOWERCASE',
          valid: this.lowerCaseLettersCount >= 1,
        },
        {
          key: 'uppercase',
          text: 'ACCOUNT.PASSWORD.REQUIREMENTS.UPPERCASE',
          valid: this.upperCaseLettersCount >= 1,
        },
        {
          key: 'number',
          text: 'ACCOUNT.PASSWORD.REQUIREMENTS.NUMBER',
          valid: this.numberCount >= 1,
        },
        {
          key: 'length',
          text: 'ACCOUNT.PASSWORD.REQUIREMENTS.LENGTH',
          valid: this.newPassword?.length >= 10,
        },
      ];
    },
    upperCaseLettersCount() {
      return (
        (
          this.newPassword?.match(
            /.*[A-ZÄÖÜÀÂÆĄÇĆÉÈÊËĘÎÏŁŃÑÔÓŒŚÙÛØÅŠŽŹŻ].*/g,
          ) || []
        ).length || 0
      );
    },
    lowerCaseLettersCount() {
      return (
        (
          this.newPassword?.match(
            /.*[a-zäöüàâæąçćéèêëęîïłńñôóœśùûøåšžźż].*/g,
          ) || []
        ).length || 0
      );
    },
    numberCount() {
      return (this.newPassword?.match(/[0-9]/g) || []).length || 0;
    },
    allPasswordRequirementsValid() {
      return this.passwordRequirements.every((req) => req.valid);
    },
  },
  methods: {
    setOldPassword(oldPassword) {
      this.resetErrors();
      this.oldPassword = oldPassword;
    },
    setNewPassword(newPassword) {
      this.resetErrors();
      this.newPassword = newPassword;
    },
    setNewPasswordConfirmation(newPasswordConfirmation) {
      this.resetErrors();
      this.newPasswordConfirmation = newPasswordConfirmation;
    },
    resetErrors() {
      this.errors.oldPassword = null;
      this.errors.newPassword = null;
      this.errors.newPasswordConfirmation = null;
    },
    async changePassword() {
      this.strictValidation = true;
      if (
        this.allPasswordRequirementsValid &&
        this.validatePasswordConfirmation()
      ) {
        try {
          await authService.changePassword({
            currentPassword: this.oldPassword,
            newPassword: this.newPassword,
          });
        } catch (error) {
          this.setErrors(error);
          return Promise.reject();
        }
      } else {
        return Promise.reject();
      }
    },
    revert() {
      this.resetErrors();
      this.strictValidation = undefined;
      this.oldPassword = null;
      this.newPassword = null;
      this.newPasswordConfirmation = null;
    },
    validatePasswordConfirmation() {
      if (this.newPassword !== this.newPasswordConfirmation) {
        this.errors.newPasswordConfirmation =
          'ACCOUNT.PASSWORD.ERRORS.PASSWORD_CONFIRMATION_WRONG';
        return false;
      }
      return true;
    },
    setErrors(error) {
      switch (error.data.error) {
        case '-00001':
          this.errors.oldPassword =
            'ACCOUNT.PASSWORD.ERRORS.CURRENT_PASSWORD_WRONG';
          this.bla = 'trued';
          break;
        case '001307':
          this.errors.newPassword =
            'ACCOUNT.PASSWORD.ERRORS.NEW_SAME_AS_CURRENT_PASSWORD';
      }
    },
    toggleRevealOldPassword() {
      this.revealOldPassword = !this.revealOldPassword;
    },
    toggleRevealNewPassword() {
      this.revealNewPassword = !this.revealNewPassword;
    },
    toggleRevealNewPasswordConfirmation() {
      this.revealNewPasswordConfirmation = !this.revealNewPasswordConfirmation;
    },
    deleteAccount() {
      dialogService.openDialog(AccountDeleteDialog);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/_type.scss';

.box.security {
  padding: 0;

  .box-header {
    padding: 24px 24px 0 24px;
  }

  .box-content {
    padding: 0 0 24px 0;

    & > div {
      padding: 0 24px;
    }
  }
}

.password-inputs {
  max-width: 400px;
  min-width: 350px;
}

.labelInput {
  margin: 0 0 16px 0;

  :deep(.error-icon) {
    display: none !important;
  }
}

.reveal-password {
  position: absolute;
  right: 10px;
  top: 10px;

  width: 18px;
  height: 18px;

  color: $pa-color-main;
  cursor: pointer;
}

.password-requirements {
  margin: 0 0 24px 0;
}

hr {
  margin: 24px 0;
}

.mfa {
  margin-top: 24px;

  h3 {
    margin: 0 0 8px 0;
  }

  p {
    color: $grey60;
    margin: 0 0 16px 0;
  }
}

.account-deletion {
  margin-top: 60px;

  p {
    @extend .text-grey;
    padding: 0 0 16px 0;
  }

  button {
    padding: 0;
  }
}
</style>
