import { shopAPI } from 'src/app/commons/api/shop/shopAPI.js';
import store from '@/store/Store';
import dialogService from '@/dialogs/wrapper/dialogService';
import ActivationSuccessDialog from 'src/app/partnerarea/pos/shop/activation/ActivationSuccessDialog.vue';
import PdvDialog from '@/dialogs/address/PdvDialog.vue';
import eventBus from '@/eventBus/eventBus';
import analyticsService from '@/tracking/analytics';

export const ActivationMixin = {
  methods: {
    showLaunchedDialog() {
      return dialogService
        .openDialog(ActivationSuccessDialog, {}, { staticBackdrop: true })
        .then(
          () => Promise.resolve(),
          () => Promise.resolve(),
        );
    },
    setShopStatus(hidden, shopLaunched) {
      if (!hidden && !shopLaunched) {
        return shopAPI.activateShop(this.$store).then(() => {
          this.$store.commit('shop/updateSectionOriginalData', {
            sectionKey: 'settings',
            origData: { hidden, shopLaunched: true },
          });

          setTimeout(() => {
            eventBus.$emit('shoparea:shopUpdate');
          }, 1000);

          return this.showLaunchedDialog();
        });
      } else {
        // basically send just one field to avoid any side effects whatsoever
        const settingsPayload = { hidden };

        return shopAPI
          .editShopSettings(this.$store, settingsPayload)
          .then(() => {
            this.$store.commit('shop/updateSectionOriginalData', {
              sectionKey: 'settings',
              origData: { hidden },
            });

            setTimeout(() => {
              eventBus.$emit('shoparea:shopUpdate');
            }, 1000);
            return Promise.resolve();
          });
      }
    },
    toggleShopStatus() {
      const settings = this.$store.state.shop.shopSavable.clientData.settings;

      if (!settings.hidden) {
        return this.setShopStatus(true, true);
      } else {
        if (!store.getters['user/hasPublishRequirements']) {
          return dialogService.openDialog(PdvDialog).then(() => {
            analyticsService.logEvent('SHOP_ACTIVATED');
            this.setShopStatus(false, settings.shopLaunched);
          });
        } else {
          analyticsService.logEvent('SHOP_ACTIVATED');
          return this.setShopStatus(false, settings.shopLaunched);
        }
      }
    },
  },
};
