import apiClient from '@/api/client/apiClient';
import { PUBLISHING_JOURNEY } from '@/publishing/publishingJourney';
import { PUBLISHING_SCOPE } from '@/publishing/publishingScope';

let resolveOnDataLoaded;

export default {
  namespaced: true,
  state: {
    data: {},
    onDataLoaded: new Promise((resolve) => {
      resolveOnDataLoaded = resolve;
    }),
    loaded: false,
  },
  getters: {
    getSetting: (state) => (key) =>
      state.data[key] ? state.data[key].value : undefined,
    getListSetting: (state) => (key) =>
      state.data[key] ? state.data[key].value : [],
    mpEnabled: (state, getters) => getters.getSetting('MP_ENABLED'),
    digitalProductsEnabled: (state, getters) =>
      getters.getSetting('DIGITAL_PRODUCTS'),
    publishingCoreEnabled: (state, getters) =>
      getters.getPublishingJourneyAvailabilityForScope(
        null,
        PUBLISHING_JOURNEY.PUBC,
      ),
    lastNpsSubmit: (state, getters) => getters.getSetting('LAST_PA_NPS_SUBMIT'),
    aiEnabled: (state, getters) =>
      ['ENABLED', 'INTERNAL'].includes(getters.getSetting('AI_ENABLED')),
    getPublishingJourneyAvailabilityForScope:
      (state, getters) => (scope, publishingJourney) => {
        if (scope === PUBLISHING_SCOPE.SHOP) {
          return getters.getPublishingJourneyAvailabilityShop(
            publishingJourney,
          );
        } else if (scope === PUBLISHING_SCOPE.MP) {
          return getters.getPublishingJourneyAvailabilityMp(publishingJourney);
        } else {
          return (
            getters.getPublishingJourneyAvailabilityShop(publishingJourney) ||
            getters.getPublishingJourneyAvailabilityMp(publishingJourney)
          );
        }
      },
    getPublishingJourneyAvailabilityShop:
      (state, getters, rootState, rootGetters) => (publishingJourney) =>
        ['SHOP', 'BOTH'].includes(rootGetters['user/partnerType']) &&
        getters
          .getListSetting('PUBLISHING_JOURNEY_SHOP')
          .includes(publishingJourney.key),
    getPublishingJourneyAvailabilityMp:
      (state, getters, rootState, rootGetters) => (publishingJourney) =>
        ['MP', 'BOTH'].includes(rootGetters['user/partnerType']) &&
        getters
          .getListSetting('PUBLISHING_JOURNEY_MP')
          .includes(publishingJourney.key),
    newPublishingJourneyAvailabilityMp: (state, getters) =>
      getters.getPublishingJourneyAvailabilityForScope(
        PUBLISHING_SCOPE.MP,
        PUBLISHING_JOURNEY.PUBC
      ),
    newPublishingJourneyAvailabilityShop: (state, getters) =>
      getters.getPublishingJourneyAvailabilityForScope(
        PUBLISHING_SCOPE.SHOP,
        PUBLISHING_JOURNEY.PUBC
      ),
    oldPublishingJourneyAvailabilityMp: (state, getters) =>
      getters.getPublishingJourneyAvailabilityForScope(
        PUBLISHING_SCOPE.MP,
        PUBLISHING_JOURNEY.FLEXI
      ),
    oldPublishingJourneyAvailabilityShop: (state, getters) =>
      getters.getPublishingJourneyAvailabilityForScope(
        PUBLISHING_SCOPE.SHOP,
        PUBLISHING_JOURNEY.FLEXI
      ),
    oldPublishingJourneyAvailabilityBoth: (state, getters) =>
      getters.oldPublishingJourneyAvailabilityMp &&
      getters.oldPublishingJourneyAvailabilityShop,
  },
  mutations: {
    fetchSettings(state, data) {
      data.forEach((item) => {
        state.data[item.key] = item;
      });
      resolveOnDataLoaded();
      state.loaded = true;
    },
    setSetting(state, { key, value }) {
      state.data[key].value = value;
    },
  },
  actions: {
    async fetchSettings({ commit, rootState }) {
      const { data } = await apiClient.request({
        method: 'GET',
        url: `/api/v1/partners/${rootState.user.data.id}/settings`,
      });
      commit('fetchSettings', data);
    },
    async setSetting({ commit, rootState, state }, { key, value }) {
      if (!Object.keys(state.data).includes(key)) {
        return Promise.reject();
      }

      await commit('setSetting', { key, value });
      await apiClient.request({
        method: 'PUT',
        url: `/api/v1/partners/${rootState.user.data.id}/settings/${key}`,
        data: state.data[key],
      });
    },
  },
};
