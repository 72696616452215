<template>
  <div class="dms-properties" :class="{ condensed }">
    <AdvancedPopover
      v-if="designChecklist.value"
      :heading="designChecklist.meta.name"
      :description="designChecklist.meta.description"
      :icon="designChecklist.meta.icon"
    >
      <div class="benefit publishing-limit active">
        <Icon :icon="designChecklist.meta.icon" />
        <p class="publishing-limit">
          <DmsDesignChecklistCounter />
          <span v-if="!condensed" class="publishing-limit-name">{{
            $t(designChecklist.meta.name)
          }}</span>
        </p>
      </div>
    </AdvancedPopover>
    <AdvancedPopover
      :heading="publishingLimit.meta.name"
      :description="publishingLimit.meta.description"
      :icon="publishingLimit.meta.icon"
    >
      <div class="benefit publishing-limit active">
        <Icon :icon="publishingLimit.meta.icon" />
        <p class="publishing-limit">
          <DmsPublishingCounter />
          <span v-if="!condensed" class="publishing-limit-name">{{
            $t(publishingLimit.meta.name)
          }}</span>
        </p>
      </div>
    </AdvancedPopover>
    <AdvancedPopover
      v-for="benefit in benefitList"
      :key="benefit.key"
      :heading="benefit.meta.name"
      :description="benefit.meta.description"
      :icon="benefit.meta.icon"
    >
      <div class="benefit" :class="{ active: benefit.value }">
        <Icon :icon="benefit.meta.icon" />
        <div>
          <p v-if="!condensed">
            {{ $t(benefit.meta.name) }}
          </p>
        </div>
      </div>
    </AdvancedPopover>
  </div>
</template>

<script>
import { DMS_PROPERTIES } from '@/api/models/dms/dmsStates';
import { mapGetters } from 'vuex';
import DmsPublishingCounter from './DmsPublishingCounter.vue';
import DmsDesignChecklistCounter from './DmsDesignChecklistCounter.vue';
import AdvancedPopover from 'src/app/components/popover/AdvancedPopover.vue';

export default {
  name: 'DmsPropertyList',
  components: {
    DmsPublishingCounter,
    DmsDesignChecklistCounter,
    AdvancedPopover,
  },
  props: {
    condensed: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      benefitList: 'dms/benefitList',
      getProperty: 'dms/getProperty',
      currentGroup: 'dms/currentGroup',
      publishingCount: 'dms/publishingCount',
    }),
    publishingLimit() {
      return this.getProperty(DMS_PROPERTIES.PUBLISHING_LIMIT);
    },
    designChecklist() {
      return this.getProperty(DMS_PROPERTIES.DESIGN_CHECKLIST);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/styleguide/mixins/media';

.dms-properties {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.condensed {
    flex-direction: row;

    .benefit {
      margin-bottom: 0;

      &.active .icon {
        color: $grey80;
      }

      p {
        font-weight: normal;
      }
    }

    .publishing-limit .icon {
      margin-right: 4px;
    }
  }

  .benefit {
    display: flex;
    align-items: center;
    color: $grey20;
    margin-bottom: 16px;

    &.active {
      color: $grey80;

      .icon {
        color: $pa-color-main;
      }
    }

    p {
      margin: 0;
      font-weight: bold;
    }

    .icon {
      height: 20px;
      width: 20px;
      margin-right: 12px;

      @include small-desktop {
        margin-right: 8px;
      }
    }
  }

  .publishing-limit {
    display: flex;
    align-items: center;
    margin-right: 16px;

    .publishing-limit-name {
      margin-left: 8px;
    }
  }
}
</style>
