<template>
  <div
    v-if="alertsToDisplay && alertsToDisplay.length"
    class="account-warnings"
  >
    <component
      :is="alert.routeOnClick && !isOnCurrentPage(alert) ? 'a' : 'div'"
      v-for="alert in alertsToDisplay"
      :key="alert.key"
      class="color-box"
      :class="`${alert.severity}`"
      @click.prevent="goToAlert(alert)"
    >
      {{ $t(alert.description) }}

      <p>{{ $t(alert.linkText || 'ACCOUNT.ALERT_LINK') }}</p>
    </component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isSubset } from '@/utils';

export default {
  name: 'AccountWarnings',
  props: {
    count: {
      type: Number,
      default: 1,
    },
    prioritizeCurrentPageAlerts: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('account', {
      alerts: 'alerts',
    }),
    alertsToDisplay() {
      const displayedAlerts = this.prioritizeCurrentPageAlerts
        ? [...this.alerts].sort((a, b) => {
            if (!this.isOnCurrentPage(a) && this.isOnCurrentPage(b)) {
              return 1;
            } else if (this.isOnCurrentPage(a) && !this.isOnCurrentPage(b)) {
              return -1;
            } else {
              return 0;
            }
          })
        : this.alerts;

      return displayedAlerts.slice(0, this.count);
    },
  },
  methods: {
    goToAlert(alert) {
      if (alert.routeOnClick) {
        this.$router.push({
          name: alert.routeOnClick.route,
          params: alert.routeOnClick.params,
        });
      }
    },
    isOnCurrentPage(alert) {
      return (
        alert.routeOnClick?.route === this.$route.name &&
        isSubset(this.$route.params, alert.routeOnClick?.params)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.account-warnings {
  margin-bottom: 16px;

  .color-box {
    padding: 10px 24px;

    p {
      display: none;
    }
  }

  a.color-box {
    display: flex;
    align-items: center;

    &:hover {
      box-shadow: 0px 0px 4px #0000003b;
    }

    p {
      display: inline;
      margin: 0 0 0 auto;
      font-weight: 700;
      flex-shrink: 0;
      padding-left: 16px;
    }
  }
}
</style>
