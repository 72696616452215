<template>
  <div :id="htmlId" class="tileList">
    <div v-for="sellable in sellables" :key="sellable.id" class="tile">
      <SprdLazyImage
        class="tileImg"
        :src="sellable.imageUrl"
        :alt="sellable.name"
      />
      <div class="tileText boldText">{{ sellable.name }}</div>
      <div class="tileText boldText">{{ sellable.productTypeName }}</div>
      <div class="tileText">{{ sellable.priceFormatted }}</div>
      <div class="statusRow boldText">
        <div class="statusCircle" :style="{ backgroundColor: stateColor }" />
        <div>{{ $t(tileTitle) }}</div>
      </div>
    </div>
    <div
      v-for="index in 7"
      :key="`placeholder-${index}`"
      class="tile tile-placeholder"
    ></div>
  </div>
</template>

<script>
import {
  getStateColor,
  getStateListHtmlId,
  getStateTitle,
} from './youtubeSellableStates';
import SprdLazyImage from '@/image/SprdLazyImage.vue';

export default {
  name: 'YoutubeSellableTiles',
  components: {
    SprdLazyImage,
  },
  props: {
    sellables: {
      type: Array,
      required: true,
    },
    tileType: {
      type: String,
      required: true,
    },
  },
  computed: {
    stateColor() {
      return getStateColor(this.tileType);
    },
    tileTitle() {
      return getStateTitle(this.tileType);
    },
    htmlId() {
      return getStateListHtmlId(this.tileType);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/constants';
@import 'src/scss/styleguide/colors';

.tileList {
  display: flex;
  flex-wrap: wrap;
  margin-right: -24px;
}

.tile {
  background-color: #ffffff;
  padding: 8px;
  border-radius: $border-radius-medium;
  margin: 0 24px 24px 0;
  flex: 1 1 150px;
  min-width: 150px;
}

.tile-placeholder {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
}

.tileImg {
  margin-bottom: 17px;
  background-color: $sprd-color-lightest-grey;
  border-radius: $border-radius-medium;
  position: relative;

  &:before {
    content: ' ';
    display: block;
    padding-top: 100%;
  }

  :deep(img) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.tileText {
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;
  margin-left: 2px;
  font-size: 12px;
}

.boldText {
  font-weight: bold;
}

.statusRow {
  font-size: 14px;
  width: 100%;
  overflow: hidden;
  display: flex;
  margin-top: 12px;
}

.statusCircle {
  width: 16px;
  height: 16px;
  flex: none;
  border-radius: 50%;
  margin-right: 8px;
}
</style>
