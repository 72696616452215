<template>
  <TagInput
    v-bind="{ ...$props, ...$attrs }"
    :min="validatorOptions.tags.min"
    :max="validatorOptions.tags.max"
    :max-length="validatorOptions.tags.maxlength"
    :is-valid="valid"
    :name="name"
  />
</template>

<script>
import digitalProductValidator from '@/api/validators/digitalProduct/digitalProductValidator';
import TagInput from './TagInput.vue';

export default {
  name: 'DigitalProductTagInput',
  components: {
    TagInput,
  },
  props: {
    validation: {
      type: Boolean,
    },
    digitalProduct: {
      type: Object,
    },
    locale: {
      type: String,
    },
  },
  data() {
    return {
      validatorOptions: digitalProductValidator.defaultOptions,
    };
  },
  computed: {
    name() {
      return this.digitalProduct ? this.digitalProduct.workingState.name : null;
    },
    valid() {
      if (!this.digitalProduct || !this.validation?.errors.tags) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
