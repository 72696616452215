<template>
  <div class="modal-container ai-generator-dialog dialog-size-lg">
    <AiDesignGenerator @on-done="modalInstance.close" />
  </div>
</template>

<script>
import AiDesignGenerator from './AiDesignGenerator.vue';

export default {
  name: 'AiDesignGeneratorDialog',
  components: {
    AiDesignGenerator,
  },
  props: ['modalInstance', 'data'],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.ai-generator-dialog {
  min-width: 700px;
}
</style>
