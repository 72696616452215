<template>
  <PosAndTimeFilter
    v-if="timeFilters"
    :time-filters="timeFilters"
    :default-time-filter="defaultTimeFilter || TIME_FILTERS.HALF_YEAR"
    :pos-filters="posFilters"
    :min-pos-filters="2"
    @change="emitCurrentFilters"
  />
</template>

<script>
import PosAndTimeFilter from 'src/app/components/charts/timeline/PosAndTimeFilter.vue';
import statisticsService from '@/api/statisticsService/statisticsService';
import {
  TIME_FILTERS,
  getTimeFiltersSinceYear,
} from 'src/app/components/charts/sales/timeFilters';
import { mapState } from 'vuex';
import { getYear } from 'date-fns';

export default {
  name: 'SalesEarningsFilter',
  components: {
    PosAndTimeFilter,
  },
  props: {
    defaultTimeFilter: {
      type: Object,
      required: false,
    },
    startDate: {
      type: Date,
      required: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      timeFilters: null,
    };
  },
  computed: {
    TIME_FILTERS() {
      return TIME_FILTERS;
    },
    posFilters: () => statisticsService.getPosFilters(),
    ...mapState({
      registrationDate: (state) => state.user.data.created,
    }),
  },
  created() {
    this.timeFilters = getTimeFiltersSinceYear(
      getYear(new Date(this.startDate || this.registrationDate)),
    );
  },
  methods: {
    emitCurrentFilters(filters) {
      this.$emit('change', filters);
    },
  },
};
</script>
