<template>
  <div class="shopMenuContainer">
    <LoadingHeart v-if="loading" />
    <div class="sa-menu" :class="{ hidden: loading }">
      <div v-if="navigation.parent || unsavedChanges" class="back-save-bar">
        <transition name="slidein-left">
          <div v-if="!unsavedChanges" class="back-bar">
            <a v-if="navigation.parent" class="back" @click="back">
              <Icon icon="left"></Icon>
              {{ $t(navigation.parent.label) }}
            </a>
          </div>
        </transition>
        <transition name="slidein-right">
          <div v-if="unsavedChanges" class="save-bar">
            <a class="link-main save" @click.prevent="save">{{
              $t('GENERAL.SAVE')
            }}</a>
            <a class="link reset" @click.prevent="reset">{{
              $t('GENERAL.DISCARD')
            }}</a>
          </div>
        </transition>
      </div>

      <div v-if="displayValidationError" class="validation-error">
        {{ $t('SA.navigation.invalidData') }}
      </div>
      <div v-if="failedErrorMsg" class="failed-save-error">
        <span>{{ failedErrorMsg }}</span>
        <a @click="hideErrorMsg">
          <Icon icon="close"></Icon>
        </a>
      </div>

      <div class="page-wrapper content-wrapper overflow-y">
        <h1
          class="header row"
          :class="navigation.parent ? 'subPageHeader heading2' : 'heading1'"
        >
          {{ $t(navigation.label) }}
        </h1>

        <ul class="root-entry">
          <li v-if="navigation.subheading" class="entry subheader">
            {{ $t(navigation.subheading) }}
          </li>

          <li
            v-for="entry in navigationEntries"
            :key="entry.url"
            class="entry"
            :class="entry.entryClass"
          >
            <a
              v-if="!entry.type"
              class="entryLink position-relative"
              :class="{ subPageLink: navigation.parent }"
              @click="show(entry)"
            >
              <div>{{ $t(entry.label) }}</div>
              <Icon icon="chevron"></Icon>
            </a>
            <div v-if="entry.type === 'stc-ctn'" class="stc-ctn">
              {{ $t(entry.ctn) }}
            </div>
            <div v-if="entry.type === 'ctn'" class="ctn">
              <router-view name="context-nav@shop-area"></router-view>
            </div>
            <div v-if="entry.type === 'spacer'" class="nav-spacer"></div>
          </li>
          <li class="shop-info">
            <div class="shop-name">
              <strong>{{ shopName }}</strong>
            </div>
            <div class="shop-id">
              <Copyable id="shop-id" :content="shopId" inline>
                {{ $t('SA.shopDashboard.shopId.label') }}:
                {{ shopId }}
              </Copyable>
              <Copyable id="shopUrl" :content="shopUrl" inline>{{
                $t('SA.shopDashboard.shopUrl')
              }}</Copyable>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import navigationConfig from './navigationConfig';
import { findEntry, setNavParents } from './navigationHelpers';
import LoadingHeart from 'src/app/commons/LoadingHeart/LoadingHeart.vue';
import Copyable from '@/copyable/Copyable.vue';
import analyticsService from '@/tracking/analytics';
import eventBus from '@/eventBus/eventBus';

const navTree = setNavParents(navigationConfig);

export default {
  name: 'ShopNavigation',
  components: { Copyable, LoadingHeart },
  emits: ['navigationUpdate'],
  data() {
    return {
      navigation: { parent: null },
    };
  },
  computed: {
    currentShop() {
      return this.$store.state.shop.currentShop;
    },
    shopId() {
      return this.$store.state.shop.currentShop.id;
    },
    shopName() {
      return this.$store.state.shop.currentShop.name;
    },
    shopUrl() {
      return this.$store.getters['shop/getSpreadshopUrl']('', false);
    },
    displayValidationError() {
      return (
        Object.keys(this.$store.state.shop.shopSavableValidationError).length >
        0
      );
    },
    failedErrorMsg() {
      return this.$store.state.shop.shopSavableSavingError;
    },
    loading() {
      return this.$store.state.shop.loadingJobs.length > 0;
    },
    unsavedChanges() {
      if (this.navigation.customSaving?.dirty) {
        return this.navigation.customSaving.dirty();
      }

      return this.$store.getters['shop/shopSavableDirty'];
      // this.$store.commit('shop/validateShopSavableSubset', from.name);
      // unsavedChangesService.triggerCheck({
      //   check: () => this.$store.getters['shop/shopSavableDirty'],
      //   save: () => {
      //     if (!this.$store.getters['shop/hasShopSavableValidationErrors']) {
      //       this.$store.dispatch('shop/saveShopSavable').then(() => {
      //         this.deactivatedShopReminder(to, from, next);
      //       });
      //     }
      //   },
      //   cancel: () => {
      //     this.$store.commit('shop/resetShopSavable');
      //     this.deactivatedShopReminder(to, from, next);
      //   },
      //   unchanged: () => {
      //     this.deactivatedShopReminder(to, from, next);
      //   },
      // });
    },
    navigationEntries() {
      return (
        this.navigation.children &&
        this.navigation.children.filter(
          (entry) =>
            !entry.hideNavEntry &&
            (!entry.isEnabled || entry.isEnabled(this.currentShop)),
        )
      );
    },
  },
  watch: {
    $route: {
      handler(to) {
        this.navigation = findEntry(to.name, navTree) || this.navigation;
        this.$emit('navigationUpdate', this.navigation);
      },
      immediate: true,
    },
  },
  methods: {
    back() {
      const entry = this.navigation.parent;
      this.$router.push({ name: entry ? entry.url : 'shop-dashboard' });
    },
    show(entry) {
      this.$router.push({ name: entry.url });
    },
    hideErrorMsg() {
      this.$store.commit('shop/setShopSavableSavingError', '');
    },
    async save() {
      if (this.navigation.customSaving?.save) {
        return this.navigation.customSaving.save();
      }

      analyticsService.logEvent('shop-area-save', {
        page: this.$route.name,
      });

      this.$store.commit('shop/validateShopSavableSubset', this.$route.name);
      if (this.$store.getters['shop/hasShopSavableValidationErrors']) {
        return Promise.reject();
      } else {
        await this.$store.dispatch('shop/saveShopSavable');
        if (this.navigation.updateShopDataOnSave) {
          eventBus.$emit('shoparea:shopUpdate');
        }
      }
    },
    reset() {
      if (this.navigation.customSaving?.reset) {
        return this.navigation.customSaving.reset();
      }

      this.$store.commit('shop/resetShopSavable');

      analyticsService.logEvent('shop-area-reset', {
        page: this.$route.name,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';
@import 'src/scss/page-layout.scss';

.hidden {
  display: none;
}

.shopMenuContainer {
  position: relative;
  padding: 0 15px;
  float: left;
  width: 400px;
  height: 100%;
  background-color: $grey0;

  @include small-desktop {
    width: 300px;
  }
}

.header {
  margin: 24px;
  line-height: 1;
}

.subPageHeader {
  margin-top: 0;
}

.entryLink {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  color: $grey80;
  font-family: $mueso500;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    background-color: $grey10;
  }

  & > div {
    flex-grow: 1;
  }

  .icon {
    color: $grey40;
    width: 20px;
    height: 20px;
  }

  &[disabled] {
    &,
    & .icon {
      color: $grey20;
    }
  }
}

.subPageLink {
  font-family: $sprd-default-font-family;
  font-size: 16px;
}

.sa-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 -15px;
  overflow: hidden;
  overflow-y: auto;
  color: $grey80;
  padding-top: 24px;

  .root-entry {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0;
    padding-top: 1px;
  }

  .back-save-bar {
    position: relative;
    height: 68px;
  }

  .save-bar,
  .back-bar {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 24px;
  }

  .save-bar {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 700;

    .reset {
      margin-left: 16px;
    }
  }

  .back {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    color: $pa-color-main;

    .icon {
      flex-shrink: 0;
      margin-left: -0.25em;
      margin-right: 8px;
      font-size: 1.01em;
    }
  }

  .entry {
    list-style: none;
    margin: 0;

    &.subheader {
      margin: 0 24px 8px;
      position: relative;
      line-height: 1.5;
      color: $grey60;

      @include small-desktop {
        margin: 0 16px 8px;
      }
    }

    .ctn {
      margin: 16px 24px 0;

      @include small-desktop {
        margin: 16px;
        margin-bottom: 0;
      }
    }

    &:first-child .ctn {
      margin-top: 0;
    }

    .stc-ctn {
      padding: 21px 20px;
      text-align: center;
      line-height: 1.4;
    }

    .nav-spacer {
      padding: 10px 0;

      &::before {
        content: '\00a0';
      }
    }

    &--max,
    &--max .ctn {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  .sub-menu {
    width: 100%;
    padding: 0;
    position: absolute;
    left: 100%;
    top: 0;
    padding-bottom: 50px;
  }

  .validation-error,
  .failed-save-error {
    background-color: $pa-color-red;
    color: $grey0;
    z-index: 3;
    padding: 5px 10px;
    position: absolute;
    top: 60px;
    width: calc(100% - 20px);
    margin: 0 10px;
  }

  .failed-save-error {
    display: flex;

    & > span {
      padding-right: 10px;
    }

    a {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    .icon {
      color: $grey0;
    }
  }

  .shop-info {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    padding: 36px 24px 0;
    font-size: 14px;

    @include small-desktop {
      padding: 36px 16px 0;
    }

    a {
      display: inline-block;
      .icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .shop-name {
    margin-bottom: 8px;
  }

  .shop-id {
    margin-bottom: 12px;
  }
}

.uVDropdown {
  .uVDropdown-content {
    bottom: 0;
    position: absolute;
    left: -180px;
  }
}

@keyframes slidein-left {
  0% {
    margin-left: -50%;
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    margin-left: 0;
    opacity: 1;
  }
}

@keyframes slidein-right {
  0% {
    margin-left: 50%;
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    margin-left: 0;
    opacity: 1;
  }
}

.slidein-left-enter-active {
  animation: slidein-left 0.3s linear;
}
.slidein-left-leave-active {
  animation: slidein-left 0.3s reverse linear;
}

.slidein-right-enter-active {
  animation: slidein-right 0.3s linear;
}
.slidein-right-leave-active {
  animation: slidein-right 0.3s reverse linear;
}
</style>
