// checks if PA can support a composition with the features and settings it currently has

import { Composition, CompositionSchema } from './compositionTypes';

export function compositonProductSettingsAreSupported(
  composition: Composition,
): boolean {
  // product settings means assortment rules, hotspots, appearances
  const validationResult = CompositionSchema.safeParse(composition);
  if (!validationResult.success) {
    console.error(validationResult.error.issues);
  }
  return validationResult.success;
}
