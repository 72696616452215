import { createApp } from 'vue';
import GoogleMapsTC from './GoogleMapsTC.vue';

async function loadGoogleApi() {
  return new Promise((resolve) => {
    if (window.google && window.google.maps) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => {
      resolve();
      return;
    };
    script.src =
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDBURR1TDuvU4Nt8xKIeDUCMIA5LQPf6QA&libraries=places&types=routes';
    const element = document.getElementsByTagName('script')[0];
    element.parentNode.insertBefore(script, element);
  });
}

function getAddressComponent(components, opts) {
  const defaultOptions = {
    occurance: 0,
  };
  const options = Object.assign({}, defaultOptions, opts);
  const result = components.filter((component) =>
    component.types.includes(options.type),
  );
  return result[options.occurance]
    ? result[options.occurance][
        options.preferShortName ? 'short_name' : 'long_name'
      ]
    : '';
}

function getPlaceData(autocomplete) {
  const place = autocomplete.getPlace();
  const components = place.address_components;

  if (!components) {
    return null;
  }

  return {
    country: getAddressComponent(components, {
      type: 'country',
      preferShortName: true,
    }),
    street: getAddressComponent(components, { type: 'route' }),
    street2: getAddressComponent(components, { type: 'route', occurance: 1 }),
    city:
      getAddressComponent(components, { type: 'locality' }) ||
      getAddressComponent(components, { type: 'postal_town' }),
    postalCode: getAddressComponent(components, { type: 'postal_code' }),
    streetNumber: getAddressComponent(components, { type: 'street_number' }),
    name: place.name,
  };
}

function addTcLink(element) {
  const app = createApp(GoogleMapsTC);
  const wrapper = document.createElement('div');
  wrapper.setAttribute('id', 'googleMapsTC');
  element.parentNode.insertBefore(wrapper, element.nextSibling);
  app.mount('#googleMapsTC');
}

const countryMapping = {
  IC: 'ES',
};

const pdvAutoComplete = {
  init: async function (options = {}) {
    await loadGoogleApi();
    const element = document.getElementById(options.elementId);

    addTcLink(element);

    const autocomplete = new google.maps.places.Autocomplete(element, {
      types: ['address'],
    });
    if (countryMapping[options.countryId] || options.countryId) {
      autocomplete.setComponentRestrictions({
        country: [countryMapping[options.countryId] || options.countryId],
      });
    }
    autocomplete.addListener('place_changed', () => {
      const result = getPlaceData(autocomplete);
      if (result) {
        options.onPlaceChange(result);
      }
    });

    // prevent form from beeing submitted when user selects something with enter from the dropdown
    google.maps.event.addDomListener(element, 'keydown', function (e) {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    });

    return Promise.resolve({
      changeCountry: function (countryId) {
        autocomplete.setComponentRestrictions({
          country: [countryMapping[countryId] || countryId],
        });
      },
    });
  },
};

export default pdvAutoComplete;
