<template>
  <div>
    <div class="entry sp-subheader">
      {{ $t('POS.SHOP_SETTINGS.STARTPAGE.PRODUCT_RANGE.SUBHEADING') }}
    </div>
    <ToggleSwitch
      label="POS.SHOP_SETTINGS.STARTPAGE.PRODUCT_RANGE.ENABLE_PRODUCT_RANGE"
      :disabled="
        !productTypeCategoriesAvailable &&
        !startPage.productTypeCategoriesEnabled
      "
      :model-value="startPage.productTypeCategoriesEnabled"
      @update:model-value="onToggle"
    />
    <LegalLabelInput
      v-model="startPage.productTypeCategoryCaption"
      class="sprd-shop-input"
      maxlength="50"
      :disabled="!productTypeCategoriesEnabled"
      :label="$t('POS.SHOP_SETTINGS.STARTPAGE.CAPTION_LABEL')"
    />
    <LegalLabelInput
      v-model="startPage.productTypeCategoryButtonCaption"
      class="sprd-shop-input"
      maxlength="50"
      :disabled="!productTypeCategoriesEnabled"
      :label="$t('POS.SHOP_SETTINGS.STARTPAGE.BUTTON_LABEL')"
    />
    <div
      class="image-type"
      :class="{ 'image-type--disabled': !productTypeCategoriesEnabled }"
    >
      <p class="subsection-title">
        {{ $t('POS.SHOP_SETTINGS.STARTPAGE.PRODUCT_RANGE.PICTURE_STYLE') }}
      </p>
      <ul class="image-type__items">
        <li
          v-for="imageType in imageTypes"
          :key="imageType"
          class="image-type__item"
        >
          <label
            :class="`image-type__label image-type__${imageType} ${
              imageType === startPage.productTypeCategoryImageType
                ? 'image-type--checked'
                : null
            }`"
          >
            <input
              type="radio"
              name="imageType"
              :checked="imageType === startPage.productTypeCategoryImageType"
              @change="onSelectImageType(imageType)"
            />
            <span class="image-type__image"></span>
          </label>
        </li>
      </ul>
    </div>
    <div
      class="categories"
      :class="{ 'categories--disabled': !productTypeCategoriesEnabled }"
    >
      <p class="subsection-title">
        {{ $t('POS.SHOP_SETTINGS.STARTPAGE.PRODUCT_RANGE.SELECTOR.HEADING') }}
      </p>
      <ul class="categories__items">
        <li
          v-for="category in availableProductTypeCategories"
          :key="category.id"
          class="categories__item"
        >
          <Checkbox
            :model-value="productTypeCategoryIds.includes(category.id)"
            :disabled="!productTypeCategoriesEnabled"
            @update:model-value="onSelectCategory(category.id)"
          >
            {{ category.name }}
          </Checkbox>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/checkbox/Checkbox.vue';
import LegalLabelInput from '../../common/LegalLabelInput.vue';
import ToggleSwitch from '@/toggleSwitch/ToggleSwitch.vue';
import { StartPageData } from 'src/app/partnerarea/pos/shop/shopSavable/StartPageData';

const IMAGE_TYPES = [
  'STANDARD', // regular product type or model images
  'NEUTRAL', // special photos stored in cloudinary variant 1
  'MOOD', // special photos stored in cloudinary variant 2
];

const MIN_PRODUCT_TYPE_CATEGORIES = 2;
const MAX_PRODUCT_TYPE_CATEGORIES = 4;

export default {
  name: 'ProductTypeCategory',
  components: {
    Checkbox,
    LegalLabelInput,
    ToggleSwitch,
  },
  mixins: [StartPageData],
  data() {
    this.categorySelectionOrder = []; // not in data as it should not be reactive
    return {
      imageTypes: IMAGE_TYPES,
    };
  },
  computed: {
    productTypeCategoryIds: {
      get() {
        return this.startPage.productTypeCategoryIds || [];
      },
      set(productTypeCategoryIds) {
        return (this.startPage.productTypeCategoryIds = productTypeCategoryIds);
      },
    },
    availableProductTypeCategories() {
      return this.startPage.availableProductTypeCategories || [];
    },
    productTypeCategoriesAvailable() {
      return (
        this.availableProductTypeCategories.length >=
        MIN_PRODUCT_TYPE_CATEGORIES
      );
    },
    productTypeCategoriesEnabled() {
      return (
        this.startPage.productTypeCategoriesEnabled &&
        this.productTypeCategoriesAvailable
      );
    },
  },
  watch: {
    productTypeCategoryIds: {
      handler() {
        const { productTypeCategoryIds } = this;
        const countSelectedCategories = productTypeCategoryIds.length;

        if (countSelectedCategories < MIN_PRODUCT_TYPE_CATEGORIES) {
          const plainProductTypeCategoryIds = [...productTypeCategoryIds];
          const sortedCategoryIds = [...this.categorySelectionOrder]
            .reverse()
            .concat(this.availableProductTypeCategories.map(({ id }) => id));
          for (const categoryId of sortedCategoryIds) {
            if (!plainProductTypeCategoryIds.includes(categoryId)) {
              plainProductTypeCategoryIds.push(categoryId);
              if (
                plainProductTypeCategoryIds.length >=
                MIN_PRODUCT_TYPE_CATEGORIES
              ) {
                break;
              }
            }
          }

          if (plainProductTypeCategoryIds.length > countSelectedCategories) {
            // hack is necessary to re-check the ToggleSwitches
            return this.$nextTick().then(() => {
              this.productTypeCategoryIds = plainProductTypeCategoryIds;
            });
          }
        } else if (countSelectedCategories > MAX_PRODUCT_TYPE_CATEGORIES) {
          // remove categories that are over the max count in the order they have been added (FIFO)
          return this.categorySelectionOrder
            .splice(0, countSelectedCategories - MAX_PRODUCT_TYPE_CATEGORIES)
            .forEach((categoryId) =>
              productTypeCategoryIds.splice(
                productTypeCategoryIds.indexOf(categoryId),
                1,
              ),
            );
        }

        this.categorySelectionOrder = this.categorySelectionOrder.filter((id) =>
          productTypeCategoryIds.includes(id),
        ); // make sure we don't have stale ids in the order array
        productTypeCategoryIds.forEach((id) => {
          if (!this.categorySelectionOrder.includes(id)) {
            this.categorySelectionOrder.push(id);
          }
        });
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.$store.commit('shop/setLivePreview', {
      path: '',
      section: 'sprd-startpage-product-type-categories',
    });
  },
  methods: {
    onToggle(enabled) {
      this.startPage.productTypeCategoriesEnabled = enabled;
    },
    onSelectImageType(selectedImageType) {
      if (this.productTypeCategoriesEnabled) {
        this.startPage.productTypeCategoryImageType = selectedImageType;
      }
    },
    onSelectCategory(categoryId) {
      const productTypeCategoryIds = this.productTypeCategoryIds;
      const categoryIndex = productTypeCategoryIds.indexOf(categoryId);
      const isCategorySelected = categoryIndex !== -1;

      if (isCategorySelected) {
        productTypeCategoryIds.splice(categoryIndex, 1);
      } else {
        // keep the selected categories in the same order as the available categories
        const selectedCategoryIds = productTypeCategoryIds.concat(categoryId);
        this.startPage.productTypeCategoryIds =
          this.availableProductTypeCategories
            .map(({ id }) => id)
            .filter((id) => selectedCategoryIds.includes(id));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../startPageCommon';

.subsection-title {
  font-weight: bold;
}

.image-type {
  margin-top: 30px;
}

.image-type__items {
  display: flex;
}

.image-type__item {
  flex: 1 1 0;
  min-width: 0;

  & + & {
    margin-left: 15px;
  }
}

.image-type__label {
  display: block;
  text-align: center;
  cursor: pointer;

  input[type='radio'] {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
}

.image-type__image {
  display: block;
  margin-bottom: 0.25em;
  padding-top: 100%;
  border-radius: 0.125em;
  box-shadow: inset 0 0 0 1px $grey20;

  .image-type--checked & {
    box-shadow: inset 0 0 0 3px $pa-color-main;
  }

  .image-type__STANDARD & {
    background-image: url('/images/shop_startpage_ptcats_standard.jpg');
    background-size: cover;
  }

  .image-type__NEUTRAL & {
    background-image: url('/images/shop_startpage_ptcats_neutral.jpg');
    background-size: cover;
  }

  .image-type__MOOD & {
    background-image: url('/images/shop_startpage_ptcats_mood.jpg');
    background-size: cover;
  }
}

.image-type--disabled {
  color: $grey20;

  .image-type__image {
    opacity: 0.4;
  }
}

.categories {
  margin-top: 30px;
}

.categories__label {
  display: flex;
}

.categories__item {
  margin-top: 1em;
}

.categories--disabled {
  color: $grey20;
}
</style>
